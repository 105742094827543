import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import NormalBtnLink from "../components/NormalBtnLink";
import { useEffect } from "react";

const Notice = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="noticeContainer">
      {/**Header */}
      <div className="noticeHeaderContainer">
        <Header isHome={false} hide={false} />
      </div>

      {/**content */}
      <div className="noticeContentContainer">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {" "}
          <h2
            style={{ fontWeight: "bold", marginBottom: 32, fontSize: "1.8rem" }}
          >
            {/* REGISTRATION INSTRUCTIONS */}
            {t("notice_title")}
          </h2>
        </div>

        {/**content */}
        <div
          style={{
            width: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "10rem",
          }}
        >
          <p style={{ color: "blue" }}>{t("register_advise")}</p>
        </div>

        <div
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            marginTop: 32,
          }}
        >
          <NormalBtnLink
            title={t("agree_and_register_btn")}
            path="/register/register-form"
          />
        </div>
      </div>
    </div>
  );
};

export default Notice;
