import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useLocation } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import Loading from "../components/Loading";

const publishKey = import.meta.env.VITE_STRIPE_PUBLISH_KEY;
const PreCheckout = () => {
  const nodeEnv: string = publishKey as string;
  const params = useLocation();
  const secret: any = params.state;

  const stripePromise = loadStripe(nodeEnv);

  type StripeTypes = {
    clientSecret: string;
    appearance: {
      theme: "stripe";
      variables: {
        colorPrimary: string;
      };
    };
  };

  const options: StripeTypes = {
    // pass the client secret
    clientSecret: secret.client_secret,
    // Fully customizable with appearance API.
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: "#fecf45",
      },
    },
  };
  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      {!secret && <Loading />}
      {secret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
};

export default PreCheckout;
