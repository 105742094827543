const getAvailableToWho = (data: string) => {
  let user = "学生和老师";

  if (data === "student") {
    user = "student";
  } else if (data === "teacher") {
    user = "teacher";
  } else if (data === "school") {
    user = "school_user";
  } else {
    user = "student_teacher";
  }

  return user;
};

export default getAvailableToWho;
