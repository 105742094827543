import { useState } from "react";
import { useTranslation } from "react-i18next";
import TitleComponent from "./TitleComponent";
interface Props {
  type: Number;
}
const StoreFaqItem = ({ type }: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const titles = [
    `1.${t("mearningful_store_title")}`,
    `2.${t("sutable_for_what_store_title")}`,
    `3.${t("which_kind_of_store_not_allowed_to_join_school_glory_title")}`,
    `4.${t("what_store_user_can_do_title")}`,
    `5.${t("what_datas_store_user_needs_title")}`,
    `6.${t("what_school_glory_will_offer_for_store_user_title")}`,
    `7.${t("how_can_store_user_check_if_applier_met_require_title")}`,
    `8.${t("how_can_store_user_sort_title")}`,
    `9.${t("how_to_calculate_reward_amount_title")}`,
    `10.${t("steps_for_store_users_title")}`,
    `11.${t("how_to_modify_reward_title")}`,
    `12.${t("how_to_withdraw_from_display_title")}`,
    `13.${t("how_to_change_payment_method_title")}`,
    `14.${t("how_to_change_password_title_store")}`,
    `15.${t("language_suggetion_title")}`,
    `16.${t("if_student_teaching_staff_allowed_to_re_redeem_rewards")}`,
  ];

  //1.奖励商店的意义
  const content_1 = [t("mearningful_store_content")];

  //2.适合什么样的商家加入？
  const content_2 = [
    t("sutable_for_what_store_content_main_1"),
    t("sutable_for_what_store_content_main_2"),
    t("sutable_for_what_store_content_1"),
    t("sutable_for_what_store_content_2"),
    t("sutable_for_what_store_content_last"),
  ];

  //3.什么行业不允许加入？
  const content_3 = [
    t("which_kind_of_store_not_allowed_to_join_school_glory_content_main"),
    t("which_kind_of_store_not_allowed_to_join_school_glory_content_1"),
    t("which_kind_of_store_not_allowed_to_join_school_glory_content_2"),
    t("which_kind_of_store_not_allowed_to_join_school_glory_content_3"),
  ];

  //4.奖励商店的商家可以做什么？
  const content_4 = [
    t("what_store_user_can_do_content_1"),
    t("what_store_user_can_do_content_2"),
    t("what_store_user_can_do_content_3"),
    t("what_store_user_can_do_content_4"),
  ];

  //5.所需要的资料是什么？
  const content_5 = [
    t("what_datas_store_user_needs_content_1"),
    t("what_datas_store_user_needs_content_2"),
    t("what_datas_store_user_needs_content_3"),
    t("what_datas_store_user_needs_content_4"),
    t("what_datas_store_user_needs_content_5"),
    t("what_datas_store_user_needs_content_6"),
    t("what_datas_store_user_needs_content_7"),
    t("what_datas_store_user_needs_content_8"),
    t("what_datas_store_user_needs_content_9"),
    t("what_datas_store_user_needs_content_10"),
    t("what_datas_store_user_needs_content_11"),
    t("what_datas_store_user_needs_content_12"),
  ];

  //6.平台提供什么使用渠道？
  const content_6 = [
    t("what_school_glory_will_offer_for_store_user_content_1"),
    t("what_school_glory_will_offer_for_store_user_content_2"),
  ];

  //7.商家如何判断学生或教师是否符合奖励规定？
  const content_7 = [
    t("how_can_store_user_check_if_applier_met_require_content_1"),
    t("how_can_store_user_check_if_applier_met_require_content_2"),
  ];

  //8.奖励商城首页的默认排列顺序是依照什么排列的？
  const content_8 = [
    t("how_can_store_user_sort_content_1"),
    t("how_can_store_user_sort_content_2"),
    t("how_can_store_user_sort_content_3"),
    t("how_can_store_user_sort_content_4"),
  ];

  //9.学生的奖励分数是如何来的？
  const content_9 = [
    t("how_to_calculate_reward_amount_content_positive_main"),
    t("how_to_calculate_reward_amount_content_positive_main_1"),
    t("how_to_calculate_reward_amount_content_1"),
    t("how_to_calculate_reward_amount_content_2"),
    t("how_to_calculate_reward_amount_content_3"),
    t("how_to_calculate_reward_amount_content_4"),
    t("how_to_calculate_reward_amount_content_negative_main"),
    t("how_to_calculate_reward_amount_content_1_negative"),
    t("how_to_calculate_reward_amount_content_2_negative"),
    t("how_to_calculate_reward_amount_content_3_negative"),
    t("how_to_calculate_reward_amount_content_4_negative"),
    t("how_to_calculate_reward_amount_content_last"),
  ];

  //10.商家加入奖励商城的步骤：
  const content_10 = [
    t("steps_for_store_users_content_title_1"),
    t("steps_for_store_users_content_1"),
    t("steps_for_store_users_content_title_2"),
    t("steps_for_store_users_content_2"),
    t("steps_for_store_users_content_title_3"),
    t("steps_for_store_users_content_3"),
    t("steps_for_store_users_content_title_4"),
    t("steps_for_store_users_content_4"),
    t("steps_for_store_users_content_title_5"),
    t("steps_for_store_users_content_5"),
    t("steps_for_store_users_content_last"),
  ];

  //11.如何修改奖励内容?
  const content_11 = [
    t("how_to_modify_reward_content_1"),
    t("how_to_modify_reward_content_2"),
  ];

  //12.如何暂时撤下奖励商城?
  const content_12 = [
    t("how_to_withdraw_from_display_content_main"),
    t("how_to_withdraw_from_display_content_1"),
    t("how_to_withdraw_from_display_content_2"),
  ];

  //13.如何更换付款方式?
  const content_13 = [
    t("how_to_change_payment_method_content_main"),
    t("how_to_change_payment_method_content_1"),
    t("how_to_change_payment_method_content_2"),
  ];

  //14.如何更换密码?
  const content_14 = [
    t("how_to_change_password_content_1"),
    t("how_to_change_password_content_2_store"),
    t("how_to_change_password_content_3"),
    t("how_to_change_password_content_4"),
    t("how_to_change_password_content_5"),
    t("how_to_change_password_content_6"),
  ];

  //填写资料建议使用哪种语言？
  const content_17 = [t("language_suggestion_content")];

  //学生用户、教职人员兑换过奖励后是否还能再兑换奖励？
  const content_19 = [
    t("if_student_teaching_staff_allowed_to_re_redeem_rewards_content"),
  ];

  return (
    <div>
      {type === 1 && (
        <>
          <TitleComponent
            title={titles[0]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_1[0]}</p>
            </div>
          )}
        </>
      )}
      {/**2.适合什么样的商家加入？*/}
      {type === 2 && (
        <>
          <TitleComponent
            title={titles[1]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_2[0]}</p>
              <p className="faq_content">{content_2[1]}</p>
              <p className="faq_content">{content_2[2]}</p>
              <p className="faq_content">{content_2[3]}</p>
              <p className="faq_content">{content_2[4]}</p>
            </div>
          )}
        </>
      )}
      {/**3.什么行业不允许加入？*/}
      {type === 3 && (
        <>
          <TitleComponent
            title={titles[2]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_3[0]}</p>
              <p className="faq_content">{content_3[1]}</p>
              <p className="faq_content">{content_3[2]}</p>
              <p className="faq_content">{content_3[3]}</p>
              <p className="faq_content">{content_3[4]}</p>
            </div>
          )}
        </>
      )}
      {/**4.奖励商店的商家可以做什么？*/}
      {type === 4 && (
        <>
          <TitleComponent
            title={titles[3]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_4[0]}</p>
              <p className="faq_content">{content_4[1]}</p>
              <p className="faq_content">{content_4[2]}</p>
              <p className="faq_content">{content_4[3]}</p>
            </div>
          )}
        </>
      )}
      {/**5.所需要的资料是什么？*/}
      {type === 5 && (
        <>
          <TitleComponent
            title={titles[4]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_5[0]}</p>
              <p className="faq_content">{content_5[1]}</p>
              <p className="faq_content">{content_5[2]}</p>
              <p className="faq_content">{content_5[3]}</p>
              <p className="faq_content">{content_5[4]}</p>
              <p className="faq_content">{content_5[5]}</p>
              <p className="faq_content">{content_5[6]}</p>
              <p className="faq_content">{content_5[7]}</p>
              <p className="faq_content">{content_5[8]}</p>
              <p className="faq_content">{content_5[9]}</p>
              <p className="faq_content">{content_5[10]}</p>
            </div>
          )}
        </>
      )}
      {/**6.平台提供什么使用渠道？*/}
      {type === 6 && (
        <>
          <TitleComponent
            title={titles[5]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_6[0]}</p>
              <p className="faq_content">{content_6[1]}</p>
            </div>
          )}
        </>
      )}
      {/**7.商家如何判断学生或教师是否符合奖励规定？*/}
      {type === 7 && (
        <>
          <TitleComponent
            title={titles[6]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_7[0]}</p>
              <p className="faq_content">{content_7[1]}</p>
            </div>
          )}
        </>
      )}
      {/**8.奖励商城首页的默认排列顺序是依照什么排列的？*/}
      {type === 8 && (
        <>
          <TitleComponent
            title={titles[7]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_8[0]}</p>
              <p className="faq_content">{content_8[1]}</p>
              <p className="faq_content">{content_8[2]}</p>
              <p className="faq_content">{content_8[3]}</p>
            </div>
          )}
        </>
      )}
      {/**9.学生的奖励分数是如何来的？*/}
      {type === 9 && (
        <>
          <TitleComponent
            title={titles[8]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_9[0]}</p>
              <p className="faq_content">{content_9[1]}</p>
              <p className="faq_content">{content_9[2]}</p>
              <p className="faq_content">{content_9[3]}</p>
              <p className="faq_content">{content_9[4]}</p>
              <p className="faq_content">{content_9[5]}</p>
              <p className="faq_content">{content_9[6]}</p>
              <p className="faq_content">{content_9[7]}</p>
              <p className="faq_content">{content_9[8]}</p>
              <p className="faq_content">{content_9[9]}</p>
              <p className="faq_content">{content_9[10]}</p>
              <p className="faq_content">{content_9[11]}</p>
            </div>
          )}
        </>
      )}
      {/**10.商家加入奖励商城的步骤*/}
      {type === 10 && (
        <>
          <TitleComponent
            title={titles[9]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content" style={{ fontWeight: "bold" }}>
                {content_10[0]}
              </p>
              <p className="faq_content">{content_10[1]}</p>
              <p className="faq_content" style={{ fontWeight: "bold" }}>
                {content_10[2]}
              </p>
              <p className="faq_content">{content_10[3]}</p>
              <p className="faq_content" style={{ fontWeight: "bold" }}>
                {content_10[4]}
              </p>
              <p className="faq_content">{content_10[5]}</p>
              <p className="faq_content" style={{ fontWeight: "bold" }}>
                {content_10[6]}
              </p>
              <p className="faq_content">{content_10[7]}</p>
              <p className="faq_content" style={{ fontWeight: "bold" }}>
                {content_10[8]}
              </p>
              <p className="faq_content">{content_10[9]}</p>
              <p className="faq_content">{content_10[10]}</p>
              <p className="faq_content">{content_10[11]}</p>
            </div>
          )}
        </>
      )}
      {/**11.如何修改奖励内容*/}
      {type === 11 && (
        <>
          <TitleComponent
            title={titles[10]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_11[0]}</p>
              <p className="faq_content">{content_11[1]}</p>
            </div>
          )}
        </>
      )}
      {/**12.如何暂时撤下奖励商城*/}
      {type === 12 && (
        <>
          <TitleComponent
            title={titles[11]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_12[0]}</p>
              <p className="faq_content">{content_12[1]}</p>
              <p className="faq_content">{content_12[2]}</p>
            </div>
          )}
        </>
      )}
      {/**13.如何更换付款方式*/}
      {type === 13 && (
        <>
          <TitleComponent
            title={titles[12]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_13[0]}</p>
              <p className="faq_content">{content_13[1]}</p>
              <p className="faq_content">{content_13[2]}</p>
            </div>
          )}
        </>
      )}
      {/**14.如何更换密码*/}
      {type === 14 && (
        <>
          <TitleComponent
            title={titles[13]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_14[0]}</p>
              <p className="faq_content">{content_14[1]}</p>
              <p className="faq_content">{content_14[2]}</p>
              <p className="faq_content">{content_14[3]}</p>
              <p className="faq_content">{content_14[4]}</p>
              <p className="faq_content">{content_14[5]}</p>
            </div>
          )}
        </>
      )}

      {/**15.填写资料建议使用哪种语言？*/}
      {type === 15 && (
        <>
          <TitleComponent
            title={titles[14]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_17[0]}</p>
            </div>
          )}
        </>
      )}

      {/**18.学生用户、教职人员兑换过奖励后是否还能再兑换奖励? */}
      {type === 16 && (
        <>
          <TitleComponent
            title={titles[15]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_19[0]}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StoreFaqItem;
