import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import { useEffect, useState } from "react";
import AuthService from "../../services/service";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";

const StoreStatement = () => {
  const { t } = useTranslation();
  const [statementNow, setStatementNow] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    AuthService.getStoreStatement()
      .then(({ data }) => {
        setStatementNow(data.statement);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  const handleSwitchChange = (checked: boolean) => {
    // 根据开关状态设置 statementNow 的值
    setStatementNow(checked ? "all-good" : "已移除");
    if (statementNow === "all-good") {
      AuthService.inactivePage()
        .then()
        .catch((e) => {
          setError(e);
          setStatementNow("all-good");
        });
    } else {
      AuthService.activePage()
        .then()
        .catch((e) => {
          setError(e);
          setStatementNow("已删除");
        });
    }
  };
  return (
    <CommonPageWithHeader loading={loading} error={error}>
      <div className="normalCenterContainerColumn">
        {statementNow === "all-good" ? (
          <div className="normalCenterContainerColumn">
            <p style={{ fontSize: "1.2rem" }}>
              {/* 在首页展示中... */}
              {t("displaying_in_homepage")}
            </p>
            {/**switch 为check的状态 */}
            <Switch
              onChange={handleSwitchChange}
              checked={true} // 当 statementNow 为 "all-good" 时设置为 checked
            />
          </div>
        ) : (
          <div className="normalCenterContainerColumn">
            <p style={{ fontSize: "1.2rem" }}>
              {/* 已不在首页中展示... */}
              {t("not_displaying_in_homepage")}
            </p>
            {/**switch 为uncheck的状态 */}
            <Switch
              onChange={handleSwitchChange}
              checked={false} // 当 statementNow 不是 "all-good" 时设置为 unchecked
            />
          </div>
        )}
      </div>
    </CommonPageWithHeader>
  );
};

export default StoreStatement;
