import { atom } from "jotai";

export interface CommonUserType {
  userType: string;
}

export interface Teacher {
  name?: string;
  image?: string;
  active?: boolean;
  userType: string;
}

export interface Reward {
  applyUserId: string;
  userType: string;
  storeId: string;
  type: string;
  chooseOnlinePlatform: string;
  approved: boolean;
  time: string;
}

export interface Store {
  _id?: string;
  publicId?: string;
  tempRegisterId?: string;
  cus_id?: string;
  name?: string;
  icon: string;
  image?: string;
  bio?: string;
  rewardContent?: string;
  rewardPointsRequirement?: string;
  type?: string;
  productType?: string;
  availableForWho?: string;
  platformName?: string;
  platformLink?: string;
  active?: boolean;
  statement?: string;
  userType?: string;
  region?: string;
  rewardedAmount?: string;
  rewardValidTill?: string;
  getMode?: string;
  good?: number;
  neutral?: number;
  negative?: number;
  activeMode?: boolean;
  rewardValid?: boolean;
  minAge?: number;
  maxAge?: number;
}

export interface Comment {
  _id: string;
  owner: ApplyRewardHistory;
  comment: string;
  userType: string;
  time: string;
  rate: string;
}

export interface TempToken {
  temp: string;
}

export interface User {
  votedSchools: School[];
  name: string;
  image: string;
  role: string;
}

export interface AllSchoolsNum {
  schools: School[];
}

export interface StudentVerify {
  _id: string;
  studentName: string;
  studentEmail: string;
  validDate: string;
  validYear: number;
}

export interface RewardPunishItem {
  _id: string;
  owner: string; // schoolself
  type: string; //punish , reward
  point: number;
}

export interface RewardPunishmentRule {
  _id: string;
  index?: number;
  type: string; //punish , reward
  content: string;
  point: number;
  selft_added?: boolean;
}

export interface School {
  _id?: string;
  name?: string;
  joinDate?: string;
  icon?: string;
  email?: string;
  region?: string;
  ranking?: number;
  type?: string;
  publicId?: string;
  address?: string;
  votes?: number;
  votedBy?: User[];
  statement?: string;
  active?: boolean;
  heros?: Hero[];
  studentsVerify?: StudentVerify[];
  receivedReports?: Report[];
  events?: Event[];
  rules?: [
    { reward: RewardPunishItem[]; range: string },
    { punish: RewardPunishItem[]; range: string }
  ];
}

export interface Hero {
  _id: string;
  image: string;
  heroName: string;
  schoolId: any;
  studentEmail: string;
  time: string;
  validDate: string;
  validYear: number;
  info: string;
  events: Event[];
  reports: Report[];
  rewards?: Reward[];
}

export interface Event {
  _id: string;
  studentId: Hero;
  belongSchool: string;
  type: string; //punishment or reward
  reason: string;
  time: string;
  statement?: boolean;
  points: number;
  solvedTime?: string;
  baseOn?: string;
}

export interface Report {
  _id: string;
  userId?: Hero;
  schoolId: string; //school's id
  reportContent: string; //report content
  state: boolean; // resolve or not
  time: string; // report date
  image: string;
  studentEmail: string;
}

export interface ApplyRewardHistory {
  _id: string;
  applyUserId: string;
  userType: string; //school; teacher ;student
  storeId?: Store;
  type: string;
  approved: false; // report date
  time: string;
  approvedTime?: string;
  comment?: Comment;
}
const applyRewardHistoryAtom = atom<ApplyRewardHistory>({
  _id: "",
  applyUserId: "",
  userType: "", //school; teacher ;student
  type: "",
  approved: false, // report date
  time: "",
  comment: {
    _id: "",
    owner: {
      _id: "",
      applyUserId: "",
      userType: "", //school; teacher ;student
      type: "",
      approved: false, // report date
      time: "",
    },
    comment: "",
    userType: "",
    time: "",
    rate: "",
  },
});
const rewardAtom = atom<Reward>({
  applyUserId: "",
  userType: "",
  storeId: "",
  type: "",
  chooseOnlinePlatform: "",
  approved: false,
  time: "",
});

const teacherAtom = atom<Teacher>({
  name: "",
  image: "",
  userType: "",
});

const storeAtom = atom<Store>({
  name: "",
  icon: "",
  userType: "",

  // image: "",
  // bio: "",
  // rewardContent: "",
  // rewardPointsRequirement: "",
  // type: "",
  // productType: "",
  // availableForWho: "",
  // platformName: "",
  // platformLink: "",
  // active: false,
  // statement: "",
});

const commentAtom = atom<Comment>({
  _id: "",
  owner: {
    _id: "",
    applyUserId: "",
    userType: "", //school; teacher ;student
    type: "",
    approved: false, // report date
    time: "",
  },
  comment: "",
  userType: "",
  time: "",
  rate: "",
});
const commonUserTypeAtom = atom<CommonUserType>({
  userType: "",
});
const rewardPunishmentRuleAtom = atom<RewardPunishmentRule>({
  _id: "",
  index: 1,
  type: "", //punish , reward
  content: "",
  point: 1,
  selft_added: false,
});

const reportAtom = atom<Report>({
  _id: "",
  schoolId: "", //school's id
  reportContent: "", //report content
  state: false, // resolve or not
  time: "", // report date
  image: "",
  studentEmail: "",
});

const eventAtom = atom<Event>({
  _id: "",
  studentId: {
    _id: "",
    image: "",
    heroName: "",
    schoolId: "",
    studentEmail: "",
    time: "",
    validDate: "",
    validYear: 0,
    info: "",
    events: [],
    reports: [],
  },
  belongSchool: "",
  type: "",
  reason: "",
  time: "",
  statement: false,
  points: 0,
  solvedTime: "",
  baseOn: "",
});

const studentHeroAtom = atom<Hero>({
  _id: "",
  image: "",
  heroName: "",
  schoolId: "",
  studentEmail: "",
  time: "",
  validDate: "",
  validYear: 0,
  info: "",
  events: [],
  reports: [],
});
const tempTokenAtom = atom<TempToken>({
  temp: "",
});

const schoolAtom = atom<School>({
  _id: "",
  name: "",
  joinDate: "",
  icon: "",
  email: "",
  region: "",
  type: "",
  publicId: "",
  ranking: 0,
  votes: 0,
  votedBy: [],
  address: "",
  statement: "",
  active: false,
  heros: [],
  studentsVerify: [],
  receivedReports: [],
  events: [],
});

const allSchoolsAtom = atom<AllSchoolsNum>({ schools: [] });

const userAtom = atom<User>({
  name: "",
  image: "",
  votedSchools: [],
  role: "",
});

export {
  schoolAtom,
  userAtom,
  tempTokenAtom,
  allSchoolsAtom,
  studentHeroAtom,
  eventAtom,
  reportAtom,
  storeAtom,
  rewardPunishmentRuleAtom,
  rewardAtom,
  commonUserTypeAtom,
  teacherAtom,
  applyRewardHistoryAtom,
  commentAtom,
};
