import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { useEffect, useRef, useState } from "react";
import ReportItem from "../components/report/ReportItem";
import AuthService from "../services/service";
import { Report } from "../datas/atoms";
import { useTranslation } from "react-i18next";

const AwaitingSolveReports = () => {
  const { t } = useTranslation();
  // const [selectedTab, setSelectedTab] = useState("all");
  const selectedTabRef = useRef("all");
  const [allDatas, setAllDatas] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [pendingNum, setPendingNum] = useState(0);
  const [completedNum, setCompletedNum] = useState(0);
  const handleTabClick = (tab: string) => {
    // setSelectedTab(tab);
    filterData(tab);
    selectedTabRef.current = tab;
  };
  const filterData = (tab: string) => {
    if (tab === "all") {
      setFilteredDatas(allDatas);
    } else if (tab === "pending") {
      const pendingReports = allDatas.filter(
        (item: Report) => item.state === false
      );
      setFilteredDatas(() => pendingReports);
      setPendingNum(pendingReports.length);
    } else if (tab === "completed") {
      const completedReports = allDatas.filter(
        (item: Report) => item.state === true
      );
      setFilteredDatas(() => completedReports);
      setCompletedNum(completedReports.length);
    }
  };
  const renderTab = (tabName: string) => (
    //当点击全部的时候，filterd为所有代码，当点击pending的时候，
    <div
      key={tabName}
      className={`tab ${selectedTabRef.current === tabName ? "selected" : ""}`}
      onClick={() => handleTabClick(tabName)}
    >
      {tabName === "all" && (
        <p>
          {/* 全部 */}
          {t("all")}（{allDatas.length}）
        </p>
      )}
      {tabName === "pending" && (
        <p>
          {/* 未处理 */}
          {t("undeal")}（{pendingNum}）
        </p>
      )}
      {tabName === "completed" && (
        <p>
          {/* 已处理 */}
          {t("dealed")}（{completedNum}）
        </p>
      )}
    </div>
  );

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    setLoading(true);
    AuthService.getAllReportsOfSchool()
      .then(({ data }) => {
        setAllDatas(data.reports);
        setFilteredDatas(data.reports);
        console.log("收到：", data.reports);
        setPendingNum(data.pending);
        setCompletedNum(data.completed);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  const onPressStateChange = (value: string, mode: string) => {
    setLoading(true);
    AuthService.updateReportState(value)
      .then(() => {
        initData();
        updateTab(mode);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
    console.log(`现在的value:${value};mode:${mode}`);
  };

  const updateTab = (mode: string) => {
    if (mode === "pending") {
      handleTabClick("completed");
    } else if (mode === "completed") {
      handleTabClick("pending");
    } else {
      handleTabClick("all");
    }
  };
  return (
    <CommonPageWithHeader
      loading={loading}
      error={error}
      explain={true}
      type={4}
    >
      <div className="normalContainerColumn">
        <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          {t("reported_amount")}
        </p>
        {/**title */}
        <div className="tabContainer">
          {renderTab("all")}
          {renderTab("pending")}
          {renderTab("completed")}
        </div>
        {/**list */}

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ol className="olContainer">
            {filteredDatas.map((item: Report, index) => (
              <li key={index}>
                <ReportItem
                  _id={item._id}
                  userId={item!.userId!._id}
                  schoolId={item.schoolId}
                  reportContent={item.reportContent}
                  state={item.state}
                  time={item.time}
                  image={item.image}
                  studentEmail={item.studentEmail}
                  studentName={item!.userId!.heroName}
                  studentInfo={item!.userId!.info}
                  userImg={item!.userId!.image}
                  onPressStateChange={onPressStateChange}
                  mode={selectedTabRef.current}
                />
              </li>
            ))}
          </ol>
        </div>
      </div>
    </CommonPageWithHeader>
  );
};

export default AwaitingSolveReports;
