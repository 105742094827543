import CommonPageWithHeader from "../../components/CommonPageWithHeader";

import { useState, useRef, useEffect } from "react";
import { PiMedalFill } from "react-icons/pi";

import AuthService from "../../services/service";
import { useTranslation } from "react-i18next";
import TexteraNomal from "../../components/store/TexteraNomal";
// import data from "../../datas/en.json";
// import { FaLocationDot } from "react-icons/fa6";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import { AiOutlineCloseCircle } from "react-icons/ai";
import getUserLocale from "get-user-locale";

const IMG_SIZE = 150;
const LOGO_SIZE = 100;

const UploadInfoStore = () => {
  const { t } = useTranslation();
  //3.什么行业不允许加入？
  const content_3 = [
    t("which_kind_of_store_not_allowed_to_join_school_glory_content_main"),
    t("which_kind_of_store_not_allowed_to_join_school_glory_content_1"),
    t("which_kind_of_store_not_allowed_to_join_school_glory_content_2"),
    t("which_kind_of_store_not_allowed_to_join_school_glory_content_3"),
  ];
  // 可选的电商主营产品列表
  // const products = [
  //   "餐具",
  //   "床上用品",
  //   "服装",
  //   "电子产品",
  //   "家具",
  //   "食品",
  //   "化妆品",
  //   "运动用品",
  //   "图书",
  //   "珠宝首饰",
  //   "生鲜市场",
  //   "大型超市",
  //   "学习用品",
  //   "房地产",
  //   "餐厅",
  //   "旅游景点",
  //   "游乐园",
  //   "名人（艺人,体育明星,政治人物，作家,导演，世界500强企业家)",
  //   "电影制片",
  //   "体育俱乐部",
  //   "新闻报社",
  //   "教育",
  //   "酒店",
  //   "旅行社",
  //   "银行",
  //   "交通",
  //   "加油站",
  // ];

  const products = [
    { id: "amusement_parks", name: t("amusement_parks") },
    { id: "banks", name: t("banks") },
    { id: "bedding", name: t("bedding") },
    { id: "books", name: t("books") },
    { id: "car", name: t("car") },
    { id: "celebrities", name: t("celebrities") },
    { id: "clothing", name: t("clothing") },
    { id: "cosmetics", name: t("cosmetics") },
    { id: "education", name: t("education") },
    { id: "electronics", name: t("electronics") },
    { id: "film_production", name: t("film_production") },
    { id: "food", name: t("food") },
    { id: "fresh_produce_market", name: t("fresh_produce_market") },
    { id: "furniture", name: t("furniture") },
    { id: "game", name: t("game") },
    { id: "gas_stations", name: t("gas_stations") },
    { id: "hotels", name: t("hotels") },
    { id: "influencer", name: t("influencer") },
    { id: "jewelry", name: t("jewelry") },
    { id: "news_agencies", name: t("news_agencies") },
    { id: "real_estate", name: t("real_estate") },
    { id: "restaurants", name: t("restaurants") },
    { id: "school_supplies", name: t("school_supplies") },
    { id: "sporting_goods", name: t("sporting_goods") },
    { id: "sports_clubs", name: t("sports_clubs") },
    { id: "study_app", name: t("study_app") },
    { id: "supermarket", name: t("supermarket") },
    { id: "tableware", name: t("tableware") },
    { id: "tourist_attractions", name: t("tourist_attractions") },
    { id: "transportation", name: t("transportation") },
    { id: "travel_agencies", name: t("travel_agencies") },
    { id: "other", name: t("other") },
  ];

  const storeType = [
    { id: "airbnb", value: "Airbnb" },
    { id: "aliexpress", value: "Aliexpress" },
    { id: "amazon", value: "Amazon" },
    { id: "ebay", value: "Ebay" },
    { id: "facebook", value: "Facebook" },
    { id: "flipkart", value: "Flipkart" },
    { id: "tnstagram", value: "Instagram" },
    { id: "jd", value: "JD" },
    { id: "lazada", value: "Lazada" },
    { id: "shopify", value: "Shopify" },
    { id: "taobao", value: "Taobao" },
    { id: "tiktok", value: "Tiktok" },
    { id: "tmall", value: "Tmall" },
    { id: "twitter", value: "Twitter" },
    { id: "twitch", value: "Twitch" },
    { id: "walmart", value: "Walmart" },
    { id: "weibo(微博)", value: "Wei bo(微博)" },
    { id: "wish", value: "Wish" },
    { id: "youtube", value: "Youtube" },
  ];
  type ValuePiece = Date | null;

  type Value = ValuePiece | [ValuePiece, ValuePiece];
  const today = new Date(); // 获取今天的日期
  const tenDaysLater = new Date(today); // 创建一个新日期对象，初始化为今天的日期
  tenDaysLater.setDate(today.getDate() + 15); // 将日期设置为今天的日期加上10天
  const [value, onChange] = useState<Value>(tenDaysLater);

  const imageRef = useRef<HTMLInputElement>(null);
  const iconRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState("");
  const [icon, setIcon] = useState("");
  const [selectedNumber, setSelectedNumber] = useState(1);
  const [selectedMinAge, setSelectedMinAge] = useState(2);
  const [selectedMaxAge, setSelectedMaxAge] = useState(100);
  const [selectedOption, setSelectedOption] = useState("online"); // type
  const [selectedProduct, setSelectedProduct] = useState(""); //productType
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");

  const [bio, setBio] = useState("");
  const [rewardContent, setRewardContent] = useState("");

  const [availableForWho, setAvailableForWho] = useState("student_teacher"); // student ; teacher ;both

  const [platformLink, setPlatformLink] = useState("");
  const [selectedPlatForm, setSelectedPlatForm] = useState(""); //platform name
  const [selectedPlatformId, setSelectedPlatformId] = useState("");
  // const [region, setRegion] = useState("All");

  const [notAllowPop, setNotAllowPop] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Only if choose other type of online store

  const handleImageClick = () => {
    imageRef!.current!.click();
  };

  const handleIconClick = () => {
    iconRef!.current!.click();
  };

  const handleIconChange = (e: any) => {
    const file = e.target.files[0];

    setIcon(URL.createObjectURL(file));
  };
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    setImage(URL.createObjectURL(file));
  };

  const handleNumberChange = (event: any) => {
    const newValue = parseInt(event.target.value, 10);
    setSelectedNumber(newValue);
  };

  const handleMinAgeNumberChange = (event: any) => {
    const newValue = parseInt(event.target.value, 10);
    setSelectedMinAge(newValue);
  };

  const handleMaxAgeNumberChange = (event: any) => {
    const newValue = parseInt(event.target.value, 10);
    setSelectedMaxAge(newValue);
  };

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const handleAvailableForWhoChange = (event: any) => {
    setAvailableForWho(event.target.value);
  };
  const handleProductChange = (event: any) => {
    setSelectedProduct(event.target.value);
  };

  const handleUpload = () => {
    setLoading(true);
    if (
      name !== "" &&
      iconRef!.current!.value !== "" &&
      imageRef!.current!.value !== "" &&
      rewardContent !== "" &&
      selectedProduct !== ""
    ) {
      if (selectedPlatForm.length > 0 && platformLink.length === 0) {
        setLoading(false);
        return setError(t("inComplete"));
      }

      if (selectedPlatForm !== "" && platformLink !== "") {
        const isValid = checkPlatformLinkValidity(
          selectedPlatformId,
          platformLink
        );
        if (!isValid) {
          setLoading(false);
          return setError(t("invalid_url"));
        }
      }
      const formData = new FormData();
      formData.append("name", name);
      formData.append("icon", iconRef!.current!.files![0]);
      formData.append("image", imageRef!.current!.files![0]);
      formData.append("bio", bio);
      formData.append("rewardContent", rewardContent);
      formData.append("rewardPointsRequirement", `${selectedNumber}`);
      formData.append("minAge", `${selectedMinAge}`);
      formData.append("maxAge", `${selectedMaxAge}`);
      formData.append("type", selectedOption);
      formData.append("productType", selectedProduct);
      formData.append("availableForWho", availableForWho);
      // formData.append("region", region);
      formData.append("platformName", selectedPlatForm);
      formData.append("platformLink", platformLink);

      const languageNow = getUserLocale().split("-")[0];
      console.log("现在的Language now:", languageNow);
      formData.append("language", languageNow);
      if (value instanceof Date) {
        formData.append("rewardValidTill", value.toISOString());
      } else if (Array.isArray(value)) {
        // 根据 [ValuePiece, ValuePiece] 的结构创建适当的字符串并附加
        const stringValue = `${value[0]} - ${value[1]}`;
        formData.append("rewardValidTill", stringValue);
      }

      AuthService.createStoreProfile(formData)
        .then(async () => {
          console.log("成功");
          handleCheckoutSession();
        })
        .catch((error) => {
          setLoading(false);
          return setError(error.response.data.message);
        });
    } else {
      setLoading(false);
      return setError(t("inComplete"));
    }
  };

  const handleCheckoutSession = () => {
    AuthService.storeCreateCheckoutSessionForRegister()
      .then(({ data }) => {
        window.location.replace(data.url);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const handleClosePopup = () => {
    setNotAllowPop(false);
  };
  //check if url valid
  const checkPlatformLinkValidity = (
    selectedPlatform: string,
    platformLink: string
  ) => {
    if (selectedPlatform && platformLink) {
      const selectedId = selectedPlatform.toLowerCase();
      const linkContainsId = platformLink.toLowerCase().includes(selectedId);

      return linkContainsId;
    }
    return false;
  };

  // const handlePlatformChange = (event: any) => {
  //   setSelectedPlatForm(event.target.value);
  //   // console.log("现在的selectedPlatform:", event.target.value);
  // };

  const handlePlatformChange = (event: any) => {
    const selectedPlatform = storeType.find(
      (product) => product.value === event.target.value
    );

    setSelectedPlatForm(event.target.value);
    setSelectedPlatformId(selectedPlatform!.id);
  };

  return (
    <CommonPageWithHeader loading={loading} error={error}>
      <h1 className="registerTitleH1">
        {/* 奖励商家注册 */}
        {t("store_register_title")}
        (2/5)
      </h1>
      <div className="storeUserUploadContainer">
        <p style={{ color: "red" }}>
          {/* 请仔细阅读不允予上传的商品 */}
          {t("not_allow_upload_content")}：
          <span
            className="iconHover"
            style={{ color: "blue", textDecoration: "underline" }}
            onClick={() => setNotAllowPop(true)}
          >
            {/* 不允许上传商品 */}
            {t("not_allow_url")}
          </span>
          ，
          {/* 如果您提交的商品或服务违反了上述政策或其他使用规定，我们将无法批准您的申请，且缴纳的费用将不予退还。我们鼓励您在提交前仔细检查您的产品和内容，以确保其合法性和遵守我们的规定。我们的目标是创建一个安全、合法和尊重的奖励商店榜，以确保学生及其家人和老师的权益得到保护，感谢您的支持与配合。 */}
        </p>
        <p className="label">
          *{/* 请上传一张你商店的Logo(最佳展示尺寸：108*108) */}
          {t("your_logo")}
        </p>
        {/**upload image*/}
        <div className="uploadIconContainer" onClick={handleIconClick}>
          <div
            style={{
              width: LOGO_SIZE,
              height: LOGO_SIZE,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(181, 160, 160, 0.377)",
              borderRadius: 15,
              marginTop: 5,
            }}
          >
            {icon !== "" ? (
              <img
                src={icon}
                style={{
                  width: LOGO_SIZE,
                  height: LOGO_SIZE,
                  borderRadius: 15,
                  objectFit: "cover",
                }}
                alt="School Icon"
              />
            ) : (
              <img
                src={"../../images/picture.png"}
                style={{
                  width: LOGO_SIZE,
                  height: LOGO_SIZE,
                  borderRadius: 15,
                  objectFit: "cover",
                }}
                alt="Default Icon"
              />
            )}
          </div>

          <input
            type="file"
            ref={iconRef}
            style={{ display: "none" }}
            onChange={handleIconChange}
          />
        </div>

        <p className="label">
          *{/* 请上传一张你商店的图片(最佳展示尺寸：700x700) */}
          {t("your_store_cover_image")}
        </p>
        {/**upload image*/}
        <div className="uploadIconContainer" onClick={handleImageClick}>
          <div
            style={{
              width: IMG_SIZE,
              height: IMG_SIZE,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(181, 160, 160, 0.377)",
              borderRadius: "1rem",
              marginTop: 5,
            }}
          >
            {image !== "" ? (
              <img
                src={image}
                style={{
                  width: IMG_SIZE,
                  height: IMG_SIZE,
                  borderRadius: "1rem",
                  objectFit: "cover",
                }}
                alt="School Icon"
              />
            ) : (
              <img
                src={"../../images/picture.png"}
                style={{
                  width: IMG_SIZE,
                  height: IMG_SIZE,
                  borderRadius: "1rem",
                  objectFit: "cover",
                }}
                alt="Default Icon"
              />
            )}
          </div>

          <input
            type="file"
            ref={imageRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </div>

        {/*商店名字*/}
        <p className="label">
          *{/* 商店名字 */}
          {t("your_display_name")}
        </p>
        <input
          className="normalInput"
          onChange={(e) => setName(e.target.value)}
        />

        {/*商店简介*/}

        <TexteraNomal
          className="normalInputText"
          name={"other"}
          maxLength={200}
          handleChange={(e) => setBio(e)}
          height={100}
          label={`*${t("store_intro")}`}
        />

        {/*商店简介*/}
        {/* <p className="label">*店主寄语，给予被奖励者的鼓励的话(0/500)</p>
        <textarea className="normalInput" /> */}

        {/*奖励内容*/}
        {/* <p className="label">*奖励内容(0/500)</p> */}
        <TexteraNomal
          className="normalInputText"
          name={"other"}
          maxLength={200}
          handleChange={(e) => setRewardContent(e)}
          height={100}
          label={`*${t("reward_content")}`}
        />
        {/**奖励有效期至 */}
        <>
          <p className="label">
            *{/* 奖励有效期至(设置后可以随时更新，请放心设置) */}
            {t("reward_valid_date")}：
          </p>
          <DatePicker onChange={onChange} value={value} />
        </>

        {/*申请条件*/}
        <p className="label">
          *
          {/* 申请条件（学生的奖励分数需要达到的最少分数。温馨提示：默认排名根据奖励分数由低到高） */}
          {t("reward_condition")}
        </p>
        <div className="storeSelector">
          <PiMedalFill size={"2rem"} color={"#4fd431"} />
          <select value={selectedNumber} onChange={handleNumberChange}>
            {/* 生成1到100的选项 */}
            {Array.from({ length: 100 }, (_, index) => index + 1).map(
              (number) => (
                <option key={number} value={number}>
                  {number}
                </option>
              )
            )}
          </select>
        </div>

        {/*商店类型*/}
        <p className="label">
          *
          {/* 商店类型(温馨提示：实体店默认所有实体店都可以使用您提供的奖励) */}
          {t("store_type")}
        </p>
        <div className="radiosContainer">
          <label>
            <input
              type="radio"
              value="online"
              checked={selectedOption === "online"}
              onChange={handleOptionChange}
              className="radionStyle"
            />
            {/* 网店 */}
            {t("online_store")}
          </label>

          <label>
            <input
              type="radio"
              value="physical"
              checked={selectedOption === "physical"}
              onChange={handleOptionChange}
              className="radionStyle"
            />
            {/* 实体店 */}
            {t("physical_store")}
          </label>

          <label>
            <input
              type="radio"
              value="online_physical"
              checked={selectedOption === "online_physical"}
              onChange={handleOptionChange}
              className="radionStyle"
            />
            {/* 网店+实体店 */}
            {t("online_physical")}
          </label>
        </div>
        {/**适合年龄*/}

        <p className="label">
          *
          {/* 申请条件（学生的奖励分数需要达到的最少分数。温馨提示：默认排名根据奖励分数由低到高） */}
          {t("suitable_age_range")}
        </p>
        <div className="storeSelector">
          {t("min_age")}：
          <select value={selectedMinAge} onChange={handleMinAgeNumberChange}>
            {/* 生成1到100的选项 */}
            {Array.from({ length: 100 }, (_, index) => index + 1).map(
              (number) => (
                <option key={number} value={number}>
                  {number}
                </option>
              )
            )}
          </select>
          {t("max_age")}：
          <select value={selectedMaxAge} onChange={handleMaxAgeNumberChange}>
            {/* 生成1到100的选项 */}
            {Array.from({ length: 100 }, (_, index) => index + 1).map(
              (number) => (
                <option key={number} value={number}>
                  {number}
                </option>
              )
            )}
          </select>
        </div>

        {/*商家主营产品*/}
        <div style={{ padding: "1rem" }} />
        <p className="label">
          {" "}
          *{/* 请选择主营产品 */}
          {t("product_type_title")}
        </p>
        <select
          value={selectedProduct}
          onChange={handleProductChange}
          style={{
            border: "1px solid gray",
            padding: "0.8rem 1rem",
            borderRadius: "1rem",
          }}
        >
          <option value=""></option>
          {products.map((product) => (
            <option key={product.id} value={product.id}>
              {product.name}
            </option>
          ))}
        </select>

        {/*适用申请对象*/}
        <p className="label">
          *{/* 适用申请对象（包含学生的家人，教师本人） */}
          {t("available_to_title")}
        </p>
        <div className="radiosContainer">
          <label>
            <input
              type="radio"
              value="teacher"
              checked={availableForWho === "teacher"}
              onChange={handleAvailableForWhoChange}
              className="radionStyle"
            />
            {t("teacher")}
          </label>

          <label>
            <input
              type="radio"
              value="student"
              checked={availableForWho === "student"}
              onChange={handleAvailableForWhoChange}
              className="radionStyle"
            />
            {t("student")}
          </label>

          <label>
            <input
              type="radio"
              value="student_teacher"
              checked={availableForWho === "student_teacher"}
              onChange={handleAvailableForWhoChange}
              className="radionStyle"
            />
            {/* 教师 + 学生（及家人） */}
            {t("student_teacher")}
          </label>
        </div>

        {/*支持平台*/}
        <>
          {selectedOption !== "physical" && (
            <>
              <p className="label" style={{ marginTop: "2rem" }}>
                *{/* 请选择一个支持的网络平台 */}
                {t("support_platfrom_title")}
              </p>

              <select
                value={selectedPlatForm}
                onChange={handlePlatformChange}
                style={{
                  border: "1px solid gray",
                  padding: "0.8rem 1rem",
                  borderRadius: "1rem",
                }}
              >
                <option value=""></option>
                {storeType.map((product) => (
                  <option key={product.id} value={product.value}>
                    {product.value}
                  </option>
                ))}
              </select>

              {/* {selectedPlatForm === "Other" && (
                <input
                  placeholder={t("enter_platform_name")}
                  className="normalInput"
                  style={{ width: "40%", marginTop: "1rem" }}
                  onChange={(e) => setSelectedPlatForm(e.target.value)}
                />
              )} */}
              {selectedPlatForm !== "" && (
                <>
                  <p style={{ marginTop: "1rem" }} className="label">
                    （*
                    {/* 只能填一个有效的能够链接到您的商店的网址,验证成功后将无法修改，请仔细检查 */}
                    {t("online_store_url_title")}）
                  </p>
                  <input
                    placeholder={t("enter_web_url")}
                    className="normalInput"
                    onChange={(e) => setPlatformLink(e.target.value)}
                  />
                </>
              )}
            </>
          )}
        </>
      </div>

      {/**need to pay for register fee */}
      <button
        type="button"
        className="btn btn-warning"
        style={{
          padding: "1rem 5rem",
          color: "white",
          marginBottom: "3rem",
        }}
        onClick={handleUpload}
      >
        {/* 提交 */}
        {t("submit")}
      </button>
      {notAllowPop && (
        <div className="addHeroComponent">
          <div
            className="addEditHeroContainer"
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            <p className="faq_content">{content_3[0]}</p>
            <div
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                alignItems: "flex-start",
                fontWeight: "bold",
                marginTop: "2rem",
                color: "red",
              }}
            >
              <p className="faq_content">{content_3[1]}</p>
              <p className="faq_content">{content_3[2]}</p>
              <p className="faq_content">{content_3[3]}</p>
              <p className="faq_content">{content_3[4]}</p>
            </div>
          </div>
          <AiOutlineCloseCircle
            size={"3rem"}
            color={"white"}
            style={{ marginTop: "1rem" }}
            onClick={handleClosePopup}
            className="iconHover"
          />
        </div>
      )}
    </CommonPageWithHeader>
  );
};

export default UploadInfoStore;
