const getSchoolPaymentType = (data: string) => {
  let type = "basic";

  if (data === "basic") {
    type = `€${import.meta.env.VITE_SCHOOL_BASE}/`;
  } else if (data === "stander") {
    type = `€${import.meta.env.VITE_SCHOOL_STANDER}/`;
  } else if (data === "pro") {
    type = `€${import.meta.env.VITE_SCHOOL_PRO}/`;
  }

  return type;
};

export default getSchoolPaymentType;
