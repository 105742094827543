import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import StoreItem from "../../components/store/StoreItem";
import AuthService from "../../services/service";
import {
  Store,
  schoolAtom,
  storeAtom,
  studentHeroAtom,
} from "../../datas/atoms";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import getUserType from "../../utils/getUserType";

const Stores = () => {
  const { t } = useTranslation();
  const [showJoinBtn, setShowJoinBtn] = useState(true);
  const [userType, setUserType] = useState("student");
  const [stores, setStores] = useState<Store[]>([]);
  const [school, setSchool] = useAtom(schoolAtom);
  const [store, setStore] = useAtom(storeAtom);
  const [hero, setHero] = useAtom(studentHeroAtom);
  const [userData, setUserData] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [teacherData, setTeacherData] = useState([]);

  const onPressRegisterStore = () => {
    onPressLogout();
    // window.location.href = "/store-register-email";
    window.location.href = "/register/store";
  };

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    AuthService.getAllStores()
      .then(({ data }) => {
        const _data = data.stores.filter(
          (m: Store) => m.getMode === "all-good"
        );
        getUserData();
        console.log("商店收到获取到的data:", data.stores);
        setStores(_data);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  const getUserData = () => {
    const userNow = getUserType();
    setUserType(userNow);
    if (userNow === "student") {
      AuthService.getHeroProfileForStore()
        .then(({ data }) => {
          setUserData(data.extraInfo);
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    }
    if (userNow === "teacher" || userNow === "school") {
      AuthService.getTeacherProfileForStore()
        .then(({ data }) => {
          setTeacherData(data.total);
          setLoading(false);
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    }
  };

  const onPressLogout = () => {
    if (school.name !== "") {
      localStorage.removeItem("newUser");
      const emptySchool = {
        _id: "",
        name: "",
        joinDate: "",
        icon: "",
        email: "",
        // region: "",
        type: "",
        publicId: "",
        ranking: 0,
        votes: 0,
        votedBy: [],
        address: "",
        statement: "",
        active: false,
        heros: [],
        studentsVerify: [],
        receivedReports: [],
        events: [],
      };
      setSchool(emptySchool);
    }

    if (hero.heroName !== "") {
      setHero((users) => ({
        ...users,
        _id: "",
        image: "",
        heroName: "",
        schoolId: "",
        studentEmail: "",
        time: "",
        validDate: "",
        validYear: 0,
        info: "",
        events: [],
        reports: [],
      }));
      localStorage.removeItem("userSelf");
    }

    if (store.name !== "") {
      setStore((users) => ({
        ...users,

        name: "",
        icon: "",
      }));
      localStorage.removeItem("storeUser");
    }
  };
  return (
    <CommonPageWithHeader loading={loading} error={error}>
      {/**Join store button + title */}
      {showJoinBtn && (
        <div className="storeTopContainer">
          <p
            style={{
              color: "white",
              padding: "1rem 2rem",
              textAlign: "center",
            }}
          >
            {/* 每日按申请顺序，上新30个审核通过的商家 */}
            {t("upload_store_tips")}
          </p>
          <button
            type="button"
            className="btn btn-primary applyBtn"
            onClick={onPressRegisterStore}
          >
            {/* 申请入驻奖励商店 */}
            {t("join_stores_btn")}
          </button>
          {/**close  */}
          <AiOutlineCloseCircle
            size={"2rem"}
            color={"white"}
            className="iconHover closeIccon"
            onClick={() => setShowJoinBtn(false)}
          />
        </div>
      )}

      {/**user's record */}
      {/**main component */}
      <div className="storeMainComponent" style={{ width: "100%" }}>
        {/**filter */}

        {/**Searching bar "*/}
        <div className="storeBardContainer">
          {/* <div className="filterContainer ">
            <MdFilterListAlt
              size={"2rem"}
              className="iconHover filterIcon"
              color={"gray"}
            />
            筛选
          </div> */}

          <div className="innerStoreBardContainer">
            {/**input */}
            <input
              placeholder={t("please_enter_store_name")}
              style={{ fontSize: "1.2rem", width: "100%" }}
            />
            {/**search button */}
            <button type="button" className="btn btn-warning storeSearchBtn">
              {t("search")}
            </button>
          </div>
        </div>

        {/**Select store type：记得修改 */}
        {/* <div className="storeTypeContainer">
          <p
            className="filtersContainer iconHover"
            style={{ border: "1px solid gray", borderRadius: "1rem" }}
          >
            默认排序：奖励分数↑
          </p>
          <p
            className="filtersContainer iconHover"
            style={{ border: "1px solid gray", borderRadius: "1rem" }}
          >
            选择支持网店
          </p>
        </div> */}

        {/**List */}
        <div className="storeListOuterContainer">
          {/**user's information */}
          {userType === "teacher" && (
            <div className="importantBar">
              {/* 你的身份：老师 ; 所有符合申请条件 */}
              {t("You_match_id_teacher")}：
              <Link to="/getAbleToReward" state={{ total: teacherData }}>
                {teacherData.length}
              </Link>
            </div>
          )}
          {userType === "school" && (
            <div className="importantBar">
              {t("You_match_id_school")}
              {/* 你的身份：学校账号管理者 ; 所有符合申请条件 */}：
              <Link to="/getAbleToReward" state={{ total: teacherData }}>
                {teacherData.length}
              </Link>
            </div>
          )}
          {userType === "student" && userData && (
            <div className="importantBar" style={{ marginTop: "1.5rem" }}>
              {/* 你的总分 */}
              {t("your_total_points")}：{(userData as any).total_points} -
              {/* 可申请奖励 */}
              {t("able_to_apply")}：
              <Link to="/getAbleToReward" style={{ fontSize: "1rem" }}>
                {(userData as any).able_to_apply_reward_amount}
              </Link>
            </div>
          )}
          {/**store list */}
          {stores.length > 0 ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <ol>
                {stores.map((item, index) => (
                  <li
                    // className={"list-group-item"}
                    onClick={() => {}}
                    key={index}
                  >
                    <StoreItem
                      _id={item._id}
                      name={item.name}
                      icon={item.icon}
                      image={item.image}
                      rewardContent={item.rewardContent}
                      rewardPointsRequirement={item.rewardPointsRequirement}
                      type={item!.type!}
                      productType={item.productType}
                      availableForWho={item!.availableForWho!}
                      platformName={item.platformName}
                      rewardedAmount={item.rewardedAmount}
                      rewardDateTill={item.rewardValidTill}
                      good={item!.good}
                      neutral={item!.neutral}
                      negative={item!.negative}
                      minAge={item!.minAge}
                      maxAge={item!.maxAge}
                    />
                  </li>
                ))}
              </ol>
            </div>
          ) : (
            <div style={{ paddingTop: "3rem" }}>
              ----{t("no_record_yet")}---
            </div>
          )}
        </div>
      </div>
    </CommonPageWithHeader>
  );
};

export default Stores;
