import CommonPageWithHeader from "../components/CommonPageWithHeader";
import SchoolPlanComponent from "../components/SchoolPlanComponent";

const ReceivedBonus = () => {
  return (
    <CommonPageWithHeader>
      <SchoolPlanComponent
        onPressPunishment={() => {}}
        onPressReward={() => {}}
      />
    </CommonPageWithHeader>
  );
};

export default ReceivedBonus;
