import { useTranslation } from "react-i18next";

interface Props {
  number: any;
}
const AlreadyJoin = ({ number }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="alreadyJoinContainer">
      <p>{t("schools_has_joined")}</p>
      <h3 style={{ fontSize: "2.5rem" }}>{number}</h3>
    </div>
  );
};

export default AlreadyJoin;
