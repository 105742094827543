import { useTranslation } from "react-i18next";
import Slogan from "../components/Slogan";
import AlreadyJoin from "../components/AlreadyJoin";
import SearchBar from "../components/SearchBar";
import AuthService from "../services/service";
import { useEffect, useRef, useState } from "react";
import { School, allSchoolsAtom } from "../datas/atoms";
import SchoolItem from "../components/SchoolItem";
import TargetSchool from "../components/TargetSchool";
import { useAtom } from "jotai";

const Home = () => {
  const { t } = useTranslation();

  const [allSchools, setAllSchools] = useState([]);
  const [datas, setDatas] = useState(allSchools);
  const [findOneTarget, setFindOneTarget] = useState(false);
  const [filteredDatas, setFilteredDatas] = useState<School[]>(allSchools);
  const [targetNotFound, setTargetNotFound] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false); // for detect if all data loaded;
  const [selectedSchool, setSelectedSchool] = useState<School>({});
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(-1);
  const [schools, setAllSchoolNum] = useAtom(allSchoolsAtom);

  //For filtering
  const [allSelectedValue, setAllSelectedValue] = useState({
    location: "",
    type: "",
    name: "",
  });

  // const searchValueRef = useRef("");
  const selectedType = useRef("all");
  const selectedLocation = useRef("all");

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    // get all schools

    AuthService.getAllSchools()
      .then(({ data }) => {
        setAllSchools(data.schools);
        setDatas(data.schools);
        setFilteredDatas(data.schools);
        setDataLoaded(true);
        setAllSchoolNum(data.schools);
      })
      .catch((error) => {
        console.log("Erros ：", error.response.data.message);
      });
  };

  //when click back
  const onPressReset = () => {
    initData();
    //重新获取数据
    setDatas(allSchools);
    setFindOneTarget(false);
    setFilteredDatas(allSchools);
    setTargetNotFound(false);
    selectedType.current = "all";
    selectedLocation.current = "all";
  };

  const onPressItem = (value: any) => {
    setSelectedSchool(value);
  };

  const onLocationSelected = (code: string) => {
    //1.当传入的code ==='' && allSelectedValue.name ===''&&allSelectedValue.type==='' : 全部；
    if (
      code === "" &&
      allSelectedValue.name === "" &&
      allSelectedValue.type === ""
    ) {
      setFilteredDatas(allSchools);
    }
    if (code === "") {
      setAllSelectedValue({ ...allSelectedValue, location: "" });
    }
    //2.当传入的code!==''&&allSelectedValue.name===''&&allSelectedValue.type==='' :setAllSelectedValue中的location:code ; 并只搜索与code相一致的;
    if (
      code !== "" &&
      allSelectedValue.name === "" &&
      allSelectedValue.type === ""
    ) {
      setAllSelectedValue({ ...allSelectedValue, location: code });
      const datas = allSchools.filter((m: School) => m.region === code);
      setFilteredDatas(datas);
    }
    //3.当传入的code!==''&&allSelectedValue.name!==''&&allSelectedValue.type==='':setAllSelectedValue中的location:code ;并传入与code和name同时相符的;
    if (
      code !== "" &&
      allSelectedValue.name !== "" &&
      allSelectedValue.type === ""
    ) {
      setAllSelectedValue({ ...allSelectedValue, location: code });
      const datas = allSchools.filter(
        (m: School) =>
          m.region === code &&
          m!.name!.toLowerCase().startsWith(allSelectedValue.name.toLowerCase())
      );
      setFilteredDatas(datas);
    }
    //4.当传入的code!==''&&allSelectedValue.name===''&&allSelectedValue.type!=='':setAllSelectedValue中的location:code ;并传入与code和type同时相符的;
    if (
      code !== "" &&
      allSelectedValue.name === "" &&
      allSelectedValue.type !== ""
    ) {
      setAllSelectedValue({ ...allSelectedValue, location: code });
      const datas = allSchools.filter(
        (m: School) => m.region === code && m.type === allSelectedValue.type
      );
      setFilteredDatas(datas);
    }
  };

  const onTypeSelected = (value: string) => {
    //1.当传入的value ==='' && allSelectedValue.name ===''&&allSelectedValue.location==='' : 全部；
    if (
      value === "" &&
      allSelectedValue.name === "" &&
      allSelectedValue.location === ""
    ) {
      setFilteredDatas(allSchools);
    }
    if (value === "") {
      setAllSelectedValue({ ...allSelectedValue, type: "" });
    }
    //2.当传入的value!==''&&allSelectedValue.name===''&&allSelectedValue.location==='' :setAllSelectedValue中的location:value ; 并只搜索与value相一致的;
    if (
      value !== "" &&
      allSelectedValue.name === "" &&
      allSelectedValue.location === ""
    ) {
      setAllSelectedValue({ ...allSelectedValue, type: value });
      const datas = allSchools.filter((m: School) => m.type === value);
      setFilteredDatas(datas);
    }
    //3.当传入的value!==''&&allSelectedValue.name!==''&&allSelectedValue.location==='':setAllSelectedValue中的location:value ;并传入与value和name同时相符的;
    if (
      value !== "" &&
      allSelectedValue.name !== "" &&
      allSelectedValue.location === ""
    ) {
      setAllSelectedValue({ ...allSelectedValue, type: value });
      const datas = allSchools.filter(
        (m: School) =>
          m.type === value &&
          m!.name!.toLowerCase().startsWith(allSelectedValue.name.toLowerCase())
      );
      setFilteredDatas(datas);
    }
    //4.当传入的value!==''&&allSelectedValue.name===''&&allSelectedValue.location!=='':setAllSelectedValue中的location:value ;并传入与value和location同时相符的;
    if (
      value !== "" &&
      allSelectedValue.name === "" &&
      allSelectedValue.location !== ""
    ) {
      setAllSelectedValue({ ...allSelectedValue, type: value });
      const datas = allSchools.filter(
        (m: School) =>
          m.type === value && m.region === allSelectedValue.location
      );
      setFilteredDatas(datas);
    }
  };

  const handleSearch = (value: string) => {
    setAllSelectedValue({ ...allSelectedValue, name: value });
    const filtered = allSchools.filter((item: School) =>
      item!.name!.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredDatas(filtered);
  };
  const handleButtonClick = (value: any) => {
    if (value.name === "" && value.type === "" && value.location === "") {
      setFilteredDatas(allSchools);
    }
    const datas = allSchools.filter(
      (m: School) =>
        m.name === value.name &&
        m.type === value.type &&
        m.region === value.location
    );
    setFilteredDatas(datas);
  };

  //after user give a like or remove a like ,trigger updated
  const onPresstriggerLike = () => {
    initData();
    console.log(datas[0]);
  };

  return (
    <div className="homeContainer">
      {dataLoaded && !findOneTarget && !targetNotFound && (
        <>
          {/**nav bar */}
          {/**subtitile+ image */}

          <Slogan />

          {/**already join: only when list >=1 */}
          <AlreadyJoin number={allSchools.length} />

          {/**search bar */}
          <SearchBar
            onLocationSelected={onLocationSelected}
            onTypeSelected={onTypeSelected}
            onSearch={handleSearch}
            onButtonClick={handleButtonClick}
          />
          {/**符合条件的个数：（only when the button click）;也就是searchbar的内容不为空的时候出现 */}

          <div className="totalContainer">
            <h1>
              <span style={{ fontWeight: "bold", marginRight: "1rem" }}>
                {" "}
                {t("match_requirement")}
              </span>
              {filteredDatas.length}
            </h1>
          </div>

          {/**search list */}
          <div className="listContainer">
            <ol className="list-group">
              {filteredDatas.map((item, index) => (
                <li
                  className={"list-group-item"}
                  onClick={() => {
                    onPressItem(item);
                    setFindOneTarget(true);
                    setSelectedIndex(item.ranking);
                  }}
                  key={index}
                >
                  <SchoolItem
                    index={item.ranking}
                    name={item.name}
                    flag={item.icon}
                    type={item.type}
                    region={item.region}
                    votes={item.votes}
                    ranking={item.ranking}
                    user={false}
                    allSchoolsNum={schools.schools}
                  />
                </li>
              ))}
            </ol>
          </div>
        </>
      )}

      {dataLoaded && findOneTarget && !targetNotFound && (
        <TargetSchool
          id={selectedSchool._id}
          name={selectedSchool.name}
          type={selectedSchool.type}
          region={selectedSchool.region}
          votes={selectedSchool.votes}
          icon={selectedSchool.icon}
          index={selectedIndex}
          schoolSelf={selectedSchool}
          onPressBack={() => onPressReset()}
          onActiveLike={onPresstriggerLike}
          joinDate={selectedSchool.joinDate}
          address={selectedSchool.address}
          heros={selectedSchool.heros}
        />
      )}
      {dataLoaded && (targetNotFound || filteredDatas.length === 0) && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "3rem",
            textAlign: "center",
            padding: "0 10%",
          }}
        >
          --- {t("no_school_found")}
          {/* 暂时没有找到符合条件的学校 */}
          {/* 其他控件隐藏的逻辑可以放在这里 */}
          {/**这里添加广告 */}
          {/* <h1 style={{ marginTop: 30 }}>广告</h1> */}
        </div>
      )}
    </div>
  );
};

export default Home;
