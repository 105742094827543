{
  /**Allow school user to make a plan for the new  semester*/
}
import { TiStopwatch } from "react-icons/ti";
import { PiTargetBold } from "react-icons/pi";
import { PiMedalBold } from "react-icons/pi";
import { FaRegSadCry } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface Props {
  type: number;
  title: string;
  content?: string;
  school: boolean;
  onPressPop: () => void;
}

const TargetItem = ({ type, title, school, onPressPop }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="targetItemContainer" onClick={onPressPop}>
      {/**icon */}
      {type === 0 && <p>待奖励/总共：10/20</p>}
      {type === 0 && <p>待惩罚/总共：10/20</p>}
      {type === 1 && <TiStopwatch color={"#666"} size={"2rem"} />}
      {type === 2 && <PiTargetBold color={"#666"} size={"2rem"} />}
      {type === 3 && <PiMedalBold color={"green"} size={"2.6rem"} />}
      {type === 4 && <FaRegSadCry color={"red"} size={"2rem"} />}
      {/**explain */}
      {type !== 0 && <p className="targetItemTitle">{title}</p>}
      {/**target */}
      {/* <p className="targinItemContent">{content}</p> */}
      {/**detail*/}

      {school && <p className="clickTitle">编辑</p>}
      {!school && <p className="clickTitle">{t("check_details")}</p>}
    </div>
  );
};

export default TargetItem;
