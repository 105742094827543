import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import About from "./pages/About";
import Login from "./pages/Login";
import Notice from "./pages/Notice";
import RegisterForm from "./pages/RegisterForm";
import RegisterSchoolFormInfo from "./pages/RegisterSchoolFormInfo";
import PreCheckout from "./pages/PreCheckout";
import CheckoutForm from "./pages/CheckoutForm";
import EmailVerify from "./pages/EmailVerify";
import ProtectTempSchool from "./utils/ProtectTempSchool";
import SchoolProfile from "./pages/SchoolProfile";
import ProtectSchool from "./utils/ProtectSchool";
import EditSchoolProfile from "./pages/EditSchoolProfile";
import { useAtom } from "jotai";
import {
  schoolAtom,
  storeAtom,
  studentHeroAtom,
  teacherAtom,
} from "./datas/atoms";
import AuthService from "./services/service";
import LoginEmailSchool from "./pages/LoginEmailSchool";
import RegisterEmailUser from "./pages/RegisterEmailUser";
import ProtectInitUser from "./utils/ProtectInitUser";
import UserUploadInfo from "./pages/UserUploadInfo";
import UserProfile from "./pages/UserProfile";
import LoginUserEmail from "./pages/LoginUserEmail";
import PasswordForgoten from "./pages/PasswordForgoten";
import StoryBehind from "./pages/StoryBehind";
import { useTranslation } from "react-i18next";
import ProtectNewUser from "./utils/ProtectNewUser";
import UsersWhoVoted from "./pages/UsersWhoVoted";
import getUserLocale from "get-user-locale";
import ContactInitial from "./pages/ContactInitial";
import ContactUs from "./pages/ContactUs";
import SchoolProfileHeros from "./pages/SchoolProfileHeros";
import ReceivedBonus from "./pages/ReceivedBonus";
import AwaitingPunishmentAndReward from "./pages/AwaitingPunishment";
import AddRewardRule from "./pages/AddRewardRule";
import AllowEmails from "./pages/AllowEmails";
import AddPunishmentRule from "./pages/AddPunishmentRule";
import UserPasswordForgotten from "./pages/UserPasswordForgotten";
import AwaitingReward from "./pages/AwaitingReward";
import Stores from "./pages/store/stores";
import Pricing from "./pages/Pricing";
import UploadInfoStore from "./pages/store/UploadInfoStore";
import StoreDetails from "./pages/store/StoreDetails";
import StoreOwnerProfile from "./pages/store/StoreOwnerProfile";
import StoreRegister from "./pages/store/StoreRegister";
import StoreEmailVerify from "./pages/store/StoreEmailVerify";
import StoreLogin from "./pages/store/StoreLogin";
import ProtectStoreUser from "./utils/ProtectStoreUser";
import StoreEditProfile from "./pages/store/StoreEditProfile";
import StoreSubStatement from "./pages/store/StoreSubStatement";
import AllowEmailsTeacher from "./pages/AllowEmailsTeacher";
import AwaitingSolveReports from "./pages/AwaitingSolveReports";
import LoginTeacherEmail from "./pages/LoginTeacherEmail";
import TeacherPasswordForgotten from "./pages/TeacherPasswordForgotten";
import GetAbleToRewardList from "./pages/store/GetAbleToRewardList";
import ProtectAll from "./utils/ProtectAll";
import SeachReward from "./pages/store/SeachReward";
import SuccessfullyRewarded from "./pages/store/SuccessfullyRewarded";
import StoreStatement from "./pages/store/StoreStatement";
import StoreFotgetPassword from "./pages/store/StoreFotgetPassword";
import SchoolStatement from "./pages/SchoolStatement";
import SchoolSubStatement from "./pages/SchoolSubStatement";
import RegisterNotice from "./pages/store/RegisterNotice";
import AwaitingComments from "./pages/store/AwaitingComments";
import PaymentProgressStore from "./pages/store/PaymentProgressStore";
import SchoolPaymentProgress from "./pages/schoolPaymentProgress/SchoolPaymentProgress";
import FaqsPage from "./pages/FAQ/FaqsPage";

const App: React.FC = () => {
  const [school, setSchool] = useAtom(schoolAtom);
  const [hero, setHero] = useAtom(studentHeroAtom);
  const [teacher, setTeacher] = useAtom(teacherAtom);
  const { i18n } = useTranslation();
  const [store, setStore] = useAtom(storeAtom);
  const [selectedLanguage] = useState<string>(getUserLocale().split("-")[0]);

  const getTitle = () => {
    return selectedLanguage === "zh" ? "校园荣耀" : "School Glory";
  };
  useEffect(() => {
    // 在选定的语言变化时，设置文档头部的 <title> 标签内容
    document.title = getTitle();
  }, [selectedLanguage]);

  // const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const lang_code = e.target.value;
  //   setSelectedLanguage(lang_code);
  //   i18n.changeLanguage(lang_code);
  // };

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    localStorage.setItem("lang", getUserLocale().split("-")[0]);
    if (
      localStorage.getItem("newUser") &&
      localStorage.getItem("userSelf") &&
      localStorage.getItem("teacherUser") &&
      localStorage.getItem("storeUser")
    ) {
      //同时注册为普通用户，又注册为学校
      localStorage.removeItem("newUser");
      localStorage.removeItem("userSelf");
      localStorage.removeItem("teacherUser");
      localStorage.removeItem("storeUser");
    }

    if (localStorage.getItem("temp") && localStorage.getItem("email")) {
      localStorage.removeItem("temp");
      localStorage.removeItem("email");
    }
    // exists user
    if (localStorage.getItem("userSelf")) {
      // setTokenNow(localStorage.getItem("userSelf"));
      AuthService.getHeroProfile()
        .then(({ data }) => {
          const { user } = data;
          setHero({
            _id: user._id,
            image: user.image,
            heroName: user.heroName,
            schoolId: user.schoolId,
            studentEmail: user.studentEmail,
            time: user.time,
            validDate: user.validDate,
            validYear: user.validYear,
            info: user.info,
            events: user.events,
            reports: user.reports,
          });
        })
        .catch((error) => {
          console.log("error：", error.response.data.message);
          localStorage.removeItem("userSelf");
        });
    }

    // exists school
    if (localStorage.getItem("newUser")) {
      AuthService.getCurrentUser()
        .then(({ data }) => {
          const { schoolData } = data;

          setSchool({
            _id: schoolData._id,
            name: schoolData.name,
            joinDate: schoolData.joinDate,
            icon: schoolData.icon,
            email: schoolData.email,
            region: schoolData.region,
            type: schoolData.type,
            votes: schoolData.votes,
            votedBy: schoolData.votedBy,
            address: schoolData.address,
            statement: schoolData.statement,
            active: schoolData.active,
            ranking: schoolData.ranking,
          });
        })
        .catch((error) => {
          console.log("Error：", error.response.data.message);
        });
    }

    //exists teacher
    if (localStorage.getItem("teacherUser")) {
      AuthService.getTeacherProfile()
        .then(({ data }) => {
          const { teacher } = data;
          setTeacher({
            name: teacher.name,
            image: teacher.image,
            userType: teacher.userType,
          });
        })
        .catch((error) => {
          console.log("Error：", error.response.data.message);
          console.log("现在的teacher:", teacher);
        });
    }

    //exists store
    if (localStorage.getItem("storeUser")) {
      AuthService.getCurrentStoreUser()
        .then(({ data }) => {
          const { storeData } = data;

          setStore({
            name: storeData.name,
            icon: storeData.icon,
            userType: storeData.userType,
          });
        })
        .catch((error) => {
          console.log("Error：", error.response.data.message);
        });
    }
  };

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/register/register-user/email"
        element={<RegisterEmailUser />}
      />
      <Route path="/about" element={<About />} />
      <Route path="/faqs" element={<FaqsPage />} />
      <Route path="/contactInitial" element={<ContactInitial />} />
      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/reward-stores" element={<Stores />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route
        path="/storybehind"
        element={<StoryBehind selectedLanguage={i18n.language} />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/login-email-school" element={<LoginEmailSchool />} />
      <Route path="/login-email-user" element={<LoginUserEmail />} />
      <Route path="/notice" element={<Notice />} />
      <Route path="/register/register-form" element={<RegisterForm />} />
      <Route path="/register/store" element={<RegisterNotice />} />
      <Route path="/login/password-forgotten" element={<PasswordForgoten />} />
      <Route
        path="/store/login/store-password-forgotten"
        element={<StoreFotgetPassword />}
      />
      <Route
        path="/login/user-password-forgotten"
        element={<UserPasswordForgotten />}
      />
      {/*********==========store */}
      <Route path="/store-register-email" element={<StoreRegister />} />
      <Route path="/store-login-email" element={<StoreLogin />} />
      {/**商店需要注册才能到达这里 */}
      <Route
        path="/uploadInfoStore"
        element={
          <ProtectTempSchool>
            <UploadInfoStore />
          </ProtectTempSchool>
        }
      />
      <Route path="/store-email-verify" element={<StoreEmailVerify />} />
      <Route path="/store-details" element={<StoreDetails />} />
      <Route
        path="/store-owner-profile"
        element={
          <ProtectStoreUser>
            <StoreOwnerProfile storeItem={store} />
          </ProtectStoreUser>
        }
      />
      <Route
        path="/store-statement"
        element={
          <ProtectStoreUser>
            <StoreStatement />
          </ProtectStoreUser>
        }
      />
      <Route
        path="/paying-progress-store"
        element={
          <ProtectStoreUser>
            <PaymentProgressStore />
          </ProtectStoreUser>
        }
      />
      <Route
        path="/store-edit-profile"
        element={
          <ProtectStoreUser>
            <StoreEditProfile />
          </ProtectStoreUser>
        }
      />
      <Route
        path="/store-edit-profile/subscription-statement"
        element={
          <ProtectStoreUser>
            <StoreSubStatement />
          </ProtectStoreUser>
        }
      />
      <Route
        path="/store-profile/search-reward"
        element={
          <ProtectStoreUser>
            <SeachReward />
          </ProtectStoreUser>
        }
      />
      {/**如果没有注册，不能到达这个界面 */}
      <Route
        path="/userUploadProfile"
        element={
          <ProtectInitUser>
            <UserUploadInfo />
          </ProtectInitUser>
        }
      />
      {/**如果没有注册，不能到达这个界面 */}
      <Route
        path="/userprofile"
        element={
          <ProtectInitUser>
            <UserProfile heroItem={hero} />
          </ProtectInitUser>
        }
      />
      {/**如果没有注册，不能到达这个界面 */}
      <Route
        path="/successful-rewarded-list"
        element={
          <ProtectAll>
            <SuccessfullyRewarded />
          </ProtectAll>
        }
      />
      {/**如果没有注册，不能到达这个界面:schoolInit */}
      <Route
        path="/register/register-form/register-form-school-info"
        element={
          <ProtectTempSchool>
            <RegisterSchoolFormInfo />
          </ProtectTempSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面:schoolInit */}
      <Route
        path="/register/register-form/register-form-school-info/allHeros"
        element={
          <ProtectSchool>
            <SchoolProfileHeros />
          </ProtectSchool>
        }
      />
      <Route
        path="/school-profile/display-statement"
        element={
          <ProtectSchool>
            <SchoolStatement />
          </ProtectSchool>
        }
      />
      <Route
        path="/school-profile/subscription-statement"
        element={
          <ProtectSchool>
            <SchoolSubStatement />
          </ProtectSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面:receivedBonus */}
      <Route
        path="/register/register-form/register-form-school-info/receivedBonus"
        element={
          <ProtectSchool>
            <ReceivedBonus />
          </ProtectSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面:awaitingPunishment */}
      <Route
        path="/register/register-form/register-form-school-info/awaitingPunishment"
        element={
          <ProtectSchool>
            <AwaitingPunishmentAndReward />
          </ProtectSchool>
        }
      />
      <Route
        path="/register/register-form/register-form-school-info/awaitingReward"
        element={
          <ProtectSchool>
            <AwaitingReward />
          </ProtectSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面:rewardPunishmentRule */}
      <Route
        path="/register/register-form/register-form-school-info/addRewardRule"
        element={
          <ProtectSchool>
            <AddRewardRule />
          </ProtectSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面:rewardPunishmentRule */}
      <Route
        path="/register/register-form/register-form-school-info/addPunishmentRule"
        element={
          <ProtectSchool>
            <AddPunishmentRule />
          </ProtectSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面:allowEmails */}
      <Route
        path="/register/register-form/register-form-school-info/allowEmails"
        element={
          <ProtectSchool>
            <AllowEmails />
          </ProtectSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面:allowEmails */}
      <Route
        path="/register/register-form/register-form-school-info/allowEmailsTeacher"
        element={
          <ProtectSchool>
            <AllowEmailsTeacher />
          </ProtectSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面:schoolInit */}
      <Route
        path="/register/register-form/register-form-school-info/preCheckout"
        element={
          <ProtectTempSchool>
            <PreCheckout />
          </ProtectTempSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面 */}
      <Route
        path="/register/register-form/register-form-school-info/checkout/checkout-form"
        element={
          <ProtectTempSchool>
            <CheckoutForm />
          </ProtectTempSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面 */}
      <Route
        path="/register/register-form/register-form-school-info/checkout/checkout-form/email-verify"
        element={<EmailVerify />}
      />
      {/**如果没有注册，不能到达这个界面,而且不是newUser,是user而已 */}
      <Route
        path="/school-profile"
        element={
          <ProtectSchool>
            <SchoolProfile schoolItem={school} />
          </ProtectSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面,而且不是newUser,是user而已 */}
      <Route
        path="/school-payment-progress"
        element={
          <ProtectSchool>
            <SchoolPaymentProgress />
          </ProtectSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面,而且不是newUser,是user而已 */}
      <Route
        path="/getAbleToReward"
        element={
          <ProtectAll>
            <GetAbleToRewardList />
          </ProtectAll>
        }
      />
      {/**如果没有注册，不能到达这个界面,而且不是newUser,是user而已 */}
      <Route
        path="/awaitingComments"
        element={
          <ProtectAll>
            <AwaitingComments />
          </ProtectAll>
        }
      />
      {/**如果没有注册，不能到达这个界面,而且不是newUser,是user而已 */}
      <Route
        path="/school-profile/reports"
        element={
          <ProtectSchool>
            <AwaitingSolveReports />
          </ProtectSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面 */}
      <Route
        path="/edit-school-profile"
        element={
          <ProtectSchool>
            <EditSchoolProfile />
          </ProtectSchool>
        }
      />
      {/**如果没有注册，不能到达这个界面 */}
      <Route
        path="/userWhoVoted"
        element={
          <ProtectNewUser>
            <UsersWhoVoted />
          </ProtectNewUser>
        }
      />
      //==========================教师=============================
      <Route path="/login-teacher" element={<LoginTeacherEmail />} />
      <Route
        path="/login/teacher-password-forgotten"
        element={<TeacherPasswordForgotten />}
      />
    </Routes>
  );
};

export default App;
