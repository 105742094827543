import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputNormal from "../components/InputNormal";
import { FiEye, FiEyeOff } from "react-icons/fi";
import CommonPageWithHeader from "../components/CommonPageWithHeader";
import AuthService from "../services/service";
import { useAtom } from "jotai";
import { commonUserTypeAtom, teacherAtom } from "../datas/atoms";
import { Link } from "react-router-dom";

const LoginTeacherEmail = () => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useAtom(commonUserTypeAtom);
  const [teacher, setTeacher] = useAtom(teacherAtom);

  useEffect(() => {
    console.log("userType :", userType);
    console.log("teacher:", teacher.userType);
  }, []);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onPressHandleLoginSchool = () => {
    setLoading(true);

    if (email.trim() === "" || password.trim() === "") {
      setError(t("inComplete"));

      return;
    }
    //在用户上传资料的时候，先查看用户是否localestorage有记录
    const token = localStorage.getItem("newUser");
    if (token) {
      localStorage.removeItem("newUser");
    }
    AuthService.teacherLogin(email, password)
      .then(({ data }) => {
        setUserType(data.userInfo.userType);

        localStorage.setItem("teacherUser", data.token);
        // 进行相关的操作，如页面跳转等
        setTeacher({
          image: data.userInfo.image,
          userType: data.userInfo.userType,
        });

        setLoading(false);
        window.location.href = "/";
      })
      .catch((error) => {
        setLoading(false);
        return setError(error.response.data.message);
      });
  };
  return (
    <CommonPageWithHeader error={error} loading={loading}>
      <div className="registerFormContainer">
        {/**Error */}

        <h1 className="registerTitleH1">
          {/* 教职人员登录 */}
          {t("teacher_login_title")}
        </h1>

        {/**all inputs */}
        <div className="allInputsContainer">
          {/**Email */}

          <InputNormal
            placeholder="Email"
            name={"email"}
            className="normalInputContainer"
            handleChange={handleEmailChange}
          />
          {/**Password */}
          <div
            className="form-group"
            style={{ width: "100%", marginBottom: "1.5rem" }}
          >
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                name="password"
                value={password}
                onChange={handlePassword}
                placeholder={t("password")}
                style={{ padding: "1.2rem", color: "#666", fontSize: "1.2rem" }}
              />
              <span
                className="password-toggle-icon"
                onClick={toggleShowPassword}
              >
                {showPassword ? <FiEye /> : <FiEyeOff />}
              </span>
            </div>
          </div>

          <Link
            to={"/login/teacher-password-forgotten"}
            style={{ fontSize: 16, marginTop: 16, textDecoration: "none" }}
          >
            {t("password_forgotten")}
          </Link>

          {/**Submit button */}

          <button
            type="button"
            className="btn btn-warning noticeBtn"
            onClick={onPressHandleLoginSchool}
          >
            {/* 下一步 */}
            {t("process_payment")}
          </button>
        </div>
      </div>
    </CommonPageWithHeader>
  );
};

export default LoginTeacherEmail;
