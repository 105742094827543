import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import { useTranslation } from "react-i18next";
import NormalBtnLink from "../../components/NormalBtnLink";

const RegisterNotice = () => {
  const { t } = useTranslation();
  return (
    <CommonPageWithHeader>
      <div className="noticeContainer">
        {/**content */}
        <div className="noticeContentContainer">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {" "}
            <h2
              style={{
                fontWeight: "bold",
                marginBottom: 32,
                fontSize: "1.8rem",
              }}
            >
              {/* REGISTRATION INSTRUCTIONS */}
              {t("store_register_title")}
            </h2>
          </div>

          {/**content */}
          <div
            style={{
              width: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "10rem",
            }}
          >
            <p style={{ color: "blue" }}>{t("register_advise")}</p>
          </div>

          <div
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              marginTop: 32,
            }}
          >
            <NormalBtnLink
              title={t("agree_and_register_btn")}
              path="/store-register-email"
            />
          </div>
        </div>
      </div>
    </CommonPageWithHeader>
  );
};

export default RegisterNotice;
