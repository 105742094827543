import { useRef, useState } from "react";
import ErrorMsg from "./ErrorMsg";
import { AiOutlineCloseCircle } from "react-icons/ai";
import InputNormal from "./InputNormal";
import Loading from "./Loading";
import successImg from "/images/done.png";
import { useTranslation } from "react-i18next";
import AuthService from "../services/service";

interface Props {
  single: boolean;
  handleClosePopup: () => void;
  selectedName?: string;
  selectedEmail?: string;
  selectedValidDate?: string;
  selectedValidYear?: number;
  userType?: string;
}
const AddEmailsFrom = ({
  single,
  handleClosePopup,
  selectedEmail,
  selectedName,
  selectedValidYear,
  userType,
}: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [name, setName] = useState(selectedName || "");
  // const [email, setEmail] = useState(selectedEmail || "");
  // const [validYear, setValidYear] = useState(selectedValidYear || 0);

  const nameRef = useRef(selectedName || "");
  const emailRef = useRef(selectedEmail || "");
  const validYearRef = useRef(selectedValidYear || 1);

  const [bulkData, setBulkData] = useState([]);

  const handleNameChange = (value: any) => {
    nameRef.current = value.target.value;
  };

  const handleEmailChange = (value: any) => {
    emailRef.current = value.target.value;
  };

  const handleYearChange = (value: any) => {
    console.log("====value:", value);
    validYearRef.current = value;
  };

  //上传
  const handleUploadOneSingleEmail = async () => {
    if (userType === "teacher") {
      if (selectedEmail !== "") {
        //判断selectedEmail是否为email格式，如果不是，返回：return setError("格式有问题，上传失败")
        const isEmailValid = validateEmail(selectedEmail!);
        if (!isEmailValid) {
          return setError("Invalid format, please re-enter.");
        }
        //用于更新
        try {
          setLoading(true);
          const response = await AuthService.updateAllowedTeacherEmail(
            emailRef.current,
            nameRef.current,
            validYearRef.current
          );
          if (response.data.code === 200) {
            setLoading(false);
            setSuccess(true);
          }
        } catch (error) {
          setError("Failed,please retry.");
          console.error(error);
        }
      } else {
        try {
          //判断emailRef.current是否为email格式，如果不是，返回：return setError("格式有问题，上传失败")
          const isEmailValid = validateEmail(emailRef.current);
          if (!isEmailValid) {
            return setError("Invalid format, please re-enter.");
          }
          setLoading(true);

          const response = await AuthService.uploadOneSingleTeacherEmail(
            emailRef.current,
            nameRef.current,
            validYearRef.current
          );

          if (response.data.code === 200) {
            setSuccess(true);
          }
        } catch (error) {
          setError("Failed,please retry.");
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    } else {
      if (selectedEmail !== "") {
        //判断selectedEmail是否为email格式，如果不是，返回：return setError("格式有问题，上传失败")
        const isEmailValid = validateEmail(selectedEmail!);
        if (!isEmailValid) {
          return setError("Invalid format, please re-enter.");
        }
        //用于更新
        try {
          setLoading(true);
          const response = await AuthService.updateAllowedEmail(
            emailRef.current,
            nameRef.current,
            validYearRef.current
          );
          if (response.data.code === 200) {
            setLoading(false);
            setSuccess(true);
          }
        } catch (error) {
          setError("Failed,please retry.");
          console.error(error);
        }
      } else {
        try {
          setLoading(true);
          //判断emailRef.current是否为email格式，如果不是，返回：return setError("格式有问题，上传失败")
          const isEmailValid = validateEmail(emailRef.current);
          if (!isEmailValid) {
            return setError("Invalid format, please re-enter.");
          }

          const response = await AuthService.uploadOneSingleEmail(
            emailRef.current,
            nameRef.current,
            validYearRef.current
          );

          if (response.data.code === 200) {
            setSuccess(true);
          }
        } catch (error) {
          setError("Failed,please retry.");
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  function validateEmail(email: string) {
    // 使用正则表达式来验证邮箱格式
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  // const handleUploadOneSingleEmail = async () => {
  //   console.log(
  //     `nameRef:${nameRef.current};email:${emailRef.current};validYear:${
  //       validYearRef.current
  //     };selectedName==='':${selectedName === ""}`
  //   );
  // };

  const handleBulkDataChange = (value: any) => {
    // 这里假设用户输入的数据是一个字符串，您需要将其转换为数组

    const dataArray = value.target.value.split(";");
    console.log("现在dataArray:", dataArray);
    setBulkData(dataArray);
  };

  const handleUploadBulkEmail = async () => {
    if (userType === "teacher") {
      try {
        setLoading(true);

        const response = await AuthService.uploadBulkTeacherEmails({
          data: bulkData,
        });

        if (response.data.code === 200) {
          setLoading(false);
          setSuccess(true);
        }
      } catch (error) {
        setError("Failed,please retry.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);

        const response = await AuthService.uploadBulkEmails({ data: bulkData });

        if (response.data.code === 200) {
          setLoading(false);
          setSuccess(true);
        }
      } catch (error) {
        setError("Failed,please retry.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <div className="addHeroComponent">
      {/**component */}
      {error !== "" && <ErrorMsg error={error} />}
      <div className="addEditHeroContainer">
        {!loading && !success && (
          <div className="uploadStudentContainer">
            {single && (
              <>
                {" "}
                {/**name */}
                <InputNormal
                  label={t("teacher_name")}
                  name="name"
                  handleChange={handleNameChange}
                  className="inputContainer"
                  placeholder={nameRef.current}
                />
                {/**email */}
                {selectedEmail ? (
                  <p style={{ fontSize: "1.2rem" }}>
                    Email: {emailRef.current}
                  </p>
                ) : (
                  <InputNormal
                    label="Email"
                    name="email"
                    handleChange={handleEmailChange}
                    className="inputContainer"
                    placeholder={emailRef.current}
                  />
                )}
                {/**Valid very */}
                <label
                  className="inputLabel"
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    textAlign: "center",
                  }}
                >
                  {t("student_valid_year")}：
                </label>
                <select
                  style={{ fontSize: "1.5rem" }}
                  onChange={(e) => handleYearChange(e.target.value)}
                >
                  {" "}
                  <option
                    value={validYearRef.current}
                    className="hideSelection"
                  >
                    {validYearRef.current}
                  </option>
                  {selectedValidYear && validYearRef.current && (
                    <option value="0">0</option>
                  )}
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                {/**Upload button */}
                <button
                  type="button"
                  className="btn btn-warning"
                  style={{
                    minWidth: 100,
                    padding: "1rem ",
                    marginTop: "2rem",
                    fontWeight: "bold",
                  }}
                  onClick={handleUploadOneSingleEmail}
                >
                  {t("submit")}
                </button>
              </>
            )}

            {!single && (
              <>
                <div className="form-group">
                  <label>
                    请输入每个学生的名字和Email并使用逗号分开，每个不同学生使用分号隔开，例如Jane,jane@gmail.com,3
                    ; Peter,peter@gmail.com,3
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={10}
                    onChange={(e) => handleBulkDataChange(e)}
                  ></textarea>
                </div>
                {/**Upload button */}
                <button
                  type="button"
                  className="btn btn-warning"
                  style={{
                    minWidth: 100,
                    padding: "1rem ",
                    marginTop: "2rem",
                    fontWeight: "bold",
                  }}
                  onClick={handleUploadBulkEmail}
                >
                  {t("submit")}
                </button>
              </>
            )}
          </div>
        )}
        {loading && !success && <Loading />}
        {success && !loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={successImg} style={{ width: "6rem", height: "6rem" }} />
          </div>
        )}
      </div>
      <AiOutlineCloseCircle
        size={"3rem"}
        color={"white"}
        style={{ marginTop: "1rem" }}
        onClick={handleClosePopup}
        className="iconHover"
      />
    </div>
  );
};

export default AddEmailsFrom;
