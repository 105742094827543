import { useTranslation } from "react-i18next";

interface Props {
  statement: string;
  active?: boolean;
  // onPressChange: () => void;
}

const SchoolProfileVerifyStatement = ({ statement, active }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="SchoolProfileVerifyStatementContainer">
      {statement === "verifying" && !active && (
        <>
          <h2
            style={{
              marginBottom: 16,
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            {/* Verifying... */}
            {t("verifying")}
          </h2>
          <p className="verifyP">
            {/* Using manual review, it will take approximately 7-15 business days.
            Thank you for your patience. */}
            {t("verifying_notice")}
          </p>
        </>
      )}
      {statement === "error" && !active && (
        <>
          <h2
            style={{
              marginBottom: 16,
              marginTop: 10,
              fontWeight: "bold",
              color: "red",
              fontSize: "1.5rem",
            }}
          >
            {/* Verify Failed... */}
            {t("error")}
          </h2>
          <p>{t("verify_error")}</p>
        </>
      )}
      {statement === "success" && active && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Switch onChange={onPressChange} checked={active} /> */}
          <img
            src="./images/done.png"
            style={{ width: "2rem", height: "2rem" }}
          />
          <p style={{ marginTop: 16, fontWeight: "bold", marginLeft: "1rem" }}>
            {/* You have successfully joined School Glory. */}
            {t("verify_success")}
          </p>
        </div>
      )}

      {statement === "success" && !active && (
        <>
          {/* <Switch onChange={onPressChange} checked={!active} /> */}

          <p style={{ marginTop: 16, fontWeight: "bold" }}>
            {/* You have temporarily withdrawn from School Glory. We look forward to
            your return! */}
            {t("withdrawed")}
          </p>
        </>
      )}
    </div>
  );
};

export default SchoolProfileVerifyStatement;
