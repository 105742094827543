import formatJoinDate from "../../utils/formatJoinDate";
import getAvailableToWho from "../../utils/getAvailableToWho";
import getStoreType from "../../utils/getStoreType";
import { PiMedalFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";

interface Props {
  type: string;
  rewardId: string;
  userType: string;
  time: string;
  approved: boolean;
  approvedTime?: string;
  owner?: string;
  onPressUserUseReward?: (value: string) => void;
  onPressStoreReward?: (value: string) => void;
}

const ApplyHistory = ({
  type,
  rewardId,
  userType,
  time,
  approved,
  approvedTime,
  owner,
  onPressUserUseReward,
  onPressStoreReward,
}: Props) => {
  const currentDate = new Date();
  const { t } = useTranslation();

  // 根据不同条件设置按钮内容
  let buttonText = "";
  if (approved) {
    if (currentDate > new Date(time)) {
      // 已过期，已使用
      buttonText = t("rewarded");
    } else {
      // 未过期，已使用
      buttonText = t("rewarded");
    }
  } else {
    if (currentDate > new Date(time)) {
      // 已过期，未使用
      buttonText = t("expired_accounts");
    } else {
      // 未过期，未使用
      if (owner !== "store") {
        buttonText = t("use_now");
      } else {
        buttonText = t("reward_now");
      }
    }
  }

  return (
    <div className="applyHistoryItemContainer">
      {/**icon : if it is online store or all */}
      <div className="validDateContainer" style={{ color: "blue" }}>
        {t(getStoreType(type))}
      </div>
      <div className="validDateContainer">ID：{rewardId} </div>
      {/** identify */}
      <div className="validDateContainer">{t(getAvailableToWho(userType))}</div>
      {/**Store type*/}
      <div className="validDateContainer">
        {t("valid_till")}： {formatJoinDate(time)}
      </div>
      <div
        className="validDateContainer"
        style={{ color: approvedTime ? "black" : "red" }}
      >
        {t("date_of_use")}：{" "}
        {approvedTime ? formatJoinDate(approvedTime) : t("not_rewarded")}
      </div>

      {/** 按钮 */}
      {buttonText === t("use_now") && owner !== "store" && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => onPressUserUseReward && onPressUserUseReward(rewardId)}
        >
          {buttonText}
        </button>
      )}
      {buttonText === t("reward_now") && owner === "store" && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => onPressStoreReward && onPressStoreReward(rewardId)}
        >
          {buttonText}
        </button>
      )}

      {buttonText === t("rewarded") && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <PiMedalFill color={"green"} />
          {buttonText}
        </div>
      )}

      {buttonText === t("expired_accounts") && (
        <div style={{ display: "flex", alignItems: "center", color: "red" }}>
          {buttonText}
        </div>
      )}
    </div>
  );
};

export default ApplyHistory;
