import { useTranslation } from "react-i18next";

interface Props {
  heroName?: string;
  onPressCancel: () => void;
  onPressConfirm: (email: string) => void;
  email: any;
}
const AlertDeleteHero = ({
  heroName,
  onPressCancel,
  onPressConfirm,
  email,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="alertContainer">
      <div className="alertPop">
        {/**title */}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            padding: "1rem",
            alignItems: "center",
          }}
        >
          <p>
            {t("are_you_sure_to_remove")}:{heroName}？{" "}
          </p>
        </div>
        {/**buttons */}
        <div
          style={{
            display: "flex",
            width: "80%",

            justifyContent: "space-around",
            marginTop: "2rem",
          }}
        >
          {/**Yes */}
          <div
            style={{
              width: "40%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onPressConfirm(email)}
              style={{ width: "100%", padding: "1.2rem" }}
            >
              {t("yes")}
            </button>
          </div>

          {/**No */}
          <div
            style={{
              width: "40%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <button
              type="button"
              className="btn btn-outline-primary"
              style={{ color: "blue", width: "100%", padding: "1.2rem" }}
              onClick={onPressCancel}
            >
              {t("no")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertDeleteHero;
