const getStoreType = (data: string) => {
  let storeType = "网店和实体店";

  if (data === "online") {
    storeType = "online_store";
  } else if (data === "physical") {
    storeType = "physical_store";
  } else {
    storeType = "online_physical";
  }

  return storeType;
};

export default getStoreType;
