import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { AiOutlineUserAdd } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { useEffect, useState } from "react";
import EmailItem from "../components/EmailItem";
import AddEmailsFrom from "../components/AddEmailsFrom";
import AuthService from "../services/service";
import AlertDeleteHero from "../components/AlertDeleteHero";

interface Props {
  studentEmail: string;
  studentName: string;
  validDate: string;
  validYear: number;
}

const AllowEmails = () => {
  const [inputValue, setInputValue] = useState("");
  const [selectedIdentity, setSelectedIdentity] = useState("name");
  const [showSinglePop, setShowSinglePop] = useState(false);
  const [showBulkPop, setShowBulkPop] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);

  //selectedEmail,selectedName,selectedValidDate;
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedValidYear, setSelectedValidYear] = useState(0);

  const [filteredStudents, setFilteredStudents] = useState([]);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);

    // 根据用户选择的标识符进行筛选
    const filteredStudents = emails.filter((student: Props) => {
      if (selectedIdentity === "name") {
        return student.studentName
          .toLowerCase()
          .includes(inputValue.toLowerCase());
      } else if (selectedIdentity === "email") {
        return student.studentEmail
          .toLowerCase()
          .includes(inputValue.toLowerCase());
      }
      return true; // 如果没有选择标识符，默认显示所有学生
    });

    // 更新符合条件的学生数目

    setFilteredStudents(filteredStudents);
  };

  const handleIdentityChange = (event: any) => {
    setSelectedIdentity(event.target.value);
  };

  const handleCloseSingle = () => {
    initData();
    setShowSinglePop(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    setLoading(true);
    AuthService.getAllAllowedEmails()
      .then(({ data }) => {
        setEmails(data.data);
        setFilteredStudents(data.data);
        console.log("现在的filteredData:", data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const handleCloseBulk = () => {
    initData();
    setShowBulkPop(false);
  };

  const handleEdit = (
    studentEmail: string,
    studentName: string,
    validYear: number
  ) => {
    // 在这里处理编辑事件，可以访问email
    console.log(
      `收到email:${studentEmail};name:${studentName};validDate:${validYear}`
    );
    setSelectedEmail(studentEmail);
    setSelectedName(studentName);
    setSelectedValidYear(validYear);
    setShowSinglePop(true);
  };

  const handleDelete = () => {
    setConfirmDeletePopup(false);
    setLoading(true);
    AuthService.deleteAllowedEmail(selectedEmail)
      .then(() => {
        initData();
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const handlePreDelete = (email: string, name: string) => {
    console.log(`此时的name:${name}`);
    setSelectedEmail(email);
    setSelectedName(name);
    setConfirmDeletePopup(true);
  };

  return (
    <CommonPageWithHeader
      loading={loading}
      error={error}
      explain={true}
      type={5}
    >
      {/** Search */}
      <div className="twoButtonsContainer" style={{ marginTop: "1%" }}>
        {/** Add one*/}
        <button
          type="button"
          className="btn btn-warning"
          style={{
            fontWeight: "bold",
            padding: "1rem 2rem",
            color: "white",
            minWidth: 120,
          }}
          onClick={() => {
            setShowSinglePop(true);
            setSelectedEmail("");
            setSelectedName("");
            setSelectedValidYear(0);
          }}
        >
          {/* Add A hero */}
          单个添加Email
          <AiOutlineUserAdd
            color={"white"}
            size={"1.8rem"}
            style={{ marginLeft: 2 }}
          />
        </button>

        {/** Add multiple*/}
        <button
          type="button"
          className="btn btn-warning"
          style={{
            fontWeight: "bold",
            padding: "1rem 2rem",
            color: "white",
            minWidth: 120,
            marginLeft: "1rem",
            alignItems: "center",
          }}
          onClick={() => {
            setShowBulkPop(true);
          }}
        >
          {/* Add A hero */}
          批量添加Email
          <HiUserGroup
            color={"white"}
            size={"1.8rem"}
            style={{ marginLeft: 2 }}
          />
        </button>
      </div>
      <div className="heroSearchContainer" style={{ marginTop: "1rem" }}>
        <input
          type="text"
          value={inputValue}
          className="searchHeroInput"
          onChange={handleInputChange}
          placeholder={`搜索学生的${selectedIdentity}`}
          style={{
            marginLeft: 3,
            fontSize: "1.1rem",
            paddingLeft: 16,
            borderRadius: 4,
            paddingTop: "1rem",
            paddingBottom: "1rem",
            borderColor: "#b6b4b4",
          }}
        />

        {/**添加按什么搜查 */}
        <div className="searchSelectionContainer">
          搜索内容：
          {/**student'name */}
          <label>
            <input
              className="radioStyle"
              type="radio"
              value="name"
              checked={selectedIdentity === "name"}
              onChange={handleIdentityChange}
            />
            name
          </label>
          {/**student'email */}
          <label>
            <input
              className="radioStyle"
              type="radio"
              value="email"
              checked={selectedIdentity === "email"}
              onChange={handleIdentityChange}
            />
            email
          </label>
        </div>

        {/**total :符合条件的，默认是全部*/}
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
            display: "flex",
            marginTop: "1rem",
            fontWeight: "bold",
          }}
        >
          总共:{filteredStudents.length}
        </div>

        {/**Emails */}
        <div className="emailsListContainer">
          <div className="tableTitle">
            <h5 className="numberContainer"></h5>
            <h5>名字</h5>
            <h5>Email</h5>
            <h5>有效期限</h5>
            <h5></h5>
          </div>
          <ol className="list-group">
            {filteredStudents.map((item: any, index) => (
              <li className={"list-group-item-each"} key={index}>
                <EmailItem
                  index={index + 1}
                  studentEmail={item.studentEmail}
                  studentName={item.studentName}
                  validDate={item.validDate}
                  validYear={item.validYear}
                  onEdit={handleEdit}
                  onDelete={handlePreDelete}
                />
              </li>
            ))}
          </ol>
        </div>
      </div>

      {/**Emails */}

      {/**popups */}
      {showSinglePop && (
        <AddEmailsFrom
          single={true}
          handleClosePopup={handleCloseSingle}
          selectedEmail={selectedEmail}
          selectedName={selectedName}
          selectedValidYear={selectedValidYear}
        />
      )}
      {showBulkPop && (
        <AddEmailsFrom single={false} handleClosePopup={handleCloseBulk} />
      )}

      {confirmDeletePopup && (
        <AlertDeleteHero
          email={selectedEmail}
          heroName={selectedName}
          onPressCancel={() => {
            setSelectedName("");
            setConfirmDeletePopup(false);
          }}
          onPressConfirm={handleDelete}
        />
      )}
    </CommonPageWithHeader>
  );
};

export default AllowEmails;
