import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
interface Props {
  onPressCloseNotice: () => void;
}

const ImportantNotice = ({ onPressCloseNotice }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="topNoticeContainer">
      {/* 温馨提醒：在向申请用户提供相应奖励后，请不要忘记点击奖励按钮! */}
      {t("dont_forget_reward_tip")}
      <AiOutlineCloseCircle
        size={"2rem"}
        color={"white"}
        style={{ marginLeft: "1rem" }}
        className={"closeIcon iconHover"}
        onClick={onPressCloseNotice}
      />
    </div>
  );
};

export default ImportantNotice;
