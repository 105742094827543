import { useTranslation } from "react-i18next";
interface Props {
  label?: string;
  placeholder?: string;
  name: string;
  className?: string;
  handleChange: (value: any) => void;
  onFocus?: () => void;
  value?: any;
}
const InputNormal = ({
  label,
  placeholder,
  name,
  className,
  handleChange,
  onFocus,
  value,
}: Props) => {
  const { t } = useTranslation();

  const labelTranslated = t(label!);
  const placeholderTranslated = t(placeholder!);
  return (
    <div className={className}>
      {/**label */}
      <label htmlFor="email" className="inputLabel">
        {labelTranslated}
      </label>

      {/**input bar */}
      <input
        type="text"
        className="form-control"
        name={name}
        onChange={(value) => handleChange(value)}
        placeholder={placeholderTranslated}
        onFocus={onFocus}
        value={value}
        style={{
          padding: "1rem",
          color: "#666",
          marginBottom: "1rem",
          fontSize: "1rem",
        }}
      />
    </div>
  );
};

export default InputNormal;
