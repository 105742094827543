import CommonPageWithHeader from "../components/CommonPageWithHeader";

import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <CommonPageWithHeader>
      {/**content */}
      <div className="noticeContentContainer">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: 16,
          }}
        >
          {" "}
          <h2 style={{ fontWeight: "bold", fontSize: "1.8rem" }}>
            {/* REGISTRATION INSTRUCTIONS */}
            {t("about_title")}
          </h2>
        </div>

        <p className="noticeP">{t("phase_1")}</p>

        <p className="noticeP">{t("phase_2")}</p>

        <p className="noticeP">{t("phase_3")}</p>

        <p className="noticeP" style={{ marginBottom: 30 }}>
          {t("phase_4")}
        </p>

        <p className="noticeP" style={{ marginBottom: 30 }}>
          {t("phase_4_1")}
        </p>

        <div
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            marginTop: 32,
          }}
        >
          {/* <Link type="button" className="noticeBtn" to={"/storybehind"}>
            {t("story_behind_btn")}
          </Link> */}
        </div>
      </div>
    </CommonPageWithHeader>
  );
};

export default About;
