import { RewardPunishmentRule } from "../../datas/atoms";
import AuthService from "../../services/service";
import RuleItem from "./RuleItem";
import { useState, useEffect } from "react";

interface Props {
  type?: string;
}

const RuleComponent = ({ type }: Props) => {
  const [data, setData] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  useEffect(() => {
    initData();
  }, []);
  const initData = () => {
    AuthService.getAllDefaultRules()
      .then(({ data }) => {
        setData(data.rules);
        if (type) {
          const d = data.rules.filter(
            (m: RewardPunishmentRule) => m.type === type
          );
          console.log("========d:", d);
          setFilteredDatas(d);
        }
      })
      .catch(() => {});
  };
  return (
    <div className="ruleComponentContainer">
      <ol className="list-group">
        {!type &&
          data.map((item: any, index: number) => (
            <li className={"list-group-item-each"} key={index}>
              <RuleItem
                item={item}
                index={index}
                content={item.content}
                point={item.point}
                type={item.type}
                self_added={false}
              />
            </li>
          ))}
        {type &&
          filteredDatas.map((item: any, index: number) => (
            <li className={"list-group-item-each"} key={index}>
              <RuleItem
                item={item}
                index={index}
                content={item.content}
                point={item.point}
                type={item.type}
                self_added={false}
              />
            </li>
          ))}
      </ol>
    </div>
  );
};

export default RuleComponent;
