import { Event } from "../datas/atoms";
import formatJoinDate from "../utils/formatJoinDate";
import { PiMedalFill } from "react-icons/pi";
import { BsTrash } from "react-icons/bs";
import { FaSadCry } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface Props {
  userType: string; // student or school
  event: Event;

  onPressChange?: (state: boolean, eventId: string) => void;
  onPressDeleteEvent?: (eventId: string) => void;
}

const RewardPunishUserItem = ({
  event,
  userType,
  onPressDeleteEvent,
}: Props) => {
  const { t } = useTranslation();
  const img_url = import.meta.env.VITE_IMG_URL;
  const heroImg = `${img_url}${event.studentId.image}`;
  const color = event.type === "punishment" ? "red" : "green";
  const PUNISHMENT = "punishment";
  const REWARD = "reward";

  // 当onPressChange中的value的值是true的时候，弹出是否要取消event的执行；
  //当onPressChange中的value的值是false的时候，弹出确定已经解决了event，并询问是否要留言给学生。

  return (
    <div className="rewardPunishUserContainer">
      {/**delete icon */}
      {userType !== "student" && (
        <button>
          <BsTrash
            size={"2rem"}
            className="deleteIcon"
            onClick={() => onPressDeleteEvent && onPressDeleteEvent(event._id)}
          />
        </button>
      )}
      {/**profile image  + email */}
      <div className="innerRewardPunishUserContainer1">
        <div className="userWithInfoContainer">
          <div className="userWithInfoImgContainer">
            <img src={heroImg} className="imgShape" />
          </div>

          {/**username + email + reason */}

          <p className="rewardPunishUserName1" style={{ fontWeight: "bold" }}>
            {event.studentId.heroName}
          </p>
          <div className="reasonContainer">
            <p className="rewardPunishUserName2" style={{ fontWeight: "bold" }}>
              {event.studentId.heroName}
            </p>
            <p>
              {t("student_email")}: {event.studentId.studentEmail}
            </p>
            {event.studentId.info.length > 0 && (
              <p>
                {/* Info */}
                {t("student_info")}:{event.studentId.info}
              </p>
            )}
            <p>
              {/* 时间 */}
              {t("event_time")}: {formatJoinDate(event.time)}
            </p>

            <p>
              {/* 依据 */}
              {t("accord_to")}：{event.baseOn}
            </p>
            <p>
              {/* 详细 */}
              {t("detail_added")}：{event.reason}
            </p>
          </div>
        </div>

        {/**points + statement + switch */}
        <div className="userWithInfoContainer2">
          {event.type === REWARD && <PiMedalFill color={color} size={"2rem"} />}
          {event.type === PUNISHMENT && (
            <FaSadCry color={color} size={"2rem"} />
          )}
          <p style={{ fontSize: "1.5rem", color: color, fontWeight: "bold" }}>
            {event.points}
          </p>

          {/**school + statement : false ;type:punishment*/}
          {/* {userType === "school" &&
            event!.statement === false &&
            event.type === PUNISHMENT && (
              <>
                <p style={{ color: color }}>等待处理...</p>
                <Switch
                  onChange={() =>
                    onPressChange && onPressChange(false, event._id)
                  }
                  checked={false}
                />
              </>
            )} */}

          {/**school + statement : true ;type:punishment*/}
          {/* {userType === "school" &&
            event!.statement === true &&
            event.type === PUNISHMENT && (
              <>
                <p style={{ color: color }}>已惩罚</p>
                <Switch
                  onChange={() =>
                    onPressChange && onPressChange(true, event._id)
                  }
                  checked={true}
                />
              </>
            )} */}

          {/**school + statement : false ;type:reward*/}
          {/* {userType === "school" &&
            event!.statement === false &&
            event.type === REWARD && (
              <>
                <p style={{ color: color }}>等待奖励...</p>
                <Switch
                  onChange={() =>
                    onPressChange && onPressChange(false, event._id)
                  }
                  checked={false}
                />
              </>
            )} */}

          {/**school + statement : true ;type:reward*/}
          {/* {userType === "school" &&
            event!.statement === true &&
            event.type === REWARD && (
              <>
                <p style={{ color: color }}>已奖励</p>
                <Switch
                  onChange={() =>
                    onPressChange && onPressChange(true, event._id)
                  }
                  checked={true}
                />
              </>
            )} */}

          {/**student + statement : false ;type:punishment*/}
          {/* {userType === "student" &&
            event!.statement === false &&
            event!.type === "punishment" && (
              <>
                <p style={{ color: color }}>等待惩罚...</p>
                <PiClockCountdownFill color={color} size={"2rem"} />
              </>
            )} */}

          {/**student + statement : true ;type:punishment*/}
          {/* {userType === "student" &&
            event!.statement === true &&
            event!.type === PUNISHMENT && (
              <>
                <p style={{ color: color }}>已惩罚</p>
                <FaSadCry color={color} size={"2rem"} />
              </>
            )} */}

          {/**student + statement : false ;type:reward*/}
          {/* {userType === "student" &&
            event!.statement === false &&
            event!.type === REWARD && (
              <>
                <p style={{ color: color }}>等待奖励...</p>
                <PiClockCountdownFill color={color} size={"2rem"} />
              </>
            )} */}

          {/**student + statement : true ;type:reward*/}
          {/* {userType === "student" &&
            event!.statement === true &&
            event!.type === REWARD && (
              <>
                <p style={{ color: color }}>已奖励</p>
                <PiMedalFill color={color} size={"2rem"} />
              </>
            )} */}
        </div>
      </div>

      {/* <CommentComponent userType="school" event={event} /> */}
    </div>
  );
};

export default RewardPunishUserItem;
