import { useTranslation } from "react-i18next";
import { IoLocationSharp } from "react-icons/io5";
import returnRegionName from "../utils/returnRegionName";

interface School {
  name?: string;
  flag?: string;
  index?: number;
  type?: string;
  region?: string;
  votes?: number;
  ranking?: number;
  user?: boolean; // if it is from user's profile
  allSchoolsNum?: School[];
}

const SchoolItem = ({
  name,
  flag,
  index,
  type,
  region,
  ranking,
  user,
}: School) => {
  const img_url = import.meta.env.VITE_IMG_URL;
  const iconImg = `${img_url}${flag}`;
  const { t } = useTranslation();

  return (
    <div className="schoolItemContainer">
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          fontSize: index! > 100 ? "1.2rem" : "1rem",
          display: "flex",
          padding: 10,
        }}
        className="rankingNumContainer"
      >
        {/**ranking */}
        {user ? index : ranking}
      </div>
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
          fontSize: "1rem",
          textAlign: "center",
          display: "flex",
        }}
      >
        {/**ranking */}
        {name}
      </div>
      {/**icon */}
      {/**icon */}{" "}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <img
          src={iconImg}
          style={{ width: "2.5rem", height: "2.5rem", borderRadius: "2.5rem" }}
          alt="icon"
        />
      </div>
      {/**grade */}
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem",
          display: "flex",
          fontSize: "1rem",
        }}
      >
        {t(`${type}`)}
      </div>
      {/**region */}
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem",
          display: "flex",
          fontWeight: "bold",
          fontSize: "1rem",
        }}
      >
        <IoLocationSharp color={"#666"} size={"1.5rem"} />
        {returnRegionName(region!)}
      </div>
      {/**likes */}
      {/* <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
          display: "flex",
          fontSize: "1rem",
        }}
      >
        <AiFillHeart
          color={"#ff6b81"}
          size={"1.5rem"}
          style={{ marginRight: 5 }}
        />
        {votes}
      </div> */}
    </div>
  );
};

export default SchoolItem;
