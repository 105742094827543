import retrunImgUrl from "../utils/retrunImgUrl";
import CommonPageWithHeader from "./CommonPageWithHeader";
import { useTranslation } from "react-i18next";
import formatJoinDate from "./../utils/formatJoinDate";
import { IoLocationSharp } from "react-icons/io5";
import { BiSolidBook } from "react-icons/bi";
import { RiArrowGoBackFill } from "react-icons/ri";

import { useState, useRef } from "react";

import LoginCreateAccountPopup from "./LoginCreateAccountPopup";

import SchoolPlanComponent from "./SchoolPlanComponent";
import { AiOutlineCloseCircle } from "react-icons/ai";
import SchoolRules from "./rule/SchoolRules";

interface Hero {
  _id: string;
  image: string;
  heroName: string;
}

interface Props {
  id?: string;
  name?: string;
  type?: string;
  region?: string;
  votes?: number;
  icon?: string;
  index?: number;
  schoolSelf?: any;
  onPressBack: () => void;
  onActiveLike: () => void;
  joinDate?: string;
  address?: string;
  active?: boolean;
  heros?: Hero[];
}

const TargetSchool = ({
  id,
  name,
  type,
  icon,
  index,
  onPressBack,

  joinDate,
  address,
}: Props) => {
  const { t } = useTranslation();
  // const likeAmount = useRef(votes);
  const rankingAmount = useRef(index);
  // const [user, setUser] = useAtom(userAtom);
  // const [school] = useAtom(schoolAtom);
  const [error] = useState("");
  // const [setLikeClicked] = useState(false);
  // const [alertPop, setAlertPop] = useState(false);
  const [popup, setPopup] = useState(false);
  const [typeNow, setTypeNow] = useState("reward");
  const [rulePopup, setRulePopup] = useState(false);

  // const isSchoolVoted = user
  //   ? user.votedSchools.some((school: School) => school._id == id)
  //   : false;

  // const alert = (message: string, callback: () => void) => {
  //   window.alert(message);
  //   if (callback) {
  //     callback();
  //   }
  // };
  // const onClickedLike = () => {
  //   //在liked + 1 ；
  //   //把按钮变成：您已点赞
  //   //用户有登录
  //   if (user.name !== "") {
  //     //like 按钮没有按过并且用户没给这所学校投票过
  //     if (!likClicked && !isSchoolVoted) {
  //       likeAmount.current = likeAmount.current! + 1;
  //       setLikeClicked(true);
  //       //将school整个object添加进user中
  //       const updatedUser = { ...user };
  //       updatedUser.votedSchools.push({ _id: id }); // 将被点赞的学校添加到votedSchools数组中
  //       setUser(updatedUser); // 更新用户信息的状态
  //       AuthService.giveOrRemoveVote("add", id!)
  //         .then(() => {
  //           onActiveLike!();
  //           return;
  //         })
  //         .catch((error) => {
  //           setError(error.response.data.message);
  //           console.log("error:", error);
  //         });
  //     } else {
  //       setAlertPop(true);
  //     }
  //   } else {
  //     //用户没有登录，或者是学校自己
  //     if (school.name !== "") {
  //       return alert(t("school_not_allow_vote"), () => {});
  //     } else {
  //       setPopup(true);
  //     }
  //   }
  // };

  // const onPressRemoveVote = () => {
  //   setLikeClicked(false);
  //   likeAmount.current = likeAmount.current! - 1;
  //   setAlertPop(false);

  //   // 从user.votedSchools数组中移除对应的学校
  //   const updatedUser = { ...user };
  //   updatedUser.votedSchools = updatedUser.votedSchools.filter(
  //     (school: School) => school._id !== id
  //   );
  //   setUser(updatedUser); // 更新用户信息的状态

  //   AuthService.giveOrRemoveVote("delete", id!)
  //     .then(() => {
  //       onActiveLike!();
  //       return;
  //     })
  //     .catch((error) => {
  //       setError(error.response.data.message);
  //       console.log("error:", error);
  //     });
  // };

  const onPressReward = () => {
    setTypeNow("reward");
    setRulePopup(true);
  };

  const onPressPunishment = () => {
    setTypeNow("punishment");
    setRulePopup(true);
  };

  const handleCloseRulePopup = () => {
    setRulePopup(false);
    setTypeNow("");
  };

  return (
    <CommonPageWithHeader error={error}>
      {/* {alertPop && (
        <AlertPopup
          schoolName={name}
          onPressCancel={() => setAlertPop(false)}
          // onPressConfirm={onPressRemoveVote}
        />
      )} */}
      <div className="targetContainer">
        {/**back icon */}
        <RiArrowGoBackFill
          className="backIcon"
          color={"black"}
          size={"2.5rem"}
          onClick={onPressBack}
          style={{ marginBottom: "2rem", marginTop: "2rem" }}
        />
        {/**school icon */}
        <img src={retrunImgUrl(icon)} className="targetImg" />
        {/**school name*/}
        <h1>{name}</h1>
        {/**school type*/}
        <span>
          {" "}
          <BiSolidBook
            color={"#666"}
            size={"1.5rem"}
            style={{ marginRight: 2 }}
          />
          {t(`${type}`)}
        </span>
        {/**school location */}
        <span className="targtAddress">
          {" "}
          <IoLocationSharp color={"#ea6969"} size={"1.5rem"} />
          {address}
        </span>
        {/**school join date + meaning*/}
        <p>
          {formatJoinDate(joinDate!)}
          {/* , {t("rest_content")} */}
        </p>
        {/**received bonus */}
        {/* <div
          style={{
            backgroundColor: "#96a5ab",
            color: "white",
            padding: "1rem 2rem",
            borderRadius: "1rem",
            fontWeight: "bold",
          }}
        >
          已收获奖金：130€
        </div> */}
        {/**ranking + like amount */}
        <div className="rankingLikeContainer">
          <div className="rankingContainer" style={{ marginRight: "2rem" }}>
            <span style={{ fontWeight: "bold" }}>
              {t("ranking")}
              <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                {rankingAmount.current}{" "}
              </span>
            </span>
          </div>
          {/* <div className="rankingContainer" style={{ marginLeft: "2rem" }}>
            <span style={{ fontWeight: "bold" }}>
              {t("received_likes")}{" "}
              <AiFillHeart color={"#ea6969"} size={"1.5rem"} />{" "}
              <span style={{ fontWeight: "normal" }}>{likeAmount.current}</span>
            </span>
          </div> */}
        </div>

        {/**give a like button*/}
        {/* {likClicked || isSchoolVoted ? (
          <button
            className="clickLikeBtn"
            onClick={onClickedLike}
            style={{ backgroundColor: "#666" }}
          >
            <MdDone color={"#ffffff"} size={15} />
            {t("you_have_liked")}{" "}
          </button>
        ) : (
          <button className="clickLikeBtn" onClick={onClickedLike}>
            <AiFillHeart color={"#ffffff"} size={"1.5rem"} />
            {t("give_a_like_btn")}
          </button>
        )} */}
      </div>
      {popup && (
        <div className="popupToLogin">
          <LoginCreateAccountPopup onPressCloseModal={() => setPopup(false)} />
        </div>
      )}
      {/* <div style={{ width: "60%", border: "1px solid #e4e6eb" }} /> */}
      <SchoolPlanComponent
        onPressPunishment={onPressPunishment}
        onPressReward={onPressReward}
      />

      {/**popup rules */}
      {rulePopup && (
        <div className="addHeroComponent">
          <div className="alertPop">
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                padding: "3rem",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SchoolRules type={typeNow} schoolId={id!} />
            </div>
          </div>
          <AiOutlineCloseCircle
            size={"3rem"}
            color={"white"}
            style={{ marginTop: "1rem" }}
            onClick={handleCloseRulePopup}
            className="iconHover"
          />
        </div>
      )}
    </CommonPageWithHeader>
  );
};

export default TargetSchool;
