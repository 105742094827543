import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import { useState } from "react";
import AuthService from "../../services/service";
import { useTranslation } from "react-i18next";

const SchoolPaymentProgress = () => {
  const { t } = useTranslation();
  const [selectedPaymentType, setSelectedPaymentType] = useState("basic"); // month , year,life
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleOptionChange = (event: any) => {
    setSelectedPaymentType(event.target.value);
    console.log("现在选择：", event.target.value);
  };
  const handleProcessPayment = () => {
    setLoading(true);
    AuthService.reCreateCheckoutSession(selectedPaymentType)
      .then(({ data }) => {
        window.location.replace(data.url);
      })
      .catch((e) => setError(e));
  };
  return (
    <CommonPageWithHeader loading={loading} error={error}>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "80vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            height: "100%",
          }}
        >
          <label>
            <input
              type="radio"
              value="basic"
              checked={selectedPaymentType === "basic"}
              onChange={handleOptionChange}
              className="radionStyle"
            />
            €{import.meta.env.VITE_SCHOOL_BASE}/{t("pay_by_year")}
          </label>

          <label>
            <input
              type="radio"
              value="stander"
              checked={selectedPaymentType === "stander"}
              onChange={handleOptionChange}
              className="radionStyle"
            />
            €{import.meta.env.VITE_SCHOOL_STANDER}/{t("pay_by_year")}
          </label>

          <label>
            <input
              type="radio"
              value="pro"
              checked={selectedPaymentType === "pro"}
              onChange={handleOptionChange}
              className="radionStyle"
            />
            €{import.meta.env.VITE_SCHOOL_PRO}/{t("pay_by_year")}
          </label>

          {/**Submit button */}
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginTop: "3rem", padding: "1rem 3rem" }}
            onClick={handleProcessPayment}
          >
            {/* 去付款 */}
            {t("process_payment")}
          </button>
        </div>
      </div>
    </CommonPageWithHeader>
  );
};

export default SchoolPaymentProgress;
