import TikItem from "../TikItem";
import { useTranslation } from "react-i18next";

interface Props {
  price: any;
  type: string; // month , year, all life
  num: number; // 1:month ; 2.year ;3.life
}
const StorePricingItem = ({ price, type, num }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="schoolPricingItemContainer">
      {/**price / year */}
      <p className="priceYearP">
        {price}€/{type}
      </p>

      {/**suitable for how many teachers */}
      <p style={{ fontSize: "large", fontWeight: "bold", textAlign: "center" }}>
        +{import.meta.env.VITE_VERIFY_FEE}€ {t("register_fee")}
      </p>
      {/**seperate line */}
      <div className="seperateLineForPricing" />
      {/**list items */}
      <p style={{ padding: "1rem" }}></p>
      {/* <TikItem content={"自定义奖励条件"} /> */}
      <TikItem content={t("customize_reward_conditon")} />
      {/* <TikItem content={"为自家的实体店或网店引流"} /> */}
      <TikItem content={t("help_let_others_know_your_store")} />
      {/* {num === 1 && <TikItem content={"随时暂停服务/更换付费模式"} />} */}
      {num === 1 && (
        <TikItem content={t("can_cancle_or_change_method_anytime")} />
      )}
      {/* {num === 2 && <TikItem content={"每月节省约29%的价格"} />} */}
      {/* {num === 3 && <TikItem content={"后续无限制免费使用所有升级功能"} />} */}
      {num === 3 && <TikItem content={t("unlimit_use_new_methos")} />}
      {num === 2 && (
        <TikItem content={t("can_cancle_or_change_method_anytime")} />
      )}

      {/**start button */}
      {/* <button
        type="button"
        className="btn btn-primary"
        style={{
          backgroundColor: "#5f7fff",
          padding: "1rem",
          margin: "1rem",
          width: "100%",
        }}
      >
        开始购买
      </button> */}
    </div>
  );
};

export default StorePricingItem;
