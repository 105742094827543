import { useState } from "react";
import CommonPageWithHeader from "../components/CommonPageWithHeader";
import InputNormal from "../components/InputNormal";
import AuthService from "../services/service";
import { useTranslation } from "react-i18next";

const MAX_LENGTH = 2000;
const ContactUs = () => {
  const { t } = useTranslation();
  const [main, setMain] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  //values
  const [name, setName] = useState("");
  const [web, setWeb] = useState("");
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");
  const remainingCharacters = question.length;
  const handleNameChange = (e: any) => {
    // console.log("收到name:", e.target.value);
    setName(e.target.value);
  };

  const handleWebChange = (e: any) => {
    // console.log("收到name:", e.target.value);
    setWeb(e.target.value);
  };

  const handleEmailChange = (e: any) => {
    // console.log("收到name:", e.target.value);
    setEmail(e.target.value);
  };

  const handleMessageChange = (e: any) => {
    // console.log("收到message:", e.target.value);
    if (e.target.value.length > MAX_LENGTH) {
      return setError(t("out_of_limit"));
    }
    setQuestion(e.target.value);
  };

  const handleUpload = () => {
    setLoading(true);
    AuthService.createContact(name, email, web, question)
      .then(() => {
        setMain(false);
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };
  return (
    <CommonPageWithHeader loading={loading} error={error}>
      {main && !success && (
        <div className="contactUsContainer">
          {/**title */}
          <p
            style={{
              fontSize: "1.5rem",
              textAlign: "center",
              lineHeight: "1.9",
              padding: "3rem 1%",
            }}
          >
            {/* 我们非常重视您提出的问题，为了让我们能够更好地回复您的问题，请确保您提供的信息真实有效。我们会认真检查您提供的信息，在验证通过之后，我们会尽快回复您。 */}
            {t("contact_us_title")}
          </p>
          {/**inputs */}
          {/**name */}
          <InputNormal
            label="form_your_name"
            handleChange={(e) => handleNameChange(e)}
            name="name"
            className="contactName"
          />

          {/**Contact Email */}
          <InputNormal
            label="form_your_email"
            handleChange={(e) => handleEmailChange(e)}
            name="email"
            className="contactName"
          />

          {/**website or linkedin */}
          <InputNormal
            label="from_your_web"
            handleChange={(e) => handleWebChange(e)}
            name="web"
            className="contactName"
          />

          {/**question */}
          <div style={{ width: "100%" }}>
            {/**label */}
            <label
              htmlFor="email"
              className="inputLabel"
              style={{
                color: remainingCharacters <= MAX_LENGTH ? "black" : "red",
              }}
            >
              *{t("from_your_msg")} :{" "}
              {question.length <= MAX_LENGTH
                ? `${remainingCharacters}/${MAX_LENGTH}`
                : `${t("out_of_limit")}`}
            </label>

            {/**input bar */}
            <textarea
              className="form-control"
              name={"message"}
              onChange={(value) => handleMessageChange(value)}
              placeholder={""}
              style={{
                padding: "1.2rem",
                color: "#666",
                marginBottom: "2rem",
                fontSize: "1.2rem",
                minHeight: "20vh",
                textAlign: "left",
                verticalAlign: "top",
                lineHeight: "1.8",
              }}
            />
          </div>

          <button
            className="noticeBtn"
            style={{ marginTop: 16, borderRadius: "2rem" }}
            onClick={handleUpload}
          >
            {t("submit")}
          </button>
        </div>
      )}

      {success && !main && (
        <div className="contactUsContainer">
          <h2> {t("thank_you_email")}</h2>
          <img
            src="/images/done.png"
            style={{ width: "6rem", height: "6rem", marginTop: "3rem" }}
            alt="dot image"
          />
        </div>
      )}
    </CommonPageWithHeader>
  );
};

export default ContactUs;
