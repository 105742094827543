import { useEffect, useRef } from "react";
import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import SchoolFQA from "./SchoolFQA";
import StoreFAQ from "./StoreFAQ";
import StudentFAQ from "./StudentFAQ";
import TeacherFAQ from "./TeacherFAQ";
import { useTranslation } from "react-i18next";

const FaqsPage = () => {
  const { t } = useTranslation();
  const schoolRef = useRef(null);
  const teacherRef = useRef(null);
  const studentRef = useRef(null);
  const storeRef = useRef(null);

  const scrollToRef = (ref: any) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <CommonPageWithHeader>
      <p style={{ padding: "3rem", fontSize: "1.8rem", fontWeight: "bold" }}>
        {t("faq")}
      </p>
      {/**导航 */}
      <div className="guid-container">
        <p>{t("table_of_contents")}</p>
        <div
          className="guid-line-with-point "
          onClick={() => scrollToRef(schoolRef)}
        >
          · {t("school")}
        </div>
        <div
          className="guid-line-with-point "
          onClick={() => scrollToRef(teacherRef)}
        >
          · {t("teaching_staff")}
        </div>
        <div
          className="guid-line-with-point "
          onClick={() => scrollToRef(studentRef)}
        >
          · {t("student")}
        </div>
        <div
          className="guid-line-with-point "
          onClick={() => scrollToRef(storeRef)}
        >
          · {t("reward_store")}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "3rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/**School */}
        <p className="FAQ-Title" ref={schoolRef}>
          ·{t("school")}
        </p>
        <SchoolFQA />
        <div style={{ margin: "2rem" }} />

        {/**Teaching staffs */}
        <p className="FAQ-Title" ref={teacherRef}>
          ·{t("teaching_staff")}
        </p>
        <TeacherFAQ />
        <div style={{ margin: "2rem" }} />
        {/**Students */}
        <p className="FAQ-Title" ref={studentRef}>
          ·{t("student")}
        </p>
        <StudentFAQ />
        <div style={{ margin: "2rem" }} />
        {/**Stores */}
        <p className="FAQ-Title" ref={storeRef}>
          ·{t("reward_store")}
        </p>
        <StoreFAQ />
      </div>
    </CommonPageWithHeader>
  );
};

export default FaqsPage;
