import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import getUserLocale from "get-user-locale";
const getCurrentLang = () => {
  // 获取浏览器的默认语言
  const lang = getUserLocale();

  const browserLang = lang.split("-")[0];

  // 根据浏览器语言返回相应的语言代码
  switch (browserLang) {
    case "es":
      return "es";
    case "zh":
      return "zh";
    default:
      return "en";
  }
};

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: getCurrentLang(),
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        register_advise:
          "Before you register, please read the FAQ carefully to ensure the registration process is as smooth as possible.",
        table_of_contents: "Table of contents",
        //teacher
        what_teaching_staff_can_do_title: "What can teaching staff do?",
        what_teaching_staff_can_do_content_1: "①Add students;",
        what_teaching_staff_can_do_content_2: "②Add teaching staff;",
        what_teaching_staff_can_do_content_3:
          "③Add reward/penalty events for students;",
        what_teaching_staff_can_do_content_4: "④Receive reports from students;",
        what_teaching_staff_can_do_content_5:
          "⑤Establish guidelines for school-specific reward and penalty behaviors;",

        if_teaching_staff_can_apply_reward_title:
          "Can teaching staff redeem for rewards?",
        if_teaching_staff_can_apply_reward_content:
          "Yes, reward application records are not visible to any other staff. Check your personal profile to see a record of your redemption history:",

        how_can_teaching_staff_change_password_title:
          "How can teaching staffs reset their passwords?",
        how_can_teaching_staff_change_password_content:
          "②Access login as teaching staff;",

        //Student=====================
        what_student_can_do_title: "What can student users do?",
        what_student_can_do_content_1:
          "①With one click, students can quickly report their situation to the school, ensuring their protection;",
        what_student_can_do_content_2:
          "②Students gain reward points through good performance and behaviour at school. These points can be used to redeem rewards for themselves and their families in the Rewards Marketplace. Redeeming rewards does not consume points - accumulated points will remain available for future redemption;",

        how_to_report_to_school_title: "How to report to the school?",
        how_to_report_to_school_content_1:
          "①After logging in, go to the student's profile dashboard;",
        how_to_report_to_school_content_2:
          "②Find the 'Report an incident' button, click on it, fill in the report form, and then send it to the school:",

        student_how_to_change_password_title:
          "How can student users reset their passwords?",
        student_how_to_change_password_content_1: "①Access the login page;",
        student_how_to_change_password_content_2:
          "②Enter the 'login as student' ;",
        student_how_to_change_password_content_3:
          "③Hit the 'Forgot Password' link;",
        student_how_to_change_password_content_4: "④Enter Email;",
        student_how_to_change_password_content_5:
          "⑤Retrieve the verification code from the email that you entered;",
        student_how_to_change_password_content_6:
          "⑥Verification code + new password = Password successfully changed;",

        //School=======================
        mearningful_school_title:
          "What is the significance of joining School Glory?",
        mearningful_school_content:
          "School Glory member schools are guiding students toward a positive and constructive path by establishing a points systems that rewards positive behaviors and disincentivizes negative behaviors. For example, helping a classmate being bullied will earn a student 10 points and showing improved academic performance will gain 1 point, while bullying someone will result in a deduction of 10 points. Accrued points can then be redeemed in the Rewards Marketplace. This approach aims to motivate students while also easing the burden on their families. Teachers or their families can also apply for rewards in the Rewards Marketplace, without the need for points. Our ultimate goal is to reduce campus bullying and foster a healthier, happier learning environment. We aspire to gather support from all sectors of society to collectively safeguard the future of our children.",

        type_that_school_can_apply_title: "Types of schools that can apply?",
        type_that_school_can_apply_content:
          "kindergarten, primary school, secondary school, technical institute, college or university.",

        what_school_admin_user_can_do_title:
          "What can platform administrators do?",
        what_school_admin_user_can_do_main_1:
          "· Schools can promptly provide assistance through a one-click reporting system and respond to students' signals for help;",
        what_school_admin_user_can_do_main_2:
          "· Each school can add up to 10 reward and 10 deduction rules, providing students with 'Laws' to follow regarding actions that can gain or lose points, guiding students towards positive behavior and discouraging misconduct, thereby safeguarding students’ future;",
        what_school_admin_user_can_do_main_3:
          "School users are recommended to add reward behaviors as follows:",
        what_school_admin_user_can_do_content_1:
          "①Bravely reporting to the school when being bullied +10;",
        what_school_admin_user_can_do_content_2:
          "②Stopping bullying behavior and/or seeking help from teachers if unable to stop the bullying +10;",
        what_school_admin_user_can_do_content_3:
          "③Excellent academic performance (customized by the school) +10;",
        what_school_admin_user_can_do_content_4:
          "④Improvement in academic performance +1;",
        what_school_admin_user_can_do_main_4:
          "School users are recommended to add penalty behaviors as follows:",
        what_school_admin_user_can_do_content_5:
          "①Verbal bullying: mocking, insulting, or threatening classmates -10;",
        what_school_admin_user_can_do_content_6:
          "②Social bullying: exclusion, isolation, spreading rumors, or damaging others' belongings -10;",
        what_school_admin_user_can_do_content_7:
          "③Physical bullying: fighting, or making insulting gestures -10;",
        what_school_admin_user_can_do_content_8:
          "④Falsification for reward scores -10;",
        what_school_admin_user_can_do_main_5: "",
        what_school_admin_user_can_do_main_6:
          "Teaching staff and their families can also apply for certain rewards, allowing them to enjoy reward benefits and promoting positive interactions within the school.",

        what_datas_need_to_prepare_to_join_title:
          "What documents are needed for school enrollment?",
        what_datas_need_to_prepare_to_join_content_main:
          "The registration process requires manual review, taking approximately 7 to 15 working days. Schools need to provide the following information:",
        what_datas_need_to_prepare_to_join_content_1:
          "·Official school email address;",
        what_datas_need_to_prepare_to_join_content_2:
          "·Full name of the school;",
        what_datas_need_to_prepare_to_join_content_3:
          "·Country/Region where school is primarily located;",
        what_datas_need_to_prepare_to_join_content_4:
          "·School type (e.g., kindergarten, primary school, middle school, etc.);",
        what_datas_need_to_prepare_to_join_content_5:
          "·School emblem. (Optional);",

        random_sort_school_title:
          "Default ranking of schools on School Glory's homepage?",
        random_sort_school_content:
          "The default ranking is based on when the school joined - the earlier, the higher the ranking.",

        where_to_get_receive_title:
          "Where to get receipts after school payment?",
        where_to_get_receive_content:
          "Payment must be made using the school's public account, which should match the registered school name. Please note that if the review is not approved, no refund will be given, so it is vital that the information provided is accurate and complete. After the review is completed, we will send an electronic receipt to the email address provided during registration.",

        how_to_add_student_title: "How to add enrolled students?",
        how_to_add_student_content_1:
          "①After logging in, enter the school's profile dashboard ;",
        how_to_add_student_content_2: "②Find the following:",
        how_to_add_student_content_3:
          "③Once inside, click the 'Add Student' button:",

        how_to_add_teaching_staffs_title: "How to add teaching staff?",
        how_to_add_teaching_staffs_content_1:
          "①After logging in, enter the school's profile dashboard ;",
        how_to_add_teaching_staffs_content_2: "②Find the following:",
        how_to_add_teaching_staffs_content_3:
          "③Once inside, click the 'Add Teacher's Email' button:",

        where_to_check_students_reports_title:
          "Where can reports of students be received?",
        where_to_check_students_reports_content_1:
          "①After logging in, enter the school's profile dashboard;",
        where_to_check_students_reports_content_2: "②Find the following:",

        how_to_add_reward_punishment_actions_title:
          "How to add behaviors for rewards and deductions?",
        how_to_add_reward_punishment_actions_content_1:
          "①After logging in, enter the school's profile dashboard ;",
        how_to_add_reward_punishment_actions_content_2: "②Find the following:",
        how_to_add_reward_punishment_actions_content_3: "③Click the button:",

        how_to_add_reward_punishment_events_title:
          "How to add reward or penalty events for students?",
        how_to_add_reward_punishment_events_content_1:
          "①After logging in, enter the school's profile dashboard;",
        how_to_add_reward_punishment_events_content_2: "②Find the following:",
        how_to_add_reward_punishment_events_content_3:
          "③Find the corresponding student by email or name:",
        how_to_add_reward_punishment_events_content_4:
          "④Add the corresponding event for that student:",

        can_school_admin_user_apply_reward_title:
          "Can school account administrators redeem for rewards?",
        can_school_admin_user_apply_reward_content_1: "Yes, they can.",
        can_school_admin_user_apply_reward_content_2:
          "Reward application records are not visible to any other staff. Check your personal profile to see a record of your redemption history:",

        what_can_you_do_after_joined_school_glory_title:
          "What can be done after joining the School Glory platform?",
        what_can_you_do_after_joined_school_glory_content_1:
          "①Add school-specific behaviours that can gain or lose students points;",
        what_can_you_do_after_joined_school_glory_content_2:
          "②Add information about school teaching staff;",
        what_can_you_do_after_joined_school_glory_content_3:
          "③Add information about enrolled students, which can also be added by school staff members using their accounts;",

        how_can_school_cancel_auto_charge_title:
          "How can schools cancel auto-renewal?",
        how_can_school_cancel_auto_charge_content_1:
          "①After logging in, enter the school's profile dashboard;",
        how_can_school_cancel_auto_charge_content_2:
          "②Find Settings->Change Payment Method->Cancel Auto-Renewal",

        how_to_apply_reward_school_title: "How to redeem for rewards?",
        how_to_apply_reward_school_content_1:
          "①Find the Rewards Marketplace in the homepage area:",
        how_to_apply_reward_school_content_2:
          "②Enter the Rewards Marketplace and find all rewards that you qualify for:",
        how_to_apply_reward_school_content_3:
          "③Select a reward that interests you and click to enter;",
        how_to_apply_reward_school_content_4:
          "④Choose your preferred usage method:",
        or: "or",

        how_to_change_password_content_2_school: "How to change the password?",
        how_to_change_password_content_2_school_login: "② Go to school login;",

        // STORE=========================
        mearningful_store_title: "Meaning of Reward Store(Provider)?",
        mearningful_store_content:
          "The goal of School Glory is to guide students towards positive and constructive development. As well as providing students with rewards for learning, we also encourage the involvement of students' families in applying for rewards. This not only motivates students but eases the financial burden on their families. Our reward store also allows teachers and their families to access the rewards, acknowledging and rewarding their work. Our ultimate aim is to reduce instances of bullying on campuses and create healthier, happier learning environments. We aim to unite forces from various sectors of society to collectively safeguard the future of children. The Reward Marketplace aims to create a culture of motivation and recognition that will encourage students, families, and educators toward increased learning and positive attitudes. Ultimately, we hope to create a better future for society as a whole.",
        sutable_for_what_store_title:
          "What Kind of Businesses Are Suitable to Join?",
        sutable_for_what_store_content_main_1:
          "Any eligible business is welcome to apply to join School Glory. We want to offer students, parents and educators the best range of rewards and benefits available. We are looking for reward content that aligns with students' interests while also alleviating family financial burdens. We also allow educators and their families to redeem for rewards.",
        sutable_for_what_store_content_main_2:
          "Any business can join School Glory, unless it belongs to an explicitly excluded industry. We want to offer a diverse range of rewards that can appeal to the range of people in our user base. For example:",
        sutable_for_what_store_content_1:
          "· Celebrities and Stars: Offer exclusive live meet-and-greets to students who earn more than 10 reward points, and teachers.",
        sutable_for_what_store_content_2:
          "· Online and Offline Reward Providers: Offer exclusive events, discounts, etc. to students who earn more than 10 reward points, and teachers.",
        sutable_for_what_store_content_last:
          "These examples illustrate the variety of rewards we include to give students and educators rich choices from various Reward Providers.",
        which_kind_of_store_not_allowed_to_join_school_glory_title:
          "Which Industries Are Not Allowed to Join?",
        which_kind_of_store_not_allowed_to_join_school_glory_content_main:
          "As School Glory's target audience is students, educators, and their families, we have certain restrictions on reward content. To maintain the platform's integrity and educational nature, we restrict the following industries from joining. Please note, registration fees will not be refunded if a business is found to operate in a restricted industry during the review process. Please consider this carefully when applying:",
        which_kind_of_store_not_allowed_to_join_school_glory_content_1:
          "①Adult Entertainment Industry",
        which_kind_of_store_not_allowed_to_join_school_glory_content_2:
          "②Gambling Industry",
        which_kind_of_store_not_allowed_to_join_school_glory_content_3:
          "③Drug Industry",
        what_store_user_can_do_title: "What Can Reward Providers Do?",
        what_store_user_can_do_content_1:
          "①Set their own reward scores and reward content eligible for application;",
        what_store_user_can_do_content_2:
          "②Set the validity period for rewards;",
        what_store_user_can_do_content_3:
          "③Provide a convenient channel for businesses through referral methods, eliminating the need to build a platform and enabling participation on an existing platform, such as online stores, physical stores, etc.;",
        what_store_user_can_do_content_4:
          "④Customize the audience for reward content: students (and their families), teachers (and their families), or both students (and their families) and teachers (and their families);",
        what_datas_store_user_needs_title: "What Information is Required?",
        what_datas_store_user_needs_content_1:
          "· Logo (Best display size: 108*108);",
        what_datas_store_user_needs_content_2:
          "· An image representing the store (Best display size: 700*700);",
        what_datas_store_user_needs_content_3: "·Store name;",
        what_datas_store_user_needs_content_4:
          "·Store description (200 words);",
        what_datas_store_user_needs_content_5:
          "· Reward validity period (modifiable after successful entry);",
        what_datas_store_user_needs_content_6:
          "· Application conditions (the reward score required for students to redeem for your offered reward);",
        what_datas_store_user_needs_content_7:
          "· Store type: Online/Offline/Online and Offline; (By default, all physical stores can redeem for rewards, but if there are limitations, specific branches need to be noted);",
        what_datas_store_user_needs_content_8: "· Rango de edad aplicable;",
        what_datas_store_user_needs_content_9:
          "· Main reward products/content, e.g., tableware, electronics, learning materials, celebrities, entertainment, etc.;",
        what_datas_store_user_needs_content_10:
          "·Applicable applicants: Teachers (and families); Students (and families); Teachers (and families) + Students (and families);",
        what_datas_store_user_needs_content_11:
          "· If there is an online platform, select platforms, e.g., Amazon; AliExpress; eBay; Taobao, etc;",
        what_datas_store_user_needs_content_12:
          "· Attach the website URL that can direct to your online platform;",

        what_school_glory_will_offer_for_store_user_title:
          "What Access Channels Does the Platform Provide?",
        what_school_glory_will_offer_for_store_user_content_1:
          "· Online store;",
        what_school_glory_will_offer_for_store_user_content_2:
          "· Physical store;",
        how_can_store_user_check_if_applier_met_require_title:
          "How Can Businesses Determine If Students or Teachers Meet the Reward Criteria?",
        how_can_store_user_check_if_applier_met_require_content_1:
          "· Online stores: Upon clicking the reward application, users will receive a reward code. Reward Providers can verify if the applying user meets the criteria by entering the application ID in Reward provider's profile dashboard;",
        how_can_store_user_check_if_applier_met_require_content_2:
          "· Physical stores: Upon clicking the reward application, users will receive a reward QR code. Reward Providers can scan the QR code in Reward provider's profile dashboard to verify if the applying user meets the criteria;",
        how_can_store_user_sort_title:
          "What Is the Default Sorting Order on the Reward Marketplace Homepage Based On?",
        how_can_store_user_sort_content_1:
          "①The lower the reward score requirement, the higher the ranking;",
        how_can_store_user_sort_content_2:
          "②More rewarded individuals are ranked higher;",
        how_can_store_user_sort_content_3:
          "③The earlier the join date, the higher the ranking;",
        how_can_store_user_sort_content_4:
          "④More positive reviews result in a higher ranking;",

        how_to_calculate_reward_amount_title:
          "How Are Student Reward Scores Calculated?",
        how_to_calculate_reward_amount_content_positive_main:
          "Every student's initial reward score is 5, and after the school joins the School Glory platform, it can customize up to 10 reward behaviors and 10 penalty behaviors,",
        how_to_calculate_reward_amount_content_positive_main_1:
          "School users are recommended to add reward behaviors as follows:",
        how_to_calculate_reward_amount_content_1:
          "①Bravely reporting evidence of bullying for classmates +10;",
        how_to_calculate_reward_amount_content_2:
          "②Stopping bullying behavior and seeking help from teachers when unable to stop it +10;",
        how_to_calculate_reward_amount_content_3:
          "③Outstanding academic performance (defined by the school) +10;",
        how_to_calculate_reward_amount_content_4:
          "④Improvement in academic performance +1;",
        how_to_calculate_reward_amount_content_negative_main:
          "School users are recommended to add penalty behaviors as follows:",
        how_to_calculate_reward_amount_content_1_negative:
          "①Verbal bullying: Mocking, insulting, threatening classmates -10;",
        how_to_calculate_reward_amount_content_2_negative:
          "②Social bullying: Excluding, isolating, spreading rumors, damaging others' belongings -10;",
        how_to_calculate_reward_amount_content_3_negative:
          "③Physical bullying: Fighting, making insulting gestures -10;",
        how_to_calculate_reward_amount_content_4_negative:
          "④Falsifying to obtain reward scores -10;",
        how_to_calculate_reward_amount_content_last:
          "Other rules defined by the school specify behaviors for rewarding and penalizing students, providing a framework for students' reward and penalty actions.",
        steps_for_store_users_title:
          "Steps for Businesses to Join the Reward Marketplace:",
        steps_for_store_users_content_title_1: "①Fill in Basic Information:",
        steps_for_store_users_content_1:
          "First, complete the business' basic information. Provide accurate and complete details to ensure a smooth process;",
        steps_for_store_users_content_title_2: "②Pay verification Fees:",
        steps_for_store_users_content_2:
          "After providing the basic information, a verification fee is required for the verification process;",
        steps_for_store_users_content_title_3:
          "③Verify Business Qualifications:",
        steps_for_store_users_content_3:
          "We will review the information submitted by the business to ensure compliance with entry conditions in order to maintain the quality and credibility of the Reward Marketplace. Friendly reminder: Please read the FAQ for reward provider carefully as the verification fee will not be refunded if the business does not meet the joining conditions;",
        steps_for_store_users_content_title_4:
          "④After Verification, Select Payment Method:",
        steps_for_store_users_content_4:
          "Once the business is verified, you can choose the method of paying the entry fee. You can pay annually or purchase a lifetime licence so you can choose which best fits your business plans;",
        steps_for_store_users_content_title_5: "⑤Successful Store Listing:",
        steps_for_store_users_content_5:
          "After payment, your store will be successfully listed in the reward store, allowing your products or services to be showcased;",
        steps_for_store_users_content_last:
          "These steps will ensure a smooth process for joining the Rewards Marketplace. They will position your business for greater exposure within the Marketplace to inspire growth for your business.",
        how_to_modify_reward_title: "How to Modify Reward Content?",
        how_to_modify_reward_content_1:
          ".  After logging in as a Reward Provider, access your Reward Provider dashboard;",
        how_to_modify_reward_content_2:
          ". Find 'Edit Profile,' where you can modify reward content and validity period;",
        how_to_withdraw_from_display_title:
          "How to Temporarily Withdraw from the Reward marketplace?",
        how_to_withdraw_from_display_content_main:
          "In certain situations, your reward application may need to be temporarily removed from the reward marketplace. You can follow these steps to temporarily withdraw it:",
        how_to_withdraw_from_display_content_1:
          ". After logging in as a Reward Provider, access the Reward Provider dashboard;",
        how_to_withdraw_from_display_content_2:
          ". Go to Settings -> Account Status -> Turn off the display switch.",
        how_to_change_payment_method_title: "How to Change Payment Method?",
        how_to_change_payment_method_content_main:
          "Reward provider registrations are automatically set to auto-renewal. If you need to cancel automatic renewal or change the payment method, you can follow these steps:",
        how_to_change_payment_method_content_1:
          ". After logging in as a Reward Provider, access the Reward Provider dashboard;",
        how_to_change_payment_method_content_2:
          ". Go to Settings -> Subscription Status -> Click on 'Cancel Auto-Renew' button or 'Change Payment Method'.",
        how_to_change_password_title_store: "How to Change Password?",
        how_to_change_password_content_1: "①Access the login page;",
        how_to_change_password_content_2_store:
          "②Enter the Reward Provider login;",
        how_to_change_password_content_3: "③Click on 'Forgot Password';",
        how_to_change_password_content_4: "④Enter Email + Login ID;",
        how_to_change_password_content_5:
          "⑤Retrieve the verification code from the email;",
        how_to_change_password_content_6:
          "⑥Verification code + New password = Successful password change;",

        //=================================
        ex: "celebrities ，Bookstore，Stores,Shopping malls...",
        setting: "Settings",
        awaiting_comment: "Awaiting review",
        school_name_of_student: "School name",
        only_admin_user_can_cancel_account:
          "Note: Only administrators can deactivate accounts.",
        basic_plan_explain:
          "Basic Plan (Suitable for up to 100 teaching staff)",
        stander_plan_explain:
          "Standard Plan (Suitable for up to 100 teaching staff)",

        pro_plan_explain:
          "Premium Plan (Suitable for up to 100 teaching staff)",
        reward_store: "Reward Provider",
        teaching_staff: "Teaching staff",
        teacher_name: "Teacher's name",
        add_punishment_rules: "Add deduction action",
        error_in_prolong_payment:
          "An error occurred in the renewal process, please renew again",
        active_date: "Activation date",
        terminal_at: "Expiration date",
        sate_prolong: "Status: Auto-renewal",
        cancel_prolong: "Cancel auto-renewal",
        after_cancel_you_still:
          "After canceling the subscription, you can still use until",
        confirm_cancel_sub: "Confirm subscription cancellation",
        displaying_in_homepage:
          "Currently displaying on the Reward marketplace homepage...",
        not_displaying_in_homepage:
          "No longer displayed on the Reward marketplace homepage...",
        You_match_id_teacher:
          "Your identity: Teacher - see all rewards you are eligible  for",
        You_match_id_school:
          "Your identity: School Administrator - see all rewards you are eligible  for",
        your_total_points: "Your total points",
        able_to_apply: "Eligible to redeem for rewards",

        users_apply_records:
          "Below are the records of students or teachers who have applied for rewards from you.",
        payment_statement: "Renewal Status",
        store_login_title: "Reward Provider Login",
        enter_web_url: "Please enter the URL of your corresponding platform",
        enter_platform_name:
          "Please enter the platform name, for example, Amazon",
        reward_that_you_are_able_to_apply_now:
          "   The following are rewards you can currently redeem",
        temp_no_reward_to_apply:
          "No rewards available for application at the moment",

        all_over_the_world: "All over the world",
        not_comment_yet_apply:
          "The following are unreviewed reward applications",
        event_time: "Date",
        faq: "FAQ",
        Allow_add_reward_behavior:
          "On this page: Teachers and platform administrators can input behaviors for which students can be rewarded, and modify and delete behaviors they added. Please note that once deleted, they cannot be recovered. (Suggestion: Where possible, schools should try to get students and parents feedback before making decisions).",

        Allow_add_punishment_behavior:
          "On this page: Teachers and platform administrators can input behaviors for which students will receive points deductions. (Suggestion: Where possible, schools should try to get students and parents feedback before making decisions).",

        School_change_to_another_payment_method:
          "Please note: When the account is suspended, students and their families, as well as teaching staffs and their families, will be unable to redeem for rewards. Please consider carefully disabling the auto-renewal feature.",

        stander_plan: "Stander Plan",
        get_all_reports_explain:
          "Friendly reminder: To get the full story of a students' report, schools are advised to promptly contact students and thoroughly investigate the facts.",
        according_reward_points: "Reward points for corresponding actions",
        according_punish_points: "Deduction points for corresponding actions",
        register_fee: "Verification fee",
        customize_reward_conditon: "Customize reward content",
        help_let_others_know_your_store:
          "Drive traffic to your physical or online store",
        can_cancle_or_change_method_anytime:
          "You can pause services and change payment methods at any time",
        unlimit_use_new_methos:
          "Unlimited free use of all upgraded features thereafter",
        pricing_for_reward_store: "Reward provider",
        check_details: "Check details",
        suitable_for_stores_type:
          "Applicable channels: Online stores, physical stores",
        your_experience: "Your experience",
        leave_your_rate:
          "Rate your satisfaction with this service/shopping experience",
        user_not_meet_applicant_requirement:
          "The user does not meet the application requirements!",
        user_meet_applicant_requirement:
          "The user meets the application requirements!",
        commit_to_reward: "Confirm reward!",
        successfully_rewarded: "Successfully rewarded!",
        school_user: "School",
        not_rewarded: "Not rewarded",
        date_of_use: "Date of use",
        reward_now: "Reward User",
        use_now: "Use Now",
        customize_reward_behaviors_for_this_school:
          "Set positive behaviour expectations for students",
        customize_deduction_behaviors_for_this_school:
          "Outline deduction behaviors for students to help them avoid negative consequences",
        add_penalty_events:
          "Adward students points for positive behaviours and deduct them when needed.",
        add_reward_rules: "Outline rules so students know what to expect",
        students_teachers_and_their_families_in_this_school_can_use_the_rewards_provided_in_the_reward_store:
          "This school's students, teachers, and their can access and enjoy the rewards available in the Reward Marketplace.",
        able_to_add_teaching_staff: "Suitable for up to 100 teaching staff",
        suitable_to_users_school:
          "Applicable to: Kindergarten, Primary School, Middle School, Junior High School, High School, Colleges, and other educational institutions.",
        pricing_for_school: "School",
        undeal: "Awaiting",
        dealed: "Resolved",
        all_reports: "All reports",
        reward_invalid_now:
          "The reward has expired, or the reward provider has removed this reward.",
        stores: " Rewards Marketplace",
        pricing: "Pricing",
        store_register_title: "Rewards Provider Registration",
        your_logo: "Your store's logo (best display size: 108*108)",
        your_store_cover_image:
          "Please upload a cover image that represents your store (best display size: 700*700)",
        your_display_name: "Your store's name",
        store_intro: "Store introduction",
        reward_content_short: "Reward details",
        reward_valid_date:
          "Reward valid until (this can be updated anytime after setting):",
        reward_condition:
          "Minimum reward points required(minimum reward points required for student rewards. Friendly reminder: Default ranking is based on reward points requirement from low to high)",
        store_type:
          "Available claim reward channel(s) (Friendly reminder: Physical stores default to all locations being able to use the rewards you provide)",
        online_store: "Online Store",
        physical_store: "Physical Store",
        online_physical: "Online and Physical Stores",
        place_to_use_title: "Place of use",
        product_type_title: "Please select the main product category",
        available_to_title:
          "Applicable applicants (including students' families, teachers, and their families)",
        tableware: "Tableware",
        bedding: "Bedding",
        clothing: "Clothing",
        electronics: "Electronics",
        furniture: "Furniture",
        food: "Food",
        cosmetics: "Cosmetics",
        sporting_goods: "Sporting Goods",
        books: "Books",
        jewelry: "Jewelry",
        fresh_produce_market: "Fresh Produce Market",
        supermarket: "Supermarket",
        school_supplies: "School Supplies",
        real_estate: "Real Estate",
        restaurants: "Restaurants",
        tourist_attractions: "Tourist Attractions",
        amusement_parks: "Amusement Parks",
        celebrities:
          "Celebrities (Entertainers, Athletes, Politicians, Authors, Directors, Top 500 Business Owners)",
        film_production: "Film Production",
        sports_clubs: "Sports Clubs",
        news_agencies: "News Agencies",
        education: "Education",
        hotels: "Hotels",
        travel_agencies: "Travel Agencies",
        banks: "Banks",
        transportation: "Transportation",
        gas_stations: "Gas Stations",
        game: "Games",
        study_app: "Study Apps",
        // influencer: "Influencer",
        car: "Car",
        other: "Other",
        available_for_who_title:
          "Applicable Applicants (including students' families, teachers, and their families)",
        teacher: "Teachers (including family)",
        student: "Students (including family)",
        student_teacher: "Students + Teachers",
        support_platfrom_title: "Please select a supported online platform",
        online_store_url_title:
          "Please enter a URL that links to your store. Please double-check to ensure it is a valid link.",
        dont_forget_reward_tip:
          "Friendly reminder: After providing the reward to the user,please remember to click the reward button. This is crucial to improve your ranking in the reward marketplace!",
        please_enter_id_search_bar: "Please enter ID...",
        awaiting_reward: "Awaiting Reward",
        rewarded: "Rewarded",
        valid_till: "Valid until",
        apply_condition_score: "Minimum reward points required",
        rewarded_amount: "Quantity of rewarded participants",
        available_to: "Applicable applicants",
        store_type_short: "Available claim reward channel(s)",
        product_type: "Main products",
        reward_content: "Reward content",
        valid_date: "Valid until",
        online_platform: "Reward available to claim on the online store",
        sub_state: "Subscription Status",
        account_state: "Account Status",
        cancel_account: "Cancel Account",
        upload_store_tips:
          "Every day, 30 approved reward stores will be updated in the order of application.",
        please_enter_store_name: "Please enter the store's name...",
        join_stores_btn: "Apply to join as a reward provider",
        not_allow_upload_content:
          "Please carefully read the information about prohibited uploaded content. Uploading items that violate the prohibited uploaded content policy or other usage regulations will result in the rejection of your application, and any fees paid will not be refunded. We encourage you to thoroughly check your products and content before submission to ensure their legality and compliance with our guidelines. Our aim is to create a secure, lawful, and respectful rewards list to safeguard students, teachers, and their families. Thank you for your support and cooperation.",
        not_allow_url: "Not allowed to upload items",
        awaiting_verify_store_notice:
          "Verification in progress... (awaiting manual verification, 1-15 working days; upon successful verification, you will proceed to the next step)",
        success_verify_tip:
          "Congratulations! You have successfully passed verification.",
        finish_last_step:
          "Please complete the final step by selecting your subscription method to formally join the Reward Marketplace list.",
        pay_by_month: "Month",
        pay_by_year: "Year",
        pay_by_life: "Lifetime",
        failed_apply: "Application failed!",
        reason: "Reason",
        basic_plan: "Basic Plan",
        standard_plan: "Standard Plan",
        pro_plan: "Premium Plan",
        ranking_now: "Current global ranking",
        punishment_amounts: "Total penalty events",
        reward_amount: "Total reward events",
        reported_amount: "Reports received",
        enter_reward_rules: "Setup reward actions rules",
        enter_punish_rules: "Setup penalty actions rules",
        add_teacher: "Add teaching staff",
        your_setted_reward_expired:
          "The validity period of your set reward has expired, please proceed to update.",
        to_update: "Update",
        canceled_not_allowed_restore:
          "After account cancellation, all data will be irretrievable. Are you sure you want to cancel?",
        confirm_cancel_account: "Confirm Account Cancellation",
        your_account_blocked: "Your account has been blocked!",
        re_subscribe: "Resubscribe",
        displaying_tip: "Currently displaying in the reward marketplace...",
        remove_display_tip: "Removed from the reward marketplace...",
        reward_expired_tip:
          "Your reward has expired and is no longer displayed in the reward marketplace. Please extend the validity period promptly.",
        record_of_applying_title:
          "The following is a record of students or teachers applying for rewards from you.",
        user_experience: "User Experience for All",
        user_apply_failed:
          "Application failed. You do not meet the criteria for this reward!",
        apply_reward: "Claim Reward",
        qr_code_apply_tip:
          "Note: For physical stores, please display the QR code. For online stores, copy the reward ID and send it to the store owner during payment to receive your reward.",
        apply_reward_success: "Application Successful!",
        coppy_id:
          "Please copy the following ID before claiming your reward from the store.",
        use_qr_code:
          "Here is your exclusive QR code. Please present it to the store owner during checkout.",
        to_online_shop: "Click to visit the online shop",
        valid_date_to: "Valid until",
        use_it_soon: "Please use it soon",
        select_your_plan_tip: "Select your payment plan",
        year_plan: "Year",
        joined_students_amount: "Students",
        private_teacher_reward_apply:
          "Private Reward Application for Teaching Staff",
        private_teacher_explain:
          "On this page: Teaching staff can privately redeem for their chosen rewards. This interface is only visible to logged-in teaching staff. Each staff member can only view their own application history.",
        add_student_page_explain:
          "On this page: View all enrolled students and add individual student information, including student avatar, student email, student class information, and the validity of the student's account at this school. After adding students, it's possible to modify student details, delete students, and add rewards or penalty events for students. (Note: When created, each student's account password is the same as their email. If a student wishes to reset their password, they must proceed to the login page -> student -> forgot password -> reset password to make modifications.)",
        explain_add_student:
          "Purpose: ① Add/Update/Delete student information; ② Add rewards or penalty events for students.",
        name: "Name",
        edit_student_info: "Edit Student Information",
        enter_student_info: "Please enter student's",
        enter_student_btn: "Add Student",
        search_content: "Search Content",
        student_name: "name",
        student_email: "Email",
        student_info: "Student Grade/Class",
        student_valid_year:
          "Validity of student account at this school (default is the selected year + 3 months)",
        delete_student_account_notice:
          "Permanently delete student, and cannot be restored",
        add_event_to_student: "Add penalty or reward events for students",
        student_account_valid_to: "Account Validity",
        valid_accounts: "Valid Accounts",
        expired_accounts: "Expired Accounts",
        reward_behaviar: "Reward Behavior",
        punish_behaviar: "Deductible Actions ",
        accord_to: "Reason",
        detail_added: "Additional Details",
        punished_page_explain:
          "On this page: Retrieve all recorded penalty events.",
        rewarded_page_explain:
          "On this page: Retrieve all recorded reward events.",
        add_teacher_page_explain:
          "On this page: Teaching staff and platform administrators can enter the email addresses of teaching staff permitted to use this platform (When created, account passwords are the same as the teacher's email. If a password change is required, the teacher user needs to proceed to the login page -> teacher -> forgot password -> reset password to modify.)",
        allow_add_teachers: "Current allowed number of entered teaching staff",
        add_teacher_email_btn: "Add Teacher's Email",
        searcher_teacher_info: "Search Teacher's",
        total_reward_points: "Total Reward Points",
        total_punish_points: "Cumulative Penalty Points ",
        check_available_to_reward_btn: "Redeemed rewards",
        available_to_reward_amount: "Available rewards for redemption",
        rewarded_history: "Reward History",
        punished_history: "Penalty History",
        one_click_btn: "Report an incident",
        thank_you_for_reporting:
          "Thank you for your report. School staff will investigate this matter promptly. Please wait for a notification!",
        report_content: "Report Content",
        upload_evidence: "Please upload evidence",
        able_to_reward_bahavier: " Reward Behaviors ",
        able_to_punishment_behavier: " Deductible Actions ",
        teacher_login_title: "Teaching Staff Login",
        you_have_successfully_rewarded:
          "You have successfully applied for this reward!",
        comments_explain:
          "On this page: Please provide comments based on your actual experience. Your encouragement will brighten the Reward Provider's day!",
        go_comment_btn: " Share Experience ",
        reapply_tip: "Apply again",
        please_enter_id: "Please enter ID...",
        sub_expired_date: "Expiration Date",
        sub_method: "Subscription Method",
        re_sub: "Renew Subscription",
        change_payment_method: "Change Payment Method",

        //===================================
        add_school_hero_btn: "Add student",
        pl_enter_a_hero_name: "Please Enter student's Name...",
        search: "Search",
        add_a_hero_btn: "Add student",
        our_heros: "Our League of students",
        total_heros: "Total Number of students",
        are_you_sure_to_remove: "Are You Sure You Want to Remove ",
        no_record_yet: "No records found",

        out_of_limit: "You have exceeded the word limit.",
        send_us_msg_title: "Send us message",
        send_us_msg_content:
          "If you have any questions, please feel free to drop us a line. If you don't get an answer immediately, it is because more users are awaiting a response ahead of you. Please don't worry, we will get back to you as soon as we can. Thank you so much for your patience and understanding.",
        send_us_msg_btn: "Send us message",
        follow_us_title: "Follow us,",
        follow_us_sub: "Can help you get our latest news:",
        contact_us_title:
          "We highly value your questions. To enable us to better respond to your inquiries, please ensure the information you provide is genuine and accurate. We will carefully review the information provided and, once verified, we will respond as soon as possible.",
        form_your_name: "*Your name",
        form_your_email: "*Your Email",
        from_your_web: "*Your website or LinkedIn link",
        from_your_msg: "The message you want to send us",
        thank_you_email: "Thank you so much for emailing us!",

        contact: "Contact",
        newPassword: "Please enter your new password...",
        dear_school_user_title: "Dear User ,",
        issue_content:
          "There was an issue with your application process. We apologize for the issues you encountered. Our team is working to investigate the matter thoroughly and will promptly provide a solution via email. Please keep an eye on your inbox for our response. Thank you for your patience.",
        support_bank_card:
          "Accept Visa, Mastercard, American Express, Discover, Diners Club, and China UnionPay(中国银联) Alipay(支付宝) payments from customers worldwide.",
        not_the_time:
          "You cannot rejoin School Glory yet, please retry after: ",
        view: "More Information Here",
        register_notice_tip_user:
          "We will require an email verification. Please ensure the accuracy of your email address to ensure a smooth verification process. We regret to inform you that if the verification fails, we will be unable to refund the registration fee.  Thank you for your support and understanding.",
        match_requirement: "Matched School(s) :",
        get_verification_code: "Obtain verification code",
        inComplete: "The uploaded information is incomplete!",
        only_school:
          "Only schools that consistently prioritize the safety and well-being of students can be included in the list.",
        schools_has_joined:
          "Currently,schools successfully on School Glory list:",
        apply: "APPLY NOW →",
        very_important: "Very Important",
        register_notice_tip:
          "After you have completed payment, we will require an email verification. Please ensure the accuracy of your email address to ensure a smooth verification process. We regret to inform you that if the verification fails, we will be unable to refund the registration fee. Thank you for your support and understanding.",
        school_not_allow_vote: "Voting is not allowed between schools.",
        reset_password: "Reset password",
        login_id_email_cant_empty: "Email and Login ID cannot be empty!",
        wrong_info_limit:
          "You have exceeded the limit of incorrect attempts and will be listed as a subject of focused scrutiny. Please try again after 24 hours.",
        login_as: "Login As",
        yes: "Yes",
        no: "No",
        unlike_confirm: "Are you sure you want to unlike ",
        more_detials: "Apply Now",
        login_id_is_required: "Login Id is required!",
        please_enter_email_and_password: "Please enter email and password .",
        ranking_validating:
          "Ranking will recalculate once you pass the validation.",

        password_rule:
          "Password should contain minimum 4 characters, with one UPPERCASE, lowercase, number and special character: @$! % * ? &",
        Must_add_one_UPPERCASE_letter: "Must add one UPPERCASE letter!",
        Must_add_one_lowercase_letter: "Must add one lowercase letter!",
        Must_add_one_umber: "Must add one number!",
        Must_add_one_special_symbol: "Must add one special symbol: @$! % * ? &",
        weak: "weak",
        fair: "fair",
        good: "good",
        strong: "strong",
        passwod_strength: "Password strength validation",
        we_havent_found_a_school_that_meets_requirement:
          "We haven't found a school that meets those requirements at the moment.",
        //who voted to
        who_voted_to: "Users who supported to  ",
        de_yonghu: "",
        //password forgorten
        please_enter_your_email_below_to_get_your_verify_code:
          "Please enter your Email below to get your verify code.",
        //log in to continue
        login_btn: "Log In",
        login_to_continue_title: "Log in to continue",
        login_to_continue_content:
          "Let's come together to support and empower schools that are committed to nurturing students's dreams!",
        click_create_account: "Click to create an account",
        no_account_yet: "Don't have an account yet? Sign Up",
        loading: "Loading...",
        user_login_title: "Login as student user",
        password_forgotten: " Password forgotten?",
        all: "All",
        logo: " School Glory",
        get_started: "More Details",
        no_school_found:
          "We haven't found a school that meets the requirements at the moment.",

        //header
        login_nav: "Login",
        register_nav: "Register",
        about_nav: "About",
        //Home
        title: "Let school become the best place for building dreams!",
        subtitle:
          "We are dedicated to reducing school bullying while guiding students towards positive paths.",
        search_bar_placeholder: "Enter a school name...",
        location: "Location",
        type: "Type",
        total: "Total",
        // search: "Search",

        //schools type
        Kindergarten: "Kindergarten",
        Primary: "Primary School",
        Middle: "Middle School",
        High: "High School",
        Tenical: "Technical secondary school",
        College: "College",
        University: "University",
        Other: "Other",
        worldwide: "Worldwide",
        language_suggetion_title:
          "What language is recommended for filling out information?",
        language_suggestion_content:
          "It is recommended to use your native language.",

        //School Details
        joined_us_on: "joined us on",
        rest_content:
          "which means that the school is committed to providing students with a safe and nurturing learning environment.",
        you_have_liked: "You have Liked",

        //About
        about_title: "About Us",
        phase_1:
          "Every time I hear of children being harassed or hurt in school, my blood boils, and I find myself gritting my teeth. My name is Dongjing Zhong, and I’m the founder of School Glory. I have been contemplating addressing this issue for a long time. Realizing that hatred only spawns more enmity, I decided to change my approach and tackle this issue positively: How can I encourage students to stop bullying in schools while motivating them to learn and guiding them on the right path?",
        phase_2:
          "The idea of providing learning incentives to students was inspired by the reward mechanisms in games. I learned that many children are more interested in playing video games than studying. Most video games that attract players have clear objectives and reward systems. If players progress by overcoming simple and difficult challenges to earn different rewards, why not apply a similar incentive mechanism to encourage children to stop bullying in schools while igniting their interest in studying? This sparked the idea of bringing together companies worldwide to create a Rewards Marketplace offering exciting prizes for children. For instance, when they reach a score of 10 points, they could have the opportunity to see their favorite celebrity live or  assist their families in acquiring essential products at more affordable prices. These scores would be based on regular positive behaviors, such as improvement in studies (+1 point)， bravely report bullying incidents at school (+10 points), among other options. Conversely, behaviors such as mocking, insulting, or threatening classmates (-10 points); exclusion, spreading rumors, or damaging others’ belongings (-10 points); fighting or making insulting gestures (-10 points), among others, would deduct points.",
        phase_3:
          "Not only can students request rewards, but their families can also participate and redeem them in the Rewards Marketplace. This would not only motivate students but also assist families in easing their burdens and strengthening family ties. Our Rewards Marketplace is also open to teachers and their families. Teachers or their families can apply for rewards in the Rewards Marketplace without needing points. Our ultimate goal is to reduce campus bullying and foster a healthier, happier learning environment. We aspire to gather support from all sectors and society to safeguard our children’s future.",
        phase_4:
          "Given that most people spend their formative years until they are 25 years old in educational institutions, schools have the chance to lead young individuals on the right path. Schools are seen as a second home for children, where minds and personalities are formed. ",
        phase_4_1:
          "Educational establishments should be safe, harmonious, and jubilant places where dreams can be fulfilled. Everyone should work together to transform every school into an oasis for children's growth and development!Together, we can forge a harmonious and secure learning environment for our students through united efforts. Join us on this transformative journey toward fostering a brighter, safer educational landscape. Let us champion the cause of our children’s well-being.",
        story_behind_btn: "Story Behind",
        //Register Notice
        notice_title: "REGISTRATION INSTRUCTIONS",
        notice_1:
          "1. To be eligible for participation, the applicant must be an educational institution of any kind, including kindergarten, primary school, secondary school, technical institute, college or university. ",
        notice_2:
          "2. Upon joining School Glory, it is mandatory to demonstrate a resolute commitment towards creating a healthy, happy and secure learning environment for students by opposing all kinds of violence on campus and accepting public supervision. ",
        notice_3:
          "3.The ranking in School Glory is based on the number of supportive votes received and the order in which a school joins the platform. Schools with more support votes and who join earlier will be ranked higher, encouraging wider participation and allowing for collective recognition of success. ",
        notice_4: `4. School Glory requires a one-time registration fee of € ${
          import.meta.env.VITE_REGISTER_PRICE
        }(Accept Visa, Mastercard, American Express, Discover, Diners Club, and China UnionPay(中国银联) payments from customers worldwide). The fees collected are used to establish the School Glory Charitable Foundation.`,
        notice_4_1: "• Randomly monitor schools affiliated with School Glory",
        notice_4_2:
          "• Conduct comprehensive research on harassment or violence issues occurring on campuses",
        notice_4_3:
          " • Collaboratively work with education experts to identify and provide solutions for these problems",
        notice_4_4: "• Gradually reduce and eliminate violent behaviors",
        notice_4_5:
          "We firmly believe that by working together, we can create a harmonious and safe learning environment for students.",
        phase_5:
          "5. The registration process requires a manual review and takes approximately 7 to 15 business days. Schools must provide the following information: ",
        notice_5_1: "• An official school email address.",
        notice_5_2: "• The full name of the school.",
        notice_5_3: "• The location.",
        notice_5_4: "• The type of school.",
        notice_5_5: "• A school emblem. （Optional）",
        phase_6:
          "6. Payment must be made through the school's bank account, which should match the registered name of the school. Please note that no refunds will be issued if the registration is not approved. Therefore, it is important to provide accurate and complete information to ensure successful registration.Once the verification is completed, we will send the digital receipt to the official email address you have provided via email.",
        phase_7:
          "Once a school applies to join School Glory,they are required to establish the Campus student League within 3 months.",
        agree_and_register_btn: "Done And Register",
        //Story Behind
        story_behind_title: "Our Story",
        story_behide_1: "My name is Dongjing Zhong, the founder of Gears Run  ",
        story_behind_1_1: "and the creator of the School Glory platform.",
        story_behide_2:
          "Every time I hear of children being harassed or hurt in school, my blood boils and I find myself gritting my teeth.",
        story_behide_3:
          "For a long time, I have been contemplating how to address this issue at its core.",
        story_behide_4:
          "Realizing that hatred only spawns more enmity, I came up with a proactive approach to tackle this issue by creating School Glory. It is a platform that encourages schools which are dedicated to creating a peaceful, joyful and safe atmosphere for students to join.",
        story_behide_5:
          "However, if a school experiences a campus violence incident, they are required to voluntarily withdraw from School Glory until the problem is resolved and there is no further violence in the span of three months. Only then can they rejoin. School Glory is a hall of honor, recognizing schools that consistently prioritize the safety and well-being of their students.",
        story_behide_6:
          "At first, I planned to let schools sign up for free. But it was clear that we had to establish a verification system to combat fraudulent accounts. Instead, a one-time registration fee was implemented, and the funds from this fee will be used to form the School Glory Charity Foundation. The goal of this organization is to help reduce bullying and promote a safe and healthy educational environment within the school.",
        story_behide_7:
          "When you start walking the way ,the way appears .Join us at School Glory and make educational institutions the best place to build dreams!",

        //Register
        register_as: "Register as",
        user: "Student",
        school: "School",
        school_glory_user_register: "School Glory User Register ",
        password: "Password",
        username: "Username",
        role: "Role",
        upload: "Upload",
        agree_and_continue: "Agree and Continue",
        school_glory_school_register: "school user registration",
        school_official_email: "School Official Email",
        school_name: "School full name",
        school_type: "School type",
        school_location: "School location",
        school_address: "Complete address: ",
        school_address_ex:
          "For instance: 123 Main Street, New York City, New York, 10001, United States",
        school_emble: "School emblem",
        image_require:
          "Please use an image with a white background and in JPG/PNG format.",
        process_payment: "Next",
        verify_code_send: "The verification code has been sent to your email",
        enter_verify_code: "Please enter the verification code...",
        notice: "Notice :",
        register_notice:
          "Payment must be made through the school's bank account, which should match the registered name of the school. Please note that no refunds will be issued if the registration is not approved. Therefore, it is important to provide accurate and complete information to ensure successful registration.",
        payment_btn: `Pay €${import.meta.env.VITE_REGISTER_PRICE}`,
        processing: "Processing...",
        generate_login_id:
          "The login ID is very important, and the platform only generates it once. Please keep it safe, as you will need it for logging in, changing passwords, and updating personal information. Please ensure that you obtain it in a secure environment.",
        get_login_id_btn: "Retrieve login ID",
        navigate_to_my_profile: "My Profile",
        //School profile
        verifying: "Verifying...",
        error: "Error",
        success: "Success",
        verifying_notice:
          "The registration process requires a manual review and takes approximately 7 to 15 business days. Thank you for your patiance.Once the verification is completed, we will send the digital receipt to the official email address you have provided via email.",
        verify_success: "You have successfully joined School Glory",
        verify_error:
          "Verification failed. We will send you an email to explain the reason.",
        withdrawed: "You have temporarily withdrawn from School Glory.",
        withdraw_popup_1:
          "Do you want to temporarily withdraw from School Glory? If so, we hope you can successfully resolve the issues you are currently facing, and we look forward to your return after three months once you have resolved them.",
        withdraw_popup_2:
          "Congratulations on resolving the issue and having no similar incidents within the past three months! Welcome back to School Glory!",
        // },
        ranking_rule:
          "The rankings of each school are based on the order in which they join. ",
        received_likes: "Received Likes: ",
        ranking: "Ranking: ",
        pl_enter_login_id: "Please enter your login Id:",
        back_to_home: "Return to homepage",
        logout: "Logout",
        edit_profile: "Edit Profile",

        // location: "Location",
        // type: "Type",

        loginId: "Login ID",
        submit: "Submit",
        confirm_remove: "Confirm",
        cancel_withdraw: "Cancel",

        //School Email Back
        notice_top_1: "Please use your school's official email address:",
        notice_top_2:
          "to copy the following information and send it via email to",
        notice_top_3: "for the next verification process : ",
        email_title_tip: "Email Subject :",
        email_content_tip: "Email Content :",
        email_content_for: "Topic : ",
        school_name_email: "School Name: ",
        school_type_email: "School Type: ",
        school_location_email: "School Location : ",
        school_full_address_email: "School Address : ",
        stripe_payment_id: "Verify Code : ",
        important: "Important!",
        are_you_sure_1:
          "Please ensure that you have successfully sent the information to the email address ",
        are_you_sure_2:
          "Please be aware that without this process ,you won't be able to pass the verification.",
        yes_sent: "Yes, email sent!",
        not_sent: "Not yet",

        //User profile
        user_role: "Role",
        edit_user_profile: "Edit User Profile",
        user_name: "Username",
        you_are_a: "You are a",
        parent: "Parent",
        student_: "Student",
        other_role: "Other",
        you_didnt_liked: "You haven't given any school a Like yet.",
        schools_you_liked: "Schools you have Liked",
        give_a_like_btn: "Give A Like",
        about: "About",
        register: "Register",
        login: "Login",
        view_likers_btn: "Who liked you ?",
        //password forgotten
        forgot_password: "Forgot password ?",
        please_enter_your_email_to_get_your_verify_code:
          " Please enter your Email below to get your verify code.",
        if_teaching_staff_needed_rewards_points_title:
          "Do teaching staff and their families need reward points to redeem rewards?",
        if_teaching_staff_needed_rewards_points_content:
          "Teaching staff and their families do not need reward points to redeem rewards. In cases where rewards from reward stores are not specifically designated only for student redemption, teaching staff can redeem rewards.",
        if_student_teaching_staff_allowed_to_re_redeem_rewards:
          "Can student users and teaching staff redeem rewards again after redeeming once?",
        if_student_teaching_staff_allowed_to_re_redeem_rewards_content:
          "Yes, rewards can be redeemed again before the expiration of the reward period set by the reward store.",
        //=================================
        recommend_to_add_rule:
          "Important: It is recommended that school users add the following reward and deduction behaviors.",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_title:
          "When a student is no longer enrolled in this school, such as graduating or transferring, how should their accounts be handled?",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_1:
          "There are 3 possible ways to handle this：",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_2:
          "1.When adding new students, platform administrators are able to set the validity of a student's account in this school. Each account's validity period is determined by the selected year plus three months. When a student's account expires, it will automatically change to an 'expired' status without requiring any action from platform administrators.",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3:
          "2.If you wish to retain the student's information but need to deactivate their school account, change the student's valid account duration to zero years. Here are the steps:",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_1:
          "①Enter school's profile dashboard ;",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_2:
          "②Find the following:",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_3:
          "③Find the corresponding student by email or name,click on 'Edit student information' and set the validity of student account to zero.",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_4:
          "3.Directly delete the student's account. Please note, once the account is deleted, it won't be possible to recover the student's data. Please proceed with caution.",

        what_is_student_initial_reward_point_title:
          "What is the initial reward point for students?",
        what_is_student_initial_reward_point_content:
          "The initial reward point for each student is 5 points.",

        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_title:
          "When a teacher is no longer teaching at this school, how should their account be handled?",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_1:
          "There are 3 possible ways to handle this:",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_2:
          "1.When adding new teachers, platform administrators are able to set the validity of a teacher's account in this school. Each account's validity period is determined by the selected year plus three months. When a teacher's account expires, it will automatically change to an 'expired' status without requiring any action from platform administrators.",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3:
          "2.If you wish to retain the teacher's information but need to deactivate their school account, change the teacher's valid account duration to zero years. Here are the steps:",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_1:
          "①Enter school's profile dashboard ;",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_2:
          "②Find the following:",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_3:
          "③Find the corresponding teacher by email or name,click on 'Edit teacher information' and set the validity of teacher account to zero.",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_4:
          "3.Directly delete the teacher's account. Please note, once the account is deleted, it won't be possible to recover the teacher's data. Please proceed with caution.",
        school_login_title: "Login as school user",
        add_reward_rule_btn: "Add adward points behavior",
        add_negative_rule_btn: "Add points deduction behavior",
        rule_reached_limitation:
          "You have reached the maximum limit for adding rules, unable to add more.",
        lang: "en",
        view_btn: "View",
        suitable_age_range: "Applicable Age Range",
        min_age: "Minimum Age (years old)",
        max_age: "Maximum Age (years old)",
        invalid_url: "The website does not match the selected platform!",
        //end en===
      },
    },
    es: {
      translation: {
        language_suggetion_title:
          "¿Qué idioma se recomienda para completar la información?",
        language_suggestion_content: "Se recomienda usar su idioma nativo.",
        South_America: "Sudamérica",
        Oceania: "Oceanía",
        North_America: "Norteamérica",
        Latin_America: "América Latina",
        Europe: "Europa",
        Antarctica: "Antártida",
        Africa: "África",
        Asia: "Asia",
        worldwide: "En todo el mundo",
        register_advise:
          'Para asegurar un proceso de registro sin problemas, por favor lea cuidadosamente el "FAQ" antes de registrarse.',
        table_of_contents: "Índice",
        //teacher
        what_teaching_staff_can_do_title:
          "¿Qué pueden hacer los miembros del personal docente?",
        what_teaching_staff_can_do_content_1: "①Agregar estudiantes;",
        what_teaching_staff_can_do_content_2:
          "②Agregar miembros del personal docente;",
        what_teaching_staff_can_do_content_3:
          "③Agregar eventos de recompensa/penalización para estudiantes;",
        what_teaching_staff_can_do_content_4:
          "④Recibir denuncias de los estudiantes;",
        what_teaching_staff_can_do_content_5:
          "⑤Establecer pautas para las conductas de recompensa y penalización específicas de la escuela;",

        if_teaching_staff_can_apply_reward_title:
          "¿Pueden los miembros del personal docente solicitar recompensas?",
        if_teaching_staff_can_apply_reward_content:
          "Sí, los miembros del personal docente y sus familiares pueden solicitar ciertas recompensas y no necesitan puntos para hacerlo. Los registros de solicitud de recompensas no son visibles para ningún otro miembro del personal docente. Consulte su perfil personal para ver un registro de su historial de canjes:",

        how_can_teaching_staff_change_password_title:
          "¿Cómo pueden los miembros del personal docente cambiar su contraseña?",
        how_can_teaching_staff_change_password_content:
          "②Acceso como personal docente;",

        //Student=====================
        what_student_can_do_title:
          "¿Qué pueden hacer los usuarios estudiantes?",
        what_student_can_do_content_1:
          "①Con un clic, los estudiantes pueden reportar rápidamente su situación a la escuela, asegurando su protección;",
        what_student_can_do_content_2:
          "②Los estudiantes obtienen puntos de recompensa a través de un buen desempeño y comportamiento en la escuela. Estos puntos se pueden utilizar para canjear recompensas para ellos mismos y sus familias en el Mercado de recompensas. Canjear recompensas no consume puntos; los puntos acumulados permanecerán disponibles para canjes futuros.",

        how_to_report_to_school_title: "¿Cómo reportar a la escuela?",
        how_to_report_to_school_content_1:
          "①Después de iniciar sesión, vaya al panel de control del perfil del estudiante;",
        how_to_report_to_school_content_2:
          "②Encuentra el botón de 'Denunciar un incidente', haga clic en él, complete el formulario de denuncia, y después envíalo a la escuela:",

        student_how_to_change_password_title:
          "¿Cómo pueden los usuarios estudiantes cambiar sus contraseñas?",
        student_how_to_change_password_content_1:
          "①Acceda a la página de inicio de sesión;",
        student_how_to_change_password_content_2:
          "②Ingrese el 'inicio de sesión como estudiante';",
        student_how_to_change_password_content_3:
          "③Haga clic en 'Olvidé mi contraseña';",
        student_how_to_change_password_content_4:
          "④Ingrese el correo electrónico;",
        student_how_to_change_password_content_5:
          "⑤Obtenga el código de verificación del correo electrónico que ingresaste;",
        student_how_to_change_password_content_6:
          "⑥Código de verificación + nueva contraseña = Contraseña cambiada con éxito;",

        //School=======================
        mearningful_school_title:
          "¿Cuál es el significado de unirse a School Glory?",
        mearningful_school_content:
          "Las escuelas miembro de School Glory están guiando a los estudiantes hacia un camino positivo y constructivo mediante el establecimiento de un sistema de puntos que recompensa los comportamientos positivos y desincentiva los comportamientos negativos. Por ejemplo, ayudar a un compañero de clase al que le están haciendo bullying le dará 10 puntos a un estudiante, mostrar un rendimiento académico mejorado le dará 1 punto, mientras que hacer bullying a alguien resultará en una deducción de 10 puntos. Los puntos acumulados después se pueden canjear en el Mercado de recompensas. Este enfoque tiene como objetivo motivar a los estudiantes y al mismo tiempo aliviar la carga para sus familias. Los profesores o sus familias también pueden solicitar recompensas en el Mercado de recompensas, sin necesidad de puntos. Nuestro objetivo final es reducir el bullying en el campus y fomentar un entorno de aprendizaje más saludable y feliz. Aspiramos a obtener apoyo de todos los sectores de la sociedad para salvaguardar colectivamente el futuro de nuestros niños.",

        type_that_school_can_apply_title:
          "¿Qué tipos de escuelas pueden aplicar?",
        type_that_school_can_apply_content:
          "Kinder, escuelas primarias, secundarias, institutos técnicos y universidades.",

        what_school_admin_user_can_do_title:
          "¿Qué pueden hacer los administradores escolares?",
        what_school_admin_user_can_do_main_1:
          "· Las escuelas pueden proporcionar asistencia mediante un sistema de denuncia de incidentes y responder rápidamente a las 'señales de ayuda' de los estudiantes.",
        what_school_admin_user_can_do_main_2:
          "· Cada escuela puede agregar hasta 10 reglas de recompensa y 10 reglas de deducción, proporcionando a los estudiantes 'Leyes' que seguir con respecto a acciones que pueden ganar o perder puntos;",
        what_school_admin_user_can_do_main_3:
          "Comportamientos de recompensa predeterminados:",
        what_school_admin_user_can_do_content_1:
          "①Reportar valientemente a la escuela cuando se está haciendo bullying +10;",
        what_school_admin_user_can_do_content_2:
          "②Detener el comportamiento de bullying y/o buscar ayuda de los profesores cuando no puedan detenerlo +10;",
        what_school_admin_user_can_do_content_3:
          "③Rendimiento académico excelente (personalizado por la escuela) +10;",
        what_school_admin_user_can_do_content_4:
          "④Mejora en el rendimiento académico +1;",
        what_school_admin_user_can_do_main_4:
          "Comportamientos de deducción predeterminados:",
        what_school_admin_user_can_do_content_5:
          "①Bullying verbal: burlarse, insultar, amenazar a los compañeros -10;",
        what_school_admin_user_can_do_content_6:
          "②Bullying social: exclusión, aislamiento, difusión de rumores o dañar pertenencias de otros -10;",
        what_school_admin_user_can_do_content_7:
          "③ Bullying físico: peleas o hacer gestos insultantes -10;",
        what_school_admin_user_can_do_content_8:
          "④Falsificación para puntajes de recompensas -10;",
        what_school_admin_user_can_do_main_5:
          'Otras reglas son personalizadas por la escuela para comportamientos que pueden hacer ganar o perder puntos a los estudiantes, proporcionando a los estudiantes "Leyes" para seguir.',
        what_school_admin_user_can_do_main_6:
          "El personal docente y sus familias también pueden solicitar ciertas recompensas; permitiéndoles disfrutar de los beneficios de recompensas y promover interacciones positivas dentro de la escuela.",

        what_datas_need_to_prepare_to_join_title:
          "¿Qué documentos se necesitan para la inscripción en la escuela?",
        what_datas_need_to_prepare_to_join_content_main:
          "El proceso de registro requiere una revisión manual que toma aproximadamente de 7 a 15 días hábiles. Las escuelas deben proporcionar la siguiente información:",
        what_datas_need_to_prepare_to_join_content_1:
          "·Dirección de correo electrónico oficial de la escuela;",
        what_datas_need_to_prepare_to_join_content_2:
          "·Nombre completo de la escuela;",
        what_datas_need_to_prepare_to_join_content_3:
          "·País/región donde la escuela está se encuentra principalmente la escuela;",
        what_datas_need_to_prepare_to_join_content_4:
          "·Tipo de escuela (por ejemplo, kinder, escuela primaria, secundaria, etc.);",
        what_datas_need_to_prepare_to_join_content_5:
          "·Emblema de la escuela (opcional);",

        random_sort_school_title:
          "¿Clasificación predeterminada de las escuelas en la página de inicio de School Glory?",
        random_sort_school_content:
          "La clasificación predeterminada se basa en el momento en que la escuela se unió: cuanto antes lo hizo, mayor es su posición en la clasificación.",

        where_to_get_receive_title:
          "¿Dónde conseguir los recibos después del pago escolar?",
        where_to_get_receive_content:
          "El pago debe realizarse utilizando la cuenta pública de la escuela, la cual debe coincidir con el nombre registrado de la escuela. Por favor tenga en cuenta que si la revisión no es aprobada, no se realizará ningún reembolso, por lo que es necesario que la información proporcionada sea precisa y completa. Después de completar la revisión, enviaremos un recibo electrónico a la dirección de correo electrónico proporcionada durante el registro.",

        how_to_add_student_title: "¿Cómo agregar estudiantes inscritos?",
        how_to_add_student_content_1:
          "①Después de iniciar sesión, ingrese al panel de control del perfil de la escuela;",
        how_to_add_student_content_2: "②Encuentre lo siguiente:",
        how_to_add_student_content_3:
          "③Una vez dentro, haga clic en el botón 'Agregar estudiante':",

        how_to_add_teaching_staffs_title: "¿Cómo agregar personal docente?",
        how_to_add_teaching_staffs_content_1:
          "①Después de iniciar sesión, ingrese al panel de control del perfil de la escuela;",
        how_to_add_teaching_staffs_content_2: "②Encuentre lo siguiente:",
        how_to_add_teaching_staffs_content_3:
          "③Una vez dentro, haga clic en el botón 'Agregar correo electrónico del profesor':",

        where_to_check_students_reports_title:
          "¿Dónde se pueden recibir las denuncias de los estudiantes?",
        where_to_check_students_reports_content_1:
          "①Después de iniciar sesión, ingrese al panel de control del perfil de la escuela;",
        where_to_check_students_reports_content_2: "②Encuentre lo siguiente:",

        how_to_add_reward_punishment_actions_title:
          "¿Cómo agregar comportamientos para recompensas y deducciones?",
        how_to_add_reward_punishment_actions_content_1:
          "①Después de iniciar sesión, ingrese al panel de control del perfil de la escuela;",
        how_to_add_reward_punishment_actions_content_2:
          "②Encuentre lo siguiente:",
        how_to_add_reward_punishment_actions_content_3:
          "③Haga clic en el botón:",

        how_to_add_reward_punishment_events_title:
          "¿Cómo agregar eventos de recompensa o penalización para los estudiantes?",
        how_to_add_reward_punishment_events_content_1:
          "①Después de iniciar sesión, ingrese al panel de control del perfil de la escuela;",
        how_to_add_reward_punishment_events_content_2:
          "②Encuentre lo siguiente:",
        how_to_add_reward_punishment_events_content_3:
          "③Encuentre al estudiante correspondiente por su correo electrónico o nombre:",
        how_to_add_reward_punishment_events_content_4:
          "④Agregue el evento correspondiente para ese estudiante:",

        can_school_admin_user_apply_reward_title:
          "¿Pueden los administradores de cuentas escolares canjear por recompensas?",
        can_school_admin_user_apply_reward_content_1: "Sí, pueden.",
        can_school_admin_user_apply_reward_content_2:
          "Los registros de solicitud de recompensas no son visibles para ningún otro miembro del personal. Consulte su perfil personal para ver un registro de su historial de canjes:",

        what_can_you_do_after_joined_school_glory_title:
          "¿Qué se puede hacer después de unirse a la plataforma School Glory?",
        what_can_you_do_after_joined_school_glory_content_1:
          "①Agregar comportamientos específicos de la escuela que pueden hacer que los estudiantes ganen o pierdan puntos;",
        what_can_you_do_after_joined_school_glory_content_2:
          "②Agregar información del personal docente de la escuela;",
        what_can_you_do_after_joined_school_glory_content_3:
          "③Agregar información sobre los estudiantes inscritos, la cual también puede ser añadida por miembros del personal escolar utilizando sus cuentas actuales.",

        how_can_school_cancel_auto_charge_title:
          "¿Cómo pueden las escuelas cancelar la renovación automática?",
        how_can_school_cancel_auto_charge_content_1:
          "①Después de iniciar sesión, ingrese al panel de control del perfil de la escuela;",
        how_can_school_cancel_auto_charge_content_2:
          "②Encuentre Configuración->Cambiar método de pago->Cancelar renovación automática",

        how_to_apply_reward_school_title: "¿Cómo solicitar recompensas?",
        how_to_apply_reward_school_content_1:
          "①Encuentre el Mercado de recompensas en el área de la página principal: ",
        how_to_apply_reward_school_content_2:
          "②Ingrese al Mercado de recompensas y encuentre todas las recompensas para las que califique:",
        how_to_apply_reward_school_content_3:
          "③Seleccione una recompensa que le interese y haga clic para ingresar;",
        how_to_apply_reward_school_content_4:
          "④Elija su método de uso preferido:",
        or: "o",

        how_to_change_password_content_2_school: "¿Cómo cambiar la contraseña?",
        how_to_change_password_content_2_school_login:
          "②Ir al inicio de sesión de la escuela;",

        //STORE=========================
        mearningful_store_title:
          '¿Cuál es el significado de la "Tienda de recompensas"?',
        mearningful_store_content:
          "El objetivo de School Glory es guiar a los estudiantes hacia un desarrollo positivo y constructivo. Además de proporcionarles recompensas para el aprendizaje, también alentamos la participación de las familias de los estudiantes en la solicitud de recompensas. Esto no solo motiva a los estudiantes, sino que también y alivia la carga financiera de sus familias. Nuestra tienda de recompensas también permite a los profesores y sus familias tener acceso a las recompensas, reconociendo y recompensando su trabajo. Nuestro objetivo final es reducir los casos de bullying en los campus y crear entornos de aprendizaje más saludables y felices. Esperamos unir fuerzas de diversos sectores de la sociedad para proteger juntos el futuro de los niños. El Mercado de recompensas pretende crear una cultura de motivación y reconocimiento que alentará a estudiantes, familias y educadores a aumentar el aprendizaje y actitudes positivas recompensas. Por último, esperamos crear un futuro mejor para toda la sociedad.",
        sutable_for_what_store_title:
          "¿Qué tipo de negocios son adecuados para unirse?",
        sutable_for_what_store_content_main_1:
          "Cualquier negocio elegible es bienvenido a solicitar unirse a School Glory. Queremos ofrecer a estudiantes, padres y educadores la mejor gama de recompensas y beneficios disponibles. Buscamos contenido de recompensas que se alinee con los intereses de los estudiantes al mismo tiempo que alivie las cargas financieras familiares. También permitimos a los educadores y sus familias canjear recompensas.",
        sutable_for_what_store_content_main_2:
          "Cualquier negocio puede unirse a School Glory, a menos que pertenezca a una industria explícitamente excluida. Queremos ofrecer una amplia gama de recompensas que puedan atraer a la gama de personas dentro de nuestra base de usuarios. Por ejemplo:",
        sutable_for_what_store_content_1:
          "· Celebridades y estrellas: Ofrecer encuentros exclusivos en vivo a los estudiantes y profesores que ganen más de 10 puntos de recompensa.",
        sutable_for_what_store_content_2:
          "· Proveedores de recompensas en línea y físicos: Ofrecer eventos exclusivos, descuentos, etc., a estudiantes y profesores que ganen más de 10 puntos de recompensa",
        sutable_for_what_store_content_last:
          "Estos ejemplos ilustran la variedad de recompensas que incluimos para dar a los estudiantes y educadores opciones variadas de diferentes Proveedores de recompensas.",
        which_kind_of_store_not_allowed_to_join_school_glory_title:
          "¿Qué industrias no pueden unirse?",
        which_kind_of_store_not_allowed_to_join_school_glory_content_main:
          "Como la audiencia objetivo de School Glory son estudiantes, educadores, y sus familias, tenemos ciertas restricciones sobre el contenido de recompensas. Para mantener la integridad de la plataforma y naturaleza educativa, restringimos a las siguientes industrias de unirse. Por favor, tenga en cuenta que las tarifas de inscripción no serán reembolsadas si se descubre durante el proceso de revisión que un negocio opera en una industria restringida. Por favor, considere esto cuidadosamente al aplicar:",
        which_kind_of_store_not_allowed_to_join_school_glory_content_1:
          "①Industria del entretenimiento para adultos",
        which_kind_of_store_not_allowed_to_join_school_glory_content_2:
          "②Industria del juego/apuestas",
        which_kind_of_store_not_allowed_to_join_school_glory_content_3:
          "③Industria de drogas",
        what_store_user_can_do_title:
          "¿Qué pueden hacer los negocios en la Tienda de recompensas?",
        what_store_user_can_do_content_1:
          "①Establecer sus propias puntuaciones de recompensa y el contenido de recompensa elegible para la aplicación;",
        what_store_user_can_do_content_2:
          "②Establecer el período de validez de las recompensas;",
        what_store_user_can_do_content_3:
          "③Proporcionar un canal conveniente para los negocios a través de métodos de referencia, eliminando la necesidad de construir una plataforma y permitiendo la participación en una plataforma existente, como tiendas en línea, tiendas físicas, etc;",
        what_store_user_can_do_content_4:
          "④Personalizar la audiencia para el contenido de recompensas: estudiantes (y sus familias), profesores (y sus familias), o tanto estudiantes (y sus familias) como profesores (y sus familias);",
        what_datas_store_user_needs_title: "¿Qué información es requerida?",
        what_datas_store_user_needs_content_1:
          "· Logo (tamaño de exhibición óptimo: 108 * 108);",
        what_datas_store_user_needs_content_2:
          "· Una imagen que represente la tienda (Mejor tamaño de visualización: 700 * 700);",
        what_datas_store_user_needs_content_3: "· Nombre de la tienda;",
        what_datas_store_user_needs_content_4:
          "· Descripción breve de la tienda (200 palabras);",
        what_datas_store_user_needs_content_5:
          "· Período de validez de la recompensa (se puede modificar después de la incorporación);",
        what_datas_store_user_needs_content_6:
          "· Condiciones de aplicación (la puntuación de recompensa requerida para que los estudiantes canjeen la recompensa que ofrece);",
        what_datas_store_user_needs_content_7:
          "· Tipo de tienda: tienda en línea/tienda física/tienda en línea física (Por defecto, todas las tiendas físicas pueden canjear recompensas, pero si hay restricciones, deben señalarse las sucursales específicas);",
        what_datas_store_user_needs_content_8: "· Applicable Age Range;",
        what_datas_store_user_needs_content_9:
          "· Producto/contenido principal, por ejemplo: utensilios, productos electrónicos, artículos de aprendizaje, celebridades, entretenimiento, etc.;",
        what_datas_store_user_needs_content_10:
          "· Solicitantes aplicables: Profesores (y familias); Estudiantes (y familias); Profesores (y familias) + Estudiantes (y familias);",
        what_datas_store_user_needs_content_11:
          "· Si tienes una plataforma en línea, seleccionar plataformas, por ejemplo: Amazon, AliExpress, eBay, Taobao, etc.;",
        what_datas_store_user_needs_content_12:
          "· Adjunta el enlace web a tu plataforma en línea.",

        what_school_glory_will_offer_for_store_user_title:
          "¿Qué canales de acceso proporciona la plataforma?",
        what_school_glory_will_offer_for_store_user_content_1:
          "· Tienda en línea;",
        what_school_glory_will_offer_for_store_user_content_2:
          "· Tienda física;",

        how_can_store_user_check_if_applier_met_require_title:
          "¿Cómo puede el negocio verificar si los estudiantes o profesores cumplen con los requisitos de recompensas?",
        how_can_store_user_check_if_applier_met_require_content_1:
          "· Tiendas en línea: Una vez que el usuario hace clic en la aplicación de recompensa, los usuarios recibirán un código de recompensa. Los proveedores de recompensas pueden verificar si el usuario que solicitó cumple con los requisitos ingresando el ID de aplicación proporcionado por School Glory en el panel de control del Mercado;",
        how_can_store_user_check_if_applier_met_require_content_2:
          "· Tiendas físicas: Una vez que el usuario hace clic en la aplicación de recompensa, los usuarios recibirán un código QR de recompensa. Los proveedores de recompensa pueden escanear el código QR proporcionado por el panel de control de la Tienda de recompensas de School Glory para verificar si el usuario que solicitó cumple con los requisitos;",

        how_can_store_user_sort_title:
          "¿En qué se basa el orden de clasificación predeterminado en la página de inicio de la Tienda de recompensas?",
        how_can_store_user_sort_content_1:
          "①Cuanto menor sea el requisito de puntuación de recompensa, mayor será la clasificación;",
        how_can_store_user_sort_content_2:
          "②Las personas más recompensadas ocupan las posiciones más altas;",
        how_can_store_user_sort_content_3:
          "③Cuanto antes sea la fecha de incorporación, mayor será la clasificación;",
        how_can_store_user_sort_content_4:
          "④Más comentarios positivos resultan en una clasificación más alta;",

        how_to_calculate_reward_amount_title:
          "¿Cómo se calcula la puntuación de recompensa de los estudiantes?",
        how_to_calculate_reward_amount_content_positive_main:
          "Las tiendas de recompensa iniciales de cada estudiante son 5, y después de que la escuela se une a la plataforma de School Glory, se puede personalizar hasta 10 comportamientos de recompensa y 10 comportamientos de penalización.",
        how_to_calculate_reward_amount_content_positive_main_1:
          "Comportamientos de recompensa predeterminados:",
        how_to_calculate_reward_amount_content_1:
          "①Denunciar valientemente evidencia de bullying a compañeros +10;",
        how_to_calculate_reward_amount_content_2:
          "②Detener el comportamiento de bullying y/o buscar ayuda de los profesores cuando no se puede detener +10;",
        how_to_calculate_reward_amount_content_3:
          "③Excelente rendimiento académico (definido por la escuela) +10;",
        how_to_calculate_reward_amount_content_4:
          "④Mejora en el rendimiento académico +1;",
        how_to_calculate_reward_amount_content_negative_main:
          "Comportamientos de penalización predeterminados:",
        how_to_calculate_reward_amount_content_1_negative:
          "①Bullying verbal: burlarse, insultar o amenazar a otros compañeros -10;",
        how_to_calculate_reward_amount_content_2_negative:
          "② Bullying social: excluir, aislar, difundir rumores o dañar las pertenencias de otros -10;",
        how_to_calculate_reward_amount_content_3_negative:
          "③ Bullying físico: peleas o gestos insultantes -10;",
        how_to_calculate_reward_amount_content_4_negative:
          "④Falsificar para obtener puntajes de recompensa -10;",
        how_to_calculate_reward_amount_content_last:
          "Otras reglas especificadas por la escuela para recompensar y penalizar a los estudiantes.",

        steps_for_store_users_title:
          "Pasos para que los negocios se unan al Mercado de recompensas:",
        steps_for_store_users_content_title_1:
          "①Complete la información básica:",
        steps_for_store_users_content_1:
          "Primero, complete la información básica del negocio. Proporcione detalles precisos y completos para garantizar un proceso fluido;",
        steps_for_store_users_content_title_2:
          "②Pago de tarifa de verificación:",
        steps_for_store_users_content_2:
          "Después de completar la información básica, se requiere una tarifa de verificación para el proceso de verificación.",
        steps_for_store_users_content_title_3:
          "③Verificar cualificaciones del negocio:",
        steps_for_store_users_content_3:
          "Revisaremos la información proporcionada por el negocio para asegurar que cumpla con las condiciones de incorporación para mantener la calidad y la credibilidad del Mercado de recompensas. Recordatorio: Por favor lea las condiciones de aplicación cuidadosamente ya que la tarifa de registro no será reembolsada si el negocio no cumple con los requisitos para unirse.",
        steps_for_store_users_content_title_4:
          "④Selección del método de pago después de la verificación:",
        steps_for_store_users_content_4:
          "Una vez que el negocio sea verificado, podrá elegir el método de pago de la tarifa de incorporación. Puede optar por el pago anual o comprar la licencia de por vida, de acuerdo a su plan de negocios;",
        steps_for_store_users_content_title_5:
          "⑤Publicación exitosa de la tienda:",
        steps_for_store_users_content_5:
          "Después de completar el pago, su tienda se publicará con éxito en la tienda de recompensas, lo que permitirá la exhibición de sus productos o servicios;",
        steps_for_store_users_content_last:
          "Estos pasos garantizarán un proceso fluido para unirse al Mercado de recompensas. Posicionarán su negocio para que tenga una mayor exposición en el Mercado e inspirar el crecimiento de su negocio",

        how_to_modify_reward_title:
          "¿Cómo modificar el contenido de las recompensas?",
        how_to_modify_reward_content_1:
          ". Después de iniciar sesión como Proveedor de recompensas, ingrese al panel de control de Proveedor de recompensas;",
        how_to_modify_reward_content_2:
          ". Encuentre 'Editar perfil', donde puede modificar el contenido de recompensas y el período de validez;",

        how_to_withdraw_from_display_title:
          "¿Cómo retirarse temporalmente de la Tienda de recompensas?",
        how_to_withdraw_from_display_content_main:
          "En ciertos casos, es posible que necesite retirar temporalmente su recompensa de la tienda de recompensas. Puede seguir estos pasos para retirarla temporalmente:",
        how_to_withdraw_from_display_content_1:
          ". Después de iniciar sesión como Proveedor de recompensas, ingrese al panel de control de Proveedor de recompensas;",
        how_to_withdraw_from_display_content_2:
          ". Vaya a 'Configuración->Estado de la cuenta->Desactivar la opción de exhibición';",

        how_to_change_payment_method_title: "¿Cómo cambiar el método de pago?",
        how_to_change_payment_method_content_main:
          "Todos los registros de los negocios se renuevan automáticamente. Si necesita cancelar la renovación automática o cambiar el método de pago, puede seguir estos pasos:",
        how_to_change_payment_method_content_1:
          ". Después de iniciar sesión como Proveedor de recompensas, ingrese al panel de control del Proveedor de recompensas;",
        how_to_change_payment_method_content_2:
          ". Vaya a 'Configuración->Estado de suscripción->Haga clic en 'Cancelar renovación automática' o 'Cambiar método de pago';",

        how_to_change_password_title_store: "¿Cómo cambiar la contraseña?",
        how_to_change_password_content_1:
          "①Ingrese a la página de inicio de sesión;",
        how_to_change_password_content_2_store:
          "②Seleccione la opción de inicio de sesión para Proveedores de recompensas;",
        how_to_change_password_content_3:
          "③Haga clic en 'Olvidé mi contraseña';",
        how_to_change_password_content_4:
          "④Ingrese su correo electrónico + ID de inicio de sesión;",
        how_to_change_password_content_5:
          "⑤Obtenga el código de verificación de su correo electrónico;",
        how_to_change_password_content_6:
          "⑥Código de verificación + nueva contraseña = contraseña modificada con éxito;",

        //===============================================
        ej: "Celebridades, librería, tiendas, centros comerciales...",
        setting: "Configuración",
        awaiting_comment: "Esperando comentario",
        school_name_of_studente: "Nombre de la escuela",
        only_admin_user_can_cancel_account:
          "Nota: Solo los administradores pueden cancelar cuentas.",

        basic_plan_explain:
          "Plan Básico (Adecuado para un máximo de 100 profesores)",
        stander_plan_explain:
          "Plan Estándar (Adecuado para un máximo de 50 profesores)",

        pro_plan_explain:
          "Plan Premium (Adecuado para un máximo de 100 profesores)",
        reward_store: "Tienda de recompensas",
        teaching_staff: "Personal docente",
        teacher_name: "Nombre del profesor",
        add_punishment_rules: "Agregar acción de deducción",
        error_in_prolong_payment:
          "Ocurrió un error en el proceso de renovación, por favor renueve otra vez",
        active_date: "Fecha de activación",
        terminal_at: "Fecha de vencimiento",
        sate_prolong: "Estado: Renovación automática",
        cancel_prolong: "Cancelar renovación automática",
        after_cancel_you_still:
          "Después de cancelar la suscripción, aún puede usar hasta",
        confirm_cancel_sub: "Confirmar cancelación de suscripción",
        displaying_in_homepage:
          "Mostrándose actualmente en la página de inicio.",
        not_displaying_in_homepage:
          "Ya no se muestra en la página de inicio...",
        You_match_id_teacher:
          "Su identidad: Profesor; ver todas las recompensas para las que es elegible",
        You_match_id_school:
          "Su identidad: Administrador/a escolar; ver todas las recompensas para las que es elegible",
        your_total_points: "Sus puntos totales",
        able_to_apply: "Elegible para canjear recompensas",

        users_apply_records:
          "A continuación se muestran los registros de estudiantes o profesores que han solicitado recompensas de su parte.",

        payment_statement: "Estado de renovación",
        store_login_title: "Inicio de sesión del Proveedor de recompensas",
        enter_web_url:
          "Por favor ingrese la URL de su plataforma correspondiente",
        enter_platform_name:
          "Por favor ingrese el nombre de la plataforma. Por ejemplo, Amazon",
        reward_that_you_are_able_to_apply_now:
          "Las siguientes son las recompensas que puede canjear actualmente",
        temp_no_reward_to_apply:
          "No hay recompensas disponibles para solicitar en este momento",
        all_over_the_world: "En todo el mundo",
        not_comment_yet_apply:
          "Las siguientes son solicitudes de recompensa no revisadas",
        event_time: "Fecha",
        faq: "FAQ",
        Allow_add_reward_behavior:
          "En esta página: Los profesores y administradores de la plataforma pueden ingresar comportamientos por los cuales los estudiantes pueden ser recompensados, modificar y eliminar los comportamientos agregados. Por favor tenga en cuenta que una vez eliminados, no se pueden recuperar. (Sugerencia: Cuando sea posible, las escuelas deberían intentar pedir la opinión a estudiantes y padres antes de tomar decisiones).",

        Allow_add_punishment_behavior:
          "En esta página: Los profesores y administradores de la plataforma pueden ingresar comportamientos por los cuales los estudiantes recibirán deducciones de puntos. (Sugerencia: Cuando sea posible, las escuelas deberían intentar pedir la opinión a estudiantes y padres antes de tomar decisiones).",

        School_change_to_another_payment_method:
          "Por favor, tenga en cuenta: Si la cuenta está suspendida, los estudiantes, el personal docente y sus familias no podrán canjear recompensas. Por favor, considere cuidadosamente desactivar antes la función de renovación automática.",

        stander_plan: "Plan Estándar",
        get_all_reports_explain:
          "Recordatorio: Para obtener toda la información sobre la denuncia de un estudiante, se recomienda a las escuelas que se pongan en contacto con él lo antes posible e investigar a fondo los hechos.",
        according_reward_points:
          "Puntos de recompensa para acciones correspondientes",
        according_punish_points:
          "Reducción de puntos para acciones correspondientes",
        register_fee: "Tarifa de verificación",
        customize_reward_conditon: "Personalizar el contenido de recompensas",
        help_let_others_know_your_store:
          "Atraiga tráfico a su tienda física o en línea",
        can_cancle_or_change_method_anytime:
          "Puede pausar servicios y cambiar los métodos de pago en cualquier momento",
        unlimit_use_new_methos:
          "Uso ilimitado gratuito de todas las funciones actualizadas posteriormente",
        pricing_for_reward_store: "Para la tienda de recompensas",
        check_details: "Ver detalles",
        suitable_for_stores_type:
          "Canales aplicables: Tiendas en línea, tiendas físicas",
        your_experience: "Su experiencia",
        leave_your_rate:
          "Valore su satisfacción satisfacción con este servicio/experiencia de compra",
        user_not_meet_applicant_requirement:
          "¡El usuario no cumple con los requisitos de solicitud!",
        user_meet_applicant_requirement:
          "¡El usuario cumple con los requisitos de solicitud!",
        commit_to_reward: "¡Confirmar recompensa!",
        successfully_rewarded: "¡Recompensado con éxito!",
        school_user: "Escuela",
        not_rewarded: "No recompensado",
        date_of_use: "Fecha de uso",
        reward_now: "Premiar a usuario",
        use_now: "Usar ahora",
        customize_reward_behaviors_for_this_school:
          "Establecer expectativas de comportamientos positivos para los estudiantes",
        customize_deduction_behaviors_for_this_school:
          "Definir cuáles comportamientos positivos harán ganar puntos de recompensa a los estudiantes y cuáles negativos podrían hacerlos perder puntos.",
        add_penalty_events:
          "Ganar eventos por comportamientos positivos y reducirlos cuando sea necesario",
        add_reward_rules:
          "Describir las reglas para que los alumnos sepan qué esperar",
        students_teachers_and_their_families_in_this_school_can_use_the_rewards_provided_in_the_reward_store:
          "Los estudiantes, profesores y sus familias de esta escuela pueden acceder y disfrutar las recompensas disponibles en el Mercado de recompensas",
        able_to_add_teaching_staff:
          "Número máximo de personal docente que se puede añadir: 100 .",
        suitable_to_users_school:
          "Aplicable a: Kinder, escuelas primarias, escuelas medias, secundarias, preparatorias, universidades, y otras instituciones educativas.",
        pricing_for_school: "Escuela",
        undeal: "No procesado",
        dealed: "Completado",
        all_reports: "Todas las denuncias",
        reward_invalid_now:
          "La recompensa ha caducado, o el proviedo ha retirado esta recompensa.",
        stores: "Mercado de recompensas",
        pricing: "Precios",
        store_register_title: "Registro de Proveedor de recompensas",
        your_logo:
          "El logotipo de su tienda (mejor tamaño de visualización: 108*108)",
        your_store_cover_image:
          "Por favor suba una imagen de portada que represente su tienda (mejor tamaño de visualización: 700*700)",
        your_display_name: "El nombre de su tienda",
        store_intro: "Introducción de la tienda",
        reward_content_short: "Detalles de la recompensa",
        reward_valid_date:
          "Recompensa válida hasta (se puede actualizar en cualquier momento después de establecerla):",
        reward_condition:
          "Condiciones de aplicación (puntuación mínima requerida para las recompensas de estudiantes. Recordatorio: La clasificación predeterminada se basa en la puntuación de recompensa de menor a mayor)",
        store_type:
          "Tipo de tienda (Recordatorio: Las tiendas físicas, por defecto, todas las ubicaciones pueden usar las recompensas que proporciones)",
        online_store: "Tienda en línea",
        physical_store: "Tienda física",
        online_physical: "Tiendas en línea y físicas",
        place_to_use_title: "Lugar de uso",
        product_type_title:
          "Por favor selecciona la categoría principal de productos",
        available_to_title:
          "Solicitantes elegibles (incluyendo las familias de los estudiantes, profesores y sus familias)",
        tableware: "Vajilla",
        bedding: "Ropa de cama",
        clothing: "Ropa",
        electronics: "Aparatos electrónicos",
        furniture: "Muebles",
        food: "Alimentos",
        cosmetics: "Cosméticos",
        sporting_goods: "Artículos deportivos",
        books: "Libros",
        jewelry: "Joyería",
        fresh_produce_market: "Productos frescos del mercado",
        supermarket: "Supermercado",
        school_supplies: "Material escolar",
        real_estate: "Bienes raíces",
        restaurants: "Restaurantes",
        tourist_attractions: "Atracciones turísticas",
        amusement_parks: "Parques de diversión",
        celebrities:
          "Celebridades (artistas, atletas, políticos, autores, directores, top 500 de propietarios de negocios)",
        film_production: "Producción cinematográfica",
        sports_clubs: "Clubes deportivos",
        news_agencies: "Agencias de noticias",
        education: "Educación",
        hotels: "Hoteles",
        travel_agencies: "Agencias de viaje",
        banks: "Bancos",
        transportation: "Transporte",
        gas_stations: "Gasolineras",
        game: "Juegos",
        study_app: "Aplicaciones de estudio",
        // influencer: "Influencer",
        car: "Carro",
        other: "Otros",
        available_for_who_title:
          "Solicitantes elegibles (incluyendo las familias de los estudiantes, profesores y sus familias)",
        teacher: "Profesores (incluyendo la familia)",
        student: "Estudiantes (incluyendo la familia)",
        student_teacher: "Estudiantes + Profesores",
        support_platfrom_title:
          "Por favor elige una plataforma en línea compatible",
        online_store_url_title:
          "Por favor introduce una URL que enlace a tu tienda. Por favor verifica dos veces para asegurar que sea un enlace válido.",
        dont_forget_reward_tip:
          "Recordatorio: Después de otorgar la recompensa al usuario solicitante, ¡no olvide dar clic en el botón de recompensa!",
        please_enter_id: "Por favor, introduce el ID...",
        awaiting_reward: "Esperando recompensa",
        rewarded: "Recompensado",
        valid_till: "Válido hasta",
        apply_condition_score: "Puntos mínimos requeridos para la recompensa",
        rewarded_amount: "Número de personas recompensadas",
        available_to: "Disponible para",
        store_type_short: "Canal(es) disponible(s) para reclamar la recompensa",
        product_type: "Productos principales",
        reward_content: "Contenido de la recompensa",
        valid_date: "Válido hasta",
        online_platform:
          "Recompensa disponible para reclamar en la tienda en línea",
        sub_state: "Estado de suscripción",
        account_state: "Estado de cuenta",
        cancel_account: "Cancelar cuenta",
        upload_store_tips:
          "Todos los días, 30 tiendas de recompensas aprobadas se actualizarán en orden de solicitud.",
        please_enter_store_name: "Por favor ingrese el nombre de la tienda...",
        join_stores_btn: "Solicitar unirse como tienda de recompensas",
        not_allow_upload_content:
          "Por favor lea cuidadosamente la información acerca del contenido subido prohibido: Cargar elementos que violen la política de contenido subido prohibido u otras regulaciones de uso resultará en el rechazo de su solicitud, y cualquier tarifa pagada no será reembolsada. Le animamos a que revise minuciosamente sus productos y contenido antes de enviarlos para asegurar su legalidad y cumplimiento con nuestras pautas. Nuestro objetivo es crear una lista de recompensas segura, legal y respetuosa para salvaguardar a los estudiantes, profesores, y sus familias. Gracias por su apoyo y cooperación.",
        not_allow_url: "No se permite cargar elementos",
        awaiting_verify_store_notice:
          "Verificación en progreso... (esperando verificación manual, 1-15 días hábiles; tras la verificación exitosa, procederá al siguiente paso)",
        success_verify_tip:
          "¡Felicidades! Ha pasado la verificación exitosamente.",
        finish_last_step:
          "Por favor completa el paso final seleccionando su método de suscripción para unirse formalmente a la lista del Mercado de recompensas.",
        pay_by_month: "Mes",
        pay_by_year: "Año",
        pay_by_life: "De por vida",
        failed_apply: "¡Solicitud fallida!",
        reason: "Motivo",
        basic_plan: "Plan Básico",
        standard_plan: "Plan Estándar",
        pro_plan: "Plan Premium",
        ranking_now: "Clasificación global actual",
        punishment_amounts: "Total de eventos de penalización",
        reward_amount: "Total de eventos de recompensa",
        reported_amount: "Denuncias recibidas",
        enter_reward_rules: "Ingresar acciones de recompensa elegibles",
        enter_punish_rules: "Ingresar acciones de penalización",
        add_teacher: "Agregar personal docente",
        your_setted_reward_expired:
          "El periodo de validez de su recompensa establecida ha expirado, por favor procede a actualizar.",
        to_update: "Actualizar",
        canceled_not_allowed_restore:
          "Después de la cancelación de la cuenta, todos los datos serán irrecuperables. ¿Está seguro de que quiere cancelar?",
        confirm_cancel_account: "Confirmar cancelación de cuenta",
        your_account_blocked: "¡Tu cuenta ha sido bloqueada!",
        re_subscribe: "Volver a suscribirse",
        displaying_tip: "Actualmente mostrado en la tienda de recompensas...",

        remove_display_tip: "Oculto de la tienda de recompensas...",
        reward_expired_tip:
          "Su recompensa ha caducado y ya no se muestra en la tienda de recompensas. Por favor ajuste el contenido de recompensas o extienda el período de validez.",
        record_of_applying_title:
          "Lo siguiente es un registro de estudiantes o profesores que aplican para recompensas de su parte.",
        user_experience: "Experiencia del usuario para Todos",
        user_apply_failed:
          "Solicitud fallida. ¡No cumples con los criterios para esta recompensa!",
        apply_reward: "Reclamar Recompensa",
        qr_code_apply_tip:
          "Nota: Para tiendas físicas, por favor muestra el código QR. Para tiendas en línea, copia el ID de la recompensa y envíalo al dueño de la tienda durante el pago para recibir tu recompensa.",
        apply_reward_success: "¡Solicitud exitosa!",
        coppy_id:
          "Por favor copia el siguiente ID antes de reclamar tu recompensa de la tienda.",
        use_qr_code:
          "Aquí está su código QR exclusivo. Por favor preséntalo al dueño de la tienda durante el pago.",
        to_online_shop: "Haz clic para visitar la tienda en línea",
        valid_date_to: "Válido hasta",
        use_it_soon: "Por favor úsalo pronto",
        select_your_plan_tip: "Seleccione su plan de pago",
        year_plan: "Año",
        joined_students_amount: "Estudiantes",
        private_teacher_reward_apply:
          "Solicitud de recompensa privada para personal docente",
        private_teacher_explain:
          "En esta página: El personal docente puede canjear privadamente las recompensas que elija. Esta interfaz solo es visible para el personal docente que ha iniciado sesión. Cada miembro del personal docente solo puede ver su propio historial de aplicaciones.",
        add_student_page_explain:
          "En esta página: Ver todos los estudiantes inscritos y agregar información individual del estudiante, incluyendo el avatar del estudiante, correo electrónico del estudiante, información de la clase del estudiante y la validez de la cuenta del estudiante en esta escuela. Después de agregar estudiantes, es posible modificar los detalles del estudiante, eliminar estudiantes, y agregar recompensas o eventos de penalización para los estudiantes. (Nota: Al crear, la contraseña de la cuenta de cada estudiante es la misma que su correo electrónico. Si un estudiante desea cambiar su contraseña, debe ir a la página de inicio de sesión -> estudiante -> olvidó la contraseña -> restablecer la contraseña para realizar modificaciones).",
        explain_add_student:
          "Propósito: ① Agregar/Actualizar/Eliminar información del estudiante; ② Agregar recompensas o eventos de penalización para estudiantes.",
        name: "Nombre",
        edit_student_info: "Editar información del estudiante",
        enter_student_info: "Por favor ingrese la información del estudiante",
        enter_student_btn: "Agregar estudiante",
        search_content: "Buscar contenido",
        student_name: "Nombre del estudiante",
        student_email: "Correo electrónico",
        student_info: "Grado/Clase del estudiante",
        student_valid_year:
          "Validez de la cuenta del estudiante en esta escuela (por defecto es el año seleccionado + 3 meses)",
        delete_student_account_notice:
          "Eliminar permanentemente al estudiante y no se puede restaurar",
        add_event_to_student:
          "Agregar eventos de penalización o recompensa para estudiantes",
        student_account_valid_to: "Validez de la cuenta",
        valid_accounts: "Cuentas válidas",
        expired_accounts: "Cuentas vencidas",
        reward_behaviar: "Comportamiento de recompensa",
        punish_behaviar: "Acciones deducibles",
        accord_to: "Motivo",
        detail_added: "Detalles adicionales",
        punished_page_explain:
          "En esta página: Recupera todos los eventos de penalización registrados.",
        rewarded_page_explain:
          "En esta página: Recupera todos los eventos de recompensa registrados.",
        add_teacher_page_explain:
          "En esta página: El personal docente y los administradores de la plataforma pueden ingresar las direcciones de correo electrónico del personal docente autorizado para usar esta plataforma (Después de crear, las contraseñas de cuenta son las mismas que las del correo electrónico del profesor. Si se requiere un cambio de contraseña, el usuario del profesor debe ir a la página de inicio de sesión -> profesor -> olvidó la contraseña -> restablecer contraseña para modificar).",
        allow_add_teachers:
          "Número actual permitido de personal docente ingresado",
        add_teacher_email_btn: "Agregar correo electrónico del profesor",
        searcher_teacher_info: "Buscar profesores",
        total_reward_points: "Puntos totales de recompensa",
        total_punish_points: "Puntos de penalización acumulados",
        check_available_to_reward_btn: "Recompensas canjeadas",
        available_to_reward_amount: "Recompensas disponibles para canjear",
        rewarded_history: "Historial de recompensas",
        punished_history: "Historial de penalizaciones",
        one_click_btn: "Denunciar un incidente",
        thank_you_for_reporting:
          "¡Gracias por su denuncia! El personal de la escuela investigará este asunto rápidamente. ¡Por favor espera una notificación!",
        report_content: "Contenido de la denuncia",
        upload_evidence: "Por favor carga evidencia",
        able_to_reward_bahavier: " Comportamientos de recompensa ",
        able_to_punishment_behavier: " Acciones deducibles ",
        teacher_login_title: "Inicio de sesión del personal docente",
        you_have_successfully_rewarded:
          "¡Ha aplicado con éxito para esta recompensa!",
        comments_explain:
          "En esta página: Por favor proporciona comentarios basados en tu experiencia real. ¡Su apoyo ayudará a los Proveedores de recompensas!",
        go_comment_btn: " Compartir experiencia ",
        reapply_tip: "Aplicar de nuevo",
        please_enter_id_search_bar: "Por favor, ingresa el ID...",
        sub_expired_date: "Fecha de vencimiento",
        sub_method: "Método de suscripción",
        re_sub: "Renovar suscripción",
        change_payment_method: "Cambiar método de pago",

        //===============================================
        add_school_hero_btn: "Agregar estudiante",
        pl_enter_a_hero_name: "Por favor ingresa el nombre del estudiante...",
        search: "Buscar",
        add_a_hero_btn: "Agregar estudiante",
        our_heros: "Nuestra liga de estudiantes",
        total_heros: "Número de total de estudiantes",
        are_you_sure_to_remove: "¿Está seguro de que desea eliminar?",
        no_record_yet: "No se encontraron registros",

        out_of_limit: "Ha excedido el límite de palabras.",
        send_us_msg_title: "Envíenos un mensaje",
        send_us_msg_content:
          "Si tiene alguna pregunta, no dude en escribirnos. Si no recibe una respuesta inmediata, es porque hay más usuarios esperando una respuesta antes que usted. Por favor no te preocupes; le responderemos tan pronto como sea posible. Muchas gracias por su paciencia y comprensión.",
        send_us_msg_btn: "Enviar mensaje",
        follow_us_title: "Síguenos,",
        follow_us_sub: "Puede recibir nuestras últimas noticias:",
        contact_us_title:
          "Valoramos mucho sus preguntas. Para que podamos responder mejor sus consultas, asegúrese de que la información que proporciona es veraz y precisa. Revisaremos cuidadosamente la información que nos proporciona y, una vez verificada, le responderemos lo antes posible.",
        form_your_name: "*Su nombre",
        form_your_email: "*Su correo electrónico",
        from_your_web: "*Su sitio web o enlace de LinkedIn",
        from_your_msg: "El mensaje que desea enviarnos",
        thank_you_email: "¡Muchas gracias por enviarnos un correo electrónico!",

        contact: "Contacto",
        newPassword: "Por favor ingrese su nueva contraseña...",
        dear_school_user_title: "Estimado usuario,",
        issue_content:
          "Hubo un problema con su proceso de solicitud. Lamentamos los problemas que encontró. Nuestro equipo está trabajando para investigar el asunto a fondo y le proporcionará una solución prontamente por correo electrónico. Por favor revise su bandeja de entrada para nuestra respuesta. Gracias por su paciencia.",
        support_bank_card:
          "Se aceptan pagos con Visa, Mastercard, American Express, Discover, Diners Club y China UnionPay (中国银联) de clientes en todo el mundo.",
        not_the_time:
          "No puede volver a unirse a School Glory todavía, por favor inténtelo de nuevo después de: ",
        view: "Más información aquí",
        register_notice_tip_user:
          "Requeriremos una verificación por correo electrónico. Por favor asegure la exactitud de su dirección de correo electrónico para garantizar un proceso de verificación sin problemas. Lamentamos informarle que si la verificación falla, no podremos reembolsar la tarifa de registro. Gracias por su apoyo y comprensión.",
        match_requirement: "Escuelas correspondiente(s):",
        get_verification_code: "Obtener código de verificación",
        inComplete: "¡La información subida está incompleta!",
        only_school:
          "Solo las escuelas que constantemente priorizan la seguridad y el bienestar de los estudiantes, pueden estar en la lista.",
        schools_has_joined:
          "Actualmente, escuelas en la lista de School Glory:",
        apply: "APLICAR AHORA →",
        very_important: "Muy importante",
        register_notice_tip:
          "Después de completar el pago, requeriremos una verificación por correo electrónico. Por favor asegure la exactitud de su dirección de correo electrónico para garantizar un proceso de verificación sin problemas. Lamentamos informarle que si la verificación falla, no podremos reembolsar la tarifa de registro. Gracias por su apoyo y comprensión.",
        school_not_allow_vote: "No se permite votar entre escuelas.",
        reset_password: "Restablecer contraseña",
        login_id_email_cant_empty:
          "El correo electrónico y el ID de inicio de sesión no pueden estar vacíos.",
        wrong_info_limit:
          "Ha superado el límite de intentos incorrectos y su cuenta aparecerá en la lista de control. Por favor inténtelo de nuevo después de 24 horas.",
        login_as: "Iniciar sesión como",
        yes: "Sí",
        no: "No",
        unlike_confirm: "¿Está seguro de que no le gusta?",
        more_detials: "Aplicar ahora",
        login_id_is_required: "¡Se requiere el ID de inicio de sesión!",
        please_enter_email_and_password:
          "Por favor introduzca el correo electrónico y contraseña.",
        ranking_validating:
          "La clasificación se recalcula una vez que pase la validación.",
        password_rule:
          "La contraseña debe contener como mínimo 4 caracteres, con una letra MAYÚSCULA, minúscula, número y un carácter especial: @$! % * ? &",
        Must_add_one_UPPERCASE_letter: "¡Debe agregar una letra MAYÚSCULA!",
        Must_add_one_lowercase_letter: "¡Debe agregar una letra minúscula!",
        Must_add_one_number: "¡Debe agregar un número!",
        Must_add_one_special_symbol:
          "¡Debe agregar un símbolo especial: @$! % * ? &!",
        weak: "débil",
        fair: "regular",
        good: "bueno",
        strong: "fuerte",
        passwod_strength: "Validación de la seguridad de la contraseña",
        we_havent_found_a_school_that_meets_requirement:
          "No hemos encontrado una escuela que cumpla con los requisitos en este momento.",
        //who voted to
        who_voted_to: "Usuarios que apoyaron a ",
        de_yonghu: "",
        //password forgorten
        please_enter_your_email_below_to_get_your_verify_code:
          "Por favor introduzca su correo electrónico a continuación para obtener su código de verificación.",
        login_btn: "Iniciar sesión",
        login_to_continue_title: "Inicia sesión para continuar",
        login_to_continue_content:
          "¡Unámonos para apoyar y fortalecer a las escuelas comprometidas en nutrir los sueños de los estudiantes!",
        click_create_account: "Haga clic para crear una cuenta",
        no_account_yet: "¿Todavía no tiene una cuenta? Registrarse",
        loading: "Cargando...",
        user_login_title: "Inicio de sesión de usuario",
        password_forgotten: "¿Contraseña olvidada?",
        all: "Todo",
        logo: "School Glory",
        get_started: "Más detalles",
        no_school_found:
          "No hemos encontrado una escuela que cumpla con los requisitos en este momento",
        //login
        // login_as: "Iniciar sesión como",
        //header
        login_nav: "Iniciar sesión",
        register_nav: "Registrarse",
        about_nav: "Acerca de",
        //Home
        title: "¡Hagamos de la escuela el mejor lugar para construir sueños!",
        subtitle:
          "Nuestro objetivo es reducir el acoso escolar y guiar a los estudiantes en la dirección correcta.",
        search_bar_placeholder: "Ingrese el nombre de la escuela...",
        location: "Ubicación",
        type: "Tipo",
        total: "Total",
        // search: "Buscar",
        //schools type
        Kindergarten: "Kinder",
        Primary: "Primaria",
        Middle: "Secundaria",
        High: "Preparatoria",
        Tenical: "Técnica",
        College: "Universidad",
        University: "Universidad",
        Other: "Otro",
        //School Details
        joined_us_on: "se unió a nosotros el",
        rest_content:
          "lo que significa que la escuela se compromete a proporcionar a los estudiantes un entorno de aprendizaje seguro y enriquecedor.",
        you_have_liked: "Has dado un Me gusta a",
        //About
        about_title: "Acerca de nosotros",
        phase_1:
          "Cada vez que me entero de casos de niños que son acosados o lastimados en la escuela, me hierve la sangre y me encuentro apretando los dientes. Me llamo Dongjing Zhong, y soy la fundadora de School Glory. He estado considerando abordar este problema durante mucho tiempo. Al darme cuenta de que el odio solo genera más enemistad, decidí cambiar mi enfoque y encarar este problema de manera positiva: ¿cómo puedo animar a los estudiantes a detener el acoso escolar mientras les motivo a aprender y les guío por el camino correcto?",
        phase_2:
          "La idea de proporcionar incentivos de aprendizaje a los estudiantes fue inspirada por los mecanismos de recompensa en los juegos. Aprendí que muchos niños están más interesados en jugar videojuegos que en estudiar. La mayoría de los videojuegos que atraen a los jugadores tienen objetivos claros y sistemas de recompensa. Si los jugadores progresan al superar desafíos simples y difíciles para ganar diferentes recompensas, ¿por qué no aplicar un mecanismo de incentivo similar para animar a los niños a detener el acoso escolar mientras encendemos su interés por estudiar? Esto impulsó la idea de reunir empresas de todo el mundo para crear un Mercado de Recompensas que ofrezca emocionantes premios para los niños. Por ejemplo, cuando alcanzan una puntuación de 10, podrían tener la oportunidad de ver en vivo a su estrella favorita o ayudar a sus familias a adquirir productos esenciales a precios más accesibles. Estas puntuaciones se basarían en comportamientos positivos habituales, como mejorar en los estudios (+1 punto), reportar valientemente el acoso a la escuela (+10 puntos) o ayudar en casa (+10 puntos), entre otros. Por otro lado, comportamientos como burlarse, insultar o amenazar a los compañeros (-10 puntos); exclusión, difusión de rumores o daño a pertenencias de otros (-10 puntos); pelear o hacer gestos insultantes (-10 puntos), entre otros, restarían puntos.",
        phase_3:
          "No solo los estudiantes pueden solicitar recompensas, sino que sus familias también pueden participar y canjearlas en el Mercado de Recompensas. Esto no solo motivaría a los estudiantes, sino que también ayudaría a las familias a aliviar sus cargas y fortalecer los lazos familiares. Nuestro Mercado de Recompensas también está abierto a maestros y sus familias. Los maestros o sus familias pueden solicitar recompensas en el Mercado de Recompensas sin necesidad de puntos. Nuestro objetivo final es reducir el acoso escolar y fomentar un entorno de aprendizaje más saludable y feliz. Aspiramos a reunir apoyo de todos los sectores y la sociedad para salvaguardar el futuro de nuestros niños.",
        phase_4:
          "Dado que la mayoría de las personas pasan sus años formativos hasta los 25 años en instituciones educativas, las escuelas tienen la oportunidad de guiar a los jóvenes por el camino correcto. Las escuelas son vistas como un segundo hogar para los niños, donde se forman mentes y personalidades. Los establecimientos educativos deben ser lugares seguros, armoniosos y jubilosos donde los sueños puedan cumplirse. ¡Todos deberíamos trabajar juntos para transformar cada escuela en un oasis para el crecimiento y desarrollo de los niños!Juntos, podemos forjar un entorno de aprendizaje armonioso y seguro para nuestros estudiantes a través de esfuerzos unidos. ",
        phase_4_1:
          "Únete a nosotros en este viaje transformador hacia el fomento de un panorama educativo más brillante y seguro. Defendamos la causa del bienestar de nuestros niños.",
        story_behind_btn: "Detrás de la historia",
        //Register Notice
        notice_title: "INSTRUCCIONES DE REGISTRO",
        notice_1:
          "1. Para ser elegible de participar, el solicitante debe ser una institución educativa de cualquier tipo, incluyendo preescolar, escuela primaria, secundaria, bachiller o superior.",
        notice_2:
          "2. Al unirse a School Glory, es obligatorio demostrar un compromiso firme de crear un entorno de aprendizaje saludable, feliz y seguro para los estudiantes, oponiéndose a toda forma de violencia en la escuela, y aceptando la supervisión pública. ",
        notice_3:
          "3. El ranking en School Glory se basa en el número de votos de apoyo recibidos y en el orden en que una escuela se ha unido a la plataforma. Las escuelas con más votos, y que antes se unan, serán clasificadas más arriba, fomentando una mayor participación y permitiendo un reconocimiento colectivo.",
        notice_4: `4. School Glory requiere un pago único de registro de ${
          import.meta.env.VITE_REGISTER_PRICE
        } Euro(Aceptar pagos con Visa, Mastercard, American Express, Discover, Diners Club y China UnionPay (中国银联) de clientes en todo el mundo). Los fondos recaudados se utilizarán para establecer la Fundación Caritativa de School Glory.`,
        notice_4_1:
          "• Supervisar aleatoriamente las escuelas afiliadas a School Glory",
        notice_4_2:
          "• Realizar investigaciones exhaustivas sobre problemas de acoso o violencia que ocurren en las escuelas",
        notice_4_3:
          "• Trabajar, en colaboración con expertos en educación, para identificar y ofrecer soluciones a estos problemas",
        notice_4_4:
          "• Reducir gradualmente y eliminar los comportamientos violentos",
        notice_4_5:
          "Creemos firmemente que trabajando juntos podemos crear un entorno de aprendizaje armonioso y seguro para los estudiantes.",
        phase_5:
          "5. El proceso de registro requiere de una revisión manual, por lo que se necesitan, aproximadamente, de 7 a 15 días hábiles. Las escuelas deben proporcionar la siguiente información:",
        notice_5_1:
          "• Una dirección de correo electrónico oficial de la escuela.",
        notice_5_2: "• El nombre completo de la escuela.",
        notice_5_3: "• La ubicación.",
        notice_5_4: "• El tipo de escuela.",
        notice_5_5: "• Un emblema/escudo de la escuela. (Opcional)",
        phase_6:
          "6. El pago debe realizarse a través de la cuenta bancaria de la escuela, la cual debe coincidir con el nombre de registro. Tenga en cuenta que no se realizarán reembolsos en caso de que el registro no sea aprobado. Por lo tanto, es importante proporcionar información precisa y completa para garantizar un registro exitoso. Una vez que se complete la verificación, le enviaremos el recibo digital a la dirección de correo electrónico oficial que haya proporcionado.",
        phase_7:
          "Cuando una escuela solicita unirse a School Glory, se les exige establecer la Liga de Héroes del Campus dentro de 3 meses.",
        agree_and_register_btn: "Hecho y Registrarse",
        //Story Behind
        story_behind_title: "Nuestra Historia",
        story_behide_1:
          "Mi nombre es Dongjing Zhong, la fundadora de Gears Run",
        story_behind_1_1: "y la creadora de la plataforma School Glory.",
        story_behide_2:
          "Cada vez que escucho que los niños son acosados o heridos en la escuela, me hierve la sangre y me aprieto los dientes.",
        story_behide_3:
          "Durante mucho tiempo, he estado reflexionando sobre cómo abordar este problema.",
        story_behide_4:
          "Al darme cuenta de que el odio solo engendra más enemistad, se me ocurrió un enfoque proactivo para abordar este problema, con la creación de School Glory. Una plataforma que fomenta que las escuelas se comprometan a crear un ambiente pacífico, alegre y seguro para los estudiantes se unan.",
        story_behide_5:
          "Sin embargo, si una escuela experimenta un incidente de violencia, debería de retirarse voluntariamente de School Glory, hasta que se resuelva el problema y no haya más violencia durante los siguientes tres meses. Solo entonces podrán volver a unirse. School Glory es un salón de honor, recociendo a las escuelas que constantemente priorizan la seguridad y el bienestar de sus estudiantes.",
        story_behide_6:
          "Inicialmente, había pensado que las escuelas se registraran de forma gratuita. Pero quedó claro que debíamos establecer un sistema de verificación para combatir las cuentas fraudulentas. En su lugar, fue impletada una tarifa de registro única, y los fondos de esta tarifa se utilizarán para formar la Fundación Caritativa School Glory. El objetivo de esta organización es ayudar a reducir el acoso escolar y promover un entorno educativo seguro y saludable.",
        story_behide_7:
          "Cuando comienzas a caminar el camino, el camino aparece. ¡Únete a nosotros en School Glory y convierte las instituciones educativas en el mejor lugar para construir tus sueños!",
        //Register
        register_as: "Registrarse como",
        user: "Estudiante",
        school: "Escuela",
        school_glory_user_register: "Registro de Usuario de School Glory",
        password: "Contraseña",
        username: "Nombre de usuario",
        role: "Rol",
        upload: "Subir",
        agree_and_continue: "Aceptar y Continuar",
        school_glory_school_register: "Registro de usuario escolar",
        school_official_email: "Correo electrónico oficial de la escuela",
        school_name: "Nombre completo de la escuela",
        school_type: "Tipo de escuela",
        school_location: "Ubicación de la escuela",
        school_address: "Dirección completa: ",
        school_address_ex:
          "Por ejemplo: Calle Principal 123, Ciudad de Nueva York, Nueva York, 10001, Estados Unidos",
        school_emble: "Emblema de la escuela",
        image_require:
          "Por favor, utiliza una imagen con fondo blanco y en formato JPG/PNG.",
        process_payment: "Siguiente",
        verify_code_send:
          "El código de verificación ha sido enviado a tu correo electrónico",
        enter_verify_code: "Por favor, introduce el código de verificación...",
        notice: "Aviso:",
        register_notice:
          "El pago debe realizarse a través de la cuenta bancaria de la escuela, la cual debe coincidir con el nombre registrado de la escuela. Ten en cuenta que no se realizarán reembolsos en caso de que el registro no sea aprobado. Por lo tanto, es importante proporcionar información precisa y completa para garantizar un registro exitoso.",
        payment_btn: `Pagar €${import.meta.env.VITE_REGISTER_PRICE}`,
        processing: "Procesando...",
        generate_login_id:
          "El ID de inicio de sesión es muy importante, y la plataforma lo genera solo una vez. Por favor, guárdalo en un lugar seguro, ya que lo necesitarás para iniciar sesión, cambiar contraseñas y actualizar información personal. Asegúrate de obtenerlo en un entorno seguro.",
        get_login_id_btn: "Obtener ID de inicio de sesión",
        navigate_to_my_profile: "Mi Perfil",
        //School profile
        verifying: "Verificando...",
        error: "Error",
        success: "Éxito",
        verifying_notice:
          "El proceso de registro requiere una revisión manual y tarda aproximadamente de 7 a 15 días hábiles. Gracias por tu paciencia. Una vez que se complete la verificación, enviaremos el recibo digital a la dirección de correo electrónico oficial que hayas proporcionado.",
        verify_success: "Te has unido a School Glory",
        verify_error:
          "Error en la verificación. Te enviaremos un correo electrónico para explicarte la razón.",
        withdrawed: "Te has retirado temporalmente de School Glory.",
        withdraw_popup_1:
          "¿Deseas retirarte temporalmente de School Glory? Si es así, esperamos que puedas resolver con éxito los problemas que estás enfrentando, y esperamos tu regreso después de tres meses, una vez que los hayas resuelto.",
        withdraw_popup_2:
          "¡Felicitaciones por resolver el problema y no tener incidentes similares en los últimos tres meses! ¡Bienvenido de nuevo a School Glory!",
        // },
        ranking_rule:
          "El ranking de cada escuela se basa en el orden en el que se unen.",
        received_likes: "Me gustas recibidos: ",
        ranking: "Ranking: ",
        pl_enter_login_id: "Por favor, introduce tu ID de inicio de sesión:",
        back_to_home: "Volver a la página de inicio",
        logout: "Cerrar sesión",
        edit_profile: "Editar Perfil",

        // location: "Ubicación",
        // type: "Tipo",

        loginId: "ID de inicio de sesión",
        submit: "Enviar",
        confirm_remove: "Confirmar",
        cancel_withdraw: "Cancelar",

        //School Email Back
        notice_top_1: "Utiliza el correo electrónico oficial de tu escuela:",
        notice_top_2:
          "para copiar la siguiente información y enviarla por correo electrónico a",
        notice_top_3: "para el próximo proceso de verificación: ",
        email_title_tip: "Asunto del correo electrónico:",
        email_content_tip: "Contenido del correo electrónico:",
        email_content_for: "Tema: ",
        school_name_email: "Nombre de la escuela: ",
        school_type_email: "Tipo de escuela: ",
        school_location_email: "Ubicación de la escuela: ",
        school_full_address_email: "Dirección de la escuela: ",
        stripe_payment_id: "Código de verificación: ",
        important: "¡Importante!",
        are_you_sure_1:
          "Asegúrate de haber enviado la información correctamente a la dirección de correo electrónico ",
        are_you_sure_2:
          "Ten en cuenta que sin este proceso no podrás pasar la verificación.",
        yes_sent: "Sí, correo electrónico enviado.",
        not_sent: "Aún no enviado.",

        //User profile
        user_role: "Rol",
        edit_user_profile: "Editar Perfil de Usuario",
        user_name: "Nombre de Usuario",
        you_are_a: "Eres un",
        parent: "Padre",
        student_: "Estudiante",
        other_role: "Otro",
        you_didnt_liked: "Aún no le has dado Me gusta a ninguna escuela.",
        schools_you_liked: "Escuelas a las que les has dado Me gusta",
        give_a_like_btn: "Dar me gusta",
        about: "Acerca de",
        register: "Registrarse",
        login: "Iniciar sesión",
        view_likers_btn: "¿Quién te ha dado Me gusta?",
        //password forgotten
        forgot_password: "¿Olvidaste tu contraseña?",
        please_enter_your_email_to_get_your_verify_code:
          "Por favor, introduce tu correo electrónico para obtener tu código de verificación.",
        school_name_of_student: "Numbre de la escuela",

        if_teaching_staff_needed_rewards_points_title:
          "¿El personal docente y sus familias necesitan puntos de recompensa para canjear premios?",
        if_teaching_staff_needed_rewards_points_content:
          "El personal docente y sus familias no necesitan puntos de recompensa para canjear premios. En los casos en los que las recompensas de los negocios no estén específicamente designadas solo para el canje de estudiantes, el personal docente puede canjear premios.",
        if_student_teaching_staff_allowed_to_re_redeem_rewards:
          "¿Los estudiantes y el personal docente pueden volver a canjear premios después de haberlo hecho una vez?",
        if_student_teaching_staff_allowed_to_re_redeem_rewards_content:
          "Sí, los premios se pueden canjear nuevamente antes de que expire el período de recompensas establecido por el negocio.",
        //=================================
        recommend_to_add_rule:
          "Importante: Se recomienda que los usuarios de la escuela agreguen los siguientes comportamientos de recompensa y deducción.",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_title:
          "Cuando un estudiante ya no está inscrito en esta escuela, como al graduarse o transferirse, ¿cómo deben manejarse sus cuentas?",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_1:
          "Hay 3 formas posibles de manejar esto:",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_2:
          "1. Al agregar nuevos estudiantes, los administradores escolares pueden establecer la validez de la cuenta de un estudiante en esta escuela. El período de validez de cada cuenta está determinado por el año seleccionado más tres meses. Cuando la cuenta de un estudiante caduque, cambiará automáticamente a un estado 'caducado' sin requerir ninguna acción de los administradores escolares.",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3:
          "2. Si desea conservar la información del estudiante, pero necesita desactivar su cuenta escolar, cambie la duración válida de la cuenta del estudiante a cero años. Aquí están los pasos:",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_1:
          "① Ingrese al panel de control del perfil de la escuela;",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_2:
          "② Encuentre lo siguiente:",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_3:
          "③ Encuentre al estudiante correspondiente por correo electrónico o nombre, haga clic en 'Editar información del estudiante' y establezca la validez de la cuenta del estudiante en cero.",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_4:
          "3. Elimine directamente la cuenta del estudiante. Tenga en cuenta que, una vez eliminada la cuenta, no será posible recuperar los datos del estudiante. Por favor proceda con precaución.",

        what_is_student_initial_reward_point_title:
          "¿Cuál es el punto de recompensa inicial para los estudiantes?",
        what_is_student_initial_reward_point_content:
          "El punto de recompensa inicial para cada estudiante es de 5 puntos.",

        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_title:
          "Cuando un profesor ya no está enseñando en esta escuela, ¿cómo debe manejarse su cuenta?",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_1:
          "Hay 3 formas posibles de manejar esto:",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_2:
          "1. Al agregar nuevos profesores, los administradores escolares pueden establecer la validez de la cuenta de un profesor en esta escuela. El período de validez de cada cuenta está determinado por el año seleccionado más tres meses. Cuando la cuenta de un profesor caduque, cambiará automáticamente a un estado 'caducado' sin requerir ninguna acción de los administradores escolares.",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3:
          "2. Si desea conservar la información del profesor, pero necesita desactivar su cuenta escolar, cambie la duración válida de la cuenta del profesor a cero años. Aquí están los pasos:",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_1:
          "① Ingrese al panel de control del perfil de la escuela;",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_2:
          "② Encuentre lo siguiente:",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_3:
          "③ Encuentre al profesor correspondiente por correo electrónico o nombre, haga clic en 'Editar información del profesor' y establezca la validez de la cuenta del profesor en cero.",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_4:
          "3. Elimine directamente la cuenta del profesor. Tenga en cuenta que, una vez eliminada la cuenta, no será posible recuperar los datos del profesor. Por favor proceda con precaución.",
        school_login_title: "Iniciar sesión como usuario de la escuela",
        add_reward_rule_btn:
          "Agregar comportamiento de sumar puntos de recompensa",
        add_negative_rule_btn: "Agregar comportamiento de restar puntos",
        rule_reached_limitation:
          "Has alcanzado el límite máximo para añadir reglas, no se pueden añadir más.",
        lang: "es",
        view_btn: "Detalles",
        suitable_age_range: "Rango de edad adecuado",
        min_age: "Edad mínima (años)",
        max_age: "Edad máxima (años)",
        invalid_url: "¡El web no coincide con la plataforma seleccionada!",
        //end es===
      },
    },
    zh: {
      translation: {
        language_suggetion_title: "填写资料建议使用哪种语言？",
        language_suggestion_content: "建议使用您本国的语言。",
        South_America: "南美洲",
        Oceania: "大洋洲",
        North_America: "北美洲",
        Latin_America: "拉丁美洲",
        Europe: "欧洲",
        Antarctica: "南极洲",
        Africa: "非洲",
        Asia: "亚洲",
        worldwide: "全世界",
        register_advise:
          '为了确保您可以顺利完成注册，我们建议您在注册之前已经仔细阅读过"常见问题"，请确保您已经完成再开始注册',
        table_of_contents: "导航目录",
        //teacher
        what_teaching_staff_can_do_title: "教职人员可以做什么？",
        what_teaching_staff_can_do_content_1: "①添加学生;",
        what_teaching_staff_can_do_content_2: "②添加教职人员;",
        what_teaching_staff_can_do_content_3: "③添加学生的奖励/扣分事件;",
        what_teaching_staff_can_do_content_4: "④接收学生的举报内容;",
        what_teaching_staff_can_do_content_5: "⑤制定学校可奖励与扣分的行为;",

        if_teaching_staff_can_apply_reward_title: "教职人员是否可以兑换奖励？",
        if_teaching_staff_can_apply_reward_content:
          "是的，教职人员以及教职人员的家人都可以兑换奖励。不仅可以申请，而且申请的记录也仅对申请者开放展示，学校其他教职人员均无法查看。您可以在个人界面中查看申请记录：",

        how_can_teaching_staff_change_password_title: "教职人员如何更改密码？",
        how_can_teaching_staff_change_password_content: "②进入教职人员登录;",

        //Student=====================
        what_student_can_do_title: "学生用户可以做什么？",
        what_student_can_do_content_1:
          "①本品台为学生提供了一键举报功能，方便学生可以及时向学校反应自己的情况，保护自己;",
        what_student_can_do_content_2:
          "②学生可以通过在学校表现良好积累奖励积分。这些积分可以用来在奖励市场上为他们自己和家人兑换奖励，即使在兑换奖励之后，累积的积分也会保留供将来兑换使用，不会消耗;",

        how_to_report_to_school_title: "如何向学校举报？",
        how_to_report_to_school_content_1: "①登录之后进入后台;",
        how_to_report_to_school_content_2:
          "②找到一键举报按钮，点击即可以向学校举报：",

        student_how_to_change_password_title: "学生用户如何更换密码？",
        student_how_to_change_password_content_1: "①进入登录界面;",
        student_how_to_change_password_content_2: "②进入学生登录;",
        student_how_to_change_password_content_3: "③点击忘记密码;",
        student_how_to_change_password_content_4: "④输入Email ;",
        student_how_to_change_password_content_5: "⑤到邮箱中获取验证码；",
        student_how_to_change_password_content_6:
          "⑥验证码+新密码=修改密码成功;",

        //School=======================
        mearningful_school_title: "学校加入校园荣耀的意义？",
        mearningful_school_content:
          "学校加入校园荣耀,可以自定义奖励行为和扣分行为，引导学生走向积极、正面的发展方向。除了为学生提供学习动力外，学生的家人也可以参与并兑换奖励商城中提供的奖励，这不仅能够激励学生，还可以在其承受范围内为家人带来权益和减轻生活负担。我们的奖励商城也欢迎教师及其家人兑换奖励，以表彰他们的工作成绩并给予认可与奖励。我们的最终目标是减少校园霸凌现象，营造一个更加健康、快乐的学习环境。我们希望能够凝聚社会各界的力量，共同守护孩子们的未来。",

        type_that_school_can_apply_title: "可以申请的学校类型？",
        type_that_school_can_apply_content:
          "幼儿园，小学，中学，技术学院，大学或其他教育机构。",

        what_school_admin_user_can_do_title: "学校管理者可以做什么？",
        what_school_admin_user_can_do_main_1:
          "· 学校通过学生的一键举报，收到学生的求助信号，及时提供帮助；",
        what_school_admin_user_can_do_main_2:
          "· 每一所学校可以为添加至10条奖励和10条扣分条例。在为学生添加奖励分数事项和扣分事项的时候“有法可依”。引导学生朝着积极的方向发展，减少让学生误入歧途的可能，进而保护学生们的未来;",
        what_school_admin_user_can_do_main_3:
          "学校用户被建议添加以下奖励行为：",
        what_school_admin_user_can_do_content_1:
          "①被霸凌的同学勇敢地拿出证据举报霸凌者 +10 ；",
        what_school_admin_user_can_do_content_2:
          "②制止霸凌行为，在无力制止的情况下及时向老师寻求帮助 +10；",
        what_school_admin_user_can_do_content_3:
          "③优秀的学术成绩（由学校自定义）+10；",
        what_school_admin_user_can_do_content_4: "④学习成绩有进步 +1;",
        what_school_admin_user_can_do_main_4: "学校被建议添加如下扣分行为：",
        what_school_admin_user_can_do_content_5:
          "①语言霸凌：嘲笑，辱骂，威胁同学 -10；",
        what_school_admin_user_can_do_content_6:
          "②社交霸凌：排斥，孤立，散播谣言 ，破坏别人的东西 -10；",
        what_school_admin_user_can_do_content_7:
          "③肢体霸凌：打架斗殴，做侮辱性手势 -10；",
        what_school_admin_user_can_do_content_8: "④为获得奖励分数而造假 -10；",
        what_school_admin_user_can_do_main_5:
          "其它规则由学校自定义奖励与扣分的行为，让学生的奖励与扣分行为有法可依。",
        what_school_admin_user_can_do_main_6:
          "学校的教职人员及其家人也可以兑换奖励；让教职人员也可以享受奖励福利，促进校内正向互动。",

        what_datas_need_to_prepare_to_join_title: "学校入驻需要准备什么材料？",
        what_datas_need_to_prepare_to_join_content_main:
          "注册过程需要人工审核，大约需要7到15个工作日。学校须提供以下信息：",
        what_datas_need_to_prepare_to_join_content_1:
          "· 学校的官方电子邮件地址；",
        what_datas_need_to_prepare_to_join_content_2: "· 学校的全名；",
        what_datas_need_to_prepare_to_join_content_3: "· 学校的位置；",
        what_datas_need_to_prepare_to_join_content_4:
          "· 学校类型（例如：幼儿园，小学，中学等）;",
        what_datas_need_to_prepare_to_join_content_5: "· 学校校徽。（可选）;",

        random_sort_school_title: "学校在校园荣耀首页的默认排名？",
        random_sort_school_content:
          "默认排名是根据加入的时间，加入时间越早，排名越靠前。",

        where_to_get_receive_title: "学校付款后，在哪里获得收据？",
        where_to_get_receive_content:
          "付款必须使用学校的公户，该账户应与学校注册名称相一致。请注意，如果审核未通过，将不予退款。因此，请提供准确完整的信息以确保能够顺利注册。审核完成后，我们将通过邮件的方式将电子收据发送到您的官方Email邮箱。",

        how_to_add_student_title: "如何添加在校的学生？",
        how_to_add_student_content_1: "①登录后进入学校后台界面;",
        how_to_add_student_content_2: "②找到以下：",
        how_to_add_student_content_3: "③点击进入之后，点击添加学生按钮：",

        how_to_add_teaching_staffs_title: "如何添加教职人员？",
        how_to_add_teaching_staffs_content_1: "①登录后进入学校后台界面;",
        how_to_add_teaching_staffs_content_2: "②找到以下：",
        how_to_add_teaching_staffs_content_3:
          "③点击进入之后，点击添加教师的Email按钮：",

        where_to_check_students_reports_title: "在哪里可以收到学生的举报？",
        where_to_check_students_reports_content_1: "①登录后进入学校后台界面;",
        where_to_check_students_reports_content_2: "②找到以下：",

        how_to_add_reward_punishment_actions_title:
          "如何添加学校的会被奖励的行为和会被扣分的行为?",
        how_to_add_reward_punishment_actions_content_1:
          "①登录后进入学校后台界面;",
        how_to_add_reward_punishment_actions_content_2: "②找到以下：",
        how_to_add_reward_punishment_actions_content_3: "③点击按钮：",

        how_to_add_reward_punishment_events_title:
          "如何为学生添加奖励或者扣分的事件？",
        how_to_add_reward_punishment_events_content_1:
          "①登录后进入学校后台界面;",
        how_to_add_reward_punishment_events_content_2: "②找到以下：",
        how_to_add_reward_punishment_events_content_3:
          "根据学生的Email或者学生的名字找到对应学生：",
        how_to_add_reward_punishment_events_content_4:
          "为该学生添加对应的事件:",

        can_school_admin_user_apply_reward_title:
          "学校账户管理人员是否可以兑换奖励？",
        can_school_admin_user_apply_reward_content_1: "可以。",
        can_school_admin_user_apply_reward_content_2:
          "不仅可以申请，而且申请的记录也仅对申请者开放展示，学校其他教职人员均无法查看。您可以在个人界面中查看申请记录：",

        what_can_you_do_after_joined_school_glory_title:
          "申请加入校园荣耀平台之后，可以先做什么？",
        what_can_you_do_after_joined_school_glory_content_1:
          "①添加学校的可奖励行为和会被扣分的行为;",
        what_can_you_do_after_joined_school_glory_content_2:
          "②添加学校教职人员的信息;",
        what_can_you_do_after_joined_school_glory_content_3:
          "③添加本校学生的信息，也可以由已经添加的学校的教职人员使用其账号进行添加;",

        how_can_school_cancel_auto_charge_title: "学校如何取消自动续费？",
        how_can_school_cancel_auto_charge_content_1: "①登录后进入学校后台界面;",
        how_can_school_cancel_auto_charge_content_2:
          "②找到设置->更换付款方式->取消自动续费",

        how_to_apply_reward_school_title: "如何兑换奖励？",
        how_to_apply_reward_school_content_1: "①在首页的地方找到奖励商城：",
        how_to_apply_reward_school_content_2:
          "②进入奖励商城中，找到符合您申请的所有奖励：",
        how_to_apply_reward_school_content_3:
          "③选择一项你感兴趣的申请，点击进入；",
        how_to_apply_reward_school_content_4: "④选择你需要的使用方式：",
        or: "或者",

        how_to_change_password_content_2_school: "如何更换密码?",
        how_to_change_password_content_2_school_login: "②进入学校登录;",

        //STORE=========================
        mearningful_store_title: "奖励商店的意义？",
        mearningful_store_content:
          "校园荣耀的目标在于引导学生走向积极、正面的发展方向。除了为学生提供学习动力外，我们也鼓励学生的家人参与并兑换奖励，这不仅能够激励学生，还可以在其承受范围内为家人带来权益和减轻生活负担。我们的奖励商城也欢迎教师及其家人兑换奖励，以表彰他们的工作成绩并给予认可与奖励。我们的最终目标是减少校园霸凌现象，营造一个更加健康、快乐的学习环境。我们希望能够凝聚社会各界的力量，共同守护孩子们的未来。通过奖励商店，我们努力营造一种激励和认可的文化，鼓励学生、家庭以及教职人员在积极的学习和工作态度下获得回报，并从中获得实质性的支持和认可，为整个社会创造更美好的未来。",
        sutable_for_what_store_title: "适合什么样的商家加入？",
        sutable_for_what_store_content_main_1:
          "当商家考虑加入校园荣耀时，我们欢迎各种类型的企业和商户参与，从而为学生、家长和教职人员带来更多的奖励和好处。我们的平台致力于提供符合学生兴趣的奖励内容，同时也希望能让学生家长在其中获益，减轻家庭开支负担。我们也为教职人员及其家人提供兑换奖励的机会。",
        sutable_for_what_store_content_main_2:
          "在商家加入的方面，我们不排除任何特定类型的企业，除非属于被明确排除的行业。我们欢迎所有其他类型的企业加入并为我们的社区提供丰富多样的奖励内容。因此，只要不属于禁止加入的行业，所有商家都可以考虑加入校园荣耀，为我们的用户群体带来更多选择和福利。例如：",

        sutable_for_what_store_content_1:
          "· 名人和明星：可奖励获得10分以上奖励分数的学生和教师，提供专属直播见面会。",
        sutable_for_what_store_content_2:
          "· 网店商家和实体店商家：可奖励获得10分以上奖励分数的学生和教师，提供专属活动或折扣优惠等等。",
        sutable_for_what_store_content_last:
          "这些示例展示了我们鼓励的合作形式，为学生和教职人员提供丰富的奖励选择，让他们享受到更多来自各种商家的专属奖励。",

        which_kind_of_store_not_allowed_to_join_school_glory_title:
          "什么行业不允许加入？",
        which_kind_of_store_not_allowed_to_join_school_glory_content_main:
          "校园荣耀 奖励商城的平台服务对象是学生及其家人、教职人员及其家人。因此，我们对奖励内容和参与环境有一定限制。为维护平台的纯净性和教育性质，我们限制以下行业的加入。请注意，在审核阶段，若有涉及以下行业的商家申请注册，注册费用将不予退还，请在申请时慎重考虑:",
        which_kind_of_store_not_allowed_to_join_school_glory_content_1:
          "①色情行业",
        which_kind_of_store_not_allowed_to_join_school_glory_content_2:
          "②赌博行业",
        which_kind_of_store_not_allowed_to_join_school_glory_content_3:
          "③毒品行业",

        what_store_user_can_do_title: "奖励商店的商家可以做什么？",
        what_store_user_can_do_content_1:
          "①可以自行设置允许获得奖励申请的奖励分数和奖励的内容；",
        what_store_user_can_do_content_2: "②可以自行设置奖励的有效期;",
        what_store_user_can_do_content_3:
          "③通过引流方式，为商家提供便捷通道，无需再搭建平台，可以选择一个现有的平台，例如网店，实体店的加入；",
        what_store_user_can_do_content_4:
          "④自定义奖励内容的受众：学生（及其家人）、教师（及其家人）或者学生（及其家人）和教师（及其家人);",

        what_datas_store_user_needs_title: "所需要的资料是什么？",
        what_datas_store_user_needs_content_1:
          "· Logo （最佳展示尺寸：108*108）;",
        what_datas_store_user_needs_content_2:
          "· 一张代表商店的图片（最佳展示尺寸：700*700）;",
        what_datas_store_user_needs_content_3: "· 商店的名字;",
        what_datas_store_user_needs_content_4: "· 商店的简介（200字）;",
        what_datas_store_user_needs_content_5:
          "· 奖励有效期（可以在成功加入后进行修改）;",
        what_datas_store_user_needs_content_6:
          "· 申请条件（学生的奖励分数需要达到多少分及以上才能够申请您提供的奖励）;",
        what_datas_store_user_needs_content_7:
          "· 商店的类型：网店/实体店/网店和实体店;(默认所有实体店都可以兑换奖励，如有限制，需注明特定的分店);",
        what_datas_store_user_needs_content_8: "· 适用年龄区间;",
        what_datas_store_user_needs_content_9:
          "· 奖励品类/内容，例如：餐具，电子产品，学习用品，名人，娱乐等等;",
        what_datas_store_user_needs_content_10:
          "· 适用申请对象:教师（及家人）；学生（及家人）；教师（及家人）+学生（及家人）;",
        what_datas_store_user_needs_content_11:
          "· 如果有网络的平台，可以选择平台，例如：Amazon; AliExpress; ebay; 淘宝等等；",
        what_datas_store_user_needs_content_12:
          "· 附上可以导向您的网络平台的网址；",

        what_school_glory_will_offer_for_store_user_title:
          "平台提供什么使用渠道？",
        what_school_glory_will_offer_for_store_user_content_1: "· 网店；",
        what_school_glory_will_offer_for_store_user_content_2: "· 实体店;",

        how_can_store_user_check_if_applier_met_require_title:
          "商家如何判断学生或教师是否符合奖励规定？",
        how_can_store_user_check_if_applier_met_require_content_1:
          "· 网店：用户在点击兑换奖励之后，会得到奖励码，商家使用校园荣耀商家后台提供的输入申请ID,即可核实申请的用户是否符合申请条件;",
        how_can_store_user_check_if_applier_met_require_content_2:
          "· 实体店：用户在点击兑换奖励之后，会得到奖励二维码，商家使用校园荣耀商家后台提供的二维码扫描，核实申请的用户是否符合申请条件；",

        how_can_store_user_sort_title:
          "奖励商城首页的默认排列顺序是依照什么排列的？",
        how_can_store_user_sort_content_1: "①兑换奖励分数越低，排序越靠前;",
        how_can_store_user_sort_content_2: "②已奖励人数越多排名越靠前;",
        how_can_store_user_sort_content_3: "③加入时间越早，排名越靠前;",
        how_can_store_user_sort_content_4: "④好评越多，排名越靠前;",

        how_to_calculate_reward_amount_title: "学生的奖励分数是如何来的？",
        how_to_calculate_reward_amount_content_positive_main:
          "学校加入校园荣耀平台后，可以自定义至10条奖励行为和10条扣分行为，",
        how_to_calculate_reward_amount_content_positive_main_1:
          "学校被建议添加如下的奖励行为：",
        how_to_calculate_reward_amount_content_1:
          "①被霸凌的同学勇敢地拿出证据举报霸凌者 +10 ；",
        how_to_calculate_reward_amount_content_2:
          "②制止霸凌行为，在无力制止的情况下及时向老师寻求帮助 +10；",
        how_to_calculate_reward_amount_content_3:
          "③优秀的学术成绩（由学校自定义）+10；",
        how_to_calculate_reward_amount_content_4: "④学习成绩有进步 +1;",
        how_to_calculate_reward_amount_content_negative_main:
          "学校被建议添加如下的扣分行为：",
        how_to_calculate_reward_amount_content_1_negative:
          "①语言霸凌：嘲笑，辱骂，威胁同学 -10；",
        how_to_calculate_reward_amount_content_2_negative:
          "②社交霸凌：排斥，孤立，散播谣言 ，破坏别人的东西 -10；",
        how_to_calculate_reward_amount_content_3_negative:
          "③肢体霸凌：打架斗殴，做侮辱性手势 -10；",
        how_to_calculate_reward_amount_content_4_negative:
          "④为获得奖励分数而造假 -10；",
        how_to_calculate_reward_amount_content_last:
          "其它规则由学校自定义奖励与扣分的行为，让学生的奖励与扣分行为有法可依。",

        steps_for_store_users_title: "商家加入奖励商城的步骤：",
        steps_for_store_users_content_title_1: "①填写基本资料：",
        steps_for_store_users_content_1:
          "首先，完善商家的基本信息是第一步。填写准确、完整的资料有助于顺利进行后续流程;",
        steps_for_store_users_content_title_2: "②缴纳注册费用：",
        steps_for_store_users_content_2:
          "在完成基本资料填写后，需缴纳注册费用。这笔费用是参与奖励商城的入驻所必需的;",
        steps_for_store_users_content_title_3: "③验证商家资质：",
        steps_for_store_users_content_3:
          "我们将对商家提交的资料进行审核，确保符合入驻条件。这是为了维护奖励商城的品质和信誉。温馨提示：如果商家不符合加入的条件，注册费用将无法退还，请仔细阅读申请条件;",
        steps_for_store_users_content_title_4: "④通过验证后，选择缴费方式：",
        steps_for_store_users_content_4:
          "一旦商家资质通过验证，即可选择缴纳入驻费用的方式。可选择按年付费或者一次性购买终身入驻权益，以符合您的经营计划;",
        steps_for_store_users_content_title_5: "⑤商店成功上架：",
        steps_for_store_users_content_5:
          "在完成缴费后，您的商店将成功在奖励商城中上架，让您的产品或服务得以展示;",
        steps_for_store_users_content_last:
          "以上步骤将引导您顺利加入奖励商城，并在其中建立起稳固的经营基础，让您的业务得到更多的曝光和发展机会。",

        how_to_modify_reward_title: "如何修改奖励内容?",
        how_to_modify_reward_content_1: ". 商家登录后，进入商家的后台界面;",
        how_to_modify_reward_content_2:
          ". 找到‘编辑资料’，即可以修改奖励内容和奖励期限;",

        how_to_withdraw_from_display_title: "如何暂时撤下奖励商城?",
        how_to_withdraw_from_display_content_main:
          "在某些情况下，您的奖励申请可能需要暂时不在奖励商城中展示，您可以根据以下步骤，暂时撤出奖励商城",
        how_to_withdraw_from_display_content_1:
          ". 商家登录后，进入商家的后台界面;",
        how_to_withdraw_from_display_content_2:
          ". 找到设置->账户状态->将展示开关关闭即可;",

        how_to_change_payment_method_title: "如何更换付款方式?",
        how_to_change_payment_method_content_main:
          "商家注册都是自动设置为自动续费状态，如果需要取消自动续费，或者更换付费方式，您可以根据以下步骤：",
        how_to_change_payment_method_content_1:
          ". 商家登录后，进入商家的后台界面;",
        how_to_change_payment_method_content_2:
          ". 找到设置->订阅状态->点击‘取消自动续费按钮’或者‘更换付费方式’;",

        how_to_change_password_title_store: "如何更换密码?",
        how_to_change_password_content_1: "①进入登录界面;",
        how_to_change_password_content_2_store: "②进入商家登录;",
        how_to_change_password_content_3: "③点击忘记密码;",
        how_to_change_password_content_4: "④输入Email +登录ID;",
        how_to_change_password_content_5: "⑤到邮箱中获取验证码；",
        how_to_change_password_content_6: "⑥验证码+新密码=修改密码成功;",

        //==========================================================
        ex: "名人，书店，电子产品，商店...",
        setting: "设置",
        awaiting_comment: "待评价",
        school_name_of_student: "学校名字",

        only_admin_user_can_cancel_account: "注意：只有管理员可以注销账户",
        basic_plan_explain: "基础版（适用教职人员：100）",
        stander_plan_explain: "标准版（适用教职人员：50)",

        pro_plan_explain: "高级版（适用教职人员：100)",
        reward_store: "奖励商店",
        teaching_staff: "教职人员",
        teacher_name: "名字",
        error_in_prolong_payment: "费流程出现异常，请重新续费",
        active_date: "激活时间",
        terminal_at: "到期时间",
        sate_prolong: "状态：自动续费",
        cancel_prolong: "取消自动续费",
        after_cancel_you_still: "取消订阅后，你仍可以使用到",
        confirm_cancel_sub: "确认取消订阅",

        displaying_in_homepage: "正在首页展示中...",
        not_displaying_in_homepage: "已不在首页中展示...",

        You_match_id_teacher: "你的身份：老师 - 所有符合的申请条件",
        You_match_id_school: "你的身份：学校管理者 - 所有符合条件的申请",
        your_total_points: "你的总分",
        able_to_apply: "可兑换奖励",
        users_apply_records: "以下为学生或教师用户向您兑换奖励的记录",
        payment_statement: "续费状态",
        store_login_title: "奖励商家登录",
        enter_web_url: "请输入您对应的平台的网址",
        enter_platform_name: "请输入平台名字,例如Amazon",
        reward_that_you_are_able_to_apply_now: "   以下为你目前可以申请的奖励",
        temp_no_reward_to_apply: "暂无可申请的奖励",
        all_over_the_world: "全世界",
        not_comment_yet_apply: "以下为未评价的奖励申请",
        event_time: "日期",
        faq: "常见问题",
        Allow_add_reward_behavior:
          "本页面解释：教职人员及平台管理者可以录入学生可以被给予奖励的行为,可以修改与删除自行添加的行为，并且删除后将无法恢复。（建议：条件允许的情况下，学校应尽量让学生与家长参与投票再做决定）。",

        Allow_add_punishment_behavior:
          "本页面解释：教职人员及平台管理者可以录入学生会被扣分的行为（建议：条件允许的情况下，学校应尽量让学生与家长参与投票再做决定）。",

        School_change_to_another_payment_method:
          "请注意：账户暂停时，学生及其家人，教职人员及其家人将无法兑换奖励，请谨慎考虑使用取消自动续费功能",

        get_all_reports_explain:
          "本页面解释：获取全部学生的举报信息,请学校及时联系学生并调查清楚事实。",
        according_reward_points: "对应行为的奖励分数",
        according_punish_points: "对应行为的扣分分数",
        register_fee: "验证费",
        customize_reward_conditon: "自定义奖励条件",
        help_let_others_know_your_store: "为自家的实体店或网店引流",
        can_cancle_or_change_method_anytime: "随时暂停服务和更换付费模式",
        unlimit_use_new_methos: "后续无限制免费使用所有升级功能",
        pricing_for_reward_store: "奖励商家入驻价格",
        check_details: "查看详情",
        suitable_for_stores_type: "可申请奖励的渠道 ： 网店，实体店",
        your_experience: "您的体验",
        leave_your_rate: "您对本次服务/购物的满意程度",
        user_not_meet_applicant_requirement: "该用户不符合申请条件！",
        user_meet_applicant_requirement: "该用户符合申请条件！",
        commit_to_reward: "确认奖励！",
        successfully_rewarded: "成功奖励！",
        school_user: "学校(管理者)",
        not_rewarded: "未奖励",
        date_of_use: "使用日期",
        reward_now: "马上奖励",
        use_now: "去使用",
        customize_reward_behaviors_for_this_school: "自定义本校的奖励行为",
        customize_deduction_behaviors_for_this_school: "自定义本校的扣分行为",
        add_penalty_events: "给学生添加奖励/扣分事件",
        add_reward_rules: "添加学生可获得奖励和会被扣分的行为准则",
        add_punishment_rules: "添加扣分行为",
        students_teachers_and_their_families_in_this_school_can_use_the_rewards_provided_in_the_reward_store:
          "本校的学生和老师及其家人可申请兑换奖励商城中提供的奖励内容",
        able_to_add_teaching_staff: "可添加教职人员人数最多至100名",
        suitable_to_users_school:
          "适用申请对象 ： 幼儿园 ， 小学 ，中学，初中，高中，大学等院校",
        pricing_for_school: "学校入驻价格",
        undeal: "未处理",
        dealed: "已处理",
        all_reports: "投诉内容",
        reward_invalid_now: "该奖励已经过期，或者该商家已经下架该奖励。",
        stores: "奖励商城",
        pricing: "价格",
        store_register_title: "奖励商家注册",
        your_logo: "你商店的商标 (最佳展示尺寸: 108*108)",
        your_store_cover_image:
          "请上传一张能够代表您的商店的封面图片 (最佳展示尺寸: 700*700)",
        your_display_name: "你商店的名字",
        store_intro: "商店简介",
        reward_content: "奖励内容",
        reward_valid_date: "奖励有效期至(设置后可以随时更新，请放心设置):",
        reward_condition:
          "申请条件（学生的奖励分数需要达到的最少分数。温馨提示：默认排名根据奖励分数由低到高）",
        store_type:
          "可申请奖励的渠道(温馨提示：实体店默认所有实体店都可以使用您提供的奖励)",
        online_store: "网店",
        physical_store: "实体店",
        online_physical: "网店和实体店",
        place_to_use_title: "使用地",
        product_type_title: "请选择主营产品",
        available_to_title: " 适用申请对象（包含学生的家人，教师及其家人)",
        tableware: "餐具",
        bedding: "床上用品",
        clothing: "服装",
        electronics: "电子产品",
        furniture: "家具",
        food: "食品",
        cosmetics: "化妆品",
        sporting_goods: "运动用品",
        books: "图书",
        jewelry: "珠宝首饰",
        fresh_produce_market: "生鲜市场",
        supermarket: "大型超市",
        school_supplies: "学习用品",
        real_estate: "房地产",
        restaurants: "餐厅",
        tourist_attractions: "旅游景点",
        amusement_parks: "游乐园",
        celebrities:
          "名人（艺人,体育明星,政治人物，作家,导演，世界500强企业家）",
        film_production: "电影制片",
        sports_clubs: "体育俱乐部",
        news_agencies: "新闻报社",
        education: "教育",
        hotels: "酒店",
        travel_agencies: "旅行社",
        banks: "银行",
        transportation: "交通",
        gas_stations: "加油站",
        game: "游戏",
        study_app: "学习软件",
        car: "汽车",
        // influencer: "博主",
        other: "其它",
        available_for_who_title: "适用申请对象（包含学生的家人，教师及其家人）",
        teacher: "教师（含家人）",
        student: "学生(含家人)",
        student_teacher: "学生+教师",
        support_platfrom_title: " 请选择一个支持的线上平台",
        online_store_url_title:
          "只能填一个有效的能够链接到您的商店的网址,请仔细检查",
        dont_forget_reward_tip:
          "温馨提示：向用户提供奖励后，请记得点击奖励按钮。这是帮助您提高在奖励市场的排名的关键！",
        please_enter_id: "请输入id…",
        awaiting_reward: "待奖励",
        rewarded: "已奖励",
        valid_till: "有效期至",
        apply_condition_score: "申请奖励分数需要",
        rewarded_amount: "已奖励人数",
        available_to: "适用对象",
        store_type_short: "奖励申请渠道",
        product_type: "主营产品",
        reward_content_short: "奖励内容",
        valid_date: "有效期至",
        online_platform: "可申请奖励的网店",
        sub_state: "订阅状态",
        account_state: "账户状态",
        cancel_account: "注销账户",
        upload_store_tips: "每日按申请顺序，上新30个审核通过的商家",
        please_enter_store_name: "请输入商家的名字…",
        join_stores_btn: "申请入驻奖励商店",
        not_allow_upload_content:
          "请仔细阅读不允予上传的商品：不允许上传商品，如果您提交的商品或服务违反了上述政策或其他使用规定，我们将无法批准您的申请，且缴纳的费用将不予退还。我们鼓励您在提交前仔细检查您的产品和内容，以确保其合法性和遵守我们的规定。我们的目标是创建一个安全、合法和尊重的奖励商店榜，以确保学生及其家人和老师及其家人的权益得到保护，感谢您的支持与配合。",
        not_allow_url: "不允许上传商品",
        awaiting_verify_store_notice:
          "验证中..(等待人工验证，1-15个工作日，验证通过后，您将可以进入下一步操作)",
        success_verify_tip: " 恭喜你成功通过验证",
        finish_last_step:
          " 请完成最后一步，选择您的订阅方式后，正式加入奖励商店榜",
        pay_by_month: "月",
        pay_by_year: "年",
        pay_by_life: "终身",
        failed_apply: "申请失败！",
        reason: "原因",
        your_setted_reward_expired: "你设置的奖励的有效期已过期，请前往更新",
        to_update: "去更新",
        canceled_not_allowed_restore:
          "注销账号之后所有资料将无法恢复，你确定要注销吗？",
        confirm_cancel_account: "确认注销账户",
        your_account_blocked: "你的账户被封锁!",
        re_subscribe: "再次订阅",
        displaying_tip: "正在奖励商城展示中...",
        remove_display_tip: "已从奖励商城中撤下…",
        reward_expired_tip:
          "您的奖励已过期，已不再奖励商城中展示,请及时调整奖励内容或者延期奖励有效期...",
        record_of_applying_title: " 以下为学生或者教师用户向您兑换奖励的记录",
        user_experience: "所有用户体验",
        user_apply_failed: "申请失败，您不符合申请该奖励的条件！",
        apply_reward: "获取奖励",
        qr_code_apply_tip:
          "温馨提示：实体店请展示二维码。网店，请复制奖励ID，并在付款时，发送给店主，获得专属奖励",
        apply_reward_success: "申请成功！",
        coppy_id: "请先复制以下ID,再向店主获取你的专属奖励",
        use_qr_code: "以下为您的专属二维码，请在结账时向店主展示",
        to_online_shop: "点击前往网店",
        valid_date_to: "有效期至",
        use_it_soon: "请尽快使用",
        select_your_plan_tip: "请选择你的付款计划",
        year_plan: "年",
        basic_plan: "基础版",
        stander_plan: "标准版",
        pro_plan: "高级版",
        ranking_now: "当前世界排名",
        joined_students_amount: "本校学生",
        punishment_amounts: "所有扣分事件",
        reward_amount: "所有奖励事件",
        reported_amount: "收到举报",
        enter_reward_rules: "录入可奖励行为",
        enter_punish_rules: "录入会扣分行为",
        add_teacher: "添加教职人员",
        private_teacher_reward_apply: "教职人员的私人奖励申请",
        private_teacher_explain:
          " 本页面解释：教职人员可以各自申请喜欢的奖励，本界面只对登录的教职人员公布，每个教职人员只能看到自己的申请历史",
        add_student_page_explain:
          "本页面解释：获取所有已经加入的学生，并且允许单独添加学生信息:学生头像，学生Email,学生班级信息，学生账号在本校的有效时,在添加完学生后，可以修改学生资料，删除学生，以及为学生添加奖励或者扣分事件。（注意：创建后，每个学生的账户的密码与Email一致，如学生用户需更改密码，需要学生用户前往登录界面->学生->忘记密码->重置密码，进行修改",
        explain_add_student:
          "作用: ①添加/更新/删除学生的资料; ②给学生添加奖励/者扣分事件",
        name: "名",
        edit_student_info: "修改学生资料",
        enter_student_info: "请输入学生的",
        enter_student_btn: "添加学生",
        search_content: "搜索内容",
        student_name: "学生名字",
        student_email: "学生的Email",
        student_info: "学生的年级班级",
        student_valid_year: " 账号在本校的有效时间（默认为选择的年份+ 3个月）",
        delete_student_account_notice: "永久删除学生，且无法恢复",
        add_event_to_student: "为学生添加扣分或者奖励的事件",
        student_account_valid_to: "账号有效期",
        valid_accounts: "有效账号",
        expired_accounts: "已过期",
        reward_behaviar: "奖励行为",
        punish_behaviar: "扣分行为",
        accord_to: "依据",
        detail_added: "详细补充",
        punished_page_explain: "本页面解释：获取全部已经录入的扣分事件。",
        rewarded_page_explain: "本页面解释：获取全部已经录入的奖励事件。",
        add_teacher_page_explain:
          " 本页面解释：教职人员及平台管理者可以录入允许使用本平台的在校教职人员的邮箱（创建后，密码与Email一致，如需更改密码，需要教师用户前往登录界面->教师->忘记密码->重置密码，进行修改）。",
        allow_add_teachers: "当前允许录入教师人数为",
        add_teacher_email_btn: "添加教师的Email",
        searcher_teacher_info: "搜索老师的",
        total_reward_points: "奖励总分",
        total_punish_points: "扣分总分",
        check_available_to_reward_btn: "查看已成功申请的奖励",
        available_to_reward_amount: "可兑换奖励",
        rewarded_history: "奖励历史",
        punished_history: "扣分历史",
        one_click_btn: "一键举报",
        thank_you_for_reporting:
          " 感谢你的举报，学校教职人员会尽快调查此事，请等待通知！",
        report_content: "举报内容",
        upload_evidence: "请上传证据",
        able_to_reward_bahavier: " 可以获得奖励的行为",
        able_to_punishment_behavier: "扣分行为",
        teacher_login_title: "教职人员登录",
        you_have_successfully_rewarded: "您已成功申请的奖励",
        comments_explain:
          " 本页面解释：请根据您的真实体验进行评论，您的鼓励将会让奖励商店的商家做得更好！",
        go_comment_btn: "去评价",
        reapply_tip: "再次申请",
        please_enter_id_search_bar: "请输入ID…",
        sub_expired_date: "到期时间",
        sub_method: "订阅方式",
        re_sub: "重新续费",
        change_payment_method: "更换付费方式",

        //============================================================
        add_school_hero_btn: "添加校园学生",
        pl_enter_a_hero_name: "请输入学生的名字...",
        search: "查询",
        add_a_hero_btn: "添加学生",
        our_heros: "我们的学生联盟",
        total_heros: "总人数",
        are_you_sure_to_remove: "你确定要移除",
        no_record_yet: "目前还没有记录",

        out_of_limit: "你已经超过了字数限制。",
        send_us_msg_title: "给我们发邮件",
        send_us_msg_content:
          "如果您有任何问题，请随时给我们发送邮件。如果您没有立即收到回复，可能是因为前面有更多用户在等待回复。请不要担心，我们会尽快给您回复。非常感谢您的耐心和理解。",
        send_us_msg_btn: "前往发送邮件",
        follow_us_title: "关注我们，",
        follow_us_sub: "可以帮助您获取我们的最新消息：",
        contact_us_title:
          "我们非常重视您提出的问题。为了能更好地回复您的咨询，请确保提供的信息真实准确。我们将仔细审核您提供的信息，并在验证通过后尽快回复您。",
        form_your_name: "*您的名字",
        form_your_email: "*您的电子邮箱",
        from_your_web: "*您的网站或领英链接",
        from_your_msg: "您想要发送给我们的消息",
        thank_you_email: "非常感谢您的来邮！",

        contact: "联系",
        newPassword: "请输入新密码",
        dear_school_user_title: "尊敬的用户 ,",
        issue_content:
          "您的申请流程出现问题。对于您在申请过程中遇到的问题，我们深感抱歉。我们的团队正在全力调查此事，并将通过邮件及时提供解决方案。请保持耐心，密切关注您的收件箱以获取我们的回复。感谢您的耐心等待。",
        support_bank_card:
          "接受全球客户使用 Visa、Mastercard、American Express、Discover、大来国际卡和部分带有中国银联标志的银行卡的支付，支付宝。",
        not_the_time: "您上次撤出校园荣耀 还不满三个月，请在以下日期后再尝试: ",
        view: "点击查看详情",
        register_notice_tip_user:
          "我们将需要进行邮箱验证。请您务必认真确认邮箱地址的准确性，以确保顺利完成验证过程。感谢您的支持和理解。",
        match_requirement: "符合条件的学校:",
        get_verification_code: "点击获取验证码",
        inComplete: "信息上传不完整!",
        only_school: "只有始终将学生的安全和福祉放在首位的院校才能上榜。",
        schools_has_joined: "目前，全球已成功上榜荣誉学院的学校：",
        apply: "申请加入校园荣耀 →",
        very_important: "非常重要",
        register_notice_tip:
          "在您完成付款后，我们将需要进行邮箱验证。请您务必认真确认邮箱地址的准确性，以确保顺利完成验证过程。如果验证失败，我们将无法退还注册费用。感谢您的支持和理解。",
        school_not_allow_vote: "学校之间不允许投票。",
        reset_password: "重置密码",
        login_id_email_cant_empty: "Email 和 登录 ID 不能为空!",
        wrong_info_limit:
          "您已超过错误次数限制，将被列入重点审查对象，请24小时后再重试。",
        login_as: "登录身份为：",
        yes: "是的",
        no: "取消",
        unlike_confirm: "你确定要取消支持",
        more_detials: "申请加入校园荣耀",
        login_id_is_required: "登录 ID 不能为空!",
        please_enter_email_and_password: "Email和密码不能为空！",
        ranking_validating: "一旦您通过验证，排名将会重新计算。",
        password_rule:
          "密码应至少包含至少4个字符，需要包含至少一个大写字母、一个小写字母、一个数字和一个特殊字符：@$! % * ? &",
        Must_add_one_UPPERCASE_letter: "密码必须包含至少一个大写字母！",
        Must_add_one_lowercase_letter: "密码必须包含至少一个小写字母！",
        Must_add_one_number: "密码必须包含至少一个数字！",
        Must_add_one_special_symbol:
          "密码必须包含至少一个特殊符号：@$! % * ? &",
        weak: "弱",
        fair: "一般",
        good: "及格",
        strong: "强",

        passwod_strength: "密码安全性检测",
        no_school_found: "我们目前还没有找到符合要求的学校",
        //who voted to
        who_voted_to: "以下为支持 ",
        de_yonghu: "的用户",
        //password forgorten
        please_enter_your_email_below_to_get_your_verify_code:
          "请在下方输入你的Email以获取你的验证码",
        login_btn: "登录",
        login_to_continue_title: "请登录后再继续操作",
        login_to_continue_content:
          "让我们一起支持与鼓励愿意为学生们筑梦的学校！",
        click_create_account: "点击创建一个账号",
        no_account_yet: "还没有账号：点击注册",
        loading: "加载中...",
        user_login_title: "以学生身份登录",
        password_forgotten: "忘记密码？",
        all: "全部",
        //页眉
        logo: " 校园荣耀",
        login_nav: "登录",
        register_nav: "注册",
        about_nav: "关于",
        //首页
        title: "让校园成为筑梦的殿堂！",
        subtitle:
          "我们致力于以循序渐进的方式减少校园霸凌行为，并为学生提供积极行为奖励，为学生的未来保驾护航。",
        search_bar_placeholder: "请输入学校名称...",
        location: "位置",
        type: "类型",
        total: "总共",
        // search: "查询",

        //学校类型
        Kindergarten: "幼儿园",
        Primary: "小学",
        Middle: "中学",
        High: "高中",
        Tenical: "专职院校",
        College: "大专院校",
        University: "大学",
        Other: "其他",

        //学校详情
        joined_us_on: "已顺利进入校园荣耀，时间为：",
        rest_content:
          "进入校园荣耀的学校承诺将一直致力于为学生提供一个安全健康与和谐的校园环境。",
        you_have_liked: "您已点赞过",

        //关于
        about_title: "关于我们",
        phase_1:
          "我的名字叫钟东菁，是校园荣耀的创始人。每次听到学校里的孩子受到霸凌的新闻，我都心如刀绞，为被霸凌的学生感到愤怒和悲伤。很长一段时间以来，我都希望可以做点什么来帮助这些被霸凌的学生，一直在思考如何从根本上解决校园暴力，校园霸凌等问题。意识到仇恨只会滋生更多敌意，我决定改变思维方式，逆向思考：我们如何能够鼓励学生停止在校园霸凌，同时激励他们学习，引导他们走上正确的道路？",
        phase_2:
          "在思考的过程中我受到游戏开发的启发，那就是游戏的底层逻辑，难度由低到高，不同的游戏等级可以获得不同的奖励。在这个过程中，游戏玩家通过由易到难层层通关，最后获得自己想要的奖励，勋章。那我们是不是也可以使用类似的机制，奖励学生摒弃校园霸凌，专注于提升学习成绩，塑造更好的自我呢？于是一个灵感直击我的脑门，我决定联合全世界的商家，创建一个全世界最大的奖励商城，为学生们提供他们感兴趣的奖励。比如，当他们获得15分奖励积分的时候，可以以优惠的价格，帮助家人购买生活必需品；当他们获得20分积分的时候，可以与他们喜欢的偶像见面等等！这些积分将来自于他们平时的积极表现。比如，学习成绩有进步（+1分）；当被别人霸凌的时候，勇敢地向学校举报（+10分）；帮助爸爸妈妈做家务（+10分）等等；相反，对同学进行言语霸凌，身体霸凌，传播同学谣言等（-10分）；",
        phase_3:
          "不仅学生可以申请奖励，而且他们的家人也可以参与并在奖励商城上兑换奖品。允许学生们用自己的力量帮助家里人减轻负担，增进亲子间的关系。我们的奖励市场也将对教师及其家人开放。教师或他们的家人可以在奖励市场上申请奖励，并且不需要积分。我们的最终目标是减少校园霸凌，营造一个更健康、更快乐的学习环境。我们渴望得到社会各界的支持，共同捍卫孩子们的未来。",
        phase_4:
          "我们大多数人，在25岁之前，大部分时间都是在校园中度过，学校也因此被大多数学生视为第二个家。如此一来，也意味着学校将拥有更多的时间和机会帮助学生做出正确选择，为他们实现人生理想保驾护航。",

        phase_4_1:
          "我们期待你的加入，一起为学生提供一个更加安全，健康和谐的校园环境，让校园成为学生孕育梦想的殿堂！",
        story_behind_btn: "背后的故事",
        //注册须知
        notice_title: "注册须知",
        notice_1:
          "1. 注册者必须是一个教育机构，包括幼儿园、小学、中学、技术学院、大学或者其他教育机构。",
        notice_2:
          "2. 加入校园荣耀后，必须坚定地致力于为学生创造健康、快乐和安全的学习环境，反对任何形式的校园暴力，并接受公众监督。",
        notice_3:
          "3. 校园荣耀的排名是根据获得的支持点赞数和加入平台的顺序确定的。收到的点赞数越多，加入时间越早的学校排名越靠前，其目的在于鼓励更多的公众参与，实现公众对学校共同的成功认可。",
        notice_4: `4. 校园荣耀平台采取收取一次性注册费 ${
          import.meta.env.VITE_REGISTER_PRICE
        }欧元（接受全球客户使用 Visa、Mastercard、American Express、Discover、大来国际卡和部分带有中国银联标志的银行卡的支付，支付宝）。所收取的费用将筹集用于建立校园荣耀慈善基金会。`,
        notice_4_1: "• 对校园荣耀上的院校进行随机调查",
        notice_4_2: "• 对校园发生的骚扰，暴力等问题进行研究",
        notice_4_3: "• 与教育专家合作，找出并提供解决这些问题的方案",
        notice_4_4: "• 帮助更多学校逐步减少校园暴力等行为",
        notice_4_5:
          "我们坚信，通过共同努力，我们可以为更多的学生创造和谐安全的学习环境。",
        phase_5:
          "5. 注册过程需要人工审核，大约需要7到15个工作日。学校须提供以下信息：",
        notice_5_1: "• 学校的官方电子邮件地址。",
        notice_5_2: "• 学校的全名。",
        notice_5_3: "• 位置。",
        notice_5_4: "• 学校类型（例如：幼儿园，小学，中学等）。",
        notice_5_5: "• 学校校徽。（可选）",
        phase_6:
          "6. 付款必须使用学校的公户，该账户应与学校注册名称相一致。请注意，如果审核未通过，将不予退款。因此，请提供准确完整的信息以确保能够顺利注册。审核完成后，我们将通过邮件的方式将电子收据发送到您的官方Email邮箱。",
        phase_7: "学校在申请加入校园荣耀后，需要在3个月内创建校园学生联盟 。",
        agree_and_register_btn: "已完成并注册",
        //背后的故事
        story_behind_title: "我们的故事",
        story_behide_1: "我的名字叫钟东菁，是给予得",
        story_behind_1_1: "软件的创始人和校园荣耀平台的创造者。",
        story_behide_2:
          "每次听到学校里的孩子受到霸凌的新闻，我都心如刀绞，为被霸凌的学生感到愤怒和悲伤。",
        story_behide_3:
          "很长一段时间以来，我都希望可以做点什么来帮助这些被霸凌的学生，一直在思考如何从根本上解决校园暴力，校园霸凌等问题。",
        story_behide_4:
          "意识到仇恨只会滋生更多敌意，我决定改变思维方式，以积极的态度来应对这个问题。经过反复的权衡和修改，最终创造了校园荣耀的平台。鼓励那些致力于为学生创造和平、快乐和安全的校园环境的学校加入。",
        story_behide_5:
          "前提条件是学校需要承诺，一旦所在学校发生了校园暴力事件，须自行退出校园荣耀，直到问题解决并在三个月内没有任何暴力事件发生，才能重新加入。校园荣耀是一个荣誉殿堂，表彰那些始终将学生的安全和福祉放在首位的学校。",
        story_behide_6:
          "起初，我计划让学校免费注册。但后来考虑到需要建立一个验证系统来预防虚假账户。因此，平台实施了收取一次性注册费，收集到的费用将用于筹建校园荣耀慈善基金。校园荣耀慈善基金的目标是帮助减少校园霸凌暴力等行为，让更多学生可以在安全健康快乐的校园环境中获取知识，构筑梦想。",
        story_behide_7:
          "校园是孕育梦想的地方。安全，健康，和谐的校园环境是肥沃的土壤，让梦想的种子得以生根发芽并茁壮成长。加入校园荣耀，让校园成为筑梦的殿堂！",

        //注册

        register_as: "注册为",
        user: "学生",
        school: "学校",
        school_glory_user_register: "校园荣耀，用户注册",
        password: "密码",
        username: "用户名",
        role: "你的身份",
        upload: "上传",
        agree_and_continue: "同意并继续",
        school_glory_school_register: "学校注册",
        school_official_email: "学校官方电子邮箱",
        school_name: "学校全名：",
        school_type: "学校类型：",
        school_location: "学校位置：",
        school_address: "完整地址：",
        school_address_ex: "例如：纽约市曼哈顿区主街123号，邮编10001，美国",
        school_emble: "校徽",
        image_require: "请使用白色背景并且为JPG/PNG格式的图像。",
        process_payment: "下一步",
        verify_code_send: "验证码已发送到您的电子邮件",
        enter_verify_code: "请输入验证码...",
        notice: "注意：",
        register_notice:
          "付款必须通过学校的公户进行，该账户应与学校注册名称一致。请注意，如果注册未获批准，将不予退款。因此，请提供准确完整的信息以确保成功注册。",
        payment_btn: `支付 ${import.meta.env.VITE_REGISTER_PRICE} 欧元`,
        processing: "稍等一下，处理中...",
        generate_login_id:
          "登录ID非常重要，平台只生成一次，请妥善保管，因为您需要用它登录、更改密码和更新个人信息。请确保在安全的环境中获取。",
        get_login_id_btn: "点击获取登录ID",
        navigate_to_my_profile: "我的个人资料",
        //学校资料
        verifying: "验证中...",
        error: "错误",
        success: "成功",
        verifying_notice:
          "注册过程需要人工审核，大约需要7到15个工作日。感谢您的耐心等待。审核完成后，我们将通过邮件方式将电子收据发送到您提供的官方电子邮件地址。",
        verify_success: "您已成功加入校园荣耀",
        verify_error: "验证失败。我们将通过电子邮件向您解释原因。",
        withdrawed: "您已暂时退出校园荣耀。",
        withdraw_popup_1:
          "您是否要暂时退出校园荣耀？如果是的话，我们希望您能成功解决当前面临的问题，并期待在三个月后您解决问题后再次回归。",
        withdraw_popup_2:
          "恭喜您解决了问题，并在过去的三个月内没有发生类似事件！欢迎回归校园荣耀！",
        // },
        ranking_rule:
          "每个学校的排名基于加入平台的顺序,加入时间越早，排名越靠前。",
        received_likes: "获得的赞数：",
        ranking: "排名：",
        pl_enter_login_id: "请输入您的登录ID：",
        back_to_home: "返回首页",
        logout: "退出登录",
        edit_profile: "编辑资料",
        // location: "位置",
        // type: "类型",
        loginId: "登录ID",
        submit: "提交",
        confirm_remove: "确认",
        cancel_withdraw: "取消退出",
        //学校邮件反馈
        notice_top_1: "请使用您学校的官方电子邮件地址：",
        notice_top_2: "将以下信息复制并发送到电子邮件",
        notice_top_3: "以供下一步验证过程使用：",
        email_title_tip: "邮件主题：",
        email_content_tip: "邮件内容：",
        email_content_for: "主题：",
        school_name_email: "学校名称：",
        school_type_email: "学校类型：",
        school_location_email: "学校位置：",
        school_full_address_email: "学校地址：",
        stripe_payment_id: "验证代码：",
        important: "重要！",
        are_you_sure_1: "请确保您已成功将信息发送至电子邮件地址",
        are_you_sure_2: "请注意，没有此过程，您将无法通过验证。",
        yes_sent: "是的，已发送电子邮件！",
        not_sent: "尚未发送",

        //用户资料
        user_role: "你的身份",
        edit_user_profile: "编辑用户资料",
        user_name: "用户名",
        you_are_a: "您是",
        parent: "家长",
        other_role: "其他",
        you_didnt_liked: "您还没有给任何学校点赞",
        schools_you_liked: "您赞过的学校",
        give_a_like_btn: "给一个赞",
        about: "关于",
        register: "注册",
        login: "登录",
        view_likers_btn: "谁给您点了赞？",
        //忘记密码
        forgot_password: "忘记密码？",
        please_enter_your_email_to_get_your_verify_code:
          "请输入您的电子邮件以获取验证码。",
        if_teaching_staff_needed_rewards_points_title:
          "教职人员与其家人是否需要奖励分数来兑换奖励？",
        if_teaching_staff_needed_rewards_points_content:
          "教职人员与其家人无需奖励分数来兑换奖励。在奖励商家无特别著名只适用于学生兑换奖励的情况下，教职人员都可以兑换奖励。",
        if_student_teaching_staff_allowed_to_re_redeem_rewards:
          "学生用户、教职人员兑换过奖励后是否还能再兑换奖励？",
        if_student_teaching_staff_allowed_to_re_redeem_rewards_content:
          "是的，在奖励商家的奖励期限到期前，都可以再次兑换奖励。",

        //===============================
        recommend_to_add_rule: "* 重要：建议学校用户添加以下奖励和扣分行为。",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_title:
          "当学生已不在本校就读，如毕业，转校等，应该如何处理这些学生的账号？",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_1:
          "可以有以下3种处理方式：",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_2:
          "1.在添加新生的时候，已经设置好了账号的使用期限，每个账号的使用期限是选择的年份+3个月，到期的时候，学生的账号会自动变为'已过期'状态，学校管理人员无需做任何操作。",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3:
          "2.如果想要保留该学生的信息，但是需要停用其在学校的账号，就需要把学生的有效账号改为0年，步骤如下：",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_1:
          "①进入学校的个人界面;",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_2:
          "②找到如下：",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_3:
          "③找到要修改的学生后，点击修改资料，并将学生的账号有效期设置为0",
        how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_4:
          "3.直接删除该学生的账号，注意，账户删除后将无法恢复学生资料，请谨慎操作。",

        what_is_student_initial_reward_point_title:
          "学生的初始化奖励分数是多少？",
        what_is_student_initial_reward_point_content:
          "每位学生的初始奖励分数是5分。",

        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_title:
          "当教师已不在本校教书，应该如何处理其账号？",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_1:
          "可以有以下3种处理方式：",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_2:
          "1.在添加教师的时候，已经设置好了账号的使用期限，每个账号的使用期限是选择的年份+3个月，到期的时候，教师的账号会自动变为'已过期'状态，学校管理人员无需做任何操作。",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3:
          "2.如果想要保留该教师的信息，但是需要停用其在学校的账号，就需要把教职人员的有效账号改为0年，步骤如下：",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_1:
          "①进入学校的个人界面;",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_2:
          "②找到如下：",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_3:
          "③找到要修改的教职人员后，点击修改资料，并将教职人员的账号有效期设置为0",
        how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_4:
          "3.直接删除该教职人员的账号，注意，账户删除后将无法恢复该教职人员的资料，请谨慎操作。",
        school_login_title: "以学校管理者身份登录",
        add_reward_rule_btn: "添加可获得奖励的行为",
        add_negative_rule_btn: "添加会被扣分的行为",
        rule_reached_limitation: "您已到达添加规则条数的最大限制，无法再添加",
        lang: "zh",
        view_btn: "进入查看",
        suitable_age_range: "适用年龄区间",
        min_age: "最小年龄(周岁)",
        max_age: "最大年龄（周岁）",
        invalid_url: "网址与所选平台不符！",

        //end zh===
      },
    },
  },
});

export default i18n;
