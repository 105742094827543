interface Props {
  type: string; // punishment or reward
  number?: string;
}
import { FaSadCry } from "react-icons/fa";
import { PiMedalFill } from "react-icons/pi";

const PUNISHMENT = "punishment";

const RewardPunishBtn = ({ type, number }: Props) => {
  return (
    <div
      className="rewardPunishIconContainer"
      style={{
        fontSize: "1.2rem",
        color: type === PUNISHMENT ? "red" : "green",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/**icon */}
      {type === PUNISHMENT ? (
        <FaSadCry
          size={"1.6rem"}
          color={type === PUNISHMENT ? "red" : "green"}
        />
      ) : (
        <PiMedalFill
          size={"1.6rem"}
          color={type === PUNISHMENT ? "red" : "green"}
        />
      )}
      {/**number */}

      {number}
    </div>
  );
};

export default RewardPunishBtn;
