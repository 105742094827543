import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoMdCloseCircleOutline } from "react-icons/io";

interface Props {
  onPressCloseModal: () => void;
}

const LoginToContinue = ({ onPressCloseModal }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="loginToContinueContainer">
      {/**title */}
      <div className="titleContainer">
        <h1
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            marginBottom: "1.5rem",
          }}
        >
          {/* Log in to continue */}
          {t("login_to_continue_title")}
        </h1>
        {/**关闭按钮 */}

        <IoMdCloseCircleOutline
          className="closeIcon"
          size={"2.5rem"}
          color={"#666"}
          style={{
            position: "absolute",
            right: "15%",
            top: "25%",
            color: "#666",
          }}
          onClick={onPressCloseModal}
        />
      </div>

      {/**icon */}
      <img
        src="/images/school_glory_icon.png"
        style={{ width: "5rem", height: "5rem", borderRadius: "5rem" }}
        alt="school glory logo"
      />
      {/**一段解说 */}
      <p style={{ textAlign: "center", margin: 30 }}>
        {/* 让我们一起支持与鼓励愿意为学生筑梦的学校！ */}
        {t("login_to_continue_content")}
      </p>
      {/**登录 */}
      <Link className="noticeBtn" to={"/login-email-user"}>
        {/* Log in */}
        {t("login_btn")}
      </Link>
      {/**创建 */}
      <Link
        className="registerTxt"
        style={{ marginTop: 30, color: "#666" }}
        to={"/register/register-user/email"}
      >
        {/* 点击创建一个账号 */}
        {t("click_create_account")}
      </Link>
    </div>
  );
};

export default LoginToContinue;
