import { useState } from "react";
import { useTranslation } from "react-i18next";
import InputNormal from "../components/InputNormal";
import StrengthMeter from "../utils/StrengthMeter";
import { FiEye, FiEyeOff } from "react-icons/fi";
import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/service";
import SentOtpComponent from "../components/SentOtpComponent";
import { useEffect } from "react";

const RegisterEmailUser = () => {
  const { t } = useTranslation();
  const [error, setError] = useState("");

  const [isStrong, initRobustPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpPop, setOtpPop] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSchoolIdChange = (e: any) => {
    setSchoolId(e.target.value);
  };

  const handlePassword = (e: any) => {
    // let password = e.target.value;
    setPassword(e.target.value);
    setError("");
    // let caps, small, num, specialSymbol;
    // if (password.length < 4) {
    //   // setError(t("password_rule"));
    //   return;
    // } else {
    //   caps = (password.match(/[A-Z]/g) || []).length;
    //   small = (password.match(/[a-z]/g) || []).length;
    //   num = (password.match(/[0-9]/g) || []).length;
    //   specialSymbol = (password.match(/\W/g) || []).length;
    //   // if (caps < 1) {
    //   //   setError(t("Must_add_one_UPPERCASE_letter"));
    //   //   return;
    //   // } else if (small < 1) {
    //   //   setError(t("Must_add_one_lowercase_letter"));
    //   //   return;
    //   // } else if (num < 1) {
    //   //   setError(t("Must_add_one_number"));
    //   //   return;
    //   // } else if (specialSymbol < 1) {
    //   //   setError(t("Must_add_one_special_symbol"));
    //   //   return;
    //   // }
    // }
  };
  const initPwdInput = async (childData: any) => {
    initRobustPassword(childData);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onPressHandleRegister = () => {
    setLoading(true);
    ///register/register-form/register-form-school-info
    // navigate("/register/register-form/register-form-school-info");

    AuthService.userRegister(email, password, schoolId)
      .then(({ data }) => {
        //后面上传之后，记得把这个tempToken删除
        localStorage.setItem("userSelf", data.token);
        setOtpPop(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  const handleEmailVerify = (e: any) => {
    setOtp(e.target.value);
  };

  const onPressCheckIfCodeValid = () => {
    setLoading(true);
    AuthService.verifyUserEmail(otp)
      .then(() => {
        setLoading(false);
        navigate("/userUploadProfile");
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };
  return (
    <CommonPageWithHeader error={error} loading={loading}>
      <div className="registerFormContainer">
        {/**Error */}

        <h1 className="registerTitleH1">
          {t("school_glory_user_register")}(1/2)
        </h1>

        {/**all inputs */}
        <div className="allInputsContainer">
          {/**Email */}
          <InputNormal
            placeholder="Email"
            name={"email"}
            className="normalInputContainer"
            handleChange={handleEmailChange}
            onFocus={() => setError("")}
          />
          {/**Password */}
          <div className="form-group" style={{ width: "100%" }}>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                name="password"
                value={password}
                onChange={handlePassword}
                placeholder={t("password")}
                style={{ padding: "1.2rem", color: "#666", fontSize: "1.2rem" }}
              />
              <span
                className="password-toggle-icon"
                onClick={toggleShowPassword}
              >
                {showPassword ? <FiEye /> : <FiEyeOff />}
              </span>
            </div>
            <StrengthMeter password={password} actions={initPwdInput} />
          </div>

          {/**School Id*/}
          <InputNormal
            placeholder="School ID"
            name={"schoolId"}
            className="normalInputContainer"
            handleChange={handleSchoolIdChange}
            onFocus={() => setError("")}
          />

          {/**Submit button */}

          <button
            disabled={isStrong ? false : false}
            type="button"
            className="btn btn-warning noticeBtn"
            onClick={onPressHandleRegister}
          >
            {/* 下一步 */}
            {t("process_payment")}
          </button>
        </div>
        {/**注意 */}
        <div className="registerNotice">
          <p>
            <span style={{ fontWeight: "bold" }}>{t("very_important")}：</span>
            {t("register_notice_tip_user")}
          </p>
        </div>
      </div>
      {otpPop && (
        <SentOtpComponent
          email={email}
          handleEmailVerify={handleEmailVerify}
          onPressCheckIfCodeValid={onPressCheckIfCodeValid}
        />
      )}
    </CommonPageWithHeader>
  );
};

export default RegisterEmailUser;
