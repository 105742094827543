interface Props {
  title: string;
  show: boolean;
  onClick: () => void;
}
import { FcCollapse } from "react-icons/fc";
import { IoIosArrowDown } from "react-icons/io";
const TitleComponent = ({ title, show, onClick }: Props) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
      }}
      onClick={onClick}
      className="iconHover"
    >
      {" "}
      <p className="faq_title_1" style={{ fontWeight: "bolder" }}>
        {title}
      </p>
      {show && <FcCollapse size={"1.5rem"} />}
      {!show && <IoIosArrowDown size={"1.5rem"} />}
    </div>
  );
};

export default TitleComponent;
