import { LANGUAGES } from "../constants";
import { useTranslation } from "react-i18next";
import getUserLocale from "get-user-locale";
import { Link } from "react-router-dom";
import { GrUnorderedList } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import retrunImgUrl from "../utils/retrunImgUrl";
import { useAtom } from "jotai";
import {
  schoolAtom,
  storeAtom,
  studentHeroAtom,
  teacherAtom,
} from "../datas/atoms";
interface Props {
  isHome: boolean;
  hide: boolean;
}
const Header = ({ isHome, hide }: Props) => {
  const { i18n, t } = useTranslation();
  const [navBarActive, setNavbarActive] = useState("navBar");
  const [school, setSchool] = useAtom(schoolAtom);
  const [hero, setHero] = useAtom(studentHeroAtom);
  const [store, setStore] = useAtom(storeAtom);
  const [teacher, setTeacher] = useAtom(teacherAtom);
  const navBarHome = isHome ? "header" : "headerHome";
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    getUserLocale().split("-")[0]
  );

  const navigate = useNavigate();
  const onPressToggleMenu = () => {
    navBarActive === "navBar"
      ? setNavbarActive(isHome ? "toggle-activeHome" : "toggle-active")
      : setNavbarActive(isHome ? "navBar" : "navBar");
  };

  const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = e.target.value;
    setSelectedLanguage(lang_code);
    i18n.changeLanguage(lang_code);
    localStorage.setItem("lang", lang_code);
  };

  const onPressLogout = () => {
    if (school.name !== "") {
      localStorage.removeItem("newUser");
      const emptySchool = {
        _id: "",
        name: "",
        joinDate: "",
        icon: "",
        email: "",
        region: "",
        type: "",
        publicId: "",
        ranking: 0,
        votes: 0,
        votedBy: [],
        address: "",
        statement: "",
        active: false,
        heros: [],
        studentsVerify: [],
        receivedReports: [],
        events: [],
      };

      setSchool(emptySchool);

      window.location.href = "/";
    }

    if (hero.heroName !== "") {
      setHero((users) => ({
        ...users,
        _id: "",
        image: "",
        heroName: "",
        schoolId: "",
        studentEmail: "",
        time: "",
        validDate: "",
        validYear: 0,
        info: "",
        events: [],
        reports: [],
      }));
      localStorage.removeItem("userSelf");

      window.location.href = "/";
    }

    if (store.name !== "") {
      setStore((users) => ({
        ...users,

        name: "",
        icon: "",
      }));
      localStorage.removeItem("storeUser");

      window.location.href = "/";
    }

    if (teacher.image !== "") {
      localStorage.removeItem("teacherUser");
      setTeacher({
        name: "",
        image: "",
        userType: "",
      });

      window.location.href = "/";
    }
  };

  // useEffect(() => {
  //   console.log("现在的store===:", store);
  //   console.log("现在的userSelf===:", userSelfType);
  // }, []);

  return (
    <div
      className={navBarHome}
      style={{
        backgroundColor: isHome ? "transparent" : "white",
        boxShadow: isHome ? "none" : "0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1)",
        padding: isHome ? "0rem 1rem" : "1.2rem 1rem",
      }}
    >
      {/**icon */}
      <div className="headerIconContainer" onClick={() => navigate("/")}>
        <h1 className="logo" style={{ color: isHome ? "white" : "black" }}>
          {t("logo")}
        </h1>
        <img src={"/images/dot.png"} alt="dot image" width={25} height={25} />
      </div>
      {/**select language */}
      <select
        value={selectedLanguage}
        // defaultValue={getUserLocale().split("-")[0]}
        onChange={onChangeLang}
        className="selectorHome"
        style={{
          color: isHome ? "white" : "black",
          backgroundColor: "transparent",
          fontWeight: "bold",
          fontSize: "1.2rem",
          marginTop: isHome ? "0rem" : 0,
        }}
      >
        {LANGUAGES.map(({ code, label }) => (
          <option key={code} value={code}>
            {label}
          </option>
        ))}
      </select>

      {/**list */}
      <nav className={navBarActive} style={{ marginTop: isHome ? "0rem" : 0 }}>
        <Link
          to="/about"
          className="headerLink"
          style={{ color: isHome ? "white" : "black" }}
        >
          {t("about_nav")}
        </Link>

        <Link
          to="/contactInitial"
          className="headerLink"
          style={{ color: isHome ? "white" : "black" }}
        >
          {t("contact")}
        </Link>

        <Link
          to="/reward-stores"
          className="headerLink"
          style={{ color: isHome ? "white" : "black" }}
        >
          {/* 奖励商店榜 */}
          {t("stores")}
        </Link>

        <Link
          to="/pricing"
          className="headerLink"
          style={{ color: isHome ? "white" : "black" }}
        >
          {/* 价格 */}
          {t("pricing")}
        </Link>

        <Link
          to="/faqs"
          className="headerLink"
          style={{ color: isHome ? "white" : "black" }}
        >
          {/* 常见问题 */}
          {t("faq")}
        </Link>

        {school.name === "" &&
          hero.heroName === "" &&
          store.name === "" &&
          teacher.image === "" && (
            <>
              {" "}
              <Link
                to="/login"
                className="headerLink"
                style={{ color: isHome ? "white" : "black" }}
              >
                {t("login_nav")}
              </Link>
              <Link
                to="/register"
                className="headerLink"
                style={{ color: isHome ? "white" : "black" }}
              >
                {t("register_nav")}
              </Link>
            </>
          )}

        {/**store login state */}

        {store.name !== "" && (
          <Link to="/store-owner-profile" className="headerLink">
            <img
              src={retrunImgUrl(store.icon)}
              className="userImg"
              alt="user profile image"
            />
          </Link>
        )}
        {store.name !== "" && (
          <button
            className="headerLink"
            style={{
              color: "white",
              backgroundColor: isHome ? "#d0cccc" : "#9c9898",
              borderRadius: 5,
              padding: "0.8rem 1.2rem",
              margin: "0.1rem 1rem",
            }}
            onClick={onPressLogout}
          >
            {t("logout")}
          </button>
        )}

        {/**teacher login state */}

        {teacher.name !== "" && (
          <Link to="/school-profile" className="headerLink">
            <img
              src={retrunImgUrl(teacher.image)}
              className="userImg"
              alt="user profile image"
            />
          </Link>
        )}
        {teacher.image !== "" && (
          <button
            className="headerLink"
            style={{
              color: "white",
              backgroundColor: isHome ? "#d0cccc" : "#9c9898",
              borderRadius: 5,
              padding: "0.8rem 1.2rem",
              margin: "0.1rem 1rem",
            }}
            onClick={onPressLogout}
          >
            {t("logout")}
          </button>
        )}

        {/**school login state */}

        {school.name !== "" && (
          <Link to="/school-profile" className="headerLink">
            <img
              src={retrunImgUrl(school.icon)}
              className="userImg"
              alt="user profile image"
            />
          </Link>
        )}
        {school.name !== "" && (
          <button
            className="headerLink"
            style={{
              color: "white",
              backgroundColor: isHome ? "#d0cccc" : "#9c9898",
              borderRadius: 5,
              padding: "0.8rem 1.2rem",
              margin: "0.1rem 1rem",
            }}
            onClick={onPressLogout}
          >
            {t("logout")}
          </button>
        )}

        {hero.heroName !== "" && !hide && (
          <Link to="/userprofile" className="headerLink">
            <img
              src={retrunImgUrl(hero.image)}
              className="userImg"
              alt="user profile image"
            />
          </Link>
        )}
        {hero.heroName !== "" && (
          <button
            className="headerLink"
            style={{
              color: "white",
              backgroundColor: isHome ? "#d0cccc" : "#9c9898",
              borderRadius: 5,
              padding: "0.8rem 1.2rem",
              margin: "0.1rem 1rem",
            }}
            onClick={onPressLogout}
          >
            {t("logout")}
          </button>
        )}
      </nav>
      {navBarActive !== "toggle-active" &&
        navBarActive !== "toggle-activeHome" && (
          <GrUnorderedList
            color={isHome ? "white" : "#666"}
            size={25}
            className="collapseIcon"
            onClick={onPressToggleMenu}
            style={{ marginTop: isHome ? "0rem" : 0 }}
          />
        )}
      {(navBarActive === "toggle-active" ||
        navBarActive === "toggle-activeHome") && (
        <AiOutlineClose
          color={isHome ? "white" : "#666"}
          size={20}
          className="collapseIcon"
          onClick={onPressToggleMenu}
          style={{ marginTop: isHome ? "0rem" : 0 }}
        />
      )}
    </div>
  );
};

export default Header;
