import { RewardPunishmentRule } from "../../datas/atoms";
import AuthService from "../../services/service";
import Loading from "../Loading";
import RuleItem from "./RuleItem";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  type?: string;
  schoolId: string;
}

const SchoolRules = ({ type, schoolId }: Props) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    initData();
  }, []);
  const initData = () => {
    setLoading(true);
    AuthService.getSchoolRules(schoolId, type)
      .then(({ data }) => {
        setData(data.rules);
        if (type) {
          const d = data.rules.filter(
            (m: RewardPunishmentRule) => m.type === type
          );
          console.log("========d:", d);
          setFilteredDatas(d);
        }
        setLoading(false);
      })
      .catch(() => {});
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="ruleComponentContainer" style={{ overflow: "auto" }}>
          {type === "reward" && (
            <p style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
              {/* 可以获得奖励的行为： */}
              {t("able_to_reward_bahavier")}
            </p>
          )}
          {type === "punishment" && (
            <p style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
              {/* 扣分行为： */}
              {t("punish_behaviar")}
            </p>
          )}
          <ol className="list-group">
            {!type &&
              data.map((item: any, index: number) => (
                <li className={"list-group-item-each"} key={index}>
                  <RuleItem
                    item={item}
                    index={index}
                    content={item.content}
                    point={item.point}
                    type={item.type}
                    self_added={false}
                  />
                </li>
              ))}
            {type &&
              filteredDatas.map((item: any, index: number) => (
                <li className={"list-group-item-each"} key={index}>
                  <RuleItem
                    item={item}
                    index={index}
                    content={item.content}
                    point={item.point}
                    type={item.type}
                    self_added={false}
                  />
                </li>
              ))}
          </ol>
        </div>
      )}
    </>
  );
};

export default SchoolRules;
