import { IoStorefrontOutline } from "react-icons/io5";
import { IoStorefrontSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
interface Props {
  type: string;
  handleClickButton: () => void;
}

const StoreTypeWithIcon = ({ type, handleClickButton }: Props) => {
  const { t } = useTranslation();
  return (
    <button
      className="btn btn-outline-primary iconBtnContainer"
      onClick={handleClickButton}
    >
      {type === "online" && (
        <IoStorefrontOutline size={"2rem"} style={{ marginRight: "1rem" }} />
      )}
      {type === "physical" && (
        <IoStorefrontSharp size={"2rem"} style={{ marginRight: "1rem" }} />
      )}
      {type === "online" && t("online_store")}
      {type === "physical" && t("physical_store")}
    </button>
  );
};

export default StoreTypeWithIcon;
