import { Navigate } from "react-router-dom";
const ProtectSchool = ({ children }: any) => {
  let user;
  if (localStorage.getItem("newUser")) {
    user = localStorage.getItem("newUser");
  } else if (localStorage.getItem("teacherUser")) {
    user = localStorage.getItem("teacherUser");
  }

  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
};
export default ProtectSchool;
