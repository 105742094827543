import { PiMedalFill } from "react-icons/pi";
import formatJoinDate from "../../utils/formatJoinDate";
import { Link } from "react-router-dom";
import { Comment } from "../../datas/atoms";
import { useState } from "react";
import AuthService from "../../services/service";
import { useTranslation } from "react-i18next";
interface Props {
  rewardId: string;
  _id: string;
  icon: string;
  name: string;
  image: string;
  requiredApplyPoints: string;
  rewardContent: string;
  rewardValidTill: string;
  appliedTime: string;
  getMode: string;
  comment?: Comment;
  activeData: () => void;
}
const SuccessfulRewardedItem = ({
  rewardId,
  _id,
  icon,
  name,
  image,
  requiredApplyPoints,
  rewardContent,
  rewardValidTill,
  appliedTime,
  getMode,
  comment,
  activeData,
}: Props) => {
  const { t } = useTranslation();
  const img_url = import.meta.env.VITE_IMG_URL;
  const [showComment, setShowComment] = useState(false); //control submit button
  const [commented, setCommented] = useState(false); //show comment input
  const [rateInfo, setRateInfo] = useState(comment?.rate || "good");

  //upload rate
  const uploadComment = () => {
    AuthService.leaveAComment(rewardId, rateInfo)
      .then(({ data }) => {
        console.log("-----收到data:", data);
        activeData();
      })
      .catch();
  };

  return (
    <div
      className="successRewardItemContainer"
      style={{ textDecoration: "none", color: "black" }}
    >
      {/**顶部：logo+公司名 ； 通过日期 */}
      <div className="smallContainer">
        <div>
          <img
            src={`${img_url}${icon}`}
            className="smallIcon"
            style={{ borderRadius: "1rem", marginRight: "1rem" }}
          />
          {name}
        </div>
      </div>
      {/**下部：coverImage ；申请条件+奖励内容+有效期至 ； 如果有效期大于等于今天：再次申请按钮 */}
      <div className="smallContainer">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={`${img_url}${image}`} className="coverImg" />
          </div>

          <div className="conditionContainer">
            <div>
              {" "}
              {/* 申请条件 */}
              {t("apply_condition_score")}
              ： <PiMedalFill color={"green"} size={"1.5rem"} />
              {requiredApplyPoints}
            </div>
            <div>
              {/* 奖励内容 */}
              {t("reward_content_short")}：{rewardContent}
            </div>
            <div>
              {/* 有效期至 */}
              {t("valid_date")}：{formatJoinDate(rewardValidTill)}
            </div>
            <div>
              {/* 使用日期 */}
              {t("date_of_use")}：{formatJoinDate(appliedTime)}
            </div>
            {getMode === "all-good" && (
              <Link
                to={"/store-details"}
                state={{ _id: _id }}
                style={{ marginTop: "1rem" }}
              >
                {/* 再次申请 */}
                {t("reapply_tip")}→
              </Link>
            )}
            {getMode !== "all-good" && (
              <p style={{ paddingTop: "2rem", color: "red" }}>
                {/* 该奖励已下架 */}
                {t("reward_invalid_now")}
              </p>
            )}
            {(comment || commented) && (
              <div
                style={{
                  width: "100%",
                  padding: "1rem",
                  borderRadius: "1rem",
                  backgroundColor: "#E6E6FA",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {/**rate  */}

                  <p>
                    {/* 您的体验 */}
                    {t("your_experience")}：
                  </p>
                  <img
                    src={`/images/${rateInfo}.png`}
                    style={{ width: "2rem", height: "2rem" }}
                  />
                </div>
              </div>
            )}

            {!comment && !showComment && !commented && (
              <button
                type="button"
                className="btn btn-primary"
                style={{ width: "100%", marginTop: "1.5rem", padding: "1rem" }}
                onClick={() => {
                  setShowComment(true);
                }}
              >
                {/* 去评价 */}
                {t("go_comment_btn")}
              </button>
            )}

            {/**Comment component */}
            {!comment && showComment && (
              <div
                style={{
                  width: "100%",
                  padding: "1rem",
                  backgroundColor: "#E6E6FA",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                {/**give a rate */}
                <p>
                  {/* 您对本次服务/购物的满意程度 */}
                  {t("leave_your_rate")}：
                </p>
                {/**radios:满意 ； 一般 ；差 */}
                <div style={{ display: "flex" }}>
                  <label htmlFor="satisfied">
                    <input
                      type="radio"
                      id="satisfied"
                      name="satisfaction"
                      style={{ display: "none" }}
                      onClick={() => {
                        // 处理满意选项的点击事件

                        setRateInfo("good");
                        setCommented(true);
                        setShowComment(false);
                        uploadComment();
                      }}
                    />
                    <img
                      src={`/images/good.png`}
                      style={{
                        width: "2rem",
                        height: "2rem",
                        marginRight: "1rem",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                  <label htmlFor="neutral">
                    <input
                      type="radio"
                      id="neutral"
                      name="satisfaction"
                      style={{ display: "none" }}
                      onClick={() => {
                        // 处理一般选项的点击事件

                        setRateInfo("neutral");
                        setCommented(true);
                        setShowComment(false);
                      }}
                    />
                    <img
                      src={`/images/neutral.png`}
                      style={{
                        width: "2rem",
                        height: "2rem",
                        marginRight: "1rem",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                  <label htmlFor="negative">
                    <input
                      type="radio"
                      id="negative"
                      name="satisfaction"
                      style={{ display: "none" }}
                      onClick={() => {
                        // 处理差选项的点击事件

                        setRateInfo("negative");
                        setCommented(true);
                        setShowComment(false);
                      }}
                    />
                    <img
                      src={`/images/negative.png`}
                      style={{
                        width: "2rem",
                        height: "2rem",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                </div>
                {/**点击上交评论的按钮*/}
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  style={{ padding: "0.5rem 2rem", marginTop: "1rem" }}
                  onClick={() => {
                    // 处理提交按钮点击事件
                    setCommented(true);
                    setShowComment(false);
                  }}
                >
                  提交
                </button> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessfulRewardedItem;
