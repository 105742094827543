import AuthService from "../services/service";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { FiEye, FiEyeOff } from "react-icons/fi";
import CommonPageWithHeader from "../components/CommonPageWithHeader";
import InputNormal from "../components/InputNormal";
import { commonUserTypeAtom } from "../datas/atoms";

const LoginEmailSchool = () => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loginId, setLoginId] = useState("");
  const [userType, setUserType] = useState(commonUserTypeAtom);

  useEffect(() => {
    console.log("现在的userType:", userType);
  }, []);

  //在用户上传资料的时候，先查看用户是否localestorage有记录

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleLoginIDChange = (e: any) => {
    setLoginId(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onPressHandleLoginSchool = () => {
    if (
      email.trim() === "" ||
      loginId.trim() === "" ||
      password.trim() === ""
    ) {
      setError(t("inComplete"));

      return;
    }
    setLoading(true);
    //在用户上传资料的时候，先查看用户是否localestorage有记录
    const token = localStorage.getItem("newUser");
    if (token) {
      localStorage.removeItem("newUser");
    }
    AuthService.login(email, loginId, password)
      .then(({ data }) => {
        localStorage.setItem("newUser", data.token);
        // setTokenNow(localStorage.setItem("newUser", data.token));
        // 进行相关的操作，如页面跳转等
        setUserType(data.userType);
        setLoading(false);
        window.location.href = "/";
      })
      .catch((error) => {
        setLoading(false);
        return setError(error.response.data.message);
      });
  };

  return (
    <CommonPageWithHeader error={error} loading={loading}>
      <div className="registerFormContainer">
        {/**Error */}

        <h1 className="registerTitleH1">{t("school_login_title")}</h1>

        {/**all inputs */}
        <div className="allInputsContainer">
          {/**Email */}

          <InputNormal
            placeholder="school_official_email"
            name={"email"}
            className="normalInputContainer"
            handleChange={handleEmailChange}
          />
          {/**Password */}
          <div
            className="form-group"
            style={{ width: "100%", marginBottom: "1.5rem" }}
          >
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                name="password"
                value={password}
                onChange={handlePassword}
                placeholder={t("password")}
                style={{ padding: "1.2rem", color: "#666", fontSize: "1.2rem" }}
              />
              <span
                className="password-toggle-icon"
                onClick={toggleShowPassword}
              >
                {showPassword ? <FiEye /> : <FiEyeOff />}
              </span>
            </div>
          </div>

          {/**login Id */}

          <InputNormal
            placeholder="loginId"
            name={"loginId"}
            className="normalInputContainer"
            handleChange={handleLoginIDChange}
          />
          <Link
            to={"/login/password-forgotten"}
            style={{ fontSize: 16, marginTop: 16, textDecoration: "none" }}
          >
            {t("password_forgotten")}
          </Link>
          {/**Submit button */}

          <button
            type="button"
            className="btn btn-warning noticeBtn"
            onClick={onPressHandleLoginSchool}
          >
            {/* 下一步 */}
            {t("process_payment")}
          </button>
        </div>
      </div>
    </CommonPageWithHeader>
  );
};

export default LoginEmailSchool;
