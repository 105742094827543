import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

interface Props {
  onPressTurnOffPopupRanking: () => void;
  rankingNow?: number;
}

const RankingPopup = ({ onPressTurnOffPopupRanking, rankingNow }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="alertContainer" style={{ flexDirection: "column" }}>
      <div
        className="alertPop"
        style={{ flexDirection: "column", padding: "2rem" }}
      >
        {/**title */}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            padding: "1rem",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              color: "#666",
              marginTop: 10,
              textAlign: "center",
              lineHeight: 2,
              fontSize: 15,
            }}
          >
            {/* ( The rankings of each school are based on the number of likes
              they receive and the order in which they join.) */}
            ({t("ranking_rule")})
          </p>

          {rankingNow === 0 && (
            <p style={{ color: "#5f7fff" }}>{t("ranking_validating")}</p>
          )}
          {rankingNow! > 0 && (
            <p style={{ color: "#5f7fff" }}>
              {/* 当前世界排名 */}
              {t("ranking_now")}：{rankingNow}
            </p>
          )}
        </div>
      </div>
      <AiOutlineCloseCircle
        size={"2rem"}
        color={"white"}
        style={{ marginTop: "1rem" }}
        onClick={onPressTurnOffPopupRanking}
        className="iconHover"
      />
    </div>
  );
};

export default RankingPopup;
