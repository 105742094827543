import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import { AiOutlineScan } from "react-icons/ai";
import { useState, useRef } from "react";
import AuthService from "../../services/service";
import { ApplyRewardHistory } from "../../datas/atoms";
import ApplyHistory from "../../components/store/ApplyHistory";
import ImportantNotice from "../../components/store/ImportantNotice";
import { useTranslation } from "react-i18next";

const SeachReward = () => {
  const { t } = useTranslation();
  const inputRef = useRef("");
  const [inputRewardId, setInputRewardId] = useState("");
  const [loading, setLoading] = useState(false);
  const [rewardItem, setRewardItem] = useState<ApplyRewardHistory>();
  const [error, setError] = useState("");
  const [popupNotice, setPopNotice] = useState(false);
  const onPressUpload = (inputRewardId: string) => {
    setLoading(true);
    AuthService.searchIfRewardExists(inputRewardId)
      .then(({ data }) => {
        setRewardItem(data.reward);
        console.log("收到data:", data);
        setPopNotice(true);
        setLoading(false);
      })
      .catch((e) => {
        setError(e.response.data.message);
        setLoading(false);
      });
  };

  const onUpdate = (value: string) => {
    setLoading(true);
    setPopNotice(false);
    AuthService.searchIfRewardExists(value)
      .then(({ data }) => {
        setRewardItem(data.reward);
        console.log("======onUpdate收到value:", value);
        console.log("=======收到data:", data.reward);
        setLoading(false);
        window.location.href = "/store-owner-profile";
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  const onPressStoreReward = () => {
    AuthService.approveReward(inputRef.current)
      .then(() => {
        onUpdate(inputRef.current);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  return (
    <CommonPageWithHeader loading={loading}>
      {popupNotice && (
        <ImportantNotice onPressCloseNotice={() => setPopNotice(false)} />
      )}
      {/** sanner  + gear +userIcon*/}
      <div className="normalContainerColumn1">
        <div className="storeOwnerProfileHistoryTopBarContainer1">
          {/**sanner icon */}
          <AiOutlineScan
            size={"3rem"}
            className="iconHover storeOwenerProfileIcon"
          />
          {/** search bar */}
          <div
            className="innerStoreBardContainer iconHover"
            style={{ width: "100%" }}
          >
            {/**input */}
            <input
              placeholder={t("please_enter_id")}
              style={{
                fontSize: "1rem",
                backgroundColor: "transparent",
                width: "100%",
                padding: "1rem",
              }}
              className="iconHover"
              onChange={(text) => {
                setInputRewardId(text.target.value);
                inputRef.current = text.target.value;
              }}
            />
            {/**search button */}
            <button
              type="button"
              className="btn btn-warning storeSearchBtn"
              onClick={() => onPressUpload(inputRewardId)}
            >
              {/* 查询 */}
              {t("search")}
            </button>
          </div>
        </div>

        {rewardItem && (
          <ApplyHistory
            type={rewardItem.type}
            rewardId={rewardItem._id}
            userType={rewardItem.userType}
            time={rewardItem.time}
            approved={rewardItem.approved}
            owner={"store"}
            onPressStoreReward={onPressStoreReward}
          />
        )}

        {error && (
          <div>
            <p style={{ color: "red", fontSize: "1.2rem" }}>{error}</p>
          </div>
        )}
      </div>
    </CommonPageWithHeader>
  );
};

export default SeachReward;
