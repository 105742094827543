import { useTranslation } from "react-i18next";
import { useState } from "react";
import Select from "react-select";
import { BiSolidBook } from "react-icons/bi";

interface Type {
  type: string;
  value: string;
}
interface Props {
  onTypeSelected: (value: string) => void;
  typeSelectRef: any;
}

const TypeSelector = ({ onTypeSelected, typeSelectRef }: Props) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState<Type | null>(null);

  const schools = [
    { value: "Kindergarten", type: t("Kindergarten") },
    { value: "Primary", type: t("Primary") },
    { value: "Middle", type: t("Middle") },
    { value: "High", type: t("High") },
    // { value: "Tenical", type: t("Tenical") },
    // { value: "College", type: t("College") },
    { value: "University", type: t("University") },
    { value: "Other", type: t("Other") },
  ];
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 280, // 设置宽度为100%,
    }),
  };
  const getOptionLabelType = (option: Type) => {
    return option.type;
  };

  const handleTypeChange = (selectedOption: Type | null) => {
    const value = selectedOption?.value ?? "";

    setSelectedType(selectedOption);
    onTypeSelected(value); // 调用回调函数并传递选中的代码
  };
  return (
    <div className="selectInputContainer">
      <BiSolidBook color={"#666"} size={25} style={{ marginRight: 8 }} />
      <Select
        ref={typeSelectRef}
        placeholder={t("all")}
        onChange={handleTypeChange}
        value={selectedType}
        styles={customStyles} // 设置自定义样式
        options={schools}
        isSearchable={true}
        getOptionLabel={getOptionLabelType}
      />
    </div>
  );
};

export default TypeSelector;
