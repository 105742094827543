import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { FaSchool } from "react-icons/fa";

import { useEffect } from "react";
import { IoStorefrontSharp } from "react-icons/io5";

const Register = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CommonPageWithHeader>
      {/**title:register as  */}
      <h1 className="registerH1">{t("register_as")}</h1>
      {/**buttons */}

      <div className="registerLoginButtonsContainer">
        {/**User */}
        {/* <Link
          className="linkBoxContainer"
          style={{ backgroundColor: "#5f7fff" }}
          to={"/register/register-user/email"}
        >
          <FaUserAlt
            color={"white"}
            size={40}
            style={{
              color: "white",
              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
          />
          {t("user")}
        </Link> */}
        {/**School */}
        <Link
          className="linkBoxContainer"
          style={{ backgroundColor: "#fecf45" }}
          to={"/notice"}
        >
          <FaSchool
            color={"white"}
            size={45}
            style={{
              color: "white",
              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
          />
          {t("school")}
        </Link>

        {/**Teacher */}
        {/* <Link
          className="linkBoxContainer"
          style={{ backgroundColor: "#3cb371" }}
          to={"/notice"}
        >
          <FaChalkboardTeacher
            color={"white"}
            size={45}
            style={{
              color: "white",
              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
          />
          教师
        </Link> */}

        {/**Store*/}
        <Link
          className="linkBoxContainer"
          style={{ backgroundColor: "#DB7093" }}
          // to={"/store-register-email"}
          to={"/register/store"}
        >
          <IoStorefrontSharp
            color={"white"}
            size={45}
            style={{
              color: "white",
              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
          />
          {/* 奖励商店 */}
          {t("reward_store")}
        </Link>
      </div>
    </CommonPageWithHeader>
  );
};

export default Register;
