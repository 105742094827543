import { useState } from "react";
import Select from "react-select";
import data from "../datas/en.json";
import { IoLocationSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";

interface Location {
  name: string;
  code: string;
}
interface Props {
  onLocationSelected: (code: string) => void;
  locationSelectRef: any;
}

const LocationSelector = ({ onLocationSelected, locationSelectRef }: Props) => {
  const { t } = useTranslation();
  // const [selectedOption, setSelectedOption] = useState<Location | null>(null);
  const [selectedLocationOption, setSelectedLocationOption] =
    useState<Location | null>(null);
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 280, // 设置宽度为100%,
    }),
  };

  //translate the name
  const getOptionLabelLocation = (option: Location) => {
    return option.name;
  };

  const handleLocationChange = (selectedOption: Location | null) => {
    const code = selectedOption?.code ?? "";

    setSelectedLocationOption(selectedOption);
    onLocationSelected(code); // 调用回调函数并传递选中的代码
  };

  return (
    <div className="selectInputContainer">
      <IoLocationSharp color={"#666"} size={28} style={{ marginRight: 8 }} />
      <Select
        ref={locationSelectRef}
        placeholder={t("worldwide")}
        onChange={handleLocationChange}
        value={selectedLocationOption}
        styles={customStyles} // 设置自定义样式
        options={data}
        isSearchable={true}
        getOptionLabel={getOptionLabelLocation}
      />
    </div>
  );
};

export default LocationSelector;
