import TeacherFaqItem from "../../components/faq/TeacherFaqItem";
const TeacherFAQ = () => {
  return (
    <div>
      {/**1.教职人员可以做什么？*/}
      <TeacherFaqItem type={1} />
      {/**2.教职人员是否可以申请奖励？*/}
      <TeacherFaqItem type={2} />
      {/**3.教职人员如何更改密码？*/}
      <TeacherFaqItem type={3} />
      {/**4.如何申请奖励？*/}
      <TeacherFaqItem type={4} />

      {/**4.教职人员与其家人是否需要奖励分数来兑换奖励？*/}
      <TeacherFaqItem type={5} />

      {/**6.学生用户、教职人员兑换过奖励后是否还能再兑换奖励？*/}
      <TeacherFaqItem type={6} />
    </div>
  );
};

export default TeacherFAQ;
