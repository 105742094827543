interface ClickHandlerObj {
  (value: RewardPunishmentRule): void;
}
interface Props {
  item: RewardPunishmentRule;
  index: number;
  content: string;
  point: number;
  type: string;
  self_added: boolean;
  onPressEdit?: ClickHandlerObj;
  onPressDelete?: ClickHandlerObj;
}
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RewardPunishmentRule } from "../../datas/atoms";
const RuleItem = ({
  item,
  index,
  content,
  point,
  type,
  self_added,
  onPressEdit,
  onPressDelete,
}: Props) => {
  return (
    <div
      style={{
        display: "flex",
        fontSize: "1.2rem",
        lineHeight: "1.8",
        marginBottom: "1rem",
        marginTop: "1rem",
      }}
    >
      {/**index */}
      <p style={{ fontWeight: "bold", marginRight: "1rem" }}> {index + 1}.</p>
      {/**Content */} {content}
      {/**points */}
      <p
        style={{
          marginLeft: "1rem",
          fontWeight: "bold",
          color: "#5f7fff",
        }}
      >
        <span>{type === "reward" ? "+" : ""}</span> {point}
      </p>
      {/**self_added */}
      {self_added && (
        <div
          style={{
            display: "flex",
            marginLeft: "2rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BiEditAlt
            size={"2rem"}
            className="iconHover"
            onClick={() => onPressEdit && onPressEdit(item)}
          />
          <RiDeleteBin6Line
            size={"2rem"}
            style={{ marginLeft: "1rem" }}
            className="iconHover"
            onClick={() => onPressDelete && onPressDelete(item)}
          />
        </div>
      )}
    </div>
  );
};

export default RuleItem;
