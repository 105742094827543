const REACT_APP_GOOGLE_CLOUD_UPLOAD_BASE_URL = import.meta.env.VITE_IMG_URL;

const retrunImgUrl = (image: any) => {
  const _isURL = isURL(image);
  if (_isURL) {
    return image;
  } else {
    return `${REACT_APP_GOOGLE_CLOUD_UPLOAD_BASE_URL}${image}`;
  }
};

export default retrunImgUrl;

function isURL(str: string) {
  // 使用正则表达式进行URL验证
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(str);
}
