import StudentFaqItem from "../../components/faq/StudentFaqItem";

const StudentFAQ = () => {
  return (
    <div>
      {/**1.学生用户可以做什么？*/}
      <StudentFaqItem type={1} />
      {/**2.如何向学校举报？*/}
      <StudentFaqItem type={2} />
      {/**3.学生用户如何更换密码？*/}
      <StudentFaqItem type={3} />
      {/**4.如何申请奖励？*/}
      <StudentFaqItem type={4} />
      {/**5.学生用户、教职人员兑换过奖励后是否还能再兑换奖励?  */}
      <StudentFaqItem type={5} />
      {/**6.学生的初始化奖励分数是多少？  */}
      <StudentFaqItem type={6} />
    </div>
  );
};

export default StudentFAQ;
