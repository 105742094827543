import { useEffect, useState, useRef } from "react";
import CommonPageWithHeader from "../components/CommonPageWithHeader";
import RewardPunishUserItem from "../components/RewardPunishUserItem";
import AuthService from "../services/service";
import { useTranslation } from "react-i18next";

import { Event } from "../datas/atoms";
import ConfirmSwitchStateComponent from "../components/ConfirmSwitchStateComponent";

// const EVENTTXT = "惩罚";
const EVENT = "punishment";
const AwaitingPunishment = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  //statement
  const [showConfirmPop, setShowConfirmPop] = useState(false);
  const [statement, setStatement] = useState(false);
  // const [action, setAction] = useState("");
  // const [selectedEventId, setSelectedEventId] = useState("");
  const selectedEventIdRef = useRef("");
  const actionRef = useRef("");

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    AuthService.getAllEvents()
      .then(({ data }) => {
        // setAllEvents(data.events);
        const _ = data.events.filter((m: Event) => m.type === EVENT);
        setEvents(_);
        setLoading(false);
        console.log("收到的events:", _);
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
      });
  };

  //update an event's statement
  const onPressChange = (state: boolean, eventId: string) => {
    actionRef.current = "update";
    selectedEventIdRef.current = eventId;
    console.log(`现在的state:${state};eventId:${eventId}`);
    setStatement(!state);
    if (!state === true) {
      //确认是否已经解决好问题
      setShowConfirmPop(true);
    } else {
      //确认是否要撤回已解决
      setShowConfirmPop(true);
    }
  };

  const onPressCancel = () => {
    selectedEventIdRef.current = "";
    setShowConfirmPop(false);
  };

  //confirm : update statement and restrive all datas
  const onPressConfirm = () => {
    setLoading(true);
    console.log("现在收到eventId:", selectedEventIdRef.current);
    if (actionRef.current === "delete") {
      deleteEventApi();
    } else {
      updateEventStateApi();
    }
  };

  const deleteEventApi = () => {
    AuthService.deleteAnEvent(selectedEventIdRef.current)
      .then(() => {
        setShowConfirmPop(false);
        selectedEventIdRef.current = "";
        initData();
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  const updateEventStateApi = () => {
    AuthService.updateAnEventStatement(selectedEventIdRef.current)
      .then(() => {
        setShowConfirmPop(false);
        initData();
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  //delete an event
  const onPressDeleteEvent = (eventId: string) => {
    selectedEventIdRef.current = eventId;
    actionRef.current = "delete";
    setShowConfirmPop(true);
    console.log("要删除的eventId:", eventId);
  };

  return (
    <CommonPageWithHeader
      loading={loading}
      error={error}
      explain={true}
      type={2}
    >
      {/**adding punish button */}
      {/* <button
        type="button"
        className="btn btn-primary "
        style={{ marginTop: "3%", padding: "1rem 3rem", fontWeight: "bold" }}
        onClick={() =>
          navigate(
            "/register/register-form/register-form-school-info/allHeros",
            {
              state: {
                statement: EVENT,
              },
            }
          )
        }
      >
        添加{EVENTTXT}
      </button> */}
      <p style={{ fontSize: "1.5rem" }}>
        {/* 总共 */}
        {t("total")}：{events.length}
      </p>
      <div className="seperateLine" />
      {/*histories*/}
      <div className="rewordPunishContainer">
        <div className="eventListContainer">
          {events.length > 0 && (
            <ol>
              {events.map((item: Event, index: number) => (
                <li key={index}>
                  <RewardPunishUserItem
                    event={item}
                    userType={"school"}
                    onPressChange={onPressChange}
                    onPressDeleteEvent={onPressDeleteEvent}
                  />
                </li>
              ))}
            </ol>
          )}
          {events.length === 0 && (
            <p>
              ---
              {/* 目前还没有记录 */}
              {t("no_record_yet")}
              ---
            </p>
          )}
        </div>
      </div>
      {showConfirmPop && (
        <ConfirmSwitchStateComponent
          action={actionRef.current}
          onPressCancel={onPressCancel}
          onPressConfirm={onPressConfirm}
          state={statement}
        />
      )}
    </CommonPageWithHeader>
  );
};

export default AwaitingPunishment;
