const getStorePaymentType = (data: string) => {
  let type = "year";

  if (data === "year") {
    type = `€${import.meta.env.VITE_STORE_YEAR}/`;
  } else if (data === "life") {
    type = `€${import.meta.env.VITE_STORE_LIFE}/`;
  } else {
    type = "其它";
  }

  return type;
};

export default getStorePaymentType;
