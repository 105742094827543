import StorePricingItem from "./StorePricingItem";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StorePricingComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="storeComponentContainer">
      {/**Big title */}
      <p className="pricingPageTitle">
        {/* 奖励商家入驻价格 */}
        {t("pricing_for_reward_store")}
      </p>

      {/**subtitle */}
      <p className="pricingPageSubTitle">
        {/* 适用适用渠道 ： 网店，实体店 */}
        {t("available_to")} {t("ex")}
      </p>
      <Link to="/faqs">
        （{/* 查看详细 */}
        {t("check_details")}）
      </Link>
      <div className="priceItemsList">
        {/* <StorePricingItem price={8} type="月" num={1} /> */}
        <StorePricingItem
          price={import.meta.env.VITE_STORE_YEAR}
          type={t("pay_by_year")}
          num={2}
        />
        <StorePricingItem
          price={import.meta.env.VITE_STORE_LIFE}
          type={t("pay_by_life")}
          num={3}
        />
      </div>
    </div>
  );
};

export default StorePricingComponent;
