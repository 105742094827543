import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import { ApplyRewardHistory } from "../../datas/atoms";
import SuccessfulRewardedItem from "../../components/store/SuccessfulRewardedItem";
import AuthService from "../../services/service";

const AwaitingComments = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    AuthService.awaitingToComments()
      .then(({ data }) => {
        setData(data.awaitingComments);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  const activeData = () => {
    setLoading(true);
    initData();
  };

  return (
    <CommonPageWithHeader
      loading={loading}
      error={error}
      explain={true}
      type={10}
    >
      <div
        style={{
          display: "flex",
          padding: "1rem",
          marginTop: "1rem",
          fontSize: "1.2rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* 以下为未评价的奖励申请 */}
        {t("not_comment_yet_apply")}
        <p style={{ fontWeight: "bold", marginTop: "1rem" }}>
          {/* 总共 */}
          {t("total")}：{data.length}
        </p>
      </div>

      {data.length > 0 && (
        <ol>
          {data.map((item: ApplyRewardHistory, index: number) => (
            <li key={index}>
              <SuccessfulRewardedItem
                _id={item.storeId!._id!}
                icon={item.storeId!.icon!}
                name={item.storeId!.name!}
                image={item.storeId!.image!}
                requiredApplyPoints={item.storeId!.rewardPointsRequirement!}
                rewardContent={item.storeId!.rewardContent!}
                rewardValidTill={item.time}
                appliedTime={item.approvedTime!}
                getMode={item.storeId!.getMode!}
                comment={item.comment}
                activeData={activeData}
                rewardId={item._id}
              />
            </li>
          ))}
        </ol>
      )}
      {data.length === 0 && <p>---{t("no_record_yet")}---</p>}
    </CommonPageWithHeader>
  );
};

export default AwaitingComments;
