import { FaRankingStar } from "react-icons/fa6";
import { AiOutlineRight } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaFaceSadCry } from "react-icons/fa6";
import { PiMedalBold } from "react-icons/pi";
import { BiSolidBell } from "react-icons/bi";
import { RiAdminFill } from "react-icons/ri";
import { FaChalkboardTeacher } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface Props {
  type: number;
  number?: number;
  onPressClickMenu: () => void;
  reportNum?: number;
}

const SchoolMenuItem = ({
  type,
  number,
  onPressClickMenu,
  reportNum,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="menuItemContainer" onClick={onPressClickMenu}>
      {/**消息提醒 */}
      {reportNum && reportNum > 0 && type === 7 ? (
        <div className="badgeContainer">{reportNum}</div>
      ) : (
        <div></div>
      )}
      {/**icon */}
      <div className="innerMenuContainer">
        {type === 1 && <FaRankingStar size={"2rem"} color={"white"} />}
        {type === 2 && <AiFillHeart size={"2rem"} color={"white"} />}
        {type === 3 && <BsFillPeopleFill size={"2rem"} color={"white"} />}
        {type === 4 && <RiAdminFill size={"2rem"} color={"white"} />}
        {type === 5 && <FaFaceSadCry size={"2rem"} color={"white"} />}
        {type === 6 && <PiMedalBold size={"2rem"} color={"white"} />}
        {type === 7 && <BiSolidBell size={"2rem"} color={"white"} />}
        {type === 8 && (
          <img
            src={"/images/reward_rules.png"}
            style={{ width: "3rem", height: "3rem" }}
          />
        )}
        {type === 9 && (
          <img
            src={"/images/emails.png"}
            style={{ width: "3rem", height: "3rem" }}
          />
        )}
        {type === 10 && (
          <img
            src={"/images/punishment_rule.png"}
            style={{ width: "3rem", height: "3rem" }}
          />
        )}
        {type === 11 && <FaChalkboardTeacher size={"2rem"} color={"white"} />}
        {/**title+ content */}
        <div className="innerMenuContainer1">
          {type === 1 && (
            <p style={{ fontWeight: "bold", color: "white" }}>世界排名</p>
          )}
          {type === 2 && (
            <p style={{ fontWeight: "bold", color: "white" }}>收获点赞</p>
          )}

          {type === 3 && (
            <>
              <p style={{ fontWeight: "bold", color: "white" }}>
                {/* 学生 */}
                {t("joined_students_amount")}
              </p>
              <p style={{ color: "white", textAlign: "center" }}>
                (
                {/* 作用: ①添加/更新/删除学生的资料; ②给学生添加奖励/者扣分事件 */}
                {t("explain_add_student")})
              </p>
            </>
          )}

          {type === 4 && (
            <>
              <p style={{ fontWeight: "bold", color: "white" }}>
                {/* 添加教职人员 */}
                {t("add_teacher")}
              </p>
            </>
          )}

          {type === 5 && (
            <p style={{ fontWeight: "bold", color: "white" }}>
              {/* 查看所有惩罚事件 */}
              {t("punishment_amounts")}
            </p>
          )}
          {type === 6 && (
            <p style={{ fontWeight: "bold", color: "white" }}>
              {/* 查看所有奖励事件 */}
              {t("reward_amount")}
            </p>
          )}
          {type === 7 && (
            <p style={{ fontWeight: "bold", color: "white" }}>
              {/* 收到举报 */}
              {t("reported_amount")}
            </p>
          )}
          {type === 8 && (
            <p style={{ fontWeight: "bold", color: "white" }}>
              {/* 录入奖励行为校规 */}
              {t("enter_reward_rules")}
            </p>
          )}
          {type === 9 && (
            <p style={{ fontWeight: "bold", color: "white" }}>
              录入允许注册学生Email
            </p>
          )}
          {type === 10 && (
            <p style={{ fontWeight: "bold", color: "white" }}>
              {/* 录入扣分行为校规 */}
              {t("enter_punish_rules")}
            </p>
          )}

          {type === 11 && (
            <p style={{ fontWeight: "bold", color: "white" }}>
              {/* 教师私人奖励申请 */}
              {t("private_teacher_reward_apply")}
            </p>
          )}
          {type !== 4 && <p style={{ color: "white" }}>{number}</p>}
          {type === 4 && <p style={{ color: "white" }}>{number}</p>}
        </div>
      </div>

      {/**icon */}
      <div className="innerMenuContainer2">
        <AiOutlineRight size={"1rem"} color={"white"} />
      </div>
    </div>
  );
};

export default SchoolMenuItem;
