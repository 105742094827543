import { useTranslation } from "react-i18next";
import { FaSchool } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { BiSolidBook } from "react-icons/bi";
import { useState } from "react";
import Select from "react-select";
import data from "../datas/en.json";
import { MdOutlineClear } from "react-icons/md";

interface Props {
  onLocationSelected: (code: string) => void;
  onTypeSelected: (type: string) => void;
  onSearch: (value: string) => void;
  onButtonClick: (inputValue: any) => void;
}

interface Location {
  name: string;
  code: string;
}

interface Type {
  type: string;
  value: string;
}
const SearchBar = ({
  onLocationSelected,
  onTypeSelected,
  onSearch,
  onButtonClick,
}: Props) => {
  const { t } = useTranslation();
  const schools = [
    { value: "Kindergarten", type: t("Kindergarten") },
    { value: "Primary", type: t("Primary") },
    { value: "Middle", type: t("Middle") },
    { value: "High", type: t("High") },
    // { value: "Tenical", type: t("Tenical") },
    // { value: "College", type: t("College") },
    { value: "University", type: t("University") },
    { value: "Other", type: t("Other") },
  ];
  const [inputValue, setInputValue] = useState("");

  const [selectedLocationOption, setSelectedLocationOption] =
    useState<Location | null>(null);

  const [selectedType, setSelectedType] = useState<Type | null>(null);
  const [allSelectedValue, setAllSelectedValue] = useState({
    location: "",
    type: "",
    name: "",
  });

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 250, // 设置宽度为100%,
    }),
  };

  const getOptionLabelLocation = (option: Location) => {
    return option.name;
  };

  const getOptionLabelType = (option: Type) => {
    return option.type;
  };

  const handleLocationChange = (selectedOption: Location | null) => {
    const code = selectedOption?.code ?? "";

    setSelectedLocationOption(selectedOption);
    onLocationSelected(code); // 调用回调函数并传递选中的代码
    setAllSelectedValue({ ...allSelectedValue, location: code });
  };

  const handleTypeChange = (selectedOption: Type | null) => {
    const name = selectedOption?.value ?? "";

    setSelectedType(selectedOption);
    onTypeSelected(name); // 调用回调函数并传递选中的代码
    setAllSelectedValue({ ...allSelectedValue, type: name });
  };
  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
    onSearch(value);
    setAllSelectedValue({ ...allSelectedValue, name: value });
  };

  const handleButtonClick = () => {
    onButtonClick(allSelectedValue);
  };

  const handleNameClear = () => {
    setInputValue(""); // Clear the school name input
    onSearch(""); // Clear the school name filter in the parent component
    setAllSelectedValue({ ...allSelectedValue, name: "" });
  };

  return (
    <div className="searchBarContainer">
      {/**reuiqrement */}
      <p className="searchBarTitle">{t("only_school")}</p>
      {/**search bar +school*/}
      {/**all input bars container */}
      <div className="allInputBarsContainer">
        {/**school name */}
        <div className="inputBarContainer">
          <FaSchool color={"#666"} size={"2rem"} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              width: "100%",
            }}
          >
            <input
              type="text"
              value={inputValue}
              className="form-control searchSchoolInput"
              onChange={handleInputChange}
              placeholder={t("search_bar_placeholder")}
              style={{
                width: "100%",
                marginLeft: 3,
                fontSize: "1.1rem",
                paddingLeft: 16,
                borderRadius: 4,
                paddingTop: "0.78rem",
                paddingBottom: "0.78rem",
                borderColor: "#b6b4b4",
              }}
            />
            {inputValue && (
              <MdOutlineClear
                color={"#666"}
                size={"1.5rem"}
                style={{ position: "absolute", right: 5 }}
                onClick={handleNameClear}
              />
            )}
          </div>
        </div>

        {/**school location */}
        <div className="inputBarContainer">
          <IoLocationSharp color={"#666"} size={"2rem"} />
          <Select
            placeholder={t("all")}
            onChange={handleLocationChange}
            value={selectedLocationOption}
            styles={customStyles} // 设置自定义样式
            options={data}
            isSearchable={true}
            getOptionLabel={getOptionLabelLocation}
            isClearable={true}
          />
        </div>

        {/**school type */}
        <div className="inputBarContainer">
          <BiSolidBook
            color={"#666"}
            size={"2rem"}
            style={{ marginRight: 2 }}
          />
          <Select
            placeholder={t("all")}
            onChange={handleTypeChange}
            value={selectedType}
            styles={customStyles} // 设置自定义样式
            options={schools}
            isSearchable={true}
            getOptionLabel={getOptionLabelType}
            isClearable={true}
          />
        </div>

        {/**search button */}
        <button
          type="button"
          className="btn btn-warning seachBtn"
          onClick={handleButtonClick}
        >
          {t("search")}
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
