import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoLocationSharp } from "react-icons/io5";
import returnRegionName from "../utils/returnRegionName";

interface Props {
  name: string;
  region: string;
  icon: string;
  type: string;
  publicId: string;
  email: string;
  address: string;
  onPressEdit: () => void;
}
const SchoolProfileCard = ({
  name,
  region,
  icon,
  type,
  publicId,
  email,
  address,
  onPressEdit,
}: Props) => {
  const { t } = useTranslation();
  const img_url = import.meta.env.VITE_IMG_URL;
  const iconImg = `${img_url}${icon}`;
  // const iconImg = `../../node_modules/country-flag-emoji-json/dist/images/${icon}.svg`;

  useEffect(() => {
    //console.log("region:", region);
  }, []);

  return (
    <div className="schoolProfileCard">
      {/**icon */}

      <img
        src={iconImg}
        style={{
          width: "5rem",
          height: "5rem",
          borderRadius: "5rem",
          marginBottom: "1.5rem",
        }}
        alt="icon"
      />

      {/**school name */}
      <h1 style={{ fontWeight: "bold" }}>{name}</h1>

      {/**type */}
      <h3 style={{ fontWeight: "bold", color: "#666" }}>
        {/* Type：  */}
        {t("type")}: <span style={{ fontWeight: "345" }}>{t(`${type}`)}</span>
      </h3>
      <p>ID: {publicId}</p>
      {/**region */}
      <div style={{ display: "flex" }}>
        {" "}
        <IoLocationSharp color={"#e11b1b"} size={"2rem"} />{" "}
        <h3>{returnRegionName(region)}</h3>
      </div>

      {/**address */}
      <h3 style={{ fontWeight: "bold", color: "#666", marginTop: "1rem" }}>
        {/* Region：{" "} */}
        {t("school_address")}
        <span
          style={{
            fontWeight: "345",
            textAlign: "center",
            marginRight: "1rem",
          }}
        >
          {address}
        </span>
      </h3>
      {/**Email */}
      <h3 style={{ fontWeight: "345" }}> {email}</h3>

      {/**Edit button */}
      <button
        type="button"
        className="btn btn-primary mt-5 px-5 py-3"
        onClick={onPressEdit}
      >
        {/* Edit Profile */}
        {t("edit_profile")}
      </button>
    </div>
  );
};

export default SchoolProfileCard;
