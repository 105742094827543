import { Link } from "react-router-dom";

interface Props {
  title: "string";
  path: any;
}
const NormalBtnLink = ({ title, path }: Props) => {
  return (
    <div className="normalBtnContainer">
      <Link className="btnStyle" to={`${path}`}>
        {title}
      </Link>
    </div>
  );
};

export default NormalBtnLink;
