const getUserType = () => {
  let userType = "";
  const teacher = localStorage.getItem("teacherUser");
  const school = localStorage.getItem("newUser");
  const student = localStorage.getItem("userSelf");

  if (teacher) {
    userType = "teacher";
  }
  if (school) {
    userType = "school";
  }
  if (student) {
    userType = "student";
  }
  return userType;
};

export default getUserType;
