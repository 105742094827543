import { useTranslation } from "react-i18next";
import TitleComponent from "./TitleComponent";
import { useState } from "react";
import getDifLangImg from "../../utils/getDifLangImg";
interface Props {
  type: Number;
}
const StudentFaqItem = ({ type }: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const titles = [
    `1.${t("what_student_can_do_title")}`,
    `2.${t("how_to_report_to_school_title")}`,
    `3.${t("student_how_to_change_password_title")}`,
    `4.${t("how_to_apply_reward_school_title")}`,
    `5.${t("if_student_teaching_staff_allowed_to_re_redeem_rewards")}`,
    `6.${t("what_is_student_initial_reward_point_title")}`,
  ];

  const content_1 = [
    t("what_student_can_do_content_1"),
    t("what_student_can_do_content_2"),
  ];
  const content_2 = [
    t("how_to_report_to_school_content_1"),
    t("how_to_report_to_school_content_2"),
  ];
  const content_3 = [
    t("student_how_to_change_password_content_1"),
    t("student_how_to_change_password_content_2"),
    t("student_how_to_change_password_content_3"),
    t("student_how_to_change_password_content_4"),
    t("student_how_to_change_password_content_5"),
    t("student_how_to_change_password_content_6"),
  ];

  const content_5 = [
    t("how_to_apply_reward_school_content_1"),
    t("how_to_apply_reward_school_content_2"),
    t("how_to_apply_reward_school_content_3"),
    t("how_to_apply_reward_school_content_4"),
  ];

  //学生用户、教职人员兑换过奖励后是否还能再兑换奖励？
  const content_6 = [t("what_is_student_initial_reward_point_content")];

  //学生用户、教职人员兑换过奖励后是否还能再兑换奖励？
  const content_19 = [
    t("if_student_teaching_staff_allowed_to_re_redeem_rewards_content"),
  ];

  return (
    <div>
      {/**1.学生用户可以做什么？*/}
      {type === 1 && (
        <>
          <TitleComponent
            title={titles[0]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_1[0]}</p>
              <p className="faq_content">{content_1[1]}</p>
            </div>
          )}
        </>
      )}
      {/**2.如何向学校举报？*/}
      {type === 2 && (
        <>
          <TitleComponent
            title={titles[1]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_2[0]}</p>
              <p className="faq_content">{content_2[1]}</p>
              <img src={getDifLangImg("2_2.png")} style={{ width: "340px" }} />
            </div>
          )}
        </>
      )}
      {/**3.学生用户如何更换密码？*/}
      {type === 3 && (
        <>
          <TitleComponent
            title={titles[2]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_3[0]}</p>
              <p className="faq_content">{content_3[1]}</p>
              <p className="faq_content">{content_3[2]}</p>
              <p className="faq_content">{content_3[3]}</p>
              <p className="faq_content">{content_3[4]}</p>
              <p className="faq_content">{content_3[5]}</p>
            </div>
          )}
        </>
      )}
      {/**4.如何申请奖励？*/}
      {type === 4 && (
        <>
          <TitleComponent
            title={titles[3]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_5[0]}</p>
              <img src={getDifLangImg("15_1.png")} style={{ width: "340px" }} />
              <p className="faq_content">{t("or")}</p>
              <img
                src={getDifLangImg("15_1_2.png")}
                style={{ width: "340px" }}
              />
              <p className="faq_content">{content_5[1]}</p>
              <img
                src={getDifLangImg("4_s_2.png")}
                style={{ width: "340px" }}
              />
              <p className="faq_content">{content_5[2]}</p>
              <p className="faq_content">{content_5[3]}</p>
              <img src={getDifLangImg("15_4.png")} style={{ width: "340px" }} />
            </div>
          )}
        </>
      )}

      {/**18.学生用户、教职人员兑换过奖励后是否还能再兑换奖励? */}
      {type === 5 && (
        <>
          <TitleComponent
            title={titles[4]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_19[0]}</p>
            </div>
          )}
        </>
      )}

      {/**5.学生的初始化奖励分数是多少？ */}
      {type === 6 && (
        <>
          <TitleComponent
            title={titles[5]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_6[0]}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StudentFaqItem;
