import { AiOutlineCloseCircle } from "react-icons/ai";
import Loading from "../Loading";
import { AiFillCheckCircle } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import { QRCodeSVG } from "qrcode.react";
import formatJoinDate from "./../../utils/formatJoinDate";
import { useTranslation } from "react-i18next";
interface Props {
  handleClosePopup: () => void;
  type: string; // online ; physical ;
  statement: string; // noneCheck ; checking;success;fail
  rewardId: string;
  validDate: string;
}
const PopupPlatformSelector = ({
  handleClosePopup,
  type,
  statement,
  rewardId,
  validDate,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="addHeroComponent">
      {" "}
      <div className="alertPop">
        {/**=================online store ====================== */}

        {/**type===online ; statement:checking;loading:true */}
        {type === "online" && statement === "checking" && (
          <>
            <Loading />
          </>
        )}

        {/**type===online ; statement:success;loading:false */}
        {type === "online" && statement === "success" && (
          <>
            {/**title: verify success */}
            <div style={{ display: "flex", fontSize: "1.5rem" }}>
              <AiFillCheckCircle
                color={"green"}
                size={"2rem"}
                style={{ marginRight: "0.5rem" }}
              />
              {/* 申请成功！ */}
              {t("apply_reward_success")}
            </div>
            {/**subtitle:please copy your id,and send it to the store owner before paying */}
            <p style={{ padding: "1rem", textAlign: "center" }}>
              {/* 请先复制以下ID,再向店主获取你的专属奖励： */}
              {t("coppy_id")}:
            </p>
            {/**ID */}
            <p
              style={{
                backgroundColor: "#637383",
                color: "white",
                padding: "1rem 4rem",
                borderRadius: "1rem",
              }}
            >
              {rewardId}
            </p>
            {/**button navigate to web link */}
            <button
              type="button"
              className="btn btn-outline-primary"
              style={{ marginTop: "1rem", padding: "1rem 4rem" }}
              onClick={() =>
                window.open(
                  "https://es.aliexpress.com/item/1005005265788498.html?spm=a2g0o.tm1000003765.3832216530.5.387638f2snkwiV&pdp_ext_f=%7B%22ship_from%22:%22CN%22,%22sku_id%22:%2212000032415893298%22%7D&scm=1007.25281.317569.0&scm_id=1007.25281.317569.0&scm-url=1007.25281.317569.0&pvid=c758ff94-6f2e-4826-8d14-92de676c52b1&utparam=%257B%2522process_id%2522%253A%2522standard-item-process-1%2522%252C%2522x_object_type%2522%253A%2522product%2522%252C%2522pvid%2522%253A%2522c758ff94-6f2e-4826-8d14-92de676c52b1%2522%252C%2522belongs%2522%253A%255B%257B%2522floor_id%2522%253A%252239583504%2522%252C%2522id%2522%253A%252232263352%2522%252C%2522type%2522%253A%2522dataset%2522%257D%252C%257B%2522id_list%2522%253A%255B%25221000490438%2522%255D%252C%2522type%2522%253A%2522gbrain%2522%257D%255D%252C%2522pageSize%2522%253A%252218%2522%252C%2522language%2522%253A%2522es%2522%252C%2522scm%2522%253A%25221007.25281.317569.0%2522%252C%2522countryId%2522%253A%2522ES%2522%252C%2522scene%2522%253A%2522TopSelection-Waterfall%2522%252C%2522tpp_buckets%2522%253A%252221669%25230%2523265320%252327_21669%25234190%252319158%252381_15281%25230%2523317569%25230%2522%252C%2522x_object_id%2522%253A%25221005005265788498%2522%257D&pdp_npi=3%40dis%21EUR%21%E2%82%AC%207%2C97%21%E2%82%AC%200%2C99%21%21%21%21%21%40211b600d16970179209183895e909d%2112000032415893298%21gdf%21%21&aecmd=true",
                  "_blank"
                )
              }
            >
              {/* 点击前往网店 → */}
              {t("to_online_shop")}→
            </button>
            <p style={{ marginTop: "2rem", color: "red" }}>
              {/* 有效期至  */}
              {t("valid_date_to")}
              {formatJoinDate(validDate)}，{/* 请尽快使用 */}
              {t("use_it_soon")}
            </p>
          </>
        )}

        {/**type===online ; statement:success;loading:false */}
        {type === "online" && statement === "fail" && (
          <>
            {" "}
            <div style={{ display: "flex", fontSize: "1.5rem" }}>
              <AiFillCloseCircle
                color={"red"}
                size={"2rem"}
                style={{ marginRight: "0.5rem" }}
              />
              {/* 申请失败，您不符合申请该奖励的条件！ */}
              {t("user_apply_failed")}
            </div>
          </>
        )}

        {/**=================physical store ====================== */}

        {/**type===physical; statement:checking;loading:true */}
        {type === "physical" && statement === "checking" && (
          <>
            <Loading />
          </>
        )}

        {/**type===physical ; statement:success;loading:false */}
        {type === "physical" && statement === "success" && (
          <>
            {/**title: verify success */}
            <div style={{ display: "flex", fontSize: "1.5rem" }}>
              <AiFillCheckCircle
                color={"green"}
                size={"2rem"}
                style={{ marginRight: "0.5rem" }}
              />
              {/* 申请成功！ */}
              {t("apply_reward_success")}
            </div>
            {/**subtitle:please copy your id,and send it to the store owner before paying */}
            <p
              style={{
                padding: "1rem",
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              {/* 以下为您的专属二维码，请在结账时向店主展示： */}
              {t("use_qr_code")}:
            </p>
            {/**qr code */}
            <QRCodeSVG value={rewardId} />

            <p style={{ padding: "1rem", marginTop: "2rem" }}>ID: {rewardId}</p>
          </>
        )}

        {/**type===physical ; statement:success;loading:false */}
        {type === "physical" && statement === "fail" && (
          <>
            {" "}
            <div style={{ display: "flex", fontSize: "1.5rem" }}>
              <AiFillCloseCircle
                color={"red"}
                size={"2rem"}
                style={{ marginRight: "0.5rem" }}
              />
              {/* 申请失败，您不符合申请该奖励的条件！ */}
              {t("user_apply_failed")}
            </div>
          </>
        )}
      </div>
      <AiOutlineCloseCircle
        size={"3rem"}
        color={"white"}
        style={{ marginTop: "1rem" }}
        onClick={handleClosePopup}
        className="iconHover"
      />
    </div>
  );
};

export default PopupPlatformSelector;
