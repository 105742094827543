import { useEffect, useRef, useState } from "react";
import AuthService from "../services/service";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CommonPageWithHeader from "../components/CommonPageWithHeader";
import SchoolProfileCard from "../components/SchoolProfileCard";
import SchoolProfileVerifyStatement from "../components/SchoolProfileVerifyStatement";
import { School, schoolAtom } from "../datas/atoms";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import AlertConfirmWithdraw from "../components/AlertConfirmWithdraw";
import EnterLoginId from "../components/EnterLoginId";
import formatJoinDate from "../utils/formatJoinDate";
import SchoolMenuItem from "../components/SchoolMenuItem";
import RankingPopup from "../components/RankingPopup";
import { PiGearFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import getUserType from "../utils/getUserType";
import { FaChalkboardTeacher } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import InputNormal from "../components/InputNormal";

interface Props {
  schoolItem: School;
}

const SchoolProfile = ({ schoolItem }: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const schoolRef = useRef<School | null>(null);
  const [enterLoginIdPopup, setEnterLoginIdPopup] = useState(false);
  const [confirmAgainIfWithdrawn, setConfirmAgainIfWithdrawn] = useState(false);
  const [school, setSchool] = useAtom(schoolAtom);

  const [loginID, setLoginID] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [errorOccured, setErrorOccured] = useState(false);
  const [popupRanking, setPopupRanking] = useState(false);
  const [showSelectOption, setShowSelectOption] = useState(false);
  const [cancelAcountWarning, setCancelAccountWarning] = useState(false);
  const [studentAmount, setStudentAmount] = useState(0);
  const [punishment_amount, setPunishmentAmount] = useState(0);
  const [reward_amount, setRewardAmount] = useState(0);
  const [awaiting_amount, setAwaitingAmount] = useState(0);
  const [teacher_amount, setTeacherAmount] = useState(0);
  const [unsolveAmount, setUnslovedAmount] = useState(0);
  const [rewardsList, setRewardsList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    setLoading(true);

    AuthService.getCurrentUser()
      .then(({ data }) => {
        schoolRef.current = data.schoolData;
        // if (!schoolItem.icon) {
        //   setSchool({
        //     _id: data.schoolData.id,
        //     name: data.schoolData.name,
        //     joinDate: data.schoolData.joinDate,
        //     icon: data.schoolData.icon,
        //     email: data.schoolData.email,
        //     region: data.schoolData.region,
        //     ranking: data.schoolData.ranking,
        //     type: data.schoolData.type,
        //     publicId: data.schoolData.publicId,
        //     address: data.schoolData.address,
        //     votes: data.schoolData.vote,
        //     votedBy: data.schoolData.votedBy,
        //     statement: data.schoolData.statement,
        //     active: data.schoolData.active,
        //   });
        // }

        getProfileDatas();
        getTeacherSchoolRewardsList();
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const getTeacherSchoolRewardsList = () => {
    AuthService.getTeacherAndSchoolPrivateRewardsList()
      .then(({ data }) => {
        setRewardsList(data.rewards);
      })
      .catch((e) => {
        setError(e);
      });
  };

  const getProfileDatas = () => {
    setLoading(true);
    // check();
    AuthService.getSchoolProfileDatas()
      .then(({ data }) => {
        setStudentAmount(data.studentAmount);
        setPunishmentAmount(data.punishment_amount);
        setRewardAmount(data.reward_amount);
        setAwaitingAmount(data.awaiting_amount);
        setTeacherAmount(data.teacherAmount);
        setUnslovedAmount(data.unsloved_amount);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  //check if school need to clear up teachers
  // const check = () => {
  //   AuthService.ifNeedToClearupTeacher().then().catch();
  // };

  //upload default punishment and reward rules

  const onPressEditProfileBtn = () => {
    const userTypeNow = getUserType();
    if (userTypeNow !== "school") {
      return alert("Only school admin user can edit school'r profile !");
    }
    window.location.href = "/edit-school-profile";
  };

  const onPressLoginIdChange = (event: any) => {
    setLoginID(event.target.value);
  };

  const onPressCloseModal = () => {
    if (loginID === "") {
      return setEnterLoginIdPopup(false);
    }
    setEnterLoginIdPopup(false);

    // 这里用于更新前端
    setSchool((prevUser) => ({
      ...prevUser,
      active: !school.active,
    }));

    // 这里用于更新后端
    AuthService.removeOrJoin(loginID)
      .then(({ data }) => {
        setReturnDate(data.returnDate);
        // 这里需要马上获取用户资料，并且把头像和一切都放在 user 那里
        initData();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("removeOrJoin修改statement失败", error);
        setErrorOccured(true);
        // 处理错误，例如显示错误消息
        const errorMsg = error.response.data.message;
        if (errorMsg.includes("It is not the time yet")) {
          const message = `${t("not_the_time")}${formatJoinDate(
            error.response.data.retryDate
          )}`;
          return setError(message);
        }
        return setError(error.response.data.message);
      });
  };

  const onPressCancelWithdraw = () => {
    setConfirmAgainIfWithdrawn(false);
  };
  const onPressConfirmWithdraw = () => {
    setConfirmAgainIfWithdrawn(false);
    setEnterLoginIdPopup(true);
  };

  //menus
  const onPressTurnOffPopupRanking = () => {
    setPopupRanking(false);
  };
  // const onPressRanking = () => {
  //   setPopupRanking(true);
  // };

  const onPressJoinedStudents = () => {
    window.location.href =
      "/register/register-form/register-form-school-info/allHeros";
  };

  // const onPressReceivedBonus = () => {
  //   window.location.href =
  //     "/register/register-form/register-form-school-info/receivedBonus";
  // };

  const onPressAwaitingPunishment = () => {
    window.location.href =
      "/register/register-form/register-form-school-info/awaitingPunishment";
  };

  const onPressAwaitingReward = () => {
    window.location.href =
      "/register/register-form/register-form-school-info/awaitingReward";
  };

  const onPressRewardRule = () => {
    window.location.href =
      "/register/register-form/register-form-school-info/addRewardRule";
  };

  // const onPressAllowEmails = () => {
  //   window.location.href =
  //     "/register/register-form/register-form-school-info/allowEmails";
  // };

  const onPressPunishmentRule = () => {
    window.location.href =
      "/register/register-form/register-form-school-info/addPunishmentRule";
  };

  const onPressAddTeachers = () => {
    window.location.href =
      "/register/register-form/register-form-school-info/allowEmailsTeacher";
  };

  const onPressReports = () => {
    window.location.href = "/school-profile/reports";
  };

  const handleloginID = (e: any) => {
    setLoginID(e.target.value);
  };

  const handleUploadLoginId = () => {
    setLoading(true);
    if (loginID !== "") {
      cancelUserAcount();
    } else {
      return setError("The uploaded information is incomplete!");
    }
  };

  //create new user
  const cancelUserAcount = () => {
    AuthService.schoolCancelAccount(loginID)
      .then(() => {
        //这里需要马上获取用户资料，并且把头像和和一切都放在user那里
        // initData();
        clearUserInfo();
        setLoading(false);
        setCancelAccountWarning(false);
        window.location.href = "/";
      })
      .catch((error) => {
        setLoading(false);

        // 处理错误，例如显示错误消息
        return setError(error.response.data.message);
      });
  };

  const clearUserInfo = () => {
    setSchool({
      _id: "",
      name: "",
      joinDate: "",
      icon: "",
      email: "",
      region: "",
      type: "",
      publicId: "",
      ranking: 0,
      votes: 0,
      votedBy: [],
      address: "",
      statement: "",
      active: false,
      heros: [],
      studentsVerify: [],
      receivedReports: [],
      events: [],
    });
    localStorage.removeItem("newUser");
  };
  return (
    <CommonPageWithHeader loading={loading} error={error}>
      {schoolRef.current !== null && (
        <div className="schoolProfileContainer">
          {/**popup */}
          {showSelectOption && (
            <div className="schoolOwenProfilePopupContainer">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <p
                  className="iconHover"
                  onClick={() => setShowSelectOption(false)}
                >
                  X
                </p>
              </div>
              {/**点击可以更改订阅状态 */}
              <p
                className="iconHover"
                onClick={() => {
                  setShowSelectOption(false);
                  navigate("/school-profile/subscription-statement");
                }}
              >
                {/* 更改订阅 */}
                {t("change_payment_method")}
              </p>
              {/**点击可以更改展示状态 */}
              {/* <p
                className="iconHover"
                onClick={() => {
                  setShowSelectOption(false);
                  navigate("/school-profile/display-statement");
                }}
              >
                展示状态
              </p> */}

              <p
                className="iconHover"
                onClick={() => setCancelAccountWarning(true)}
              >
                {/* 注销账户 */}
                {t("cancel_account")}
              </p>
            </div>
          )}
          {/**gear icon */}
          <div>
            <PiGearFill
              size={"3rem"}
              className="iconHover schoolProfileGear"
              onClick={() => setShowSelectOption(true)}
            />
          </div>
          <div className="box1">
            <SchoolProfileCard
              name={schoolRef!.current!.name! || schoolItem!.name!}
              region={schoolRef!.current!.region!}
              icon={schoolRef!.current!.icon!}
              type={schoolRef!.current!.type!}
              publicId={schoolRef!.current!.publicId!}
              email={schoolRef!.current!.email!}
              address={schoolRef!.current!.address!}
              onPressEdit={onPressEditProfileBtn}
            />
            {/**verify statement :statement:verify statement ; toggle:school's statement of joining School glory*/}

            <SchoolProfileVerifyStatement
              statement={schoolRef!.current!.statement!}
              active={schoolRef!.current!.active}
              // onPressChange={onPressStateChange}
            />
          </div>
          <div className="box2">
            <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
              {/**ranking */}
              {/* <SchoolMenuItem
                type={1}
                number={schoolRef!.current!.ranking}
                onPressClickMenu={onPressRanking}
              /> */}
              {/**received likes */}
              {/* <SchoolMenuItem
                type={2}
                number={schoolRef!.current!.votes}
                onPressClickMenu={onClickCheckWhoVoted}
              /> */}

              {/**joined students */}
              <SchoolMenuItem
                type={3}
                number={studentAmount}
                onPressClickMenu={onPressJoinedStudents}
              />

              {/**add coworkers of school */}
              <SchoolMenuItem
                type={4}
                number={teacher_amount}
                onPressClickMenu={onPressAddTeachers}
              />

              {/**received bonus*/}
              {/* <SchoolMenuItem
                type={4}
                number={300000}
                onPressClickMenu={onPressReceivedBonus}
              /> */}

              {/**awaiting punishment*/}
              <SchoolMenuItem
                type={5}
                number={punishment_amount}
                onPressClickMenu={onPressAwaitingPunishment}
              />

              {/**awaiting rewards*/}
              <SchoolMenuItem
                type={6}
                number={reward_amount}
                onPressClickMenu={onPressAwaitingReward}
              />

              {/**awaiting reports*/}
              <SchoolMenuItem
                type={7}
                number={awaiting_amount}
                onPressClickMenu={onPressReports}
                reportNum={unsolveAmount}
              />

              {/**target*/}
              {/* <SchoolMenuItem
                type={7}
                number={0}
                onPressClickMenu={onPressRanking}
              /> */}
              {/*allow enter email*/}
              {/* <SchoolMenuItem
                type={9}
                number={0}
                onPressClickMenu={onPressAllowEmails}
              /> */}

              {/**teacher's reward apply */}
              <Link
                className="menuItemContainer"
                to={"/successful-rewarded-list"}
                state={{ data: rewardsList }}
                style={{ textDecoration: "none" }}
              >
                {/**icon */}
                <div className="innerMenuContainer">
                  <FaChalkboardTeacher size={"2rem"} color={"white"} />
                  {/**title+ content */}
                  <div className="innerMenuContainer1">
                    <p style={{ fontWeight: "bold", color: "white" }}>
                      {/* 教师私人奖励申请 */}
                      {t("private_teacher_reward_apply")}
                    </p>
                  </div>
                </div>

                {/**icon */}
                <div className="innerMenuContainer2">
                  <AiOutlineRight size={"1rem"} color={"white"} />
                </div>
              </Link>

              {/**create rewards and reward standers*/}
              <SchoolMenuItem
                type={8}
                // number={0}
                onPressClickMenu={onPressRewardRule}
              />

              {/**create rewards and punishment standers*/}
              <SchoolMenuItem
                type={10}
                // number={0}
                onPressClickMenu={onPressPunishmentRule}
              />
            </div>
          </div>
        </div>
      )}
      {enterLoginIdPopup && (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            backgroundColor: "#666666a5",
            display: "flex",
            zIndex: 1000,
            top: "1%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EnterLoginId
            onPressChange={onPressLoginIdChange}
            onPressCloseModal={onPressCloseModal}
          />
        </div>
      )}

      {confirmAgainIfWithdrawn && (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            backgroundColor: "#666666a5",
            display: "flex",
            zIndex: 1000,
            top: "1%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AlertConfirmWithdraw
            schoolName={school.name}
            type={school.active}
            onPressCancel={onPressCancelWithdraw}
            onPressConfirm={onPressConfirmWithdraw}
            backDate={returnDate}
            errorOccured={errorOccured}
          />
        </div>
      )}
      {popupRanking && (
        <RankingPopup
          onPressTurnOffPopupRanking={onPressTurnOffPopupRanking}
          rankingNow={schoolRef!.current!.ranking}
        />
      )}

      {/**cancel account  */}
      {cancelAcountWarning && (
        <div className="alertContainer" style={{ flexDirection: "column" }}>
          {" "}
          <div className="alertPop" style={{ padding: "3rem" }}>
            {/*login ID*/}
            <p style={{ color: "red" }}>
              {/* 注意：只有管理员可以注销账户 */}
              {t("only_admin_user_can_cancel_account")}
            </p>
            <InputNormal
              placeholder={t("loginId")}
              name={"loginId"}
              className="normalInputContainer"
              handleChange={handleloginID}
            />
            {/**Upload button */}
            <button
              type="button"
              className="btn btn-warning"
              style={{
                minWidth: 100,
                padding: "1rem ",
                marginTop: "2rem",
                fontWeight: "bold",
              }}
              onClick={handleUploadLoginId}
            >
              {t("submit")}
            </button>
          </div>
          <AiOutlineCloseCircle
            size={"3rem"}
            color={"white"}
            style={{ marginTop: "1rem" }}
            onClick={() => setCancelAccountWarning(false)}
            className="iconHover"
          />
        </div>
      )}
    </CommonPageWithHeader>
  );
};

export default SchoolProfile;
