
      // Function to set the content and title based on the user's language
      function updateContentBasedOnLanguage() {
        const userLanguage = navigator.language || navigator.userLanguage;

        // 根据用户语言设置content和title
        if (userLanguage.startsWith("zh")) {
          document
            .querySelector('meta[name="description"]')
            .setAttribute(
              "content",
              "荣誉校园榜是一个致力于以友好善意的方式，逐步减少校园霸凌的平台。表彰致力于为学生营造安全、健康、和谐校园环境的卓越院校。让更多学生可以在安全健康快乐的校园环境中获取知识，构筑梦想。"
            );
          document.querySelector("title").innerText = "学校荣耀";
        } else if (userLanguage.startsWith("es")) {
          document
            .querySelector('meta[name="description"]')
            .setAttribute(
              "content",
              "School Glory es una plataforma comprometida en reducir el acoso escolar de manera amable. Solo las escuelas que siempre ponen la seguridad y el bienestar de los estudiantes en primer lugar, pueden estar en la lista de School Glory."
            );
          document.querySelector("title").innerText = "Gloria Escolar";
        }
        // For any other language, keep the default English content and title
      }

      // Call the function to update content based on the user's language
      updateContentBasedOnLanguage();
    