import { TiTick } from "react-icons/ti";

interface Props {
  content: string;
}

const TikItem = ({ content }: Props) => {
  return (
    <div className="tickItemContainer">
      <div>
        {" "}
        <TiTick size={"1.5rem"} color={"#32CD32"} />
      </div>
      <p className="tickItemP">{content}</p>
    </div>
  );
};

export default TikItem;
