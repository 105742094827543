import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Header from "./Header";
import backgoundImg from "/images/school.png";

const Slogan = () => {
  const { t } = useTranslation();
  return (
    <div
      className="sloganContainer"
      style={{ backgroundImage: `url(${backgoundImg})` }}
      aria-label="school glory image"
    >
      <div className="coverContainer">
        {/**Header */}

        <Header isHome={true} hide={false} />
        {/**title + subtitle + button */}

        {/**title */}
        <div className="sloganTitleContainer">
          {" "}
          <h1 className="titleH1">{t("title")}</h1>
        </div>
        {/**subtitle */}
        <p style={{ fontWeight: "340", fontSize: "1.6rem" }}>{t("subtitle")}</p>
        {/**button */}
        <div className="sloganTitleContainer">
          {" "}
          <Link to={"register"} className="registerLink">
            {t("apply")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Slogan;
