import { AiOutlineCloseCircle } from "react-icons/ai";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import picture from "/images/picture.png";
import ErrorMsg from "./ErrorMsg";
import Loading from "./Loading";
import successImg from "/images/done.png";
import AuthService from "../services/service";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { Hero } from "../datas/atoms";

interface Props {
  handleClosePopup: () => void;
  hero?: Hero;
  valid?: boolean;
}

const IMG_SIZE = 80;
const AddHeroForm = ({ handleClosePopup, hero, valid }: Props) => {
  const imgBaseUrl = import.meta.env.VITE_IMG_URL;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState("");
  const [validYear, setValidYear] = useState("1");
  const [selectedValue] = useState("");

  const imageRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [image, setImage] = useState(
    hero !== undefined ? `${imgBaseUrl}${hero!.image}` : ""
  );
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (hero) {
      initData();
    }
  }, []);

  const initData = () => {
    setName(hero!.heroName);
    setEmail(hero!.studentEmail);
    setImage(`${imgBaseUrl}${hero!.image}`);
    setInfo(hero!.info);
    setValidYear(hero!.validYear.toString());
  };

  const handleImageClick = () => {
    imageRef!.current!.click();
  };
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    setImage(URL.createObjectURL(file));
  };

  const handleYearChange = (value: any) => {
    if (value !== selectedValue) {
      setValidYear(value);
    }
  };

  const handleUpload = () => {
    if (name !== "" && image && email !== "") {
      setLoading(true);
      if (!hero) {
        createNewUser();
      } else {
        updateUser();
      }
    } else {
      return setError("The uploaded information is incomplete!");
    }
  };

  //create new user
  const createNewUser = () => {
    const formData = new FormData();

    formData.append("heroName", name);
    formData.append("image", imageRef!.current!.files![0]);
    formData.append("studentEmail", email);
    formData.append("info", info);
    formData.append("validYear", `${validYear}`);
    console.log("format:", formData);
    console.log(
      `现在的HeroName:${name};image:${
        imageRef!.current!.files![0]
      };studentEmail:${email};info:${info};validYear:${validYear}`
    );
    AuthService.uploadHeroProfile(formData)
      .then(() => {
        //这里需要马上获取用户资料，并且把头像和和一切都放在user那里
        // initData();
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setLoading(false);

        // 处理错误，例如显示错误消息
        return setError(error.response.data.message);
      });
  };

  const updateUser = () => {
    console.log(
      `现在的email:${email};name:${name};info:${info};validYear:${validYear};heroId:${hero?._id}`
    );
    const formData = new FormData();
    formData.append("id", hero!._id);
    formData.append("heroName", name);
    formData.append("image", imageRef!.current!.files![0]);
    formData.append("studentEmail", email);
    formData.append("info", info);
    formData.append("validYear", `${validYear}`);
    AuthService.updateHeroProfile(formData)
      .then(() => {
        //这里需要马上获取用户资料，并且把头像和和一切都放在user那里
        // initData();
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setLoading(false);

        // 处理错误，例如显示错误消息
        return setError(error.response.data.message);
      });
  };

  // const handleUpload = () => {
  //   console.log("现在的heroName:", nameRef!.current!.value);
  //   console.log("现在的Image:", imageRef!.current!.files![0]);
  //   console.log("现在的Hero:", hero);

  //   if (
  //     nameRef!.current !== null &&
  //     image !== null &&
  //     emailRef.current !== null
  //   ) {
  //     setLoading(true);
  //     const formData = new FormData();

  //     formData.append("heroName", nameRef!.current!.value);
  //     formData.append("image", imageRef!.current!.files![0]);
  //     formData.append("studentEmail", emailRef!.current!.value);
  //     formData.append("info", infoRef!.current!.value);
  //     formData.append("validYear", `${validYear}`);

  //     if (hero?.heroName !== "") {
  //       formData.append("id", hero?._id);
  //       AuthService.updateHeroProfile(formData)
  //         .then(() => {
  //           //这里需要马上获取用户资料，并且把头像和和一切都放在user那里
  //           // initData();
  //           setLoading(false);
  //           setSuccess(true);
  //         })
  //         .catch((error) => {
  //           setLoading(false);

  //           // 处理错误，例如显示错误消息
  //           return setError(error.response.data.message);
  //         });
  //     } else {
  //       console.log("走了hero为0");
  //       setLoading(true);
  //       AuthService.uploadHeroProfile(formData)
  //         .then(() => {
  //           //这里需要马上获取用户资料，并且把头像和和一切都放在user那里
  //           // initData();
  //           setLoading(false);
  //           setSuccess(true);
  //         })
  //         .catch((error) => {
  //           setLoading(false);

  //           // 处理错误，例如显示错误消息
  //           return setError(error.response.data.message);
  //         });
  //     }
  //   } else {
  //     return setError("The uploaded information is incomplete!");
  //   }
  // };

  return (
    <div className="addHeroComponent">
      {/**component */}
      {error !== "" && <ErrorMsg error={error} />}
      <div className="addEditHeroContainer">
        {!loading && !success && (
          <div className="uploadStudentContainer">
            {/**Profile image */}
            <div className="uploadIconContainer" onClick={handleImageClick}>
              <div
                style={{
                  width: IMG_SIZE,
                  height: IMG_SIZE,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(181, 160, 160, 0.377)",
                  borderRadius: IMG_SIZE,
                  marginTop: 5,
                }}
              >
                {image !== "" ? (
                  <img
                    src={image}
                    style={{
                      width: IMG_SIZE,
                      height: IMG_SIZE,
                      borderRadius: IMG_SIZE,
                      objectFit: "cover",
                    }}
                    alt="School Icon"
                  />
                ) : (
                  <img
                    src={picture}
                    style={{
                      width: IMG_SIZE,
                      height: IMG_SIZE,
                      borderRadius: IMG_SIZE,
                      objectFit: "cover",
                    }}
                    alt="Default Icon"
                  />
                )}
              </div>

              <input
                type="file"
                ref={imageRef}
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>
            {hero && <p style={{ margin: "1rem" }}>Email: {email}</p>}

            {/**name */}
            <div className="inputWithLabelContainer">
              <label className="label">
                {/* 学生名字 */}
                {t("student_name")}
              </label>
              <input
                onChange={(e) => setName(e.target.value)}
                className="normalInputSec"
                value={name || ""}
              />
            </div>

            {/**email */}
            {!hero && (
              <div className="inputWithLabelContainer">
                <label className="label">
                  {/* 学生的Email */}
                  {t("student_email")}
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  className="normalInputSec"
                  value={email || ""}
                />
              </div>
            )}
            {/**info */}

            <div className="inputWithLabelContainer">
              <label className="label">
                {/* 学生的年级班级 */}
                {t("student_info")}
              </label>
              <input
                onChange={(e) => setInfo(e.target.value)}
                className="normalInputSec"
                value={info || ""}
              />
            </div>

            {/**graduate time */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <label className="inputLabel">
                {" "}
                {/* 学生账号在本校的有效时间（默认为选择的年份+ 3个月） */}
                {t("student_valid_year")}：
              </label>
              <select
                style={{ fontSize: "1.5rem", padding: "1rem" }}
                onChange={(e) => handleYearChange(e.target.value)}
              >
                {" "}
                <option className="hide" value={valid ? validYear : 1}>
                  {valid ? validYear : 1}
                </option>
                {hero && <option value="0">0</option>}
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>

            {/**Upload button */}
            <button
              type="button"
              className="btn btn-warning"
              style={{
                minWidth: 100,
                padding: "1rem ",
                marginTop: "2rem",
                fontWeight: "bold",
              }}
              onClick={handleUpload}
            >
              {t("submit")}
            </button>
          </div>
        )}
        {loading && !success && <Loading />}
        {success && !loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={successImg} style={{ width: "6rem", height: "6rem" }} />
          </div>
        )}
      </div>
      <AiOutlineCloseCircle
        size={"3rem"}
        color={"white"}
        style={{ marginTop: "1rem" }}
        onClick={handleClosePopup}
        className="iconHover"
      />
    </div>
  );
};

export default AddHeroForm;
