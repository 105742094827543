import { useNavigate } from "react-router-dom";
import { RiArrowGoBackFill } from "react-icons/ri";
const NavigateBack = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        padding: "1rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <RiArrowGoBackFill
        size={"2rem"}
        className="iconHover"
        onClick={() => navigate(-1)}
        color={"black"}
      />
    </div>
  );
};

export default NavigateBack;
