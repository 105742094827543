import ErrorMsg from "./ErrorMsg";
import ExplainContext from "./ExplainContext";
import Header from "./Header";
import Loading from "./Loading";

const CommonPageWithHeader = ({
  children,
  error,
  loading,
  hide,
  explain,
  type,
  leftNum,
}: any) => {
  // useEffect(() => {
  //   if (error) {
  //     return alert(error);
  //   }
  // }, [error]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="homeContainer">
          <Header isHome={false} hide={hide} />
          {error && (
            <div
              style={{
                width: "100vw",
                padding: "5%",
                display: "flex",
              }}
            >
              {" "}
              <ErrorMsg error={error} />
            </div>
          )}
          {explain && (
            <div
              style={{
                width: "100vw",
                padding: "5%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <ExplainContext type={type} leftNum={leftNum} />
            </div>
          )}
          {children}
        </div>
      )}
    </>
  );
};

export default CommonPageWithHeader;
