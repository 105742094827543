import { Link } from "react-router-dom";
import OnlineIcon from "./OnlineIcon";
import RewardPunishBtn from "./RewardPunishBtn";
import getAvailableToWho from "../../utils/getAvailableToWho";
import getStoreType from "../../utils/getStoreType";
import formatJoinDate from "../../utils/formatJoinDate";
import { useTranslation } from "react-i18next";

interface Props {
  _id?: string;
  name?: string;
  icon: string;
  image?: string;
  rewardContent?: string;
  rewardPointsRequirement?: string;
  type: string;
  productType?: string;
  availableForWho: string;
  platformName?: string;
  platformLink?: string;
  userType?: string;
  minAge?: number;
  maxAge?: number;
  rewardedAmount?: string;
  rewardDateTill?: string;
  good?: number;
  neutral?: number;
  negative?: number;
}

const StoreItem = ({
  _id,
  name,
  icon,
  image,
  rewardContent,
  rewardPointsRequirement,
  type,
  productType,
  availableForWho,
  platformName,
  rewardedAmount,
  rewardDateTill,
  good,
  neutral,
  negative,
  minAge,
  maxAge,
}: Props) => {
  const imgBaseUrl = import.meta.env.VITE_IMG_URL;
  const { t } = useTranslation();

  return (
    <div className="storeInnerItemContainer">
      {/**Logo */}

      <img src={`${imgBaseUrl}${icon}`} className="storeItemListLogo" />

      {/**image */}
      <div className="storeItemListImg">
        <img src={`${imgBaseUrl}${image}`} className="storeListImg" />
      </div>
      {/**content */}
      <div className="storeListItemContent">
        {/**Company's name */}
        <div className="storeItemCompanyNameContainer">
          <p className="storeListItemTitle" style={{ marginBottom: "0rem" }}>
            {name}
          </p>
          <img src={`${imgBaseUrl}${icon}`} className="logoInSmallScreen" />
        </div>
        {/**reward requirement*/}
        <div
          className="rewardPunishmentContainerStoreListItemContainer"
          style={{ marginBottom: "1rem" }}
        >
          {/* 申请条件： */}
          {t("apply_condition_score")}:
          <RewardPunishBtn type="reward" number={rewardPointsRequirement} />
        </div>
        {/**rewarded */}
        <div className="storeListItemDivContainer">
          <p>
            {/* 已奖励人数 */}
            {t("rewarded_amount")}： {rewardedAmount}
          </p>
        </div>

        {/**to whom */}
        <div className="storeListItemDivContainer">
          <p>
            {/* 适用对象 */}
            {t("available_to")}： {t(getAvailableToWho(availableForWho))}
          </p>
        </div>

        {/**用户年龄 */}
        <div className="storeListItemDivContainer">
          <p>
            {/* 适用对象 */}
            {t("suitable_age_range")}：{minAge}-{maxAge}
          </p>
        </div>

        {/**user's experience */}

        <div className="storeListItemDivContainer">
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            {/* 所有用户体验 */}
            {t("user_experience")}：
            <span className="userExperienceContainer">
              <img src="/images/good.png" className="smallIcons" />
              {good}
            </span>
            <span className="userExperienceContainer">
              <img src="/images/neutral.png" className="smallIcons" />
              {neutral}
            </span>
            <span className="userExperienceContainer">
              <img src="/images/negative.png" className="smallIcons" />
              {negative}
            </span>
          </div>
        </div>

        {/**type */}
        <div className="storeListItemDivContainer">
          <p>
            {/* 商店类型 */}
            {t("store_type_short")}： {t(getStoreType(type))}
          </p>
        </div>
        {/**sell product */}
        <div className="storeListItemDivContainer">
          <p>
            {/* 主营产品 */}
            {t("product_type")}：{t(`${productType}`)}
          </p>
        </div>

        {/**reward */}
        <div className="storeListItemDivContainer">
          <p>
            {/* 奖励内容 */}
            {t("reward_content_short")}： {rewardContent}
          </p>
        </div>

        {/**region */}

        <div className="storeListItemDivContainer">
          <p>
            {/* 有效期至 */}
            {t("valid_till")}： {formatJoinDate(rewardDateTill!)}
          </p>
        </div>
        {/**online stores */}
        {platformName && type !== "physical" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* 适用网店 */}
            {t("online_platform")}
            ：
            <OnlineIcon type={platformName} />
          </div>
        )}

        {platformName && type === "physical" && (
          <div className="storeListItemDivContainer">
            {/* 适用网店 */}
            {t("online_platform")}： /
          </div>
        )}

        <div style={{ marginTop: "2.5rem", marginBottom: "1rem" }}>
          <Link
            to={"/store-details"}
            state={{ _id: _id }}
            style={{
              textDecoration: "none",
              color: "white",
              backgroundColor: "#5f7fff",
              padding: "1rem 4rem",
              borderRadius: "1rem",
              textAlign: "center",
            }}
          >
            {t("view_btn")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StoreItem;
