import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import { AiOutlineScan } from "react-icons/ai";
import { PiGearFill } from "react-icons/pi";
import { useEffect, useState } from "react";
import { BsClockHistory } from "react-icons/bs";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { ApplyRewardHistory, Store, storeAtom } from "../../datas/atoms";
import AuthService from "../../services/service";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { AiOutlineCloseCircle } from "react-icons/ai";

import RewardPunishBtn from "../../components/store/RewardPunishBtn";
import OnlineIcon from "../../components/store/OnlineIcon";
import getAvailableToWho from "../../utils/getAvailableToWho";
import getStoreType from "../../utils/getStoreType";
import ApplyHistory from "../../components/store/ApplyHistory";
import { useTranslation } from "react-i18next";
import formatJoinDate from "../../utils/formatJoinDate";
import Loading from "../../components/Loading";
import { Link } from "react-router-dom";
import ImportantNotice from "../../components/store/ImportantNotice";
import ScanProduct from "../../components/store/ScanProduct";
import { FaCircleCheck } from "react-icons/fa6";
import { RiErrorWarningFill } from "react-icons/ri";
import { FaCircleMinus } from "react-icons/fa6";

interface Props {
  storeItem: Store;
}

const StoreOwnerProfile = ({ storeItem }: Props) => {
  const { t } = useTranslation();
  const [selectedPaymentType, setSelectedPaymentType] = useState("year"); // month , year,life

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorReason, setErrorReason] = useState("");
  const [showSelectOption, setShowSelectOption] = useState(false);
  const [stopSubscriptionDate, setStopSubscriptionDate] = useState("");
  const [blocked, setBlocked] = useState(false);
  const [store, setStore] = useAtom(storeAtom);
  const [mode, setMode] = useState("");
  // const [showUpdateWarning, setShowUpdateWarning] = useState(false);
  const [cancelAcountWarning, setCancelAccountWarning] = useState(false);
  const [activeTab, setActiveTab] = useState("all"); // 初始选中的选项
  const [allData, setAllData] = useState<Store>();
  const [totalReward, setTotalReward] = useState<ApplyRewardHistory[]>();
  const [popupNotice, setPopNotice] = useState(false);

  // scan popup
  const [scanPop, setScanPop] = useState(false);

  const navigate = useNavigate();

  const currentDate = new Date();

  useEffect(() => {
    if (!blocked) {
      initData();
    }
  }, []);

  const initData = () => {
    AuthService.getCurrentStoreUser()
      .then(({ data }) => {
        const { storeData } = data;
        if (data.storeData.rewardList.length > 0) {
          setPopNotice(true);
        }
        setAllData(data.storeData);
        setTotalReward(data.storeData.rewardList);
        console.log("*********现在的storeData:", data.storeData);

        // if (storeData.rewardValidTill < new Date()) {
        //   setShowUpdateWarning(true);
        // }
        if (storeData.stopSubscriptionDate !== null) {
          setStopSubscriptionDate(storeData.stopSubscriptionDate);
        }
        setBlocked(storeData.blocked);

        console.log("现在的storeData:", storeData.getMode);

        if (storeData.getMode == "not-subscribed") {
          setMode("not-subscribed");
        } else if (storeData.getMode == "fail-subscribe") {
          setMode("fail-subscribe");
        } else if (storeData.getMode == "all-good") {
          setMode("all-good");
        } else if (storeData.getMode == "all-good-withdrawed") {
          setMode("all-good-withdrawed");
        } else {
          setMode("");
        }

        setStore({
          name: storeData.name,
          icon: storeData.icon,
          userType: storeData.userType,
        });
        //check planHistory's payWay ,if payWay==='month' =>setSubType("8€/month")

        if (storeData.statement === "fail") {
          setErrorReason(storeData.verifyFailedReson);
        }
        console.log("现在的store是：", store === undefined);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error：", error.response.data.message);
        setError(error);
        setLoading(false);
      });
  };

  const handleOptionChange = (event: any) => {
    setSelectedPaymentType(event.target.value);
    console.log("现在选择：", event.target.value);
  };

  const handleProcessPayment = () => {
    setLoading(true);
    AuthService.storeCreateCheckoutSession(selectedPaymentType)
      .then(({ data }) => {
        window.location.replace(data.url);
      })
      .catch((e) => setError(e));
  };

  const updateData = () => {
    AuthService.getCurrentStoreUser()
      .then(({ data }) => {
        const { storeData } = data;

        setAllData(data.storeData);
        setTotalReward(data.storeData.rewardList);
        console.log("*********现在的storeData:", data.storeData);

        // if (storeData.rewardValidTill < new Date()) {
        //   setShowUpdateWarning(true);
        // }
        if (storeData.stopSubscriptionDate !== null) {
          setStopSubscriptionDate(storeData.stopSubscriptionDate);
        }
        setBlocked(storeData.blocked);

        console.log("现在的storeData:", storeData.getMode);

        if (storeData.getMode == "not-subscribed") {
          setMode("not-subscribed");
        } else if (storeData.getMode == "fail-subscribe") {
          setMode("fail-subscribe");
        } else if (storeData.getMode == "all-good") {
          setMode("all-good");
        } else if (storeData.getMode == "all-good-withdrawed") {
          setMode("all-good-withdrawed");
        } else {
          setMode("");
        }

        setStore({
          name: storeData.name,
          icon: storeData.icon,
          userType: storeData.userType,
        });
        //check planHistory's payWay ,if payWay==='month' =>setSubType("8€/month")

        if (storeData.statement === "fail") {
          setErrorReason(storeData.verifyFailedReson);
        }
        console.log("现在的store是：", store === undefined);
        setPopNotice(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error：", error.response.data.message);
        setError(error);
        setLoading(false);
      });
  };

  const onPressStoreReward = (value: string) => {
    console.log("现在store用户点击的是：", value);
    setLoading(true);
    AuthService.approveReward(value)
      .then(() => {
        updateData();
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  const cancelUserAcount = () => {
    setLoading(true);
    AuthService.cancelAccount()
      .then(() => {
        //这里需要马上获取用户资料，并且把头像和和一切都放在user那里
        // initData();
        clearUserInfo();
        setLoading(false);
        setCancelAccountWarning(false);
      })
      .catch((error) => {
        setLoading(false);

        // 处理错误，例如显示错误消息
        return setError(error.response.data.message);
      });
  };

  const clearUserInfo = () => {
    setStore({
      name: "",
      icon: "",
      userType: "",
    });
    localStorage.removeItem("storeUser");
    window.location.href = "/";
  };

  return (
    <>
      {loading && <Loading />}
      {!blocked && allData && (
        <CommonPageWithHeader loading={loading} error={error}>
          {popupNotice && (
            <ImportantNotice onPressCloseNotice={() => setPopNotice(false)} />
          )}
          {/**完全成功 */}
          {(mode === "all-good" || mode === "all-good-withdrawed") &&
            storeItem.name && (
              <>
                {/**popup */}
                {showSelectOption && (
                  <div className="storeOwenProfilePopupContainer">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <p
                        className="iconHover"
                        onClick={() => setShowSelectOption(false)}
                      >
                        X
                      </p>
                    </div>
                    {/**点击可以更改订阅 */}
                    <p
                      className="iconHover"
                      onClick={() => {
                        setShowSelectOption(false);
                        navigate("/store-edit-profile/subscription-statement");
                      }}
                    >
                      {/* 续费状态 */}
                      {t("sub_state")}
                    </p>
                    <p
                      className="iconHover"
                      onClick={() => {
                        setShowSelectOption(false);
                        navigate("/store-edit-profile");
                      }}
                    >
                      {/* 修改资料 */}
                      {t("edit_profile")}
                    </p>
                    <p
                      className="iconHover"
                      onClick={() => {
                        setShowSelectOption(false);
                        navigate("/store-statement");
                      }}
                    >
                      {/* 账户状态 */}
                      {t("account_state")}
                    </p>
                    <p
                      className="iconHover"
                      onClick={() => setCancelAccountWarning(true)}
                    >
                      {/* 注销账户 */}
                      {t("cancel_account")}
                    </p>
                  </div>
                )}
                {/** sanner  + gear +userIcon*/}
                <div className="storeOwnerProfileHistoryTopBarContainer ">
                  {/**sanner icon */}

                  <AiOutlineScan
                    size={"2.8rem"}
                    className="iconHover"
                    onClick={() => setScanPop(true)}
                  />

                  {/** search bar */}
                  <Link
                    to={"/store-profile/search-reward"}
                    className="innerStoreBardContainer iconHover"
                    style={{ textDecoration: "none", color: "gray" }}
                  >
                    {/**input */}
                    {/* 请输入ID... */}
                    {t("please_enter_id_search_bar")}
                    {/**search button */}
                    <button
                      type="button"
                      className="btn btn-warning storeSearchBtn"
                    >
                      {/* 查询 */}
                      {t("search")}
                    </button>
                  </Link>
                  {/**gear icon */}
                  <div className="iconWithTip">
                    <PiGearFill
                      size={"2.5rem"}
                      className="iconHover"
                      onClick={() => setShowSelectOption(true)}
                    />
                    <div>{t("setting")}</div>
                  </div>

                  <img
                    src={`${import.meta.env.VITE_IMG_URL}${allData?.icon}`}
                    className="smallIconStore"
                    onClick={() => {
                      navigate("/store-edit-profile");
                    }}
                  />
                </div>
                {/**main container:userself + list */}
                <div className="storeProfile_container">
                  {/**user info */}
                  <div className="storeProfile_box1">
                    {/**Image */}
                    <img
                      src={`${import.meta.env.VITE_IMG_URL}${allData?.icon}`}
                      className="storeLogo"
                    />
                    <p className="userName">{allData.name}</p>
                    {mode === "all-good" && allData.activeMode && (
                      <div
                        style={{
                          display: "flex",
                          padding: "1rem",
                          alignItems: "center",
                          backgroundColor: "	#F5F5DC",
                          width: "100%",
                          justifyContent: "center",
                          borderRadius: "1rem",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        {/* 正在奖励商城展示中...  */}
                        {t("displaying_tip")}
                        <FaCircleCheck color={"green"} />
                      </div>
                    )}

                    {mode === "all-good-withdrawed" && (
                      <div
                        style={{
                          display: "flex",
                          padding: "1rem",
                          alignItems: "center",
                          backgroundColor: "	#F5F5DC",
                          width: "100%",
                          justifyContent: "center",
                          borderRadius: "1rem",
                        }}
                      >
                        {" "}
                        {/* 已从奖励商城中撤下! */}
                        {t("remove_display_tip")}
                        <RiErrorWarningFill
                          color={"red"}
                          style={{ marginLeft: "1rem" }}
                        />
                      </div>
                    )}

                    {!allData.rewardValid && (
                      <div
                        style={{
                          display: "flex",
                          padding: "2rem",
                          alignItems: "center",
                          backgroundColor: "	#F5F5DC",
                          width: "100%",
                          justifyContent: "center",
                          borderRadius: "1rem",
                          textAlign: "center",
                        }}
                        className="iconHover"
                        onClick={() => navigate("/store-edit-profile")}
                      >
                        {" "}
                        {/* 您的奖励已过期，已不再奖励商城中展示,请及时调整奖励内容或者延期奖励有效期... */}
                        {t("reward_expired_tip")}{" "}
                        <FaCircleMinus
                          color={"red"}
                          style={{ marginLeft: "1.5rem" }}
                          size={"2rem"}
                        />
                      </div>
                    )}

                    <div className="innerStoreDetailsApplyCondition">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          borderTop: "1px solid #f5eceb",
                          marginTop: "1rem",
                          paddingTop: "2rem",
                        }}
                      >
                        {/* 申请条件 */}
                        {t("apply_condition_score")}
                        ：
                        <RewardPunishBtn
                          type="reward"
                          number={allData.rewardPointsRequirement}
                        />
                      </div>
                      {/**rewarded */}
                      <div className="storeListItemDivContainer">
                        <p>
                          {/* 已奖励人数 */}
                          {t("rewarded_amount")}： {allData.rewardedAmount}
                        </p>
                      </div>

                      {/**user's experience */}
                      <div className="storeListItemDivContainer">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          {/* 所有用户体验 */}
                          {t("user_experience")}：
                          <span className="userExperienceContainer">
                            <img
                              src="/images/good.png"
                              className="smallIcons"
                            />
                            {allData.good}
                          </span>
                          <span className="userExperienceContainer">
                            <img
                              src="/images/neutral.png"
                              className="smallIcons"
                            />
                            {allData.neutral}
                          </span>
                          <span className="userExperienceContainer">
                            <img
                              src="/images/negative.png"
                              className="smallIcons"
                            />
                            {allData.negative}
                          </span>
                        </div>
                      </div>
                      {/**用户年龄 */}
                      <div className="storeListItemDivContainer">
                        <p>
                          {t("suitable_age_range")}：{allData.minAge}-
                          {allData.maxAge}
                        </p>
                      </div>

                      {/**to whom */}
                      <div className="storeListItemDivContainer">
                        <p>
                          {/* 适用对象 */}
                          {t("available_to")}：{" "}
                          {t(getAvailableToWho(allData!.availableForWho!))}
                        </p>
                      </div>
                      {/**type */}
                      <div className="storeListItemDivContainer">
                        <p>
                          {/* 商店类型 */}
                          {t("store_type_short")}：{" "}
                          {t(getStoreType(allData!.type!))}
                        </p>
                      </div>
                      {/**sell product */}
                      <div className="storeListItemDivContainer">
                        <p>
                          {/* 主营产品 */}
                          {t("product_type")}：{t(allData!.productType!)}
                        </p>
                      </div>
                      {/**reward */}
                      <div
                        className="storeListItemDivContainer"
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <p>
                          {/* 奖励内容 */}
                          {t("reward_content_short")}：
                        </p>
                        <p
                          style={{
                            lineHeight: "1.8",
                            padding:
                              allData.rewardContent!.length > 35
                                ? "0.1rem"
                                : "0rem",
                          }}
                        >
                          {" "}
                          {allData.rewardContent}
                        </p>
                      </div>

                      {/**valid date */}
                      <div className="storeListItemDivContainer">
                        <p>
                          {/* 有效期至 */}
                          {t("valid_date")}：
                          {formatJoinDate(allData!.rewardValidTill!)}
                        </p>
                      </div>

                      <div
                        className="iconHover"
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                        onClick={() => {
                          if (allData && allData.platformLink) {
                            window.open(
                              `http://${allData.platformLink}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        {" "}
                        {/* 适用网店 */}
                        {t("online_platform")}
                        ： <OnlineIcon type={allData!.platformName!} />
                      </div>

                      <img
                        src={`${import.meta.env.VITE_IMG_URL}${allData?.image}`}
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "1REM",
                        }}
                      />

                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginTop: "2rem", padding: "1rem" }}
                        onClick={() => {
                          setShowSelectOption(false);
                          navigate("/store-edit-profile");
                        }}
                      >
                        {/* 修改资料 */}
                        {t("edit_profile")}
                      </button>
                    </div>
                  </div>

                  {/**list*/}
                  <div className="storeProfile_box2">
                    <div>
                      <p
                        style={{
                          backgroundColor: "	#F5F5F5",
                          padding: "0.5rem 3rem",
                          borderRadius: "1rem",
                          lineHeight: "1.6",
                        }}
                      >
                        -{t("users_apply_records")}-
                      </p>
                    </div>
                    <div className="selector">
                      <div
                        className={`item ${
                          activeTab === "all" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("all")}
                      >
                        {t("all")}({totalReward?.length})
                      </div>
                      <div
                        className={`item ${
                          activeTab === "rewarded" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("rewarded")}
                      >
                        {t("rewarded")}(
                        {totalReward?.filter((m) => m.approved).length})
                      </div>
                      <div
                        className={`item ${
                          activeTab === "pending" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("pending")}
                      >
                        {t("awaiting_reward")}(
                        {
                          totalReward?.filter(
                            (m) =>
                              m.approved === false &&
                              m.time >= currentDate.toISOString()
                          ).length
                        }
                        )
                      </div>
                      <div
                        className={`item ${
                          activeTab === "expired" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("expired")}
                      >
                        {t("expired_accounts")}(
                        {
                          totalReward!.filter(
                            (m) => new Date(m.time) < new Date()
                          ).length
                        }
                        )
                      </div>
                    </div>
                    <div className="content" style={{ width: "100%" }}>
                      {/**全部 */}
                      <div
                        className={`tab-content ${
                          activeTab === "all" ? "active" : ""
                        }`}
                      >
                        {totalReward!.length > 0 && (
                          <ol style={{ width: "100%" }}>
                            {totalReward!.map(
                              (item: ApplyRewardHistory, index: number) => (
                                <li key={index}>
                                  <ApplyHistory
                                    type={item.type}
                                    rewardId={item._id}
                                    userType={item.userType}
                                    time={item.time}
                                    approved={item.approved}
                                    owner={"store"}
                                    onPressStoreReward={onPressStoreReward}
                                    approvedTime={item.approvedTime}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        )}

                        {/**no record yet */}
                        {totalReward!.length === 0 && (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {t("no_record_yet")}~
                          </div>
                        )}
                      </div>

                      {/**已奖励 */}
                      <div
                        className={`tab-content ${
                          activeTab === "rewarded" ? "active" : ""
                        }`}
                      >
                        {totalReward!.length > 0 && (
                          <ol style={{ width: "100%" }}>
                            {totalReward
                              ?.filter((m) => m.approved)
                              .map(
                                (item: ApplyRewardHistory, index: number) => (
                                  <li key={index}>
                                    <ApplyHistory
                                      type={item.type}
                                      rewardId={item._id}
                                      userType={item.userType}
                                      time={item.time}
                                      approved={item.approved}
                                      owner={"store"}
                                      approvedTime={item.approvedTime}
                                    />
                                  </li>
                                )
                              )}
                          </ol>
                        )}
                        {/**no record yet */}
                        {totalReward!.length > 0 &&
                          totalReward?.filter((m) => m.approved).length ===
                            0 && (
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {t("no_record_yet")}~
                            </div>
                          )}
                      </div>

                      {/*待奖励*/}
                      <div
                        className={`tab-content ${
                          activeTab === "pending" ? "active" : ""
                        }`}
                      >
                        {totalReward!.length > 0 && (
                          <ol style={{ width: "100%" }}>
                            {totalReward
                              ?.filter((m) => m.approved === false)
                              .map(
                                (item: ApplyRewardHistory, index: number) => (
                                  <li key={index}>
                                    <ApplyHistory
                                      type={item.type}
                                      rewardId={item._id}
                                      userType={item.userType}
                                      time={item.time}
                                      approved={item.approved}
                                      owner={"store"}
                                      onPressStoreReward={onPressStoreReward}
                                      approvedTime={item.approvedTime}
                                    />
                                  </li>
                                )
                              )}
                          </ol>
                        )}

                        {/**no record yet */}
                        {totalReward!.length > 0 &&
                          totalReward?.filter((m) => m.approved === false)
                            .length === 0 && (
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {t("no_record_yet")}~
                            </div>
                          )}
                      </div>

                      {/**已过期 */}
                      <div
                        className={`tab-content ${
                          activeTab === "expired" ? "active" : ""
                        }`}
                      >
                        {totalReward!.length > 0 && (
                          <ol style={{ width: "100%" }}>
                            {totalReward
                              ?.filter((m) => new Date(m.time) < new Date())
                              .map(
                                (item: ApplyRewardHistory, index: number) => (
                                  <li key={index}>
                                    <ApplyHistory
                                      type={item.type}
                                      rewardId={item._id}
                                      userType={item.userType}
                                      time={item.time}
                                      approved={item.approved}
                                      owner={"store"}
                                      approvedTime={item.approvedTime}
                                    />
                                  </li>
                                )
                              )}
                          </ol>
                        )}

                        {/**no record yet */}
                        {totalReward!.length > 0 &&
                          totalReward?.filter(
                            (m) => new Date(m.time) < new Date()
                          ).length === 0 && (
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {t("no_record_yet")}~
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

          {mode === "not-subscribed" && !stopSubscriptionDate && (
            <div className="storeContainer">
              <h1 className="registerTitleH1">
                {t("store_register_title")}(4/5)
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "3rem",
                  textAlign: "center",
                  flexWrap: "wrap",
                }}
              >
                <AiFillCheckCircle size={"3rem"} color={"green"} />{" "}
                <p style={{ lineHeight: "1.5", margin: "1.5rem" }}>
                  {" "}
                  {/* 恭喜你成功通过验证！ */}
                  {t("success_verify_tip")}
                </p>
                <p>
                  {/* 请完成最后一步，选择您的订阅方式后，正式加入奖励商店榜 */}
                  {t("finish_last_step")}:
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <label>
                  <input
                    type="radio"
                    value="month"
                    checked={selectedPaymentType === "month"}
                    onChange={handleOptionChange}
                    className="radionStyle"
                  />
                  €8/月
                </label> */}

                <label>
                  <input
                    type="radio"
                    value="year"
                    checked={selectedPaymentType === "year"}
                    onChange={handleOptionChange}
                    className="radionStyle"
                  />
                  €{import.meta.env.VITE_STORE_YEAR}/{t("pay_by_year")}
                </label>

                <label>
                  <input
                    type="radio"
                    value="life"
                    checked={selectedPaymentType === "life"}
                    onChange={handleOptionChange}
                    className="radionStyle"
                  />
                  €{import.meta.env.VITE_STORE_LIFE}/{t("pay_by_life")}
                </label>
              </div>
              {/**Submit button */}
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginTop: "3rem", padding: "1rem 3rem" }}
                onClick={handleProcessPayment}
              >
                {/* 去付款 */}
                {t("process_payment")}
              </button>
            </div>
          )}

          {/**继续完成订阅 */}
          {mode === "not-subscribed" && stopSubscriptionDate && (
            <div className="storeContainer">
              <h1 className="registerTitleH1">
                {/* 再次订阅 */}
                {t("re_subscribe")}
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "3rem",
                  textAlign: "center",
                  flexWrap: "wrap",
                }}
              >
                <p>
                  {/* 请完成最后一步，选择您的订阅方式后，再次加入奖励商店榜 */}
                  {t("finish_last_step")}:
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <label>
                  <input
                    type="radio"
                    value="month"
                    checked={selectedPaymentType === "month"}
                    onChange={handleOptionChange}
                    className="radionStyle"
                  />
                  €8/月
                </label> */}

                <label>
                  <input
                    type="radio"
                    value="year"
                    checked={selectedPaymentType === "year"}
                    onChange={handleOptionChange}
                    className="radionStyle"
                  />
                  €{import.meta.env.VITE_STORE_YEAR}/{t("pay_by_year")}
                </label>

                <label>
                  <input
                    type="radio"
                    value="life"
                    checked={selectedPaymentType === "life"}
                    onChange={handleOptionChange}
                    className="radionStyle"
                  />
                  €{import.meta.env.VITE_STORE_LIFE}/{t("pay_by_life")}
                </label>
              </div>
              {/**Submit button */}
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginTop: "3rem", padding: "1rem 3rem" }}
                onClick={handleProcessPayment}
              >
                {/* 去付款 */}
                {t("process_payment")}
              </button>
            </div>
          )}

          {/**验证失败 */}
          {mode === "fail-subscribe" && (
            <>
              <div className="storeContainer">
                <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
                  {/* 申请失败！ */}
                  {t("failed_apply")}
                </p>
                <p>
                  {/* 原因 */}
                  {t("reason")}：{errorReason}
                </p>
                <AiFillCloseCircle
                  size={"5rem"}
                  color={"red"}
                  style={{ marginTop: "2rem" }}
                />
              </div>
            </>
          )}

          {mode === "" && (
            <div className="storeContainer">
              {/* 验证中..(等待人工验证，1-15个工作日，验证通过后，您将可以进入下一步操作) */}
              {t("awaiting_verify_store_notice")}
              <BsClockHistory
                size={"5rem"}
                color={"green"}
                style={{ marginTop: "2rem" }}
              />
            </div>
          )}
          {/* 
          {showUpdateWarning && (
            <div className="addHeroComponent">
              {" "}
              <div className="addEditHeroContainer">
                <p style={{ fontSize: "1.5rem" }}>
                  你设置的奖励的有效期已过期，请前往更新
                </p>
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ padding: "1rem 3rem", marginTop: "2rem" }}
                  onClick={() => {
                    setShowUpdateWarning(false);
                    navigate("/store-edit-profile");
                  }}
                >
                  去更新
                </button>
              </div>
              <AiOutlineCloseCircle
                size={"3rem"}
                color={"white"}
                style={{ marginTop: "1rem" }}
                onClick={() => setShowUpdateWarning(false)}
                className="iconHover"
              />
            </div>
          )} */}

          {cancelAcountWarning && (
            <div className="addHeroComponent">
              <div className="addEditHeroContainer">
                <p>
                  {/* 注销账号之后所有资料将无法恢复，你确定要注销吗？ */}
                  {t("canceled_not_allowed_restore")}
                </p>

                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ marginTop: "2rem", padding: "1rem 3rem" }}
                  onClick={cancelUserAcount}
                >
                  {/* 确认注销账户 */}
                  {t("confirm_cancel_account")}
                </button>
              </div>
              <AiOutlineCloseCircle
                size={"3rem"}
                color={"white"}
                style={{ marginTop: "1rem" }}
                onClick={() => setCancelAccountWarning(false)}
                className="iconHover"
              />
            </div>
          )}

          {scanPop && (
            <div className="addHeroComponent">
              <div className="addEditHeroContainer">
                <ScanProduct />
              </div>
              <AiOutlineCloseCircle
                size={"3rem"}
                color={"white"}
                style={{ marginTop: "1rem" }}
                onClick={() => {
                  initData();
                  setScanPop(false);
                }}
                className="iconHover"
              />
            </div>
          )}
        </CommonPageWithHeader>
      )}

      {blocked && (
        <div className="storeContainer">
          <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
            {/* 你的账户被封锁！ */}
            {t("your_account_blocked")}
          </p>

          <AiFillCloseCircle
            size={"5rem"}
            color={"red"}
            style={{ marginTop: "2rem" }}
          />
        </div>
      )}
    </>
  );
};

export default StoreOwnerProfile;
