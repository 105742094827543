import { useNavigate } from "react-router-dom";
import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import { RiArrowGoBackFill } from "react-icons/ri";
import RewardPunishBtn from "../../components/store/RewardPunishBtn";
import StoreTypeWithIcon from "./StoreTypeWithIcon";
import OnlineIcon from "../../components/store/OnlineIcon";
import { useState, useEffect } from "react";
import PopupPlatformSelector from "../../components/store/PopupPlatformSelector";
// import ApplyHistory from "../../components/store/ApplyHistory";
import AuthService from "../../services/service";
import { useLocation } from "react-router-dom";
import { Store } from "../../datas/atoms";
import getAvailableToWho from "../../utils/getAvailableToWho";
import getStoreType from "../../utils/getStoreType";
import getUserType from "../../utils/getUserType";
import formatJoinDate from "../../utils/formatJoinDate";
import { FaTimesCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const StoreDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { _id } = location.state;
  const [popupPlatformSelector, setPopupPlatformSelector] = useState(false);

  //store detail
  const [storeDetail, setStoreDetail] = useState<Store | undefined>();

  //control popup statement
  const [popupType, setPopupType] = useState("");
  const [userTypeNow, setUserTypeNow] = useState("");
  const [popupStatement, setPopupStatement] = useState("checking"); //success ;fail;checking
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [rewardId, setRewardId] = useState("");
  // const [rewardData, setRewardData] = useState([]);
  const iconImg = `${import.meta.env.VITE_IMG_URL}${storeDetail?.icon}`;
  const coverImg = `${import.meta.env.VITE_IMG_URL}${storeDetail?.image}`;
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("此时收到_id:", _id);
    initData();
  }, []);

  const initData = () => {
    setLoading(true);
    getStoreDetail();
    getUserRelativeRewardList();
  };

  const getStoreDetail = () => {
    const user = getUserType();
    setUserTypeNow(user);
    AuthService.getOneStoreDetail(_id)
      .then(({ data }) => {
        if (data.storeItem.activeMode) {
          setStoreDetail(data.storeItem);
        } else {
          setError("错误");
        }

        // setAvailableFor(getAvailableToWho(data.storeItem.availableForWho));
        // setStoreType(getStoreType(data.storeItem.type));

        setLoading(false);
        console.log("现在error:", error);
      })
      .catch((e) => {
        setError(e.response.data.message);
        setLoading(false);
      });
  };

  const getUserRelativeRewardList = () => {
    setLoading(true);
    // if (userTypeNow === "student") {
    //   AuthService.getRelativeRewardedList(_id)
    //     .then(({ data }) => {
    //       setRewardData(data.data);
    //       setLoading(false);
    //     })
    //     .catch((e) => {
    //       setError(e);
    //       setLoading(false);
    //     });
    // }
  };
  // const onPressChoosePlatform = (type: string) => {
  //   setSelectedOnlinePlatform(type);
  //   setPopupStatement("success");
  // };

  const resetStatement = () => {
    setPopupType("");
    setPopupStatement("unCheck");
  };

  const onPressOnlineApply = () => {
    setPopupType("online");
    setPopupStatement("checking");
    setPopupPlatformSelector(true);
    AuthService.createApplyReward(userTypeNow, _id)
      .then(({ data }) => {
        console.log("获取到的data:", data);
        setRewardId(data.rewardId);
        setPopupStatement("success");
      })
      .catch((e) => {
        console.log(e);
        setPopupPlatformSelector(true);
        setPopupStatement("fail");
      });
  };

  // setPopupType("physical");
  //   setPopupPlatformSelector(true);

  const onPressPhysicalStore = () => {
    setPopupType("physical");
    setPopupStatement("checking");
    setPopupPlatformSelector(true);
    AuthService.createApplyReward(userTypeNow, _id)
      .then(({ data }) => {
        console.log("获取到的data:", data);
        setRewardId(data.rewardId);
        setPopupStatement("success");
      })
      .catch((e) => {
        console.log(e);
        setPopupPlatformSelector(true);
        setPopupStatement("fail");
      });
  };

  return (
    <CommonPageWithHeader loading={loading}>
      {/**back icon */}
      <RiArrowGoBackFill
        className="backIcon"
        color={"black"}
        size={"1.5rem"}
        onClick={() => navigate(-1)}
        style={{ marginTop: "2rem" }}
      />
      {storeDetail && (
        <>
          <div className="storeDetailsContainer">
            {/**Logo */}
            <img src={iconImg} className="storeDetailsLogo" />
            {/**Store Name */}
            <p className="storeDetailsCompanyName">{storeDetail!.name}</p>
            {/**Store 's intro */}
            <p className="companyIntro">{storeDetail!.bio}</p>
            {/**detail image */}
            <img src={coverImg} className="storeDetailImage" />
            {/**Apply condition */}
            <div className="storeDetailsApplyCondition">
              {/**detail image */}

              <div className="innerStoreDetailsApplyCondition">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  {/* 申请条件 */}
                  {t("apply_condition_score")}
                  ：
                  <RewardPunishBtn
                    type="reward"
                    number={storeDetail!.rewardPointsRequirement}
                  />
                </div>
                {/**rewarded */}
                <div className="storeListItemDivContainer">
                  <p>
                    {/* 已奖励人数 */}
                    {t("rewarded_amount")}： {storeDetail!.rewardedAmount}
                  </p>
                </div>

                {/**to whom */}
                <div className="storeListItemDivContainer">
                  <p>
                    {/* 适用对象 */}
                    {t("available_to")}：{" "}
                    {t(getAvailableToWho(storeDetail!.availableForWho!))}
                  </p>
                </div>

                {/**用户年龄 */}
                <div className="storeListItemDivContainer">
                  <p>
                    {t("suitable_age_range")}：{storeDetail!.minAge}-
                    {storeDetail!.maxAge}
                  </p>
                </div>

                {/**user experience */}
                <div className="storeListItemDivContainer">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    {/* 所有用户体验 */}
                    {t("user_experience")}：
                    <span className="userExperienceContainer">
                      <img src="/images/good.png" className="smallIcons" />
                      {storeDetail!.good}
                    </span>
                    <span className="userExperienceContainer">
                      <img src="/images/neutral.png" className="smallIcons" />
                      {storeDetail!.neutral}
                    </span>
                    <span className="userExperienceContainer">
                      <img src="/images/negative.png" className="smallIcons" />
                      {storeDetail!.negative}
                    </span>
                  </div>
                </div>

                {/**type */}
                <div className="storeListItemDivContainer">
                  <p>
                    {/* 商店类型 */}
                    {t("store_type_short")}：{" "}
                    {t(getStoreType(storeDetail!.type!))}
                  </p>
                </div>
                {/**sell product */}
                <div className="storeListItemDivContainer">
                  <p>
                    {/* 主营产品 */}
                    {t("product_type")}： {t(storeDetail!.productType!)}
                  </p>
                </div>

                {/**reward */}
                <div className="storeListItemDivContainer">
                  <p>
                    {/* 奖励内容 */}
                    {t("reward_content_short")}： {storeDetail.rewardContent}
                  </p>
                </div>
                {/**valid date */}
                <div className="storeListItemDivContainer">
                  <p>
                    {/* 有效期至 */}
                    {t("valid_date")}：{" "}
                    {formatJoinDate(storeDetail!.rewardValidTill!)}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {/* 适用网店 */}
                  {t("online_platform")}：{" "}
                  <OnlineIcon type={storeDetail.platformName || ""} />
                </div>
              </div>
            </div>

            {/**Avaible to Whom*/}
            {/**Please choose one of the reward way title*/}
            <p
              style={{
                margin: "1rem",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              ---
              {/* 获取奖励 */}
              {t("apply_reward")}
              ---
            </p>
            {/**notice */}
            <p className="storeDetailsNotice">
              {/* 温馨提示：实体店请展示二维码。网店，请复制奖励ID，并在付款时，发送给店主，获得专属奖励 */}
              {t("qr_code_apply_tip")}
            </p>

            {/**select method */}
            <div className="selectMethodContainer">
              {/**online */}
              <StoreTypeWithIcon
                type="online"
                handleClickButton={onPressOnlineApply}
              />
              {/**physical */}
              <StoreTypeWithIcon
                type="physical"
                handleClickButton={onPressPhysicalStore}
              />
            </div>

            {/**histories */}
            {/* <p style={{ marginTop: "3rem" }}>---以下为已申请记录---</p> */}
            {/**List */}

            {/* {rewardData.length > 0 && (
              <ol style={{ width: "100%" }}>
                {rewardData.map((item: ApplyRewardHistory, index: number) => (
                  <li key={index}>
                    <ApplyHistory
                      type={item.type}
                      rewardId={item._id}
                      userType={item.userType}
                      time={item.time}
                      approved={item.approved}
                      onPressUserUseReward={onPressUserUseReward}
                    />
                  </li>
                ))}
              </ol>
            )} */}
          </div>

          {popupPlatformSelector && (
            <PopupPlatformSelector
              handleClosePopup={() => {
                resetStatement();
                setPopupPlatformSelector(false);
              }}
              type={popupType}
              statement={popupStatement}
              rewardId={rewardId}
              validDate={storeDetail!.rewardValidTill!}
            />
          )}
        </>
      )}

      {error !== "" && (
        <div className="storeDetailsContainer">
          <FaTimesCircle
            size={"6rem"}
            color={"red"}
            style={{ marginBottom: "1.5rem" }}
          />
          <p>
            {/* 该奖励已经下架 */}
            {t("reward_invalid_now")}
          </p>
        </div>
      )}
    </CommonPageWithHeader>
  );
};

export default StoreDetails;
