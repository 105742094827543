interface Props {
  schoolName?: string;
  type?: boolean; // active ; inActive
  backDate?: string;
  errorOccured?: boolean;
  onPressCancel: () => void;
  onPressConfirm: () => void;
}

import { useTranslation } from "react-i18next";
import moment from "moment";
import formatJoinDate from "../utils/formatJoinDate";

const AlertConfirmWithdraw = ({
  type,
  onPressCancel,
  onPressConfirm,
  errorOccured,
}: Props) => {
  const { t } = useTranslation();
  const now = moment();
  const threeMonthLater = now.add(3, "months").format();

  return (
    <div className="alertPop">
      {/**title */}
      <div
        style={{
          display: "flex",
          minHeight: "60%",
          width: "100%",
          justifyContent: "center",
          padding: "10%",
          alignItems: "center",
        }}
      >
        {type === true && !errorOccured && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontSize: "1.6rem", lineHeight: 2, textAlign: "center" }}
            >
              {/* 你确定要让{schoolName}暂时撤出School
            Glory吗？如果是，希望您可以顺利解决您目前面临的问题，我们期待在您解决问题后的三个月后再次见到您的回归。{" "} */}
              {t("withdraw_popup_1")}
            </p>
            <p style={{ fontWeight: "bold" }}>
              (下次可回归时间：{formatJoinDate(threeMonthLater)})
            </p>
          </div>
        )}

        {type === false && !errorOccured && (
          <p>
            {/* 恭喜您已经处理好之前发生的事情，并且在三个月内再无发生类似的事情！欢迎
            {schoolName}回归School Glory!{" "} */}
            {t("withdraw_popup_2")}
          </p>
        )}
      </div>
      {/**buttons */}
      <div
        style={{
          display: "flex",
          height: "40%",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        {/**Yes */}
        <div
          style={{
            width: "40%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={onPressConfirm}
            style={{ width: "100%", padding: "1.2rem" }}
          >
            {/* Yes */}
            {t("confirm_remove")}
          </button>
        </div>

        {/**No */}
        <div
          style={{
            width: "40%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-primary"
            style={{ color: "blue", width: "100%", padding: "1.2rem" }}
            onClick={onPressCancel}
          >
            {/* No */}
            {t("cancel_withdraw")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertConfirmWithdraw;
