import { useState } from "react";
import NavigateBack from "./common/NavigateBack";
import { useTranslation } from "react-i18next";
import getUserType from "../utils/getUserType";

interface Props {
  leftNum?: number;
  type: number;
}

const ExplainContext = ({ leftNum, type }: Props) => {
  const { t } = useTranslation();
  const [num] = useState(import.meta.env.VITE_DEFAULT_TEACHING_SFATT_AMOUNT);
  const userType = getUserType();
  // const initData = () => {
  //   AuthService.getSchoolAllowedAddTeachersAmount()
  //     .then(({ data }) => setNum(data.amount))
  //     .catch((e) => {
  //       console.log("ExplainContext出错：", e);
  //     });
  // };

  // useEffect(() => {
  //   if (type === 8) {
  //     initData();
  //   }
  // }, []);
  // const context = [
  //   "本页面解释：获取所有已经加入的学生，并且允许单独添加学生信息:学生头像，学生Email,学生班级信息，学生账号在本校的有效时,在添加完学生后，可以修改学生资料，删除学生，以及为学生添加奖励或者扣分事件。（注意：创建后，每个学生的账户的密码与Email一致，如学生用户需更改密码，需要学生用户前往登录界面->学生->忘记密码->重置密码，进行修改",
  //   "本页面解释：获取全部已经录入的惩罚事件，并且允许学校自行添加学生会被扣分的行为。",
  //   "本页面解释：获取全部已经录入的奖励事件，并且允许学校自行添加学生会被奖励的行为。",
  //   "本页面解释：获取全部学生的举报信息,请学校及时联系学生并调查清楚事实。",
  //   "本页面解释：教职人员及平台管理者可以录入允许使用邮箱注册的学生的邮箱地址",
  //   "本页面解释：教职人员及平台管理者可以录入学生可以被给予奖励的行为,可以修改与删除自行添加的行为，并且删除后将无法恢复。（建议：条件允许的情况下，学校应尽量让学生与家长参与投票再做决定）。",
  //   "本页面解释：教职人员及平台管理者可以录入学生会被扣分的行为（建议：条件允许的情况下，学校应尽量让学生与家长参与投票再做决定）。",
  //   "本页面解释：教职人员及平台管理者可以录入允许使用本平台的在校教职人员的邮箱（创建后，密码与Email一致，如需更改密码，需要教师用户前往登录界面->教师->忘记密码->重置密码，进行修改）。",
  //   "本页面解释：教职人员可以各自申请喜欢的奖励，本界面只对登录的教职人员公布，每个教职人员只能看到自己的申请历史",
  //   "本页面解释：请根据您的真实体验进行评论，您的鼓励将会让奖励商店的商家做得更好！",
  //   "请注意：当您从教职人员较多的版本切换至教职人员较少的版本时，教职人员的数据将被清空；相反，若您从教职人员较少的版本切换至教职人员较多的版本，则教职人员的数据将被保留。例如：将标准版切换至基础版本时，学校原有的教职人员数据将被清空；但若将基础版切换至标准版，则学校的教职人员数据将保留不变。",
  // ];

  const context = [
    t("add_student_page_explain"),
    t("punished_page_explain"),
    t("rewarded_page_explain"),
    t("get_all_reports_explain"),
    "本页面解释：教职人员及平台管理者可以录入允许使用邮箱注册的学生的邮箱地址",
    t("Allow_add_reward_behavior"),
    t("Allow_add_punishment_behavior"),
    t("add_teacher_page_explain"),
    t("private_teacher_explain"),
    t("comments_explain"),
    t("School_change_to_another_payment_method"),
  ];
  return (
    <div
      style={{
        width: "60%",
        zIndex: 100,
        textAlign: "center",
        fontSize: "1.2rem",
        lineHeight: 1.8,
        color: "#5f7fff",
      }}
    >
      <NavigateBack />
      {type === 1 && context[0]}
      {type === 2 && context[1]}
      {type === 3 && context[2]}
      {type === 4 && context[3]}

      {type === 5 && context[4]}
      {type === 6 && context[5]}
      {type === 7 && context[6]}
      {type === 8 && context[7]}
      {type === 9 && userType !== "student" && context[8]}

      {type === 10 && context[9]}
      {type === 11 && context[10]}
      {type === 8 && (
        <p style={{ color: "red" }}>
          {t("allow_add_teachers")}：{num - leftNum!}
        </p>
      )}
    </div>
  );
};

export default ExplainContext;
