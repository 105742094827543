import axios from "axios";
import getUserLocale from "get-user-locale";

const baseUrl = import.meta.env.VITE_BACKEND_URL;
const API_URL = `${baseUrl}/api/v1`;

const Accept_Language = getUserLocale().split("-")[0];

class AuthService {
  ifNeedToClearupTeacher() {
    const token = this.getNewToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/school/ifNeedToClearupTeacher", null, config);
  }
  leaveAComment(applyRewardId: string, rate: string) {
    const token = this.getAllToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/teacher/leaveAComment",
      {
        applyRewardId: applyRewardId,
        rate: rate,
      },
      config
    );
  }
  getSuccessfullyRewarded() {
    const token = this.getAllToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/teacher/getSuccessfullyRewarded",
      null,
      config
    );
  }
  awaitingToComments() {
    const token = this.getAllToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/teacher/awaitingToComments", null, config);
  }
  schoolCancelAccount(loginId: string) {
    const token = this.getNewToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/schoolCancelAccount",
      { loginId },
      config
    );
  }
  unsubscribeSchool() {
    const token = this.getNewToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/school/unsubscribeSchool", null, config);
  }
  getSchoolSubStatement() {
    const token = this.getNewToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/school/getSchoolSubStatement", null, config);
  }
  schoolActivePage() {
    const token = this.getNewToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(API_URL + "/school/schoolActivePage", null, config);
  }
  schoolInactivePage() {
    const token = this.getNewToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(API_URL + "/school/schoolInactivePage", null, config);
  }
  storeForgetPassword(email: string, loginId: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };
    return axios.post(
      API_URL + "/store/storeForgetPassword",
      {
        email,
        loginId,
      },
      config
    );
  }

  storeResetPassword(
    email: string,
    password: string,
    otp: string,
    loginId: string
  ) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };
    return axios.put(
      API_URL + "/store/storeResetPassword",
      {
        email,
        password,
        otp,
        loginId,
      },
      config
    );
  }
  getTeacherAndSchoolPrivateRewardsList() {
    const token = this.getAllToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/teacher/getTeacherAndSchoolPrivateRewardsList",
      null,
      config
    );
  }
  //get user's relative reward list
  getRelativeRewardedList(storeId: string) {
    const token = this.getAllToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/store/getRelativeRewardedList",
      {
        storeId: storeId,
      },
      config
    );
  }

  //APPLY A REWARD
  createApplyReward(userType: string, storeId: string) {
    const token = this.getAllToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/teacher/createApplyReward",
      {
        userType: userType,
        storeId: storeId,
      },
      config
    );
  }

  //GET student / teacher /school able to reward list
  getAbleToRewardList(userType: string) {
    const token = this.getAllToken();
    console.log("getAbleToRewardList====的token:", token);
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/store/getAbleToRewardList",
      {
        userType: userType,
      },
      config
    );
  }
  getAllToken = () => {
    let token;
    if (this.getNewToken()) {
      token = this.getNewToken();
    } else if (this.getTeahcerToken()) {
      token = this.getTeahcerToken();
    } else if (this.getUserToken()) {
      token = this.getUserToken();
    }

    return token;
  };
  getCommonToken = () => {
    let token;
    if (this.getNewToken()) {
      token = this.getNewToken();
    } else {
      token = this.getTeahcerToken();
    } //

    return token;
  };
  //==========teacher==========
  teacherLogin(email: string, password: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };
    return axios.post(
      API_URL + "/auth/login-teacher",
      {
        email,
        password,
      },
      config
    );
  }
  getTeacherProfileForStore() {
    const token = this.getAllToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/teacher/getTeacherProfileForStore",
      null,
      config
    );
  }

  getTeacherProfile() {
    const token = this.getTeahcerToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/school/getTeacherProfile", null, config);
  }

  teacherForgetPassword(email: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/auth/teacherForgetPassword",
      {
        email: email,
      },
      config
    );
  }

  teacherResetPassword(
    email: string,
    password: string,
    otp: string,
    resetToken: string
  ) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/auth/teacherResetPassword",
      {
        email: email,
        password: password,
        otp: otp,
        resetToken: resetToken,
      },
      config
    );
  }
  //update report state
  updateReportState(reportId: string) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/school/updateReportState",
      {
        reportId: reportId,
      },
      config
    );
  }
  //get all reports from school
  getAllReportsOfSchool() {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/school/getAllReportsOfSchool", null, config);
  }
  //on click report

  reportToSchool(formData: object) {
    const token = this.getUserToken(); // 获取token

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Content-Type": "multipart/form-data",
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/hero/reportToSchool", formData, config);
  }

  //get school rules
  getSchoolRules(schoolId: string, type?: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/getSchoolRules",
      {
        type: type,
        schoolId: schoolId,
      },
      config
    );
  }
  getSchoolStatement() {
    const token = this.getNewToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/school/getSchoolStatement", null, config);
  }
  //get school profile's datas
  getSchoolProfileDatas() {
    let token;
    if (this.getNewToken()) {
      token = this.getNewToken();
    } else {
      token = this.getTeahcerToken();
    } // 获取token
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/school/getSchoolProfileDatas", null, config);
  }
  //get all rules with default rules
  getAllDetaultAndRules(type?: string) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/getAllDetaultAndRules",
      {
        type: type,
      },
      config
    );
  }
  //get all rules
  getAllRules(type?: string) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/getAllRules",
      {
        type: type,
      },
      config
    );
  } //delete rule
  deleteRule(ruleId: string) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/school/deleteRule",
      {
        ruleId: ruleId,
      },
      config
    );
  }
  //update rule
  updateRule(ruleId: string, type: string, content: string, point: string) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/school/updateRule",
      {
        ruleId: ruleId,
        type: type,
        content: content,
        point: point,
      },
      config
    );
  }
  //create rule
  createRule(type: string, content: string, point: string) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/createRule",
      {
        type: type,
        content: content,
        point: point,
      },
      config
    );
  }
  //get all default rules
  getAllDefaultRules() {
    return axios.get(API_URL + "/school/getAllDefaultRules");
  }
  //get all allowed add teachers amount
  getSchoolAllowedAddTeachersAmount() {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/getSchoolAllowedAddTeachersAmount",
      null,
      config
    );
  }

  //upload default rule
  uploadDefaultPunishmentRewardRules(data: any) {
    const token = this.getTempToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
        "Content-Type": "application/json", // 使用 JSON 格式发送数据
      },
    };

    return axios.post(
      API_URL + "/school/createDefaultRule",
      {
        data: data,
      },
      config
    );
  }
  //store
  searchIfRewardExists(rewardId: string) {
    const token = this.getNewStoreToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/teacher/searchIfRewardExists",
      {
        rewardId,
      },
      config
    );
  }

  getOneStoreDetail(storeId: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/store/getOneStoreDetail",
      {
        storeId,
      },
      config
    );
  }
  getAllStores() {
    return axios.get(API_URL + "/store/getAllStores");
  }

  // store

  cancelAccount() {
    const token = this.getNewStoreToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/store/cancelAccount", null, config);
  }

  unsubscribe() {
    const token = this.getNewStoreToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/store/unsubscribe", null, config);
  }
  getStoreStatement() {
    const token = this.getNewStoreToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/store/getStoreStatement", null, config);
  }
  getStoreSubStatement() {
    const token = this.getNewStoreToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/store/getStoreSubStatement", null, config);
  }

  inactivePage() {
    const token = this.getNewStoreToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/store/inactivePage", null, config);
  }

  activePage() {
    const token = this.getNewStoreToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/store/activePage", null, config);
  }

  updateStoreInfo(formData: object) {
    const token = this.getNewStoreToken(); // 获取token
    console.log("现在的formData:", formData);

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Content-Type": "multipart/form-data",
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(API_URL + "/store/updateStoreInfo", formData, config);
  }

  getCurrentStoreUser() {
    const token = this.getNewStoreToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/store/getStoreSelfProfile", null, config);
  }

  approveReward(rewardId: string) {
    const token = this.getNewStoreToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/teacher/approveReward",
      {
        rewardId: rewardId,
      },
      config
    );
  }

  storeLogin(email: string, idHash: string, password: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/store/storeLogin",
      {
        email,
        idHash,
        password,
      },
      config
    );
  }

  storeGenerateLoginId(token: string, tokenId: string) {
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/store/storeGenerateLoginId",
      { tokenId: tokenId },
      config
    );
  }
  registerStore(email: string, password: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };
    return axios.post(
      API_URL + "/store/register-store",
      {
        email,
        password,
      },
      config
    );
  }
  //createStoreProfile
  createStoreProfile(formData: any) {
    const token = this.getTempToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Content-Type": "multipart/form-data", // 设置请求头为multipart/form-data
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/store/createStoreProfile", formData, config);
  }

  storeCreateCheckoutSessionForRegister() {
    const token = this.getTempToken(); // 获取token

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/store/storeCreateCheckoutSessionForRegister",
      null,
      config
    );
  }

  storeCreateCheckoutSession(selectedType: string) {
    const token = this.getNewStoreToken(); // 获取token

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/store/storeCreateCheckoutSession",
      { selectedType: selectedType },
      config
    );
  }

  storeSendVerifyCode() {
    const token = this.getTempToken(); // 获取token

    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/store/storeSendVerifyCode", null, config);
  }

  storeCheckIfAlreadyPaid(cus_id: string) {
    // const token = this.getTempToken(); // 获取token

    const config = {
      headers: {
        Authorization: cus_id,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/store/storeCheckIfAlreadyPaid", null, config);
  }

  storeVerifyEmail(otp: string) {
    const token = this.getTempToken();
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/store/storeVerifyEmail",
      { otp: otp },
      config
    );
  }

  storeCreateIssueWhenGetError(cus_id: string) {
    // const token = this.getTempToken(); // 获取token

    const config = {
      headers: {
        Authorization: cus_id,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/store/storeCreateIssueWhenGetError",
      null,
      config
    );
  }

  //get all events
  getAllEvents() {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/school/getAllEvents", null, config);
  }
  // create an event
  createEvent(
    studentId: string,
    type: string,
    baseOn: string,
    reason: string,
    points: string
  ) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/createEvent",
      {
        studentId: studentId,
        type: type,
        baseOn: baseOn,
        reason: reason,
        points: points,
      },
      config
    );
  }

  // update an event's statement
  updateAnEventStatement(eventId: string) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/school/updateAnEventStatement",
      { eventId: eventId },
      config
    );
  }

  //add a comment to an event
  addCommentToAnEvent(commentId: string, content: string) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/school/addCommentToAnEvent",
      { commentId: commentId, content: content },
      config
    );
  }

  //delete an event
  deleteAnEvent(eventId: string) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/school/deleteAnEvent",
      { eventId: eventId },
      config
    );
  }

  userResetPassword(
    email: string,
    password: string,
    otp: string,
    resetToken: string
  ) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/auth/userResetPassword",
      {
        email: email,
        password: password,
        otp: otp,
        resetToken: resetToken,
      },
      config
    );
  }
  userForgetPassword(email: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/auth/userForgetPassword",
      {
        email: email,
      },
      config
    );
  }
  getHeroProfileForStore() {
    const token = this.getUserToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/hero/getHeroProfileForStore", null, config);
  }
  getHeroProfile() {
    const token = this.getUserToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/hero/getHeroProfile", null, config);
  }
  userCreateHeroProfile(formData: object) {
    const token = this.getUserToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Content-Type": "multipart/form-data", // 设置请求头为multipart/form-data
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/hero/userCreateHero", formData, config);
  }
  deleteAllowedEmail(email: string) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/school/deleteAllowedEmail",
      { email: email },
      config
    );
  }

  deleteAllowedTeacherEmail(email: string) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/school/deleteAllowedTeacherEmail",
      { email: email },
      config
    );
  }
  updateAllowedEmail(email: string, studentName: string, validYear: number) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/school/updateAllowedEmail",
      { email: email, studentName: studentName, validYear: validYear },
      config
    );
  }

  updateAllowedTeacherEmail(
    email: string,
    studentName: string,
    validYear: number
  ) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(
      API_URL + "/school/updateAllowedTeacherEmail",
      { email: email, studentName: studentName, validYear: validYear },
      config
    );
  }
  getAllAllowedEmails() {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/school/getAllAllowedEmails", null, config);
  }

  getAllAllowedTeacherEmails() {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/getAllAllowedTeacherEmails",
      null,
      config
    );
  }
  uploadBulkEmails(data: any) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
        "Content-Type": "application/json", // 使用 JSON 格式发送数据
      },
    };

    return axios.post(API_URL + "/school/addBulkEmails", data, config);
  }
  uploadBulkTeacherEmails(data: any) {
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
        "Content-Type": "application/json", // 使用 JSON 格式发送数据
      },
    };

    return axios.post(API_URL + "/school/addBulkTeacherEmails", data, config);
  }
  uploadOneSingleEmail(email: string, name: string, validYear: number) {
    console.log(`前端收到email:${email};name:${name},validYear:${validYear}`);
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/addSingleEmail",
      {
        email: email,
        name: name,
        validYear: validYear,
      },
      config
    );
  }

  uploadOneSingleTeacherEmail(email: string, name: string, validYear: number) {
    // console.log(`前端收到email:${email};name:${name},validYear:${validYear}`);
    const token = this.getCommonToken();
    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/addSingleTeacherEmail",
      {
        email: email,
        name: name,
        validYear: validYear,
      },
      config
    );
  }

  deleteHero(id: string) {
    const token = this.getCommonToken(); // 获取token
    console.log("删除用户后端：", id);

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };
    return axios.patch(API_URL + "/hero/deleteHero", { id: id }, config);
  }
  getAllHeros() {
    // const token = this.getNewToken(); // 获取token
    const token = this.getCommonToken();

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };
    return axios.post(API_URL + "/hero/getAllHeros", null, config);
  }
  updateHeroProfile(formData: object) {
    const token = this.getCommonToken(); // 获取token

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Content-Type": "multipart/form-data",
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(API_URL + "/hero/updateHero", formData, config);
  }

  getOneSingleUser() {
    const token = this.getUserToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/hero/getOneExistStudent", null, config);
  }

  uploadHeroProfile(formData: object) {
    const token = this.getCommonToken(); // 获取token
    console.log("收到formData====:", formData);
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Content-Type": "multipart/form-data", // 设置请求头为multipart/form-data
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/hero/createHero", formData, config);
  }
  //get payment type
  getPaymentType() {
    return axios.get(API_URL + "/paymentSupport");
  }

  createContact(name: string, email: string, web: string, question: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/contact",
      {
        name,
        email,
        web,
        question,
      },
      config
    );
  }

  login(email: string, idHash: string, password: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/auth/login",
      {
        email,
        idHash,
        password,
      },
      config
    );
  }
  logout() {
    localStorage.removeItem("newUser");
  }
  register(email: string, password: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };
    return axios.post(
      API_URL + "/auth/register",
      {
        email,
        password,
      },
      config
    );
  }
  sendVerifyCode() {
    const token = this.getTempToken(); // 获取token

    const config = {
      headers: {
        Authorization: token,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/auth/sendVerifyCode", null, config);
  }

  checkIfAlreadyPaid(cus_id: string) {
    // const token = this.getTempToken(); // 获取token

    const config = {
      headers: {
        Authorization: cus_id,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/auth/checkIfAlreadyPaid", null, config);
  }

  verifyEmail(otp: string) {
    const token = this.getTempToken();
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/auth/verifyEmail", { otp: otp }, config);
  }

  createIssueWhenGetError(cus_id: string) {
    // const token = this.getTempToken(); // 获取token

    const config = {
      headers: {
        Authorization: cus_id,
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/createIssueWhenGetError",
      null,
      config
    );
  }
  createSchoolProfile(formData: any) {
    const token = this.getTempToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Content-Type": "multipart/form-data", // 设置请求头为multipart/form-data
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/createSchoolProfile",
      formData,
      config
    );
  }

  getTempToken() {
    return localStorage.getItem("temp"); //temp token only for move on
  }
  getUserToken() {
    return localStorage.getItem("userSelf"); //after user done the register
  }

  getToken() {
    return localStorage.getItem("user");
  }
  getNewToken() {
    return localStorage.getItem("newUser");
  }
  getNewStoreToken() {
    return localStorage.getItem("storeUser");
  }

  getTeahcerToken() {
    return localStorage.getItem("teacherUser");
  }

  generateLoginId(token: string, tokenId: string) {
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/generateLoginId",
      { tokenId: tokenId },
      config
    );
  }
  // for school and user
  getCurrentUser() {
    let token;
    if (this.getNewToken()) {
      token = this.getNewToken();
    } else {
      token = this.getTeahcerToken();
    } // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/school/getSchoolSelfProfile", null, config);
  }

  getWhoVoted() {
    const token = this.getCommonToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/school/getWhoVoted", null, config);
  }

  createCheckoutSession(selectedType: string) {
    const token = this.getTempToken(); // 获取token

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/checkout-session",
      {
        selectedType: selectedType,
      },
      config
    );
  }

  reCreateCheckoutSession(selectedType: string) {
    const token = this.getNewToken(); // 获取token

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(
      API_URL + "/school/re-checkout-session",
      {
        selectedType: selectedType,
      },
      config
    );
  }

  getOne() {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };
    return axios.post(API_URL + "/school/getOneSchool", null, config);
  }

  userRegister(email: string, password: string, schoolId: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };
    return axios.post(
      API_URL + "/auth/register-user",
      {
        email,
        password,
        schoolId,
      },
      config
    );
  }

  verifyUserEmail(otp: string) {
    const token = this.getUserToken();
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/auth/verifyUserEmail", { otp: otp }, config);
  }

  uploadUserProfile(formData: object) {
    const token = this.getUserToken(); // 获取token
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Content-Type": "multipart/form-data", // 设置请求头为multipart/form-data
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/userinfo/createUserInfo", formData, config);
  }

  /**get user profile */
  getUserProfile() {
    const token = this.getUserToken(); // 获取token

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };

    return axios.post(API_URL + "/userinfo/getUserProfile", null, config);
  }

  updateUserProfile(formData: object) {
    const token = this.getUserToken(); // 获取token

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Content-Type": "multipart/form-data",
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(API_URL + "/userinfo/updateUser", formData, config);
  }
  updateSchoolProfile(formData: object) {
    const token = this.getNewToken(); // 获取token

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Content-Type": "multipart/form-data",
        "Accept-Language": Accept_Language,
      },
    };

    return axios.patch(API_URL + "/school/updateSchoolInfo", formData, config);
  }

  userLogin(email: string, password: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };
    return axios.post(
      API_URL + "/auth/login-user",
      {
        email,
        password,
      },
      config
    );
  }

  forgetPassword(email: string, loginId: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };
    return axios.post(
      API_URL + "/auth/forgetPassword",
      {
        email,
        loginId,
      },
      config
    );
  }

  resetPassword(email: string, password: string, otp: string, loginId: string) {
    const config = {
      headers: {
        "Accept-Language": Accept_Language,
      },
    };
    return axios.put(
      API_URL + "/auth/resetPassword",
      {
        email,
        password,
        otp,
        loginId,
      },
      config
    );
  }

  verifySchoolLoginId(loginId: string) {
    const token = this.getNewToken(); // 获取token

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };
    return axios.post(
      API_URL + "/school/verifyLoginId",
      {
        loginId: loginId,
      },
      config
    );
  }

  getAllSchools() {
    return axios.get(API_URL + "/school/getAllSchools");
  }
  removeOrJoin(loginID: string) {
    const token = this.getNewToken();
    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };
    return axios.patch(
      API_URL + "/school/removeOrJoin",
      {
        loginId: loginID,
      },
      config
    );
  }
  giveOrRemoveVote(action: string, schoolId: string) {
    const token = this.getUserToken(); // 获取token

    const config = {
      headers: {
        Authorization: token, // 在请求头中包含令牌
        "Accept-Language": Accept_Language,
      },
    };
    return axios.patch(
      API_URL + "/userinfo/giveOrRemoveVote",
      {
        action,
        schoolId,
      },
      config
    );
  }

  getHa() {
    return axios.get(API_URL + "/auth/getHa");
  }
}

export default new AuthService();
