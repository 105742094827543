import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { FaSchool, FaChalkboardTeacher } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { IoStorefrontSharp } from "react-icons/io5";

const Login = () => {
  const { t } = useTranslation();
  return (
    <CommonPageWithHeader>
      {/**title:register as  */}
      <h1 className="registerH1">{t("login_as")}</h1>
      {/**buttons */}

      <div className="registerLoginButtonsContainer">
        {/**User */}
        <Link
          className="linkBoxContainer"
          to={"/login-email-user"}
          style={{ backgroundColor: "#5f7fff" }}
        >
          <FaUserAlt
            color={"white"}
            size={40}
            style={{
              color: "white",
              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
          />
          {t("user")}
        </Link>
        {/**School */}
        <Link
          className="linkBoxContainer"
          to={"/login-email-school"}
          style={{ backgroundColor: "#fecf45" }}
        >
          <FaSchool
            color={"white"}
            size={45}
            style={{
              color: "white",
              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
          />
          {t("school")}
        </Link>

        {/**Teacher */}
        <Link
          className="linkBoxContainer"
          to={"/login-teacher"}
          style={{ backgroundColor: "#3cb371" }}
        >
          <FaChalkboardTeacher
            color={"white"}
            size={45}
            style={{
              color: "white",
              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
          />
          {t("teaching_staff")}
        </Link>

        {/**商家 */}
        <Link
          className="linkBoxContainer"
          to={"/store-login-email"}
          style={{ backgroundColor: "#DB7093" }}
        >
          <IoStorefrontSharp
            color={"white"}
            size={45}
            style={{
              color: "white",
              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
          />
          {/* 奖励商店 */}
          {t("reward_store")}
        </Link>
      </div>
    </CommonPageWithHeader>
  );
};
export default Login;
