import { Navigate } from "react-router-dom";

const ProtectTempSchool = ({ children }: any) => {
  const user = localStorage.getItem("temp");

  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectTempSchool;
