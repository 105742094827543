import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import { useState, useEffect } from "react";
import AuthService from "../../services/service";
import getUserType from "../../utils/getUserType";
import StoreItem from "../../components/store/StoreItem";
import getStoreType from "./../../utils/getStoreType";
import getAvailableToWho from "./../../utils/getAvailableToWho";
import { Store } from "../../datas/atoms";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GetAbleToRewardList = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { total } = location.state || 0;

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    setLoading(true);
    const user = getUserType();
    console.log("现在get able to reward list user:", user);
    if (user === "student") {
      AuthService.getAbleToRewardList(user)
        .then(({ data }) => {
          setData(data.matchedList);
          setLoading(false);
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    } else {
      setData(total);
      setLoading(false);
    }
  };
  return (
    <CommonPageWithHeader loading={loading} error={error}>
      <div className="normalContainerColumn">
        {data.length > 0 ? (
          <p>
            {" "}
            ---
            {/* 以下为你目前可以申请的奖励 */}
            {t("reward_that_you_are_able_to_apply_now")}（{data.length}）---
          </p>
        ) : (
          <p></p>
        )}
        {/**store list */}
        {data.length > 0 ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ol>
              {data.map((item: Store, index) => (
                <li key={index}>
                  <StoreItem
                    _id={item._id}
                    name={item.name}
                    icon={item.icon}
                    image={item.image}
                    rewardContent={item.rewardContent}
                    rewardPointsRequirement={item.rewardPointsRequirement}
                    type={getStoreType(item.type || "")}
                    productType={item.productType}
                    availableForWho={getAvailableToWho(
                      item.availableForWho || ""
                    )}
                    platformName={item.platformName}
                    // region={item.region || t("all_over_the_world")}
                    rewardedAmount={item.rewardedAmount}
                    minAge={item.minAge}
                    maxAge={item.maxAge}
                  />
                </li>
              ))}
            </ol>
          </div>
        ) : (
          <p>
            ---
            {/* 暂无可申请的奖励 */}
            {t("temp_no_reward_to_apply")}
            ---
          </p>
        )}
      </div>
    </CommonPageWithHeader>
  );
};

export default GetAbleToRewardList;
