import UserItem from "../components/UserItem";
import { RiArrowGoBackFill } from "react-icons/ri";
import AuthService from "../services/service";
import { useEffect, useRef, useState } from "react";

import ErrorMsg from "../components/ErrorMsg";
import Loading from "./../components/Loading";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const UsersWhoVoted = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [error, setError] = useState("");
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const studentNumRef = useRef(0);
  const parentNumRef = useRef(0);
  const otherNumRef = useRef(0);
  const totalNumRef = useRef(0);
  const schoolNameRef = useRef("you");

  useEffect(() => {
    initData();
  }, [
    studentNumRef.current,
    parentNumRef.current,
    otherNumRef.current,
    schoolNameRef.current,
  ]);

  const initData = () => {
    AuthService.getWhoVoted()
      .then(({ data }) => {
        const { schooName, parent, student, other, total, users } = data;

        studentNumRef.current = student;
        parentNumRef.current = parent;
        otherNumRef.current = other;
        totalNumRef.current = total;
        schoolNameRef.current = schooName;
        setDatas(users);
        setLoading(false);
      })
      .catch((error) => setError(error.response.data.message));
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            height: "100vh",
            padding: "6%",
            alignItems: "center",
          }}
        >
          {error && <ErrorMsg error={error} />}
          {/**back */}
          <RiArrowGoBackFill
            className="backIcon"
            color={"black"}
            size={"2.5rem"}
            onClick={() => navigate(-1)}
            style={{ marginBottom: "2rem" }}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              paddingBottom: 16,
              marginTop: 25,
            }}
          >
            <h1>
              {t("who_voted_to")} {schoolNameRef.current}
              {t("de_yonghu")}
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-around",
              marginBottom: 40,
              backgroundColor: "#e4dbdb",
              padding: 16,
              borderRadius: 5,
            }}
          >
            <div className="tableTitle">
              <span style={{ fontWeight: "bold" }}>{t("total")}：</span>
              {totalNumRef.current}
            </div>
            {parentNumRef.current > 0 && (
              <div className="tableTitle">
                <span style={{ fontWeight: "bold" }}>{t("parent")}：</span>
                {parentNumRef.current}
              </div>
            )}
            {studentNumRef.current > 0 && (
              <div className="tableTitle">
                <span style={{ fontWeight: "bold" }}>{t("student")}：</span>
                {studentNumRef.current}
              </div>
            )}
            {otherNumRef.current > 0 && (
              <div className="tableTitle">
                <span style={{ fontWeight: "bold" }}>{t("other_role")}：</span>
                {otherNumRef.current}
              </div>
            )}
          </div>
          <div
            style={{ display: "flex", width: "100vw", flexDirection: "column" }}
            className="whoVotedItem"
          >
            <ol className="list-group">
              {datas.map((item: any, index) => (
                <li className={"listGroupItem "} onClick={() => {}} key={index}>
                  <UserItem
                    name={item.user.name}
                    image={item.user.image}
                    role={item.user.role}
                    votedAt={item.votedAt}
                  />
                </li>
              ))}
            </ol>
          </div>
        </div>
      )}
    </>
  );
};

export default UsersWhoVoted;
