import { useRef, useState } from "react";
import { RiArrowGoBackFill } from "react-icons/ri";
import ErrorMsg from "./ErrorMsg";
import Loading from "./Loading";
import AuthService from "../services/service";
import { useTranslation } from "react-i18next";

interface Props {
  onPressCloseModal: (value: boolean) => void;
  onPressChange: (value: string) => void;
}

const EnterLoginId = ({ onPressCloseModal, onPressChange }: Props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const loginIdRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const onPressSubmit = () => {
    setLoading(true);
    AuthService.verifySchoolLoginId(loginIdRef!.current!.value)
      .then(() => {
        setLoading(false);
        onPressCloseModal(false); //close modal
      })
      .catch((error) => setErrorMsg(error.response.data.message));
  };

  return (
    <div className="alertContainer">
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {errorMsg && <ErrorMsg error={errorMsg} />}
          <RiArrowGoBackFill
            className="closeIcon"
            size={"3rem"}
            color={"white"}
            onClick={onPressCloseModal}
          />

          <h2
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "1.8rem",
              marginTop: "2rem",
            }}
          >
            {/* 请输入Login ID: */}
            {t("pl_enter_login_id")}
          </h2>
          <input
            id="otp"
            type="password"
            className="form-control"
            placeholder=""
            ref={loginIdRef}
            style={{ width: "50%", padding: "1rem", marginTop: "2rem" }}
            onChange={(value: any) => onPressChange(value)}
          />
          <button
            className="noticeBtn"
            onClick={onPressSubmit}
            style={{ marginTop: "2rem" }}
          >
            {/* 确定 */}
            {t("submit")}
          </button>
        </div>
      )}
    </div>
  );
};

export default EnterLoginId;
