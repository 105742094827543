interface Props {
  name: string;
  image: string;
  role: string;
  votedAt: string;
}
import { AiFillHeart } from "react-icons/ai";
import retrunImgUrl from "../utils/retrunImgUrl";

import { useTranslation } from "react-i18next";
import "moment/dist/locale/zh-cn";
import "moment/dist/locale/es";
import changeToLocaleTime from "../utils/changeToLocaleTime";
const UserItem = ({ name, image, role, votedAt }: Props) => {
  const relativeTime = changeToLocaleTime(votedAt);
  const { t } = useTranslation();
  return (
    <div className="userItemContainer">
      {/**name , image*/}
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          style={{
            width: "3rem",
            height: "3rem",
            borderRadius: "3rem",
            marginRight: 10,
          }}
          src={retrunImgUrl(image)}
          alt="user profile image"
        />
        <h3 style={{ fontSize: "1.3rem" }}>{name}</h3>
      </div>

      {/**role */}
      <div>
        <h3 style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
          Role:{" "}
          <span style={{ fontWeight: "normal" }}>
            {/* {t(`${role.charAt(0).toUpperCase() + role.slice(1)}`)} */}
            {t(role)}
          </span>
        </h3>
      </div>
      {/**votedAt*/}
      <div>
        <h3 style={{ fontSize: "1.3rem" }}>{relativeTime}</h3>
      </div>

      {/**heart */}
      <div>
        {" "}
        <AiFillHeart color={"#e31111"} size={"2rem"} />
      </div>
    </div>
  );
};

export default UserItem;
