import { useEffect, useState } from "react";
import Loading from "./Loading";
import AuthService from "../services/service";

const NoticeForChineseUser = ({ onPressConfirm }: any) => {
  const [debit, setDebit] = useState("");
  const [credit, setCreadit] = useState("");
  const [third, setThird] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    setLoading(true);
    AuthService.getPaymentType()
      .then(({ data }) => {
        setDebit(data.data.debit);
        setThird(data.data.thirdParty);
        setCreadit(data.data.credit);
        setLoading(false);
      })
      .catch(() => {});
  };
  return (
    <div className="alertContainer">
      {loading ? (
        <Loading />
      ) : (
        <div className="alertPop" style={{ padding: "0 10%" }}>
          {/**title */}
          <h2 style={{ fontSize: "1.8rem", fontWeight: "bold" }}>
            尊敬的中国内地的院校,
          </h2>
          {/**subtitle */}
          <p
            style={{
              lineHeight: "1.8",
              marginTop: "1.5rem",
              textAlign: "center",
              fontSize: "1.3rem",
              color: "#5f7fff",
              marginBottom: "2rem",
            }}
          >
            为确保您可以顺利汇款，完成注册环节，
            我们建议您使用已经经过我们测试，能够顺利完成汇款的支付方式，如下：
          </p>
          {/**debit*/}
          {debit !== "" && (
            <p>
              <span style={{ fontWeight: "bold" }}>借记卡（Debit）: </span>
              {debit}
            </p>
          )}
          {/**credit */}
          {credit !== "" && (
            <p>
              <span style={{ fontWeight: "bold" }}>信用卡（Credit）: </span>
              {credit}
            </p>
          )}

          {/**Third party */}
          {third !== "" && (
            <p>
              <span style={{ fontWeight: "bold" }}>第三方支付工具: </span>
              {third}
            </p>
          )}

          <button
            type="button"
            className="btn btn-primary"
            onClick={onPressConfirm}
            style={{
              width: "100%",
              padding: "1.2rem",
              fontSize: "1.5rem",
              marginTop: "2rem",
              fontWeight: "bold",
            }}
          >
            确定，前往付款
          </button>
        </div>
      )}
    </div>
  );
};

export default NoticeForChineseUser;
