import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContactInitial = () => {
  const { t } = useTranslation();
  return (
    <CommonPageWithHeader>
      <div className="contactcontainer">
        <div className="sendMsg">
          {/**title */}
          <h2
            style={{
              fontSize: "1.8rem",
              fontWeight: "bold",
              marginBottom: "2rem",
            }}
          >
            {t("send_us_msg_title")}
          </h2>
          {/**content */}
          <p style={{ fontSize: "1.4rem", color: "#333", lineHeight: "1.8" }}>
            {t("send_us_msg_content")}
          </p>
          {/**buton*/}
          <Link
            type="button"
            className="noticeBtn"
            to={"/contactUs"}
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              borderRadius: "15rem",
            }}
          >
            {t("send_us_msg_btn")}
          </Link>
        </div>
        <div className="socailMedia">
          {/**title */}
          <h1
            style={{
              fontSize: "1.8rem",
              fontWeight: "bold",
              marginBottom: "2rem",
            }}
          >
            {/* 关注我们， */}
            {t("follow_us_title")}
          </h1>
          {/**content */}
          <p
            style={{
              fontSize: "1.4rem",
              color: "#333",
              lineHeight: "1.8",
              marginTop: "2rem",
            }}
          >
            {/* 可以帮助您获得我们最新的消息 : */}
            {t("follow_us_sub")}
          </p>

          {/**Social media */}
          <a
            href="https://www.facebook.com/profile.php?id=100095393292017"
            style={{ fontSize: "1.5rem", textDecoration: "none" }}
            target="_blank"
          >
            Facebook
          </a>
          {/* <a
            href="https://twitter.com/SchoolGlory_"
            style={{
              fontSize: "1.5rem",
              textDecoration: "none",
              marginTop: "2rem",
            }}
            target="_blank"
          >
            Twitter
          </a> */}
          <a
            href="https://weibo.com/gearsrun"
            style={{
              fontSize: "1.5rem",
              textDecoration: "none",
              marginTop: "2rem",
            }}
            target="_blank"
          >
            微博(Wei Bo)
          </a>
        </div>
      </div>
    </CommonPageWithHeader>
  );
};

export default ContactInitial;
