import { BsFillTrash3Fill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import { Hero } from "../datas/atoms";
import formatJoinDate from "../utils/formatJoinDate";
import { BiSolidSquareRounded } from "react-icons/bi";
import { BsPencilSquare } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";

interface ClickHandlerObj {
  (value: Hero): void;
}
interface Props {
  home?: boolean;
  item: Hero;
  onPressEdit?: ClickHandlerObj;
  onPressPreDelete?: ClickHandlerObj;
  onPressAddEvent: ClickHandlerObj;
  valid: boolean;
}

export const HeroItem = ({
  home = false,
  item,
  onPressPreDelete,
  onPressEdit,
  onPressAddEvent,
  valid,
}: Props) => {
  console.log("Received obj in HeroItem:", item); // 添加这行日志
  const { t } = useTranslation();
  const img_url = import.meta.env.VITE_IMG_URL;
  const heroImg = `${img_url}${item.image}`;
  return (
    <div className="heroItemContainer">
      {/**Profile image */}
      <div className="heroImgWithNameContainer">
        <img src={heroImg} alt="hero image" className="userImage" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: "1rem",
          }}
        >
          {/**Name */}
          <p style={{ fontWeight: "bold" }}>
            <span>
              <BiSolidSquareRounded
                size={"1.2rem"}
                style={{ marginRight: 5 }}
                color={valid ? "green" : "red"}
              />
            </span>
            {item.heroName}
          </p>
          <p>{item.studentEmail}</p>
          <p>{item.info}</p>
          <p>
            {/* 账号有效期 */}
            {t("student_account_valid_to")}：{formatJoinDate(item.validDate)}
          </p>
        </div>
      </div>
      {/**button */}

      {/**delete */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!home && onPressEdit && (
          <AiOutlineEdit
            color={"#807d77"}
            size={"2.5rem"}
            className="iconHover"
            style={{ marginRight: "1.5rem" }}
            onClick={() => onPressEdit(item)}
          />
        )}
        {!home && onPressPreDelete && (
          <BsFillTrash3Fill
            color={"#807d77"}
            size={"2.5rem"}
            className="iconHover"
            style={{ marginRight: "1.5rem" }}
            onClick={() => onPressPreDelete(item)}
          />
        )}
        {!home && onPressPreDelete && (
          <BsPencilSquare
            color={"#807d77"}
            size={"2.5rem"}
            className="iconHover"
            onClick={() => onPressAddEvent(item)}
          />
        )}
      </div>
    </div>
  );
};
