import { AiOutlineCloseCircle } from "react-icons/ai";
import PointSelector from "./PointSelector";
import { useEffect, useState } from "react";
import AuthService from "../services/service";
import Loading from "./Loading";
import successImg from "/images/done.png";
import { RewardPunishmentRule } from "../datas/atoms";
import { useTranslation } from "react-i18next";

interface Props {
  handleClosePopup: () => void;
  type: string;
  item?: RewardPunishmentRule;
}

const AddRewardPunishmentRuleForm = ({
  handleClosePopup,
  type,
  item,
}: Props) => {
  const { t } = useTranslation();
  const [newContent, setNewContent] = useState("");
  const [newPoint, setNewPoint] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (item) {
      initData();
    }
  }, []);
  const handleSelectNum = (value: string) => {
    setNewPoint(value);
  };
  const onPressUploadRule = () => {
    if (newContent !== "" || !newPoint) {
      setLoading(true);
      if (item) {
        updateRule();
      } else {
        createNewRule();
      }
      // console.log(
      //   `现在的ruleId:${item!._id};content:${newContent};point:${newPoint};Type:${
      //     item?.type
      //   }`
      // );
    } else {
      return setError("Can't be empty!");
    }
  };

  const createNewRule = () => {
    AuthService.createRule(type, newContent, newPoint)
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((e) => setError(e));
  };

  const updateRule = () => {
    AuthService.updateRule(item!._id, item!.type, newContent, newPoint)
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((e) => setError(e));
  };

  const initData = () => {
    setNewContent(item!.content);
    setNewPoint(item!.point.toString());
  };
  return (
    <div className="addHeroComponent">
      <div className="alertPop">
        {!loading && !success && (
          <>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {type === "reward" && (
              <p>
                {/* 添加奖励行为 */}
                {t("add_reward_rule_btn")}
              </p>
            )}
            {type === "punishment" && (
              <p>
                {/* 添加惩罚行为 */}
                {t("add_negative_rule_btn")}
              </p>
            )}
            {/**behavior */}
            <div className="alertInputContainer">
              {/**content */}
              <input
                onChange={(e) => setNewContent(e.target.value)}
                className="normalInputSec"
                value={newContent || ""}
              />
              {/**point */}

              <div style={{ display: "flex", alignItems: "center" }}>
                {type === "reward" && (
                  <>
                    {/* 奖励分数 */}
                    {t("according_reward_points")}：
                  </>
                )}
                {type === "punishment" && (
                  <>
                    {/* 扣分分数 */}
                    {t("according_punish_points")}：
                  </>
                )}
                <PointSelector
                  type={type}
                  onSelect={handleSelectNum}
                  defaultSelectedNum={
                    newPoint || (type === "reward" ? "1" : "-1")
                  }
                />
              </div>
              {/**Upload button */}
              <button
                type="button"
                className="btn btn-warning"
                style={{
                  minWidth: 100,
                  padding: "1rem ",
                  marginTop: "2rem",
                  fontWeight: "bold",
                }}
                onClick={onPressUploadRule}
              >
                {t("upload")}
              </button>
            </div>
          </>
        )}
        {!success && loading && (
          <>
            <Loading />
          </>
        )}
        {success && !loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={successImg} style={{ width: "6rem", height: "6rem" }} />
          </div>
        )}
      </div>
      <AiOutlineCloseCircle
        size={"3rem"}
        color={"white"}
        style={{ marginTop: "1rem" }}
        onClick={handleClosePopup}
        className="iconHover"
      />
    </div>
  );
};

export default AddRewardPunishmentRuleForm;
