import { useTranslation } from "react-i18next";
import TitleComponent from "./TitleComponent";
import { useState } from "react";
import getDifLangImg from "../../utils/getDifLangImg";
interface Props {
  type: Number;
}
const SchoolFAQItem = ({ type }: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const titles = [
    `1.${t("mearningful_school_title")}`,
    `2.${t("type_that_school_can_apply_title")}`,
    `3.${t("what_school_admin_user_can_do_title")}`,
    `4.${t("what_datas_need_to_prepare_to_join_title")}`,
    `5.${t("random_sort_school_title")}`,
    `6.${t("where_to_get_receive_title")}`,
    `7.${t("how_to_add_student_title")}`,
    `8.${t("how_to_add_teaching_staffs_title")}`,
    `9.${t("where_to_check_students_reports_title")}`,
    `10.${t("how_to_add_reward_punishment_actions_title")}`,
    `11.${t("how_to_add_reward_punishment_events_title")}`,
    `12.${t("can_school_admin_user_apply_reward_title")}`,
    `13.${t("what_can_you_do_after_joined_school_glory_title")}`,
    `14.${t("how_can_school_cancel_auto_charge_title")}`,
    `15.${t("how_to_apply_reward_school_title")}`,
    `16.${t("how_to_change_password_content_2_school")}`,
    `17.${t("language_suggetion_title")}`,
    `18.${t("if_teaching_staff_needed_rewards_points_title")}`,
    `19.${t("if_student_teaching_staff_allowed_to_re_redeem_rewards")}`,
    `20.${t("recommend_to_add_rule")}`,
    `21.${t(
      "how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_title"
    )}`,
    `22.${t(
      "how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_title"
    )}`,
  ];

  //学校加入School Glory的意义？
  const content_1 = [t("mearningful_school_content")];

  //可以申请的学校类型？
  const content_2 = [t("type_that_school_can_apply_content")];

  //学校管理者可以做什么？
  const content_3 = [
    t("what_school_admin_user_can_do_main_1"),
    t("what_school_admin_user_can_do_main_2"),
    t("what_school_admin_user_can_do_main_3"),
    t("what_school_admin_user_can_do_content_1"),
    t("what_school_admin_user_can_do_content_2"),
    t("what_school_admin_user_can_do_content_3"),
    t("what_school_admin_user_can_do_content_4"),
    t("what_school_admin_user_can_do_main_4"),
    t("what_school_admin_user_can_do_content_5"),
    t("what_school_admin_user_can_do_content_6"),
    t("what_school_admin_user_can_do_content_7"),
    t("what_school_admin_user_can_do_content_8"),
    t("what_school_admin_user_can_do_main_5"),
    t("what_school_admin_user_can_do_main_6"),
  ];

  //学校入驻需要准备什么材料？
  const content_4 = [
    t("what_datas_need_to_prepare_to_join_content_main"),
    t("what_datas_need_to_prepare_to_join_content_1"),
    t("what_datas_need_to_prepare_to_join_content_2"),
    t("what_datas_need_to_prepare_to_join_content_3"),
    t("what_datas_need_to_prepare_to_join_content_4"),
    t("what_datas_need_to_prepare_to_join_content_5"),
  ];
  //学校在School Glory首页的默认排名
  const content_5 = [t("random_sort_school_content")];

  //学校付款后，在哪里获得收据？
  const content_6 = [t("where_to_get_receive_content")];

  //如何添加在校的学生？
  const content_7 = [
    t("how_to_add_student_content_1"),
    t("how_to_add_student_content_2"),
    t("how_to_add_student_content_3"),
  ];

  //如何添加教职人员？
  const content_8 = [
    t("how_to_add_teaching_staffs_content_1"),
    t("how_to_add_teaching_staffs_content_2"),
    t("how_to_add_teaching_staffs_content_3"),
  ];

  //在哪里可以收到学生的举报？
  const content_9 = [
    t("where_to_check_students_reports_content_1"),
    t("where_to_check_students_reports_content_2"),
  ];

  //如何添加学校的会被奖励的行为和会被扣分的行为?
  const content_10 = [
    t("how_to_add_reward_punishment_actions_content_1"),
    t("how_to_add_reward_punishment_actions_content_2"),
    t("how_to_add_reward_punishment_actions_content_3"),
  ];

  //如何为学生添加奖励或者扣分的事件？
  const content_11 = [
    t("how_to_add_reward_punishment_events_content_1"),
    t("how_to_add_reward_punishment_events_content_2"),
    t("how_to_add_reward_punishment_events_content_3"),
    t("how_to_add_reward_punishment_events_content_4"),
  ];

  //学校账户管理人员是否可以申请奖励？
  const content_12 = [
    t("can_school_admin_user_apply_reward_content_1"),
    t("can_school_admin_user_apply_reward_content_2"),
  ];

  //申请加入School Glory平台之后，可以先做什么？
  const content_13 = [
    t("what_can_you_do_after_joined_school_glory_content_1"),
    t("what_can_you_do_after_joined_school_glory_content_2"),
    t("what_can_you_do_after_joined_school_glory_content_3"),
  ];

  //学校如何取消自动续费？
  const content_14 = [
    t("how_can_school_cancel_auto_charge_content_1"),
    t("how_can_school_cancel_auto_charge_content_2"),
  ];

  //如何申请奖励？
  const content_15 = [
    t("how_to_apply_reward_school_content_1"),
    t("how_to_apply_reward_school_content_2"),
    t("how_to_apply_reward_school_content_3"),
    t("how_to_apply_reward_school_content_4"),
  ];

  //如何更换密码?
  const content_16 = [
    t("student_how_to_change_password_content_1"),
    t("how_to_change_password_content_2_school_login"),
    t("student_how_to_change_password_content_3"),
    t("how_to_change_password_content_4"),
    t("student_how_to_change_password_content_5"),
    t("student_how_to_change_password_content_6"),
  ];

  //填写资料建议使用哪种语言？
  const content_17 = [t("language_suggestion_content")];

  //教职人员与其家人是否需要奖励分数来兑换奖励？
  const content_18 = [t("if_teaching_staff_needed_rewards_points_content")];

  //学生用户、教职人员兑换过奖励后是否还能再兑换奖励？
  const content_19 = [
    t("if_student_teaching_staff_allowed_to_re_redeem_rewards_content"),
  ];

  //重要：建议学校用户添加以下奖励和扣分行为
  const content_20 = [
    t("what_school_admin_user_can_do_main_3"),
    t("what_school_admin_user_can_do_content_1"),
    t("what_school_admin_user_can_do_content_2"),
    t("what_school_admin_user_can_do_content_3"),
    t("what_school_admin_user_can_do_content_4"),
    t("what_school_admin_user_can_do_main_4"),
    t("what_school_admin_user_can_do_content_5"),
    t("what_school_admin_user_can_do_content_6"),
    t("what_school_admin_user_can_do_content_7"),
    t("what_school_admin_user_can_do_content_8"),
  ];

  //如果学生已经转学/或者毕业，应该如何处理该学生的账号？
  const content_21 = [
    t(
      "how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_1"
    ),
    t(
      "how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_2"
    ),
    t(
      "how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3"
    ),
    t(
      "how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_1"
    ),
    t(
      "how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_2"
    ),
    t(
      "how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_3_3"
    ),
    t(
      "how_to_deal_with_the_student_account_when_they_are_no_longer_in_school_content_4"
    ),
  ];

  //如果教师已不在本校授课，应该如何处理该教师的账号？
  const content_22 = [
    t(
      "how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_1"
    ),
    t(
      "how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_2"
    ),
    t(
      "how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3"
    ),
    t(
      "how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_1"
    ),
    t(
      "how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_2"
    ),
    t(
      "how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_3_3"
    ),
    t(
      "how_to_deal_with_the_teacher_account_when_they_are_no_longer_in_school_content_4"
    ),
  ];

  return (
    <div>
      {/**1.学校加入School Glory的意义？ */}
      {type === 1 && (
        <div>
          <TitleComponent
            title={titles[0]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_1[0]}</p>
            </div>
          )}
        </div>
      )}
      {/**2.可以申请的学校类型？ */}
      {type === 2 && (
        <>
          <TitleComponent
            title={titles[1]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_2[0]}</p>
            </div>
          )}
        </>
      )}
      {/**3.学校管理者可以做什么？ */}
      {type === 3 && (
        <>
          <TitleComponent
            title={titles[2]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_3[0]}</p>
              <p className="faq_content">{content_3[1]}</p>
              <p />
              <p className="faq_content">{content_3[2]}</p>
              <p className="faq_content">{content_3[3]}</p>
              <p className="faq_content">{content_3[4]}</p>
              <p className="faq_content">{content_3[5]}</p>
              <p className="faq_content">{content_3[6]}</p>
              <p className="faq_content">{content_3[7]}</p>
              <p className="faq_content">{content_3[8]}</p>
              <p className="faq_content">{content_3[9]}</p>
              <p className="faq_content">{content_3[10]}</p>
              <p className="faq_content">{content_3[11]}</p>
              <p />
              <p className="faq_content">{content_3[12]}</p>
            </div>
          )}
        </>
      )}
      {/**4.学校入驻需要准备什么材料？ */}
      {type === 4 && (
        <>
          <TitleComponent
            title={titles[3]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_4[0]}</p>
              <p />
              <p className="faq_content">{content_4[1]}</p>
              <p className="faq_content">{content_4[2]}</p>
              <p className="faq_content">{content_4[3]}</p>
              <p className="faq_content">{content_4[4]}</p>
              <p className="faq_content">{content_4[5]}</p>
            </div>
          )}
        </>
      )}
      {/**5.学校在School Glory首页的默认排名？ */}
      {type === 5 && (
        <>
          <TitleComponent
            title={titles[4]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_5[0]}</p>
            </div>
          )}
        </>
      )}
      {/**6.学校付款后，在哪里获得收据？ */}
      {type === 6 && (
        <>
          <TitleComponent
            title={titles[5]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_6[0]}</p>
            </div>
          )}
        </>
      )}
      {/* //======================= */}
      {/**7.如何添加在校的学生？ */}
      {type === 7 && (
        <>
          <TitleComponent
            title={titles[6]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_7[0]}</p>
              <p className="faq_content">{content_7[1]}</p>
              <img src={getDifLangImg("7_2.png")} style={{ width: "270px" }} />
              <p className="faq_content">{content_7[2]}</p>
              <img src={getDifLangImg("7_3.png")} style={{ width: "270px" }} />
            </div>
          )}
        </>
      )}
      {/**8.如何添加教职人员？ */}
      {type === 8 && (
        <>
          <TitleComponent
            title={titles[7]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_8[0]}</p>
              <p className="faq_content">{content_8[1]}</p>
              <img src={getDifLangImg("8_2.png")} style={{ width: "270px" }} />
              <p className="faq_content">{content_8[2]}</p>
              <img src={getDifLangImg("8_3.png")} style={{ width: "270px" }} />
            </div>
          )}
        </>
      )}
      {/**9.在哪里可以收到学生的举报？ */}
      {type === 9 && (
        <>
          <TitleComponent
            title={titles[8]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_9[0]}</p>
              <p className="faq_content">{content_9[1]}</p>
              <img src={getDifLangImg("9_2.png")} style={{ width: "270px" }} />
            </div>
          )}
        </>
      )}
      {/**10.如何添加学校的会被奖励的行为和会被扣分的行为?*/}
      {type === 10 && (
        <>
          <TitleComponent
            title={titles[9]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_10[0]}</p>
              <p className="faq_content">{content_10[1]}</p>
              <img src={getDifLangImg("10_2.png")} style={{ width: "320px" }} />
              <p className="faq_content">{content_10[2]}</p>
              <img src={getDifLangImg("10_3.png")} style={{ width: "270px" }} />
              <img src={getDifLangImg("10_4.png")} style={{ width: "270px" }} />
            </div>
          )}
        </>
      )}
      {/**11.如何为学生添加奖励或者扣分的事件？ */}
      {type === 11 && (
        <>
          <TitleComponent
            title={titles[10]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_11[0]}</p>
              <p className="faq_content">{content_11[1]}</p>
              <img src={getDifLangImg("11_2.png")} style={{ width: "270px" }} />
              <p className="faq_content">{content_11[2]}</p>
              <img src={getDifLangImg("11_3.png")} style={{ width: "320px" }} />
              <p className="faq_content">{content_11[3]}</p>
              <img src={getDifLangImg("11_4.png")} style={{ width: "320px" }} />
            </div>
          )}
        </>
      )}
      {/**12.学校账户管理人员是否可以申请奖励？ */}
      {type === 12 && (
        <>
          <TitleComponent
            title={titles[11]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_12[0]}</p>
              <p className="faq_content">{content_12[1]}</p>
              <img src={getDifLangImg("12.png")} style={{ width: "320px" }} />
            </div>
          )}
        </>
      )}
      {/**13.申请加入School Glory平台之后，可以先做什么？ */}
      {type === 13 && (
        <>
          <TitleComponent
            title={titles[12]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_13[0]}</p>
              <p className="faq_content">{content_13[1]}</p>
              <p className="faq_content">{content_13[2]}</p>
            </div>
          )}
        </>
      )}
      {/**14.学校如何取消自动续费？ */}
      {type === 14 && (
        <>
          <TitleComponent
            title={titles[13]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_14[0]}</p>
              <p className="faq_content">{content_14[1]}</p>
            </div>
          )}
        </>
      )}
      {/**15.如何申请奖励？*/}
      {type === 15 && (
        <>
          <TitleComponent
            title={titles[14]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_15[0]}</p>
              <img src={getDifLangImg("15_1.png")} className="imgInDifSiz" />
              <p className="faq_content">{t("or")}</p>
              <img src={getDifLangImg("15_1_2.png")} className="imgInDifSiz" />
              <p className="faq_content">{content_15[1]}</p>
              <img src={getDifLangImg("15_2.png")} className="imgInDifSiz" />
              <p className="faq_content">{content_15[2]}</p>
              <p className="faq_content">{content_15[3]}</p>
              <img src={getDifLangImg("15_4.png")} className="imgInDifSiz" />
            </div>
          )}
        </>
      )}
      {/**16.如何更换密码? */}
      {type === 16 && (
        <>
          <TitleComponent
            title={titles[15]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_16[0]}</p>
              <p className="faq_content">{content_16[1]}</p>
              <p className="faq_content">{content_16[2]}</p>
              <p className="faq_content">{content_16[3]}</p>
              <p className="faq_content">{content_16[4]}</p>
              <p className="faq_content">{content_16[5]}</p>
            </div>
          )}
        </>
      )}

      {/**16.如何更换密码? */}
      {type === 17 && (
        <>
          <TitleComponent
            title={titles[16]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_17[0]}</p>
            </div>
          )}
        </>
      )}

      {/**17.教职人员与其家人是否需要奖励分数来兑换奖励？? */}
      {type === 18 && (
        <>
          <TitleComponent
            title={titles[17]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_18[0]}</p>
            </div>
          )}
        </>
      )}

      {/**18.学生用户、教职人员兑换过奖励后是否还能再兑换奖励? */}
      {type === 19 && (
        <>
          <TitleComponent
            title={titles[18]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_19[0]}</p>
            </div>
          )}
        </>
      )}

      {/**19.学校用户被建议添加以下奖励行为： */}
      {type === 20 && (
        <>
          <TitleComponent
            title={titles[19]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_20[0]}</p>
              <p className="faq_content">{content_20[1]}</p>
              <p className="faq_content">{content_20[2]}</p>
              <p className="faq_content">{content_20[3]}</p>
              <p className="faq_content">{content_20[4]}</p>
              <p className="faq_content">{content_20[5]}</p>
              <p className="faq_content">{content_20[6]}</p>
              <p className="faq_content">{content_20[7]}</p>
              <p className="faq_content">{content_20[8]}</p>
              <p className="faq_content">{content_20[9]}</p>
            </div>
          )}
        </>
      )}

      {/**20.如果学生已经转学/或者毕业，应该如何处理该学生的账号？ */}
      {type === 21 && (
        <>
          <TitleComponent
            title={titles[20]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_21[0]}</p>
              <p className="faq_content">{content_21[1]}</p>
              <p className="faq_content">{content_21[2]}</p>
              <p className="faq_content">{content_21[3]}</p>

              <p className="faq_content">{content_21[4]}</p>
              <img src={getDifLangImg("7_2.png")} style={{ width: "270px" }} />
              <p className="faq_content">{content_21[5]}</p>
              <p className="faq_content">{content_21[6]}</p>
            </div>
          )}
        </>
      )}

      {/**21.如果教师已不在本校授课，应该如何处理该教师的账号？ */}
      {type === 22 && (
        <>
          <TitleComponent
            title={titles[21]}
            show={show}
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="faq_container">
              <p className="faq_content">{content_22[0]}</p>
              <p className="faq_content">{content_22[1]}</p>
              <p className="faq_content">{content_22[2]}</p>
              <p className="faq_content">{content_22[3]}</p>

              <p className="faq_content">{content_22[4]}</p>
              <img src={getDifLangImg("8_2.png")} style={{ width: "270px" }} />
              <p className="faq_content">{content_22[5]}</p>
              <p className="faq_content">{content_22[6]}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SchoolFAQItem;
