import CommonPageWithHeader from "../components/CommonPageWithHeader";
import AddRewardPunishmentRuleForm from "../components/AddRewardPunishmentRuleForm";
import { useState, useEffect } from "react";
import AddRewardPunishmentRange from "../components/AddRewardPunishmentRange";
import AuthService from "../services/service";
import RuleComponent from "../components/rule/RuleComponent";
import { RewardPunishmentRule } from "../datas/atoms";
import RuleItem from "../components/rule/RuleItem";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";

const AddRewardPunishmentRule = () => {
  const { t } = useTranslation();
  const [showAddBehavierPopup, setshowAddBehavierPopup] = useState(false);
  const [showRangePopup, setShowRangePopup] = useState(false);
  const [defaultRewardRuleAmount, setDefaultRewardRuleAmount] = useState(0); // default reward amount
  const [allData, setAllData] = useState<RewardPunishmentRule[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedItem, setSelectedItem] = useState<RewardPunishmentRule>();
  const [reward_now, setRewardNow] = useState(0);

  useEffect(() => {
    initData();
    console.log("现在的addData:", allData);
  }, []);

  const getDefaultRules = () => {
    AuthService.getAllDefaultRules()
      .then(({ data }) => {
        const d = data.rules.filter(
          (m: RewardPunishmentRule) => m.type === "reward"
        );

        setDefaultRewardRuleAmount(d.length);
        setRewardNow(d.length);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  const getAllRules = () => {
    AuthService.getAllRules("reward")
      .then(({ data }) => {
        setAllData(data.rules);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  const initData = () => {
    getDefaultRules();
    getAllRules();
  };

  const onPressTurnOffAddBehavierPopup = () => {
    initData();
    setshowAddBehavierPopup(false);
  };

  const onPressTurnOffRangePopup = () => {
    setShowRangePopup(false);
  };

  const onPressDelete = (item: RewardPunishmentRule) => {
    setLoading(true);
    AuthService.deleteRule(item._id)
      .then(() => {
        initData();
        setSelectedItem(undefined);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setSelectedItem(undefined);
        setLoading(false);
      });
  };

  const onPressEdit = (item: RewardPunishmentRule) => {
    setSelectedItem(item);
    setshowAddBehavierPopup(true);
  };

  return (
    <CommonPageWithHeader
      explain={true}
      type={6}
      loading={loading}
      error={error}
    >
      <div className="rewardPunishContainer">
        {/**add reward rule button */}
        {/* 总数 */}
        {t("total")}：{reward_now + allData.length}/
        {import.meta.env.VITE_TOTAL_ALLOW}
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginTop: "2%", padding: "1rem 2rem" }}
          onClick={() => {
            if (
              reward_now + allData.length >=
              import.meta.env.VITE_TOTAL_ALLOW
            ) {
              return setError(t("rule_reached_limitation:"));
            }
            setSelectedItem(undefined);
            setshowAddBehavierPopup(true);
          }}
        >
          {/* 添加奖励行为 */}
          {t("add_reward_rule_btn")}
          <FaPlus size={"1.5rem"} color={"white"} style={{ marginLeft: 5 }} />
        </button>
        {/**add points range */}
        {/**rewards rules */}
        <div className="rulesContainer">
          <RuleComponent type={"reward"} />
          <ol className="list-group">
            {allData.length > 0 &&
              allData.map((item: any, index: number) => (
                <li className={"list-group-item-each"} key={index}>
                  <RuleItem
                    item={item}
                    index={index + defaultRewardRuleAmount}
                    content={item.content}
                    point={item.point}
                    type={item.type}
                    self_added={true}
                    onPressDelete={onPressDelete}
                    onPressEdit={onPressEdit}
                  />
                </li>
              ))}
          </ol>
        </div>
      </div>

      {showAddBehavierPopup && (
        <AddRewardPunishmentRuleForm
          handleClosePopup={onPressTurnOffAddBehavierPopup}
          type="reward"
          item={selectedItem}
        />
      )}

      {showRangePopup && (
        <AddRewardPunishmentRange
          handleClosePopup={onPressTurnOffRangePopup}
          type="reward"
        />
      )}
    </CommonPageWithHeader>
  );
};

export default AddRewardPunishmentRule;
