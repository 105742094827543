import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import AuthService from "../../services/service";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import formatJoinDate from "../../utils/formatJoinDate";
import { AiOutlineCloseCircle } from "react-icons/ai";
import getStorePaymentType from "../../utils/getStorePaymentType";
import { t } from "i18next";

const StoreSubStatement = () => {
  const navigate = useNavigate();
  const [subType, setSubType] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeDate, setActiveDate] = useState("");
  const [stopSub, setStopSub] = useState("");
  const [warnPopup, setWarnPopup] = useState(false);
  const [lastSub, setLastSub] = useState("");
  const [active, setActive] = useState(false);
  const [unit, setUnit] = useState(t("pay_by_year"));

  const initData = () => {
    AuthService.getStoreSubStatement()
      .then(({ data }) => {
        setSubType(data.subStatement);
        setActiveDate(data.activeDate);
        setActive(data.active);

        if (data.stopSub !== null) {
          setStopSub(data.stopSub);
        }
        if (data.subStatement === "month") {
          const activeDate = new Date(data.activeDate);
          const year = activeDate.getFullYear();
          const month = activeDate.getMonth();
          const lastDayOfMonth = new Date(year, month + 1, 0);
          setLastSub(lastDayOfMonth.toISOString());
        } else if (data.subStatement === "year") {
          setUnit(t("pay_by_year"));
          const activeDate = new Date(data.activeDate);
          const year = activeDate.getFullYear() + 1;
          const month = activeDate.getMonth();
          const day = activeDate.getDate();
          setLastSub(new Date(year, month, day).toISOString());
        } else if (data.subStatement === "life") {
          setUnit(t("pay_by_life"));
          return;
        }
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    initData();
  }, []);

  const onPressUnsuscribe = () => {
    AuthService.unsubscribe()
      .then(() => {
        initData();
        setLoading(false);
        setWarnPopup(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };
  return (
    <CommonPageWithHeader loading={loading} error={error}>
      <div className="normalCenterContainerColumn">
        <RiArrowGoBackFill
          className="backIcon"
          color={"black"}
          size={"2rem"}
          onClick={() => navigate(-1)}
          style={{ marginBottom: "2rem" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/**订阅出现问题 */}
          {!active && (
            <p>
              {/* 续费流程出现异常，请重新续费 */}
              {t("error_in_prolong_payment")}
            </p>
          )}
          {/**订阅时间 */}
          {stopSub === "" && active && (
            <p style={{ fontSize: "1.5rem" }}>
              {/* 激活时间 */}
              {t("active_date")}： {formatJoinDate(activeDate)}
            </p>
          )}

          {stopSub && active && (
            <div style={{ display: "flex" }}>
              <p style={{ fontSize: "1.5rem" }}>
                {/* 到期时间 */}
                {t("terminal_at")}： {formatJoinDate(stopSub)}
              </p>
            </div>
          )}

          {/**订阅状态 */}
          {active && (
            <p style={{ fontSize: "1.5rem" }}>
              {/* 订阅方式 */}
              {t("sub_method")}： {t(getStorePaymentType(subType))}
              {unit}
            </p>
          )}
          {/**re subscribe */}
          {(stopSub || !active) && (
            <button
              type="button"
              className="btn btn-primary"
              style={{ padding: "1rem 2rem", marginTop: "1rem" }}
              onClick={() => navigate("/paying-progress-store")}
            >
              {/* 重新续费 */}
              {t("re_sub")}
            </button>
          )}

          {stopSub === "" && active && subType !== "life" && (
            <p style={{ fontSize: "1.5rem" }}>
              {/* 状态：自动续费 */}
              {t("sate_prolong")}
            </p>
          )}
          {/**Unsubscribe button */}
          {subType !== "life" && stopSub === "" && (
            <div className="btnsContainer">
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginTop: "2rem", padding: "1rem 3rem" }}
                onClick={() => setWarnPopup(true)}
              >
                {/* 取消自动续费 */}
                {t("cancel_prolong")}
              </button>

              <button
                type="button"
                className="btn btn-primary"
                style={{
                  marginTop: "2rem",
                  padding: "1rem 3rem",
                  marginLeft: "1rem",
                }}
                onClick={() => navigate("/paying-progress-store")}
              >
                {/* 更换付费方式 */}
                {t("change_payment_method")}
              </button>
            </div>
          )}
        </div>
      </div>
      {warnPopup && (
        <div className="addHeroComponent">
          <div className="addEditHeroContainer">
            <p>
              {/* 取消订阅后，你仍可以使用到 */}
              {t("after_cancel_you_still")}：
            </p>
            <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              {formatJoinDate(lastSub)}
            </p>
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginTop: "2rem", padding: "1rem 3rem" }}
              onClick={onPressUnsuscribe}
            >
              {/* 确认取消订阅 */}
              {t("confirm_cancel_sub")}
            </button>
          </div>
          <AiOutlineCloseCircle
            size={"3rem"}
            color={"white"}
            style={{ marginTop: "1rem" }}
            onClick={() => setWarnPopup(false)}
            className="iconHover"
          />
        </div>
      )}
    </CommonPageWithHeader>
  );
};

export default StoreSubStatement;
