import { useTranslation } from "react-i18next";

interface Props {
  email: string;
  handleEmailVerify: (otp: any) => void;
  onPressCheckIfCodeValid: () => void;
}

const SentOtpComponent = ({
  email,
  handleEmailVerify,
  onPressCheckIfCodeValid,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="sendVerifyCodeComponent">
      <div
        style={{
          width: "80%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background: "white",
          borderRadius: 10,
        }}
      >
        <h3
          style={{
            fontWeight: "normal",
            marginTop: "5%",
            fontSize: "1.5rem",
          }}
        >
          {/* 验证码已经发送到邮箱： */}
          {t("verify_code_send")}
        </h3>

        <h3 style={{ marginTop: 10, marginBottom: 16 }}>{email}</h3>

        <>
          <input
            id="otp"
            type="password"
            className="form-control"
            placeholder={t("enter_verify_code")}
            style={{ width: "50%", padding: 10 }}
            onChange={(otp) => handleEmailVerify(otp)}
          />
          <button
            className="noticeBtn"
            onClick={onPressCheckIfCodeValid}
            style={{ marginTop: "5%" }}
          >
            {/* 下一步 */}
            {t("process_payment")}
          </button>
        </>
      </div>
    </div>
  );
};

export default SentOtpComponent;
