import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { useEffect, useState } from "react";
import AuthService from "../services/service";
import Switch from "react-switch";

const SchoolStatement = () => {
  const [statementNow, setStatementNow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    AuthService.getSchoolStatement()
      .then(({ data }) => {
        console.log("现在前端收到的data*****:", data.statement);
        setStatementNow(data.statement);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };
  const handleSwitchChange = (checked: boolean) => {
    // 根据开关状态设置 statementNow 的值
    setStatementNow(checked ? true : false);
    if (statementNow === true) {
      AuthService.schoolInactivePage()
        .then()
        .catch((e) => {
          setError(e);
          setStatementNow(true);
        });
    } else {
      AuthService.schoolActivePage()
        .then()
        .catch((e) => {
          setError(e);
          setStatementNow(false);
        });
    }
  };

  return (
    <CommonPageWithHeader loading={loading} error={error}>
      <div className="normalCenterContainerColumn">
        {statementNow ? (
          <div className="normalCenterContainerColumn">
            <p style={{ fontSize: "1.2rem" }}>学校在首页展示中...</p>
            {/**switch 为check的状态 */}
            <Switch
              onChange={handleSwitchChange}
              checked={true} // 当 statementNow 为 "all-good" 时设置为 checked
            />
          </div>
        ) : (
          <div className="normalCenterContainerColumn">
            <p style={{ fontSize: "1.2rem" }}> 学校已不在首页中展示...</p>
            {/**switch 为uncheck的状态 */}
            <Switch
              onChange={handleSwitchChange}
              checked={false} // 当 statementNow 不是 "all-good" 时设置为 unchecked
            />
          </div>
        )}
      </div>
    </CommonPageWithHeader>
  );
};

export default SchoolStatement;
