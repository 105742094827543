import React, { useState } from "react";

interface Props {
  type: string;
  onSelect: (value: string) => void; // 添加一个 onSelect 回调函数
  defaultSelectedNum?: string;
}

const PointSelector = ({ type, onSelect, defaultSelectedNum }: Props) => {
  function generateNumbers(start: number, end: number) {
    const numbers = [];
    const step = start < end ? 1 : -1; // 确定循环递增还是递减
    for (let i = start; i !== end + step; i += step) {
      numbers.push(i);
    }
    return numbers;
  }

  const positiveNumbers = generateNumbers(1, 100);
  const negativeNumbers = generateNumbers(-1, -100);
  const [selectedValue, setSelectedValue] = useState(""); // 用于跟踪所选的值

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value);
    onSelect(event.target.value); // 调用传入的 onSelect 回调函数
  };

  return (
    <select
      className="custom-select my-2 mr-sm-1"
      id="inlineFormCustomSelectPref"
      onChange={handleSelectChange} // 在选择变化时触发事件处理函数
      value={selectedValue ?? ""} // 控制选中的值
    >
      <option
        value={defaultSelectedNum || (type === "reward" ? "1" : "-1")}
        className="hide"
      >
        {defaultSelectedNum || (type === "reward" ? "1" : "-1")}
      </option>{" "}
      {/* 使用 value 属性而不是 selected */}
      {type === "reward"
        ? positiveNumbers.map((m) => (
            <option key={m} value={m}>
              {m}
            </option>
          ))
        : negativeNumbers.map((m) => (
            <option key={m} value={m}>
              {m}
            </option>
          ))}
    </select>
  );
};

export default PointSelector;
