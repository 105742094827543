import { Link } from "react-router-dom";
import StoreFaqItem from "../../components/faq/StoreFaqItem";
import { useTranslation } from "react-i18next";

const StoreFAQ = () => {
  const { t } = useTranslation();
  return (
    <div>
      {/**1.奖励商店的意义？*/}
      <StoreFaqItem type={1} />
      {/**2.适合什么样的商家加入？*/}
      <StoreFaqItem type={2} />
      {/**3.什么行业不允许加入？*/}
      <StoreFaqItem type={3} />
      {/**4.奖励商店的商家可以做什么？*/}
      <StoreFaqItem type={4} />
      {/**5.所需要的资料是什么？*/}
      <StoreFaqItem type={5} />
      {/**6.平台提供什么使用渠道？*/}
      <StoreFaqItem type={6} />
      {/**7.商家如何判断学生或教师是否符合奖励规定？*/}
      <StoreFaqItem type={7} />
      {/**8.奖励商城首页的默认排列顺序是依照什么排列的？*/}
      <StoreFaqItem type={8} />
      {/**9.学生的奖励分数是如何来的？*/}
      <StoreFaqItem type={9} />
      {/**10.商家加入奖励商城的步骤*/}
      <StoreFaqItem type={10} />
      {/**11.如何修改奖励内容*/}
      <StoreFaqItem type={11} />
      {/**12.如何暂时撤下奖励商城*/}
      <StoreFaqItem type={12} />
      {/**13.如何更换付款方式*/}
      <StoreFaqItem type={13} />
      {/**14.如何更换密码*/}
      <StoreFaqItem type={14} />
      {/**14.填写资料建议使用哪种语言*/}
      <StoreFaqItem type={15} />
      {/**学生用户、教职人员兑换过奖励后是否还能再兑换奖励？ */}
      <StoreFaqItem type={16} />

      <Link to="/store-register-email" style={{ fontSize: "1.5rem" }}>
        {t("store_register_title")} →
      </Link>
    </div>
  );
};

export default StoreFAQ;
