import Switch from "react-switch";
import { useState, useEffect } from "react";
import formatJoinDate from "../../utils/formatJoinDate";
import { useTranslation } from "react-i18next";

interface Props {
  _id: string;
  userId: string;
  schoolId: string;
  reportContent: string;
  state: boolean;
  time: string;
  image: string;
  studentEmail: string;
  studentName: string;
  studentInfo: string;
  userImg: string;
  mode: string; // all ; pending ;completed
  onPressStateChange: (reportId: string, mode: string) => void; // 添加这行声明
}

const ReportItem = ({
  _id,
  reportContent,
  state,
  time,
  image,
  studentEmail,
  studentName,
  studentInfo,
  userImg,
  mode,
  onPressStateChange,
}: Props) => {
  const { t } = useTranslation();
  const [switchMode, setSwitchMode] = useState(state);
  const img = `${import.meta.env.VITE_IMG_URL}${image}`;
  const userImage = `${import.meta.env.VITE_IMG_URL}${userImg}`;
  const handleSwitchChange = () => {
    setSwitchMode(!switchMode); // Toggle switch state
    onPressStateChange(_id, mode); // Call the parent function with the report ID
  };

  useEffect(() => {
    if (mode === "all") {
      setSwitchMode(state);
    } else if (mode === "pending") {
      setSwitchMode(false);
    } else {
      setSwitchMode(true);
    }
  }, [mode]);

  return (
    <div className="reportItemContainer">
      {/**开关 */}
      <div className="reportSwitchContainer">
        {/**把这个function传到父组件，需要把这个Item的state也传到父组件，这个功能是改变switchMode,当switchMode是true的时候，也就是state为true,也就是item从pending转换为completed */}
        <Switch onChange={handleSwitchChange} checked={switchMode} />
        {/* {!state && "未处理"} */}
        {!state && t("undeal")}
        {state && t("dealed")}
      </div>
      {/**用户头像 */}
      <img src={userImage} className="reportImg" />
      {/**用户名 */}
      <p style={{ fontSize: "1.2rem", fontWeight: "bold", margin: "1rem" }}>
        {studentName}
      </p>
      {/**用户Email */}
      <p style={{ fontSize: "1.2rem" }}>{studentEmail}</p>
      {/**用户班级*/}
      <p style={{ fontSize: "1.2rem" }}>{studentInfo}</p>
      {/**用户投诉内容 */}
      <div
        style={{
          display: "flex",
          width: "65%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <p style={{ fontWeight: "bold", marginLeft: "1rem" }}>
          ------{t("report_content")}------
        </p>
        <p>{formatJoinDate(time)}</p>
        <p>{reportContent}</p>
      </div>
      {/**证据 */}
      <img src={img} className="evidenceImg" />
    </div>
  );
};

export default ReportItem;
