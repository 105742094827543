import TargetItem from "./TargetItem";
import { useTranslation } from "react-i18next";

interface Props {
  onPressReward: () => void;
  onPressPunishment: () => void;
}

const SchoolPlanComponent = ({ onPressPunishment, onPressReward }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="schooltargetcontainer">
      {/**Bonus */}

      {/**Rules */}
      <div className="targetAllContainer">
        {/**起始时间 */}

        {/* <TargetItem
          type={0}
          title={"起始时间"}
          content={"2022年1月至4月"}
          school={true}
        /> */}

        {/**可参与瓜分奖金的分数 */}
        <TargetItem
          type={3}
          title={t("reward_behaviar")}
          content={"4"}
          school={false}
          onPressPop={onPressReward}
        />

        {/**惩罚项目 */}
        <TargetItem
          type={4}
          title={t("punish_behaviar")}
          content={"10"}
          school={false}
          onPressPop={onPressPunishment}
        />
      </div>
    </div>
  );
};

export default SchoolPlanComponent;
