import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { MdOutlineClear } from "react-icons/md";
import { useEffect, useState } from "react";
import AddHeroForm from "../components/AddHeroForm";
import AuthService from "../services/service";
import AlertDeleteHero from "../components/AlertDeleteHero";
import { useTranslation } from "react-i18next";
import IconsExplain from "../components/IconsExplain";
import { Hero } from "../datas/atoms";
import { HeroItem } from "../components/HeroItem";
import { BiSolidSquareRounded } from "react-icons/bi";
import { useLocation } from "react-router-dom";

import AddEventForm from "../components/AddEventForm";

const SchoolProfileHeros = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [data, setDatas] = useState<Hero[]>([]);
  const [filteredDatas, setFilteredDatas] = useState<Hero[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedHero, setSelectedHero] = useState<Hero>();
  const [alertPop, setAlertPop] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [addEventPopup, setAddEventPopup] = useState(false);
  const [selectedIdentity, setSelectedIdentity] = useState("name");
  const [selectedOption] = useState("all"); // 初始选中"全部"
  const [expiredCount, setExpiredCount] = useState(0);
  const [validCount, setValidCount] = useState(0);
  const [getStatement, setGetStatement] = useState(""); // check if navigate from add reward or add punishment

  useEffect(() => {
    initData();
  }, []);

  const detectStatement = () => {
    if (location.state) {
      if (location.state.statement === "punishment") {
        setGetStatement("punishment");
      } else if (location.state.statement === "reward") {
        setGetStatement("reward");
      } else {
        setGetStatement("");
      }
    } else {
      setGetStatement("");
    }
  };

  const initData = () => {
    detectStatement();
    // 获取所有英雄数据
    setLoading(true);

    AuthService.getAllHeros()
      .then(({ data }) => {
        // 对 data.data 进行映射，创建包含 student 和 state 的新对象

        // 计算已过期和未过期账号的数量
        const currentDate = new Date();
        const expiredHeroes = data.data.filter((hero: Hero) => {
          const validDate = new Date(hero.validDate);
          return currentDate > validDate;
        });

        setExpiredCount(expiredHeroes.length);
        setValidCount(data.data.length - expiredHeroes.length);

        setDatas(data.data);
        setFilteredDatas(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Erros ：", error.response.data.message);
        setError(error.response.data.message);
      });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);

    // 使用输入值来筛选英雄
    const filteredHeroes = data.filter((hero) => {
      const heroToSearch =
        selectedIdentity === "email" ? hero.studentEmail : hero.heroName;
      return heroToSearch.toLowerCase().includes(inputValue.toLowerCase());
    });

    // 如果选择了筛选方式，只显示符合筛选条件的英雄
    if (selectedOption === "reading") {
      const currentDate = new Date();
      const filteredReadingHeroes = filteredHeroes.filter((hero) => {
        const validDate = new Date(hero.validDate);
        return currentDate <= validDate;
      });
      setFilteredDatas(filteredReadingHeroes);
    } else if (selectedOption === "graduated") {
      const currentDate = new Date();
      const filteredGraduatedHeroes = filteredHeroes.filter((hero) => {
        const validDate = new Date(hero.validDate);
        return currentDate > validDate;
      });
      setFilteredDatas(filteredGraduatedHeroes);
    } else {
      setFilteredDatas(filteredHeroes);
    }
  };

  const handleNameClear = () => {
    setInputValue("");
    setFilteredDatas(data);
  };

  const onPressPreDelete = (value: Hero) => {
    setSelectedHero(value);
    setAlertPop(true);
  };

  const onPressDelete = (value: any) => {
    setLoading(true);
    setAlertPop(false);
    AuthService.deleteHero(value)
      .then(() => {
        initData();
        setSelectedHero(undefined);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Erros ：", error.response.data.message);
        setError(error.response.data.message);
      });
  };

  const onPressEdite = (value: Hero) => {
    console.log("现在的edit选中：", value);
    setSelectedHero(value);
    setShowPopup(true);
  };

  const onPressAddEvent = (value: Hero) => {
    setSelectedHero(value);
    setAddEventPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    initData();
  };

  const onPressCancel = () => {
    setSelectedHero(undefined);
    setAlertPop(false);
  };

  const onPressConfirm = () => {
    onPressDelete(selectedHero!._id);
  };

  const handleIdentityChange = (event: any) => {
    setSelectedIdentity(event.target.value);
  };

  const handleShowAllState = () => {
    if (inputValue === "") {
      setFilteredDatas(data);
    }
    if (inputValue !== "") {
      setFilteredDatas(filteredDatas);
    }
  };
  const handleShowValidState = () => {
    if (inputValue === "") {
      const filted = data.filter((m) => new Date() < new Date(m.validDate));
      setFilteredDatas(filted);
    }
  };
  const handleShowInvalidState = () => {
    if (inputValue === "") {
      const filted = data.filter((m) => new Date() > new Date(m.validDate));
      setFilteredDatas(filted);
    }
  };

  return (
    <CommonPageWithHeader
      error={error}
      loading={loading}
      explain={true}
      type={1}
    >
      <div className="heroSearchContainer">
        <input
          type="text"
          value={inputValue}
          className="searchHeroInput"
          onChange={handleInputChange}
          placeholder={`${t("enter_student_info")}${selectedIdentity}`}
          style={{
            marginLeft: 3,
            fontSize: "1.1rem",
            paddingLeft: 16,
            borderRadius: 4,
            paddingTop: "1rem",
            paddingBottom: "1rem",
            borderColor: "#b6b4b4",
          }}
        />
        {inputValue && (
          <MdOutlineClear
            color={"#666"}
            size={"1.5rem"}
            style={{ position: "absolute", right: 5 }}
            onClick={handleNameClear}
          />
        )}
        <div className="searchSelectionContainer">
          {/* 搜索内容 */}
          {t("search_content")}：
          <label>
            <input
              className="radioStyle"
              type="radio"
              value="name"
              checked={selectedIdentity === "name"}
              onChange={handleIdentityChange}
            />
            {t("student_name")}
          </label>
          <label>
            <input
              className="radioStyle"
              type="radio"
              value="email"
              checked={selectedIdentity === "email"}
              onChange={handleIdentityChange}
            />
            {t("student_email")}
          </label>
        </div>
      </div>
      <div className="twoButtonsContainer">
        <button
          type="button"
          className="btn btn-primary"
          style={{
            margin: "0 1rem",
            fontWeight: "bold",
            padding: "1rem 2rem",
            minWidth: 120,
          }}
        >
          {t("search")}
        </button>
        <button
          type="button"
          className="btn btn-warning"
          style={{
            fontWeight: "bold",
            padding: "1rem 2rem",
            color: "white",
            minWidth: 120,
          }}
          onClick={() => {
            setSelectedHero(undefined);
            setShowPopup(true);
          }}
        >
          {/* 添加学生 */}
          {t("enter_student_btn")}
        </button>
      </div>

      {data.length > 0 ? (
        <div className="herosListContainer">
          <div className="heroHeaderContainer">
            <button className="heroTitle" onClick={handleShowAllState}>
              {/* <input
                type="radio"
                id="allOption"
                name="schoolOption"
                value="all"
                checked={selectedOption === "all"}
                onChange={() => handleOptionChange("all")}
              /> */}
              {/* 全部 */}
              {t("total")}（{filteredDatas.length || data.length}）:
            </button>
            <button
              className="heroTitle"
              style={{ color: "green" }}
              onClick={handleShowValidState}
            >
              {/* <input
                type="radio"
                id="readingOption"
                name="schoolOption"
                value="reading"
                checked={selectedOption === "reading"}
                onChange={() => handleOptionChange("reading")}
              /> */}
              <BiSolidSquareRounded
                size={"1.5rem"}
                style={{ marginLeft: 10 }}
                color={"green"}
              />{" "}
              {/* 有效账号 */}
              {t("valid_accounts")}（{validCount}）:
            </button>
            <button
              className="heroTitle"
              style={{ color: "red" }}
              onClick={handleShowInvalidState}
            >
              <BiSolidSquareRounded
                size={"1.5rem"}
                style={{ marginLeft: 10 }}
                color={"red"}
              />{" "}
              {/* 已过期 */}
              {t("expired_accounts")}（{expiredCount}）:
            </button>
          </div>
          <IconsExplain />
          <div>
            <ol>
              {filteredDatas.map((item: Hero, index: number) => (
                <li key={index}>
                  <HeroItem
                    home={false}
                    item={item}
                    onPressPreDelete={() => onPressPreDelete(item)}
                    onPressEdit={() => onPressEdite(item)}
                    onPressAddEvent={() => onPressAddEvent(item)}
                    valid={new Date() < new Date(item.validDate)}
                  />
                </li>
              ))}
            </ol>
          </div>
        </div>
      ) : (
        <p style={{ marginTop: "3rem" }}>---{t("no_record_yet")}---</p>
      )}

      {showPopup && (
        <AddHeroForm
          handleClosePopup={handleClosePopup}
          hero={selectedHero}
          // valid={new Date() < new Date(selectedHero!.validDate)}
          valid={
            selectedHero
              ? new Date() < new Date(selectedHero!.validDate)
              : false
          }
        />
      )}

      {addEventPopup && (
        <AddEventForm
          hero={selectedHero}
          handleClosePopup={() => setAddEventPopup(false)}
          statement={getStatement}
        />
      )}

      {alertPop && (
        <AlertDeleteHero
          onPressCancel={onPressCancel}
          onPressConfirm={onPressConfirm}
          heroName={selectedHero?.heroName}
          email={selectedHero?.studentEmail}
        />
      )}
    </CommonPageWithHeader>
  );
};

export default SchoolProfileHeros;
