import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { useTranslation } from "react-i18next";

import InputNormal from "../components/InputNormal";
import { useState, useRef, useEffect } from "react";
import LocationSelector from "../components/LocationSelector";
import TypeSelector from "../components/TypeSelector";
import AuthService from "../services/service";
import NoticeForChineseUser from "../components/NoticeForChineseUser";

const RegisterSchoolFormInfo = () => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [popupNotice, setPopupNotice] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("basic"); // basic , stander,pro

  const nameRef = useRef(null);
  const [image, setImage] = useState<null | string>(null);
  const addressRef = useRef(null);
  const iconRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);
  const typeRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangeSchoolName = (e: any) => {
    nameRef!.current = e.target.value;
  };
  const handleIconClick = () => {
    iconRef!.current!.click();
  };
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    //console.log("handleChange收到的file:", file);
    setImage(URL.createObjectURL(file));
  };

  const handleChangeAddress = (e: any) => {
    addressRef!.current = e.target.value;
  };
  const handleTypeSelected = (value: any) => {
    typeRef!.current!.value = value;
  };

  const handleLocationSelected = (value: any) => {
    locationRef!.current!.value = value;
  };

  const handleUpload = () => {
    setLoading(true);
    if (locationRef!.current!.value === "CN") {
      setLoading(false);
      return setPopupNotice(true);
      // return console.log("走了CN");
    }

    if (
      nameRef.current &&
      locationRef.current!.value &&
      typeRef!.current!.value &&
      addressRef.current &&
      typeRef!.current!.value
    ) {
      const formData = new FormData();
      formData.append("name", nameRef.current);
      formData.append("icon", iconRef!.current!.files![0]);
      formData.append("region", locationRef!.current!.value);
      formData.append("type", typeRef!.current!.value);
      formData.append("address", addressRef.current);
      AuthService.createSchoolProfile(formData)
        .then(async () => {
          handleCheckoutSession();
        })
        .catch((error) => {
          setLoading(false);
          //console.log("学校资料上传失败！", error);
          // 处理错误，例如显示错误消息
          return setError(error.response.data.message);
        });
    } else {
      setLoading(false);
      return setError(t("inComplete"));
    }
  };

  const handleCheckoutSession = () => {
    console.log("走了handleCheckoutSession");
    AuthService.createCheckoutSession(selectedPaymentType)
      .then(({ data }) => {
        window.location.replace(data.url);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const uploadCNInfo = () => {
    // setLoading(true);

    if (
      nameRef.current &&
      locationRef.current!.value &&
      typeRef!.current!.value &&
      addressRef.current &&
      typeRef!.current!.value
    ) {
      const formData = new FormData();
      formData.append("name", nameRef.current);
      formData.append("icon", iconRef!.current!.files![0]);
      formData.append("region", locationRef!.current!.value);
      formData.append("type", typeRef!.current!.value);
      formData.append("address", addressRef.current);
      AuthService.createSchoolProfile(formData)
        .then(async () => {
          handleCheckoutSession();
        })
        .catch((error) => {
          setLoading(false);
          //console.log("学校资料上传失败！", error);
          // 处理错误，例如显示错误消息
          return setError(error.response.data.message);
        });
    } else {
      setLoading(false);
      return setError(t("inComplete"));
    }
  };

  const handleOptionChange = (event: any) => {
    setSelectedPaymentType(event.target.value);
  };

  return (
    <CommonPageWithHeader error={error} loading={loading}>
      <div className="registerFormContainer">
        <h1 className="registerTitleH1">
          {t("school_glory_school_register")} (2/5)
        </h1>

        {/**all inputs */}
        {/**title */}

        <div className="allInputsContainer">
          {/**校名 */}
          <InputNormal
            placeholder="school_name"
            name={"name"}
            className="normalInputContainer"
            handleChange={handleChangeSchoolName}
          />
          {/**Location*/}
          <LocationSelector
            onLocationSelected={handleLocationSelected}
            locationSelectRef={locationRef}
          />
          {/**Type*/}
          <TypeSelector
            onTypeSelected={handleTypeSelected}
            typeSelectRef={typeRef}
          />
          {/*address*/}
          <InputNormal
            placeholder="school_address"
            name={"address"}
            className="normalInputContainer"
            handleChange={handleChangeAddress}
          />

          {/**校徽 */}
          <div className="imgContainerDiv" onClick={handleIconClick}>
            <div
              style={{
                width: 100,
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(181, 160, 160, 0.377)",
                borderRadius: 100,
              }}
            >
              {image !== null ? (
                <img
                  src={image}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 100,
                    objectFit: "cover",
                  }}
                  alt="School Icon"
                />
              ) : (
                <img
                  src={"/images/picture.png"}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 100,
                    objectFit: "cover",
                  }}
                  alt="Default Icon"
                />
              )}
            </div>

            <input
              type="file"
              ref={iconRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />

            <p style={{ marginTop: "1rem", color: "blue" }}>
              {/* 请使用白色底，jpg格式的图片 */}
              {t("school_emble")}:{t("image_require")}
            </p>
          </div>

          <div>
            ---
            {/* 请选择你的付款计划 */}
            {t("select_your_plan_tip")}
            ---
          </div>
          <div className="selectPaymentSchoolRadiosContainer">
            <label>
              <input
                type="radio"
                value="basic"
                checked={selectedPaymentType === "basic"}
                onChange={handleOptionChange}
                className="radionStyle"
              />
              €{import.meta.env.VITE_SCHOOL_BASE}/{t("year_plan")}{" "}
              {t("basic_plan_explain")}
            </label>
          </div>
          {/**Submit button */}
          <button
            className="noticeBtn"
            // disabled={isStrong ? false : true}
            onClick={handleUpload}
            style={{ marginTop: 16 }}
          >
            {/* 下一步 */}
            {t("process_payment")}
          </button>
        </div>
      </div>
      {popupNotice && <NoticeForChineseUser onPressConfirm={uploadCNInfo} />}
    </CommonPageWithHeader>
  );
};

export default RegisterSchoolFormInfo;
