import { useState, useRef } from "react";
import { RiMailLockLine } from "react-icons/ri";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ErrorMsg from "../components/ErrorMsg";
import AuthService from "../services/service";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";

const TeacherPasswordForgotten = () => {
  const { t } = useTranslation();
  const [main, setMain] = useState(true);
  const [resetPage, setResetPage] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  // const [schoolId, setSchoolId] = useState("");
  const schoolVerifyCodedRef = useRef<HTMLInputElement>(null);
  // const schoolIdRef = useRef<HTMLInputElement>(null);
  const schoolPasswordRef = useRef<HTMLInputElement>(null);
  const [resetToken, setResetToken] = useState("");
  const navigate = useNavigate();
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  // const handleLoginIdChange = (e: any) => {
  //   setSchoolId(e.target.value);
  // };

  const onPressSubmit = () => {
    if (email === "") {
      return setError("Email不能为空。");
    }
    setLoading(true);
    AuthService.teacherForgetPassword(email)
      .then(({ data }) => {
        if (data.resetToken) {
          setResetToken(data.resetToken);
          setMain(false);
          setResetPage(true);
          setLoading(false);
        } else {
          return setError("Incorrect Infos");
        }
      })
      .catch((error) => {
        setLoading(false);
        return setError(error);
      });
  };

  const onPressSubmitAll = () => {
    if (
      email !== "" &&
      schoolVerifyCodedRef!.current!.value !== null &&
      schoolPasswordRef!.current!.value !== null
      // schoolIdRef!.current!.value !== null
    ) {
      setError("");
      setLoading(true);

      AuthService.teacherResetPassword(
        email,
        schoolPasswordRef!.current!.value,
        schoolVerifyCodedRef!.current!.value,
        resetToken
      )
        .then(({ data }) => {
          const { token } = data;

          localStorage.setItem("teacherUser", token);

          //这里需要马上获取用户资料，并且把头像和和一切都放在user那里

          setLoading(false);
          window.location.href = "/";
        })
        .catch((error) => {
          setLoading(false);

          // 处理错误，例如显示错误消息
          return setError(error.response.data.message);
        });
    } else {
      return setError("The uploaded information is incomplete!");
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {main && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                padding: "6%",
                fontSize: "1.2rem",
              }}
            >
              {error && <ErrorMsg error={error} />}
              {/**back */}
              <IoMdArrowRoundBack
                color={"black"}
                size={40}
                className="backIconSchool"
                onClick={() => navigate(-1)}
              />
              {/**title */}
              <RiMailLockLine color={"#666"} size={100} />
              {/**content */}
              <h2
                style={{
                  fontWeight: "bold",
                  marginTop: 30,
                  textAlign: "center",
                  fontSize: "1.5rem",
                }}
              >
                {/* Forgot Password ? */}
                {t("password_forgotten")}
              </h2>
              {/**email input */}
              <p style={{ marginTop: 16, fontSize: 15, textAlign: "center" }}>
                {/* Please enter your Email below to get your verify code. */}
                {/* {t("please_enter_your_email_below_to_get_your_verify_code")} */}
              </p>
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                style={{
                  width: "50%",
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 10,
                  paddingLeft: 15,
                  marginTop: 10,
                  marginBottom: 16,
                }}
                value={email}
                onChange={handleEmailChange}
              />

              {/**button*/}
              <button
                type="button"
                className="noticeBtn"
                style={{ width: "40%" }}
                onClick={onPressSubmit}
                disabled={loading}
              >
                {loading ? t("loading") : t("submit")}
              </button>
            </div>
          )}

          {resetPage && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {error && <ErrorMsg error={error} />}
              {/**back */}

              <h1
                style={{
                  fontWeight: "bold",
                  marginTop: 30,
                  textAlign: "center",
                }}
              >
                {/* Forgot Password ? */}
                {t("reset_password")}
              </h1>
              {/**verify codes input*/}
              <p style={{ color: "blue", fontSize: "1.2rem", margin: "1rem" }}>
                {" "}
                {t("verify_code_send")}:{email}
              </p>
              {/**Password*/}
              <input
                ref={schoolVerifyCodedRef}
                type="password"
                className="form-control"
                placeholder={t("enter_verify_code")}
                style={{
                  width: "50%",
                  height: "7.8%",
                  borderRadius: 10,
                  paddingLeft: 15,
                  marginTop: 16,
                }}
              />
              {/**new password*/}
              {/**Password*/}
              <input
                ref={schoolPasswordRef}
                type="password"
                className="form-control"
                placeholder={t("newPassword")}
                style={{
                  width: "50%",
                  height: "7.8%",
                  borderRadius: 10,
                  paddingLeft: 15,
                  marginTop: 16,
                }}
              />
              {/**login id */}
              {/**Login ID*/}
              {/* <input
                    ref={schoolIdRef}
                    className="form-control"
                    placeholder={"School ID"}
                    style={{
                      width: "50%",
                      height: "7.8%",
                      borderRadius: 10,
                      paddingLeft: 15,
                      marginTop: 16,
                    }}
                  /> */}

              {/**button*/}
              <button
                type="button"
                className="noticeBtn"
                style={{ width: "40%" }}
                onClick={onPressSubmitAll}
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TeacherPasswordForgotten;
