import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const StoryBehind = ({ selectedLanguage }: { selectedLanguage: string }) => {
  const { t } = useTranslation();

  const getGearsRunLink = () => {
    return selectedLanguage === "zh" ? "www.gearsrun.net" : "www.gearsrun.com";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CommonPageWithHeader>
      {/**content */}
      <div className="noticeContentContainer">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: 16,
          }}
        >
          {" "}
          <h2 style={{ fontWeight: "bold", fontSize: "1.8rem" }}>
            {/* REGISTRATION INSTRUCTIONS */}
            {t("story_behind_title")}
          </h2>
        </div>
        <p style={{ lineHeight: 1.8, fontSize: "1.5rem", color: "#2a2929" }}>
          {/* My name is Dongjing Zhong, the founder of Gears run{" "} */}
          {t("story_behide_1")}
          <span className="link">
            {" "}
            <a
              href={`http://${getGearsRunLink()}`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              ({t("view")})
            </a>
          </span>
          {/* and the creator of the School Glory platform.  */}{" "}
          {t("story_behind_1_1")}
        </p>

        {/* Every time I hear of children being harassed or hurt in school, my blood
        boils and I find myself gritting my teeth.  */}

        <p className="noticeP">
          {t("story_behide_2")} {t("story_behide_3")}
        </p>
        {/* For a long time, I have been contemplating how to address this issue at
        its core.  */}

        {/* Realizing that hatred only spawns more enmity, I came up with a
        proactive approach to tackle this issue by creating School Glory. It is
        a platform that encourages schools which are dedicated to creating a
        peaceful, joyful and safe atmosphere for students to join. Eventually, I
        decided to take a more proactive approach. Thus, I created the School
        Glory platform, inviting schools that are determined to provide a safe,
        harmonious, and joyful environment for students to join.  */}
        <p className="noticeP">{t("story_behide_4")}</p>
        {/* However, if a school experiences a campus violence incident, they are
        required to voluntarily withdraw from School Glory until the problem is
        resolved and there is no further violence in the span of three months.
        Only then can they rejoin. School Glory is a hall of honor, recognizing
        schools that consistently prioritize the safety and well-being of their
        students. */}
        <p className="noticeP">{t("story_behide_5")}</p>
        <br />
        <p className="noticeP">
          {/* At first, I planned to let schools sign up for free. But it was clear
        that we had to establish a verification system to combat fraudulent
        accounts. Instead, a one-time registration fee was implemented, and the
        funds from this fee will be used to form the School Glory Charity
        Foundation. The goal of this organization is to help reduce bullying and
        promote a safe and healthy educational environment within the school. */}
          {t("story_behide_6")}
        </p>

        <br />
        <p className="noticeP" style={{ marginBottom: 30 }}>
          {/* When you start walking the way ,the way appears .Join us at School Glory
        and make educational institutions the best place to build dreams! */}
          {t("story_behide_7")}
        </p>

        <div
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            marginTop: 32,
          }}
        >
          <Link type="button" className="noticeBtn" to={"/register"}>
            {t("more_detials")}
          </Link>
        </div>
      </div>
    </CommonPageWithHeader>
  );
};

export default StoryBehind;
