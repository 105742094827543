import moment from "moment";
import getUserLocale from "get-user-locale";
import "moment/dist/locale/zh-cn";
import "moment/dist/locale/es";

const changeToLocaleTime = (timeString: string) => {
  const userLocale = getUserLocale().split("-")[0]; // 获取用户设备的语言设置
  console.log("现在用户设备语言：", userLocale);
  console.log("传进来的timeStamp:", timeString);

  // 根据用户的语言设置设置 moment 的语言
  switch (userLocale) {
    case "zh":
      moment.locale("zh-cn");
      break;
    case "es":
      moment.locale("es");
      break;
    default:
      moment.locale("en");
  }

  const formattedDate = moment(timeString).fromNow(); // 格式化日期
  return formattedDate;
};

export default changeToLocaleTime;
