import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import AuthService from "../services/service";
import { studentHeroAtom } from "../datas/atoms";
import { useAtom } from "jotai";
import formatJoinDate from "../utils/formatJoinDate";

const IMG_SIZE = 100;

const UserUploadInfo = () => {
  const { t } = useTranslation();
  const [hero, setHero] = useAtom(studentHeroAtom);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [image, setImage] = useState(hero.image);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [validDate, setValidDate] = useState("");
  const [validYear, setValidYear] = useState(0);
  const [schoolId, setSchoolId] = useState("");

  const imageRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    initData();
  }, []);

  const handleImageClick = () => {
    imageRef!.current!.click();
  };
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    setImage(URL.createObjectURL(file));
  };

  const handleUpload = () => {
    console.log(
      `现在的heroName:${name};image:${
        imageRef!.current!.files![0]
      },validYear:${validYear};validDate:${validDate};email:${email};schoolId:${schoolId}`
    );
    if (name !== "" && image !== null) {
      setLoading(true);
      const formData = new FormData();
      formData.append("heroName", name);
      formData.append("image", imageRef!.current!.files![0]);
      formData.append("validYear", `${validYear}`);
      formData.append("validDate", validDate);
      formData.append("studentEmail", email);
      formData.append("schoolId", schoolId);

      AuthService.userCreateHeroProfile(formData)
        .then(({ data }) => {
          console.log("收到的data：", data.hero);
          const {
            schoolId,
            studentEmail,
            image,
            heroName,
            validDate,
            validYear,
            info,
            events,
            reports,
            _id,
            time,
          } = data.hero;
          //这里需要马上获取用户资料，并且把头像和和一切都放在school Hero那里
          setHero({
            _id: _id,
            image: image,
            heroName: heroName,
            schoolId: schoolId,
            studentEmail: studentEmail,
            time: time,
            validDate: validDate,
            validYear: validYear,
            info: info,
            events: events,
            reports: reports,
          });

          setLoading(false);
          window.location.replace("/userprofile");
        })
        .catch((error) => {
          setLoading(false);

          // 处理错误，例如显示错误消息
          return setError(error.response.data.message);
        });
    } else {
      return setError("The uploaded information is incomplete!");
    }
  };

  const initData = () => {
    AuthService.getOneSingleUser()
      .then(({ data }) => {
        setName(data.data.studentName);
        setEmail(data.data.studentEmail);
        setValidDate(data.data.validDate);
        setValidYear(data.data.validYear);
        setSchoolId(data.schoolId);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  return (
    <CommonPageWithHeader error={error} loading={loading}>
      <div
        className="userUploadInfo"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <h3 style={{ marginBottom: 32, fontWeight: "bold", marginTop: "5%" }}>
          {t("school_glory_user_register")}(2/2)
        </h3>
        <div className="uploadIconContainer" onClick={handleImageClick}>
          <div
            style={{
              width: IMG_SIZE,
              height: IMG_SIZE,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(181, 160, 160, 0.377)",
              borderRadius: IMG_SIZE,
              marginTop: 5,
            }}
          >
            {image !== "" ? (
              <img
                src={image}
                style={{
                  width: IMG_SIZE,
                  height: IMG_SIZE,
                  borderRadius: IMG_SIZE,
                  objectFit: "cover",
                }}
                alt="School Icon"
              />
            ) : (
              <img
                src={"../../images/picture.png"}
                style={{
                  width: IMG_SIZE,
                  height: IMG_SIZE,
                  borderRadius: IMG_SIZE,
                  objectFit: "cover",
                }}
                alt="Default Icon"
              />
            )}
          </div>

          <input
            type="file"
            ref={imageRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </div>
        {image === "" && (
          <p style={{ color: "red", marginTop: 10 }}>请上传图片</p>
        )}
        {/**name */}
        <p className="userName">{name}</p>
        {/**email */}
        <p className="userEmail">{email}</p>
        <p className="userEmail">账号有效期至:{formatJoinDate(validDate)}</p>
        <button
          className="noticeBtn"
          style={{ marginTop: 16 }}
          onClick={handleUpload}
        >
          {t("submit")}
        </button>
      </div>
    </CommonPageWithHeader>
  );
};

export default UserUploadInfo;
