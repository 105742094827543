import ErrorMsg from "./ErrorMsg";
import { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import InputNormal from "./InputNormal";
import Loading from "./Loading";
import successImg from "/images/done.png";
import { useTranslation } from "react-i18next";
import AuthService from "../services/service";
import PointSelector from "./PointSelector";
import { Hero, RewardPunishmentRule } from "./../datas/atoms";
import { useNavigate } from "react-router-dom";

interface Props {
  handleClosePopup: () => void;
  hero?: Hero;
  statement: string;
}

const AddEventForm = ({ handleClosePopup, hero, statement }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const imgBaseUrl = import.meta.env.VITE_IMG_URL;
  const [selectOption, setSelectOption] = useState("reward");
  const [studentId] = useState(hero!._id);
  const [type, setType] = useState("reward");
  const [reason, setReason] = useState("");
  const [points, setPoints] = useState("0");
  const [allRules, setAllRules] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");

  useEffect(() => {
    initData();
  }, [selectOption]);

  const initData = () => {
    setLoading(true);
    AuthService.getAllRules(selectOption)
      .then(({ data }) => {
        setAllRules(data.rules);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  //deceide navigate to which page
  const deceideNavigateTo = () => {
    if (statement === "punishment") {
      navigate(
        "/register/register-form/register-form-school-info/awaitingPunishment"
      );
    } else if (statement === "reward") {
      navigate(
        "/register/register-form/register-form-school-info/awaitingReward"
      );
    }
  };

  const onSelectNum = (num: string) => {
    setPoints(num);
  };

  const handleOptionChange = (value: string) => {
    setSelectOption(value);
    setType(value);
    console.log("现在的selecttion:", value);
  };
  const handleReasonChange = (e: any) => {
    setReason(e.target.value);
  };
  const handleUpload = () => {
    setLoading(true);
    // deceideNavigateTo();
    console.log(
      `此时的studentId:${studentId};type:${type};reason:${reason};baseOn:${selectedReason};points:${points}`
    );
    AuthService.createEvent(studentId, type, selectedReason, reason, points)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        deceideNavigateTo();
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
      });
  };

  const handleProductChange = (event: any) => {
    const selectedContent = event.target.value; // 获取选中的产品内容
    const selectedProduct = allRules.find(
      (product: RewardPunishmentRule) => product.content === selectedContent
    ) as RewardPunishmentRule | undefined; // 使用类型断言明确指定类型

    if (selectedProduct) {
      setPoints(selectedProduct.point.toString()); // 设置所选产品的点数
    } else {
      setPoints("0"); // 如果没有选中产品，可以设置一个默认值
    }

    setSelectedReason(selectedContent); // 更新选中的产品内容
  };

  return (
    <div className="addHeroComponent">
      {/**component */}
      {error !== "" && <ErrorMsg error={error} />}
      <div className="addEditHeroContainer">
        {!loading && !success && (
          <div className="uploadStudentContainer">
            {/**image */}
            <img src={`${imgBaseUrl}${hero!.image}`} className="userImage" />
            {/**name */}
            <p style={{ fontWeight: "bold", marginTop: "1rem" }}>
              {hero!.heroName}
            </p>
            {/**email */}
            <p>{hero!.studentEmail}</p>
            <p>{hero!.info}</p>
            {/**奖励还是惩罚的radio: */}
            <div
              style={{
                display: "flex",
                padding: "2rem",
                minWidth: "50%",
                justifyContent: "space-between",
              }}
            >
              <input
                type="radio"
                id="allOption"
                name="schoolOption"
                value="reward"
                checked={selectOption === "reward"}
                onChange={() => handleOptionChange("reward")}
                style={{ marginRight: "1rem" }}
              />
              {t("reward_behaviar")}
              <input
                type="radio"
                id="allOption"
                name="schoolOption"
                value="punishment"
                checked={selectOption === "punishment"}
                onChange={() => handleOptionChange("punishment")}
                style={{ marginLeft: "1rem", marginRight: "1rem" }}
              />
              {t("punish_behaviar")}
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: "1rem",
                marginBottom: "1rem",
                flexDirection: "column",
              }}
            >
              <label style={{ fontSize: "1.2rem" }}>
                {/* 依据 */}
                {t("accord_to")}：
              </label>
              <select
                value={selectedReason}
                onChange={handleProductChange}
                style={{
                  border: "1px solid gray",
                  padding: "0.8rem 1rem",
                  borderRadius: "1rem",
                  width: "100%",
                }}
              >
                <option value=""></option>
                {allRules.map((product: RewardPunishmentRule) => (
                  <option key={product._id} value={product.content}>
                    {product.content}
                  </option>
                ))}
              </select>
            </div>
            {/**info */}
            <InputNormal
              label={t("detail_added")}
              name="reason"
              handleChange={handleReasonChange}
              className="inputContainer"
              placeholder=""
            />
            <PointSelector
              type={type}
              onSelect={onSelectNum}
              defaultSelectedNum={points}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            ></div>
            {/**Upload button */}
            <button
              type="button"
              className="btn btn-warning"
              style={{
                minWidth: 100,
                padding: "1rem ",
                marginTop: "2rem",
                fontWeight: "bold",
              }}
              onClick={handleUpload}
            >
              {t("submit")}
            </button>
          </div>
        )}
        {loading && !success && <Loading />}
        {success && !loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={successImg} style={{ width: "6rem", height: "6rem" }} />
          </div>
        )}
      </div>
      <AiOutlineCloseCircle
        size={"3rem"}
        color={"white"}
        style={{ marginTop: "1rem" }}
        onClick={handleClosePopup}
        className="iconHover"
      />
    </div>
  );
};

export default AddEventForm;
