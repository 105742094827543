import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/service";
import CommonPageWithHeader from "../../components/CommonPageWithHeader";
const StoreEmailVerify = () => {
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const otpRef = useRef("");
  const [loading, setLoading] = useState(true);
  const [readyToSend, setReadyToSend] = useState(false);

  //generate login id
  const [generateLoginId, setGenerateLoginId] = useState(false);
  const [reveal, setReveal] = useState(false);
  const [loginID, setLoginID] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const tokenIdRef = useRef("");
  const [gloCusId, setGloCusId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    //查看是否有newUser,如果有，删除，查看是否有temp和email，有删除
    const newUserToken = localStorage.getItem("newUser");

    if (newUserToken) {
      localStorage.removeItem("newUser");
    }
    //尝试验证用户是否已经付款，如果已经付款，就把setLoading设置为false
    setTimeout(() => {
      const currentURL = window.location.href;
      const paymentIntentRegex = /cus_id=([^&]+)/;
      const paymentMatch = currentURL.match(paymentIntentRegex);
      if (paymentMatch && paymentMatch.length >= 2) {
        const cus_id = paymentMatch[1];
        AuthService.storeCheckIfAlreadyPaid(cus_id)
          .then(({ data }) => {
            const { tempToken, tempEmail } = data;
            localStorage.setItem("temp", tempToken);
            localStorage.setItem("email", tempEmail);
            setToken(tempToken);
            setEmail(tempEmail);
            setGloCusId(cus_id);
            setLoading(false);
          })
          .catch((error) => {
            setError(error.response.data.message);
            handleIssueAppears(cus_id);
            setLoading(false);
          });
      }
    }, 2200);
  };

  const handleIssueAppears = (cus_id: string) => {
    AuthService.storeCreateIssueWhenGetError(cus_id)
      .then(({ data }) => {
        setEmail(data.tempEmail);
        //删除所有localStorage
        localStorage.removeItem("temp");
        localStorage.removeItem("email");
        localStorage.removeItem("newUser");
      })
      .catch((error) => {
        console.log("handleIssueAppears出错：", error);
      });
  };

  const handleEmailVerify = (e: any) => {
    otpRef.current = e.target.value;
  };
  const onPressCheckIfCodeValid = () => {
    setLoading(true);
    AuthService.storeVerifyEmail(otpRef.current)
      .then(() => {
        setGenerateLoginId(true);
        setLoading(false);
      })
      .catch((error) => {
        handleIssueAppears(gloCusId);
        setLoading(false);
        setError(error.response.data.message);
      });
  };

  const onPressNotReadyToSend = () => {
    setLoading(true);
    //先发送验证码,验证码已发送再揭开
    AuthService.storeSendVerifyCode()
      .then(({ data }) => {
        if (data.code === 201) {
          if (data.verifyToken) {
            tokenIdRef.current = data.verifyToken;
            if (data.verifyToken) {
              setReadyToSend(true);
              setLoading(false);
            }
          }
        }
      })
      .catch((error) => {
        handleIssueAppears(gloCusId);
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  const onPressToUserProfile = () => {
    navigate("/store-owner-profile", { replace: true });
  };

  const handleGenerateId = () => {
    setLoading(true);
    if (tokenIdRef.current) {
      AuthService.storeGenerateLoginId(token, tokenIdRef.current)
        .then(({ data }) => {
          const { token, loginID } = data;

          localStorage.setItem("storeUser", token);
          localStorage.removeItem("temp");
          localStorage.removeItem("email");
          setLoginID(loginID);
          setReveal(true);
          setLoading(false);
        })
        .catch((error) => {
          handleIssueAppears(gloCusId);
          setError(error.response.data.message);
          setLoading(false);
        });
    }
  };

  return (
    <CommonPageWithHeader error={error} loading={loading}>
      {error !== "" ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            padding: "5rem 10%",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#5f7fff",
              textAlign: "center",
              marginBottom: "3rem",
            }}
          >
            {" "}
            {t("dear_school_user_title")}
          </h2>
          <h3
            style={{
              fontSize: "1.5rem",
              color: "#5f7fff",
              textAlign: "center",
              lineHeight: "1.8",
            }}
          >
            {t("issue_content")}
          </h3>

          <Link className="noticeBtn" style={{ marginTop: "5%" }} to={"/"}>
            {/* 下一步 */}
            {t("back_to_home")}
          </Link>
        </div>
      ) : (
        <>
          {!generateLoginId && (
            <div className="emailVerifyContainer">
              <h2 className="registerTitleH1">
                {t("store_register_title")}(3/5)
              </h2>
              {readyToSend && (
                <h3
                  style={{
                    fontWeight: "normal",
                    marginTop: "5%",
                    fontSize: "1.5rem",
                  }}
                >
                  {/* 验证码已经发送到邮箱： */}
                  {t("verify_code_send")}
                </h3>
              )}
              <h3 style={{ marginTop: 10, marginBottom: 16 }}>{email}</h3>
              {!readyToSend && (
                <button
                  className="noticeBtn"
                  onClick={onPressNotReadyToSend}
                  style={{ marginTop: "5%" }}
                >
                  {/* 下一步 */}
                  {t("get_verification_code")}
                </button>
              )}
              {readyToSend && (
                <>
                  <input
                    id="otp"
                    type="password"
                    className="form-control"
                    placeholder={t("enter_verify_code")}
                    style={{ width: "50%", padding: 10 }}
                    onChange={handleEmailVerify}
                  />
                  <button
                    className="noticeBtn"
                    onClick={onPressCheckIfCodeValid}
                    style={{ marginTop: "5%" }}
                  >
                    {/* 下一步 */}
                    {t("process_payment")}
                  </button>
                </>
              )}
            </div>
          )}
          {generateLoginId && (
            <div className="emailVerifyContainer">
              <h1 className="registerTitleH1">
                {t("store_register_title")}(3/5)
              </h1>

              {reveal && (
                <img
                  src="/images/done.png"
                  style={{ width: 50, height: 50, marginBottom: 30 }}
                  alt="done image"
                />
              )}
              {reveal && (
                <p className="loginIdP" style={{ color: "blue" }}>
                  {loginID}
                </p>
              )}

              <p
                className="gidp"
                style={{ fontSize: "1.2rem", textAlign: "center" }}
              >
                {/* 登录Id非常重要，平台只生成一次，请妥善保管，您将会在登录，修改密码，修改个人资料时需要,请确保在安全环境下获取。 */}
                {t("generate_login_id")}
              </p>

              {!reveal && (
                <button
                  className="noticeBtn"
                  style={{ marginTop: 16 }}
                  onClick={handleGenerateId}
                >
                  {/* 点击获取登录id */}
                  {t("get_login_id_btn")}
                </button>
              )}

              {reveal && (
                <button
                  className="noticeBtn"
                  style={{ marginTop: 16 }}
                  onClick={onPressToUserProfile}
                >
                  {/* 点击进入个人界面 */}
                  {t("navigate_to_my_profile")}
                </button>
              )}
            </div>
          )}
        </>
      )}
    </CommonPageWithHeader>
  );
};

export default StoreEmailVerify;
