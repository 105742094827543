import getUserLocale from "get-user-locale";

const getDifLangImg = (baseUrl: string) => {
  let lang;
  lang =
    localStorage.getItem("lang") === ""
      ? getUserLocale().split("-")[0]
      : localStorage.getItem("lang");
  console.log("现在的lang:", lang);

  if (lang === "zh") {
    return `/images/zh/${baseUrl}`;
  } else if (lang === "es") {
    return `/images/es/${baseUrl}`;
  } else {
    return `/images/en/${baseUrl}`;
  }
};

export default getDifLangImg;
