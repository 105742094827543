import { AiOutlineCloseCircle } from "react-icons/ai";
import InputNormal from "./InputNormal";
import { useTranslation } from "react-i18next";

interface Props {
  handleClosePopup: () => void;
  type: string;
}

const AddRewardPunishmentRange = ({ handleClosePopup, type }: Props) => {
  const { t } = useTranslation();
  const placeHolder =
    type === "reward"
      ? "例如：1-3分：颁发奖状；4-6分：发放奖金;"
      : "例如：1-3分：每周五下午3点到操场在全校同学的围观下跑5圈；4-8分：通知家长;";
  return (
    <div className="addHeroComponent">
      <div className="alertPop">
        {type === "reward" && (
          <p>
            {/* 录入可奖励行为 */}
            {t("enter_reward_rules")}
          </p>
        )}
        {type === "punishment" && (
          <p>
            {/* 录入会扣分行为*/}
            {t("enter_punish_rules")}
          </p>
        )}
        {/**behavior */}
        <div className="alertInputContainer">
          {/**name */}
          <InputNormal
            label={t("according_reward_points")}
            name="name"
            handleChange={() => {}}
            className="inputContainer"
            placeholder={placeHolder}
          />

          {/**Upload button */}
          <button
            type="button"
            className="btn btn-warning"
            style={{
              minWidth: 100,
              padding: "1rem ",
              marginTop: "2rem",
              fontWeight: "bold",
            }}
            onClick={() => {}}
          >
            确定
          </button>
        </div>
        {/**point */}
      </div>
      <AiOutlineCloseCircle
        size={"3rem"}
        color={"white"}
        style={{ marginTop: "1rem" }}
        onClick={handleClosePopup}
        className="iconHover"
      />
    </div>
  );
};

export default AddRewardPunishmentRange;
