import { Navigate } from "react-router-dom";

const ProtectStoreUser = ({ children }: any) => {
  const user = localStorage.getItem("storeUser");

  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectStoreUser;
