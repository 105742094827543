import { Navigate } from "react-router-dom";

const ProtectNewUser = ({ children }: any) => {
  // const [school] = useAtom(schoolInfoAtom);
  const token = localStorage.getItem("newUser");
  if (token === "") {
    return <Navigate to="/register/register-form" />;
  }
  return children;
};

export default ProtectNewUser;
