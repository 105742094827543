import { Link } from "react-router-dom";
import SchoolFAQItem from "../../components/faq/SchoolFaqItem";
import { useTranslation } from "react-i18next";

const SchoolFQA = () => {
  const { t } = useTranslation();
  return (
    <div>
      {/**1.学校加入School Glory的意义？ */}
      <SchoolFAQItem type={1} />

      {/**2.可以申请的学校类型？ */}
      <SchoolFAQItem type={2} />

      {/**3.学校管理者可以做什么？ */}
      <SchoolFAQItem type={3} />

      {/**4.学校入驻需要准备什么材料？ */}
      <SchoolFAQItem type={4} />

      {/**5.学校在School Glory首页的默认排名？ */}
      <SchoolFAQItem type={5} />

      {/**6.学校付款后，在哪里获得收据？ */}
      <SchoolFAQItem type={6} />

      {/**7.如何添加在校的学生？ */}
      <SchoolFAQItem type={7} />

      {/**8.如何添加教职人员？ */}
      <SchoolFAQItem type={8} />

      {/**9.在哪里可以收到学生的举报？ */}
      <SchoolFAQItem type={9} />

      {/**10.如何添加学校的会被奖励的行为和会被扣分的行为?*/}
      <SchoolFAQItem type={10} />

      {/**11.如何为学生添加奖励或者扣分的事件？ */}
      <SchoolFAQItem type={11} />

      {/**12.学校账户管理人员是否可以申请奖励？ */}
      <SchoolFAQItem type={12} />

      {/**13.申请加入School Glory平台之后，可以先做什么？ */}
      <SchoolFAQItem type={13} />

      {/**14.学校如何取消自动续费？ */}
      <SchoolFAQItem type={14} />

      {/**15.如何申请奖励？*/}
      <SchoolFAQItem type={15} />

      {/**16.如何更换密码? */}
      <SchoolFAQItem type={16} />

      {/**15.填写资料建议使用哪种语言？*/}
      <SchoolFAQItem type={17} />

      {/**16.教职人员与其家人是否需要奖励分数来兑换奖励？*/}
      <SchoolFAQItem type={18} />

      {/**17.学生用户、教职人员兑换过奖励后是否还能再兑换奖励？*/}
      <SchoolFAQItem type={19} />

      {/**18.学校用户被建议添加以下奖励行为*/}
      <SchoolFAQItem type={20} />

      {/**19.如果学生已经转学/或者毕业，应该如何处理该学生的账号？*/}
      <SchoolFAQItem type={21} />

      {/**20.如果教师已不在本校授课，应该如何处理该教师的账号？*/}
      <SchoolFAQItem type={22} />
      <Link to="/register/register-form" style={{ fontSize: "1.5rem" }}>
        {t("school_glory_school_register")} →
      </Link>
    </div>
  );
};

export default SchoolFQA;
