import { useRef, useState } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { IoIosCloseCircle } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import AuthService from "../../services/service";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
const ScanProduct = () => {
  const { t } = useTranslation();
  const [main, setMain] = useState(true);
  const [scanResult, setScanResult] = useState("");
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const scanResultRef = useRef("");

  const onPressStoreReward = () => {
    setLoading(true);
    AuthService.approveReward(scanResult)
      .then(() => {
        scanResultRef.current = scanResult;
        updateData();
      })
      .catch((e) => {
        setMain(false);
        setFailed(true);
        setError(e);
        setLoading(false);
      });
  };

  const updateData = () => {
    setMain(false);
    setScanResult("");
    setSuccess(true);
    setLoading(false);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {main && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#333",
          }}
        >
          <QrScanner
            onDecode={(result) => {
              setScanResult(result);
              setMain(false);
            }}
            onError={() => {
              setMain(false);
              setFailed(true);
            }}
          />
        </div>
      )}
      {failed && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <IoIosCloseCircle size={"6rem"} color={"red"} />
          <p style={{ padding: "1rem" }}>
            {/* 该用户不符合申请条件！ */}
            {t("user_not_meet_applicant_requirement")}
          </p>
          <p>{error}</p>
        </div>
      )}
      {scanResult && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <FaCheckCircle size={"6rem"} color={"green"} />
          <p>
            {/* 该用户符合申请条件！ */}
            {t("user_meet_applicant_requirement")}
          </p>
          <p>ID:{scanResult}</p>
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginTop: "1rem", padding: "1rem 2rem" }}
            onClick={onPressStoreReward}
          >
            {/* 确认奖励 */}
            {t("commit_to_reward")}
          </button>
        </div>
      )}
      {loading && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      )}
      {success && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <FaCheckCircle size={"6rem"} color={"green"} />
          <p>ID:{scanResultRef.current}</p>
          <p>
            {/* 成功奖励！ */}
            {t("successfully_rewarded")}
          </p>
        </div>
      )}
    </div>
  );
};

export default ScanProduct;
