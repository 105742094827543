import LoginToContinue from "../pages/LoginToContinue";
interface Props {
  onPressCloseModal: () => void;
}
const LoginCreateAccountPopup = ({ onPressCloseModal }: Props) => {
  return (
    <div className="innerContainerLogin">
      <LoginToContinue onPressCloseModal={onPressCloseModal} />
    </div>
  );
};

export default LoginCreateAccountPopup;
