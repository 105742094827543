import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { useTranslation } from "react-i18next";
import InputNormal from "../components/InputNormal";
import { useState, useRef, useEffect } from "react";
import LocationSelector from "../components/LocationSelector";
import TypeSelector from "../components/TypeSelector";
import AuthService from "../services/service";
import { useAtom } from "jotai";
import { schoolAtom } from "../datas/atoms";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";

const EditSchoolProfile = () => {
  const imgBaseUrl = import.meta.env.VITE_IMG_URL;
  const { t } = useTranslation();
  const [school, setSchool] = useAtom(schoolAtom);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const nameRef = useRef(school.name);
  const [image, setImage] = useState(`${imgBaseUrl}${school.icon}`);
  const addressRef = useRef(school.address);
  const iconRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef(school.region);
  const typeRef = useRef(school.type);
  const loginIdRef = useRef(null);
  const otherRef = useRef(null);
  const [enterLoginIdPop, setEnterLoginIdPop] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    initData();
  }, [school.icon]);

  const initData = () => {
    locationRef.current = school.region;
    typeRef.current = school.type;
    setImage(`${imgBaseUrl}${school.icon}`);
  };

  const handleChangeSchoolName = (e: any) => {
    nameRef!.current = e.target.value;
  };
  const handleIconClick = () => {
    iconRef!.current!.click();
  };
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    //console.log("handleChange收到的file:", file);
    setImage(URL.createObjectURL(file));
  };

  const handleChangeAddress = (e: any) => {
    addressRef!.current = e.target.value;
  };

  const handleloginID = (e: any) => {
    loginIdRef!.current = e.target.value;
  };

  const handleOther = (e: any) => {
    otherRef!.current = e.target.value;
  };
  const handleTypeSelected = (value: any) => {
    typeRef!.current = value;
  };

  const handleLocationSelected = (value: any) => {
    locationRef!.current = value;
  };
  const handleUpload = () => {
    setLoading(true);

    if (loginIdRef.current !== null) {
      const formData = new FormData();
      formData.append("name", nameRef!.current!);
      formData.append("icon", iconRef!.current!.files![0]);
      formData.append("region", locationRef!.current!);
      formData.append("type", typeRef!.current!);
      formData.append("address", addressRef!.current!);
      formData.append("loginId", loginIdRef.current);
      formData.append("other", otherRef!.current!);

      AuthService.updateSchoolProfile(formData)
        .then(({ data }) => {
          setSchool({
            _id: data.school.id,
            name: data.school.name,
            joinDate: data.school.joinDate,
            icon: data.school.icon,
            email: data.school.email,
            region: data.school.region,
            type: data.school.type,
            ranking: data.school.ranking,
            votes: data.school.voutes,
            votedBy: [],
            address: data.school.address,
            statement: data.school.statement,
            active: false,
          });
          setLoading(false);
          setEnterLoginIdPop(false);
          navigate("/school-profile");
        })
        .catch((error) => setError(error.response.data.message));
    } else {
      setLoading(false);
      return setError(t("login_id_is_required"));
    }
  };
  return (
    <CommonPageWithHeader error={error} loading={loading}>
      <div
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <RiArrowGoBackFill
          className="backIcon"
          color={"black"}
          size={"1.5rem"}
          onClick={() => navigate(-1)}
          style={{ marginTop: "2rem" }}
        />
        <h1 className="registerTitleH1">{t("edit_profile")}</h1>

        {/**all inputs */}
        {/**title */}

        <div className="allInputsContainer">
          {/**校名 */}
          <InputNormal
            placeholder={school.name}
            name={"name"}
            className="normalInputContainer"
            handleChange={handleChangeSchoolName}
          />

          {/**Location*/}
          <LocationSelector
            onLocationSelected={handleLocationSelected}
            locationSelectRef={locationRef}
          />
          {/**Type*/}

          <TypeSelector
            onTypeSelected={handleTypeSelected}
            typeSelectRef={typeRef}
          />
          {/*address*/}
          <InputNormal
            placeholder={school.address}
            name={"address"}
            className="normalInputContainer"
            handleChange={handleChangeAddress}
          />
          {/*login ID*/}
          {/* <InputNormal
            placeholder={t("loginId")}
            name={"loginId"}
            className="normalInputContainer"
            handleChange={handleloginID}
          /> */}
          {/*other*/}
          <InputNormal
            placeholder={t("other")}
            name={"other"}
            className="normalInputContainer"
            handleChange={handleOther}
          />

          {/**校徽 */}
          <div className="imgContainerDiv" onClick={handleIconClick}>
            <div
              style={{
                width: 100,
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(181, 160, 160, 0.377)",
                borderRadius: 100,
              }}
            >
              {image !== null ? (
                <img
                  src={image}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 100,
                    objectFit: "cover",
                  }}
                  alt="School Icon"
                />
              ) : (
                <img
                  src={"/images/picture.png"}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 100,
                    objectFit: "cover",
                  }}
                  alt="Default Icon"
                />
              )}
            </div>

            <input
              type="file"
              ref={iconRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />

            <p style={{ marginTop: "1rem", color: "blue" }}>
              {/* 请使用白色底，jpg格式的图片 */}
              {t("school_emble")}:{t("image_require")}
            </p>
          </div>

          {/**Submit button */}
          <button
            className="noticeBtn"
            // disabled={isStrong ? false : true}
            onClick={() => setEnterLoginIdPop(true)}
            // onClick={() => {
            //   navigate(
            //     "/register/register-form/register-form-school-info/checkout/checkout-form/email-verify"
            //   );
            // }}
            style={{ marginTop: 16 }}
          >
            {/* 下一步 */}
            {t("process_payment")}
          </button>
        </div>

        {/**Enter login Id */}
        {enterLoginIdPop && (
          <div className="addHeroComponent">
            {" "}
            <div className="addEditHeroContainer">
              {/*login ID*/}
              <div style={{ width: "60%" }}>
                <InputNormal
                  placeholder={t("loginId")}
                  name={"loginId"}
                  className="normalInputContainer"
                  handleChange={handleloginID}
                />
              </div>

              <button
                className="noticeBtn"
                // disabled={isStrong ? false : true}
                onClick={handleUpload}
                // onClick={() => {
                //   navigate(
                //     "/register/register-form/register-form-school-info/checkout/checkout-form/email-verify"
                //   );
                // }}
                style={{ marginTop: 16 }}
              >
                {/* 下一步 */}
                {t("process_payment")}
              </button>
            </div>
            <AiOutlineCloseCircle
              size={"3rem"}
              color={"white"}
              style={{ marginTop: "1rem" }}
              onClick={() => setEnterLoginIdPop(false)}
              className="iconHover"
            />
          </div>
        )}
      </div>
    </CommonPageWithHeader>
  );
};

export default EditSchoolProfile;
