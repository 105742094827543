import { useEffect } from "react";

interface Props {
  error: string;
}

const ErrorMsg = ({ error }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="alert alert-danger"
      style={{
        width: "100vw",
        padding: 10,
        zIndex: 3000,
        textAlign: "center",
        fontSize: "1.2rem",
      }}
    >
      {error}
    </div>
  );
};

export default ErrorMsg;
