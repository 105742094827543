import { useEffect, useState } from "react";
import CommonPageWithHeader from "../../components/CommonPageWithHeader";
import { useTranslation } from "react-i18next";

import SuccessfulRewardedItem from "../../components/store/SuccessfulRewardedItem";
import { ApplyRewardHistory } from "../../datas/atoms";
import AuthService from "../../services/service";

const SuccessfullyRewarded = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    AuthService.getSuccessfullyRewarded()
      .then(({ data }) => {
        setData(data.appliedLists);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  const activeData = () => {
    initData();
  };
  return (
    <CommonPageWithHeader
      loading={loading}
      error={error}
      explain={true}
      type={9}
    >
      <div
        style={{
          display: "flex",
          padding: "1rem",
          marginTop: "1rem",
          fontSize: "1.2rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* 您已成功申请的奖励 */}
        {t("you_have_successfully_rewarded")},
        <p style={{ fontWeight: "bold", marginTop: "1rem" }}>
          {/* 总共 */}
          {t("total")}：{data.length}
        </p>
      </div>

      {data.length > 0 && (
        <ol>
          {data.map((item: ApplyRewardHistory, index: number) => (
            <li key={index}>
              <SuccessfulRewardedItem
                _id={item.storeId!._id!}
                icon={item.storeId!.icon!}
                name={item.storeId!.name!}
                image={item.storeId!.image!}
                requiredApplyPoints={item.storeId!.rewardPointsRequirement!}
                rewardContent={item.storeId!.rewardContent!}
                rewardValidTill={item.time}
                appliedTime={item.approvedTime!}
                getMode={item.storeId!.getMode!}
                comment={item.comment}
                activeData={activeData}
                rewardId={item._id}
              />
            </li>
          ))}
        </ol>
      )}
      {data.length === 0 && (
        <p>
          ---
          {/* 目前还没有记录 */}
          {t("no_record_yet")}
          ---
        </p>
      )}
    </CommonPageWithHeader>
  );
};

export default SuccessfullyRewarded;
