import Lottie from "lottie-react";
import LoadingAnimation from "../assets/animtions/loading.json";

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
      }}
    >
      <Lottie
        animationData={LoadingAnimation}
        loop={true}
        style={{ width: "10%" }}
      />
    </div>
  );
};

export default Loading;
