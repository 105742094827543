import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useEffect, useState } from "react";
import EmailItem from "../components/EmailItem";
import AddEmailsFrom from "../components/AddEmailsFrom";
import AuthService from "../services/service";
import AlertDeleteHero from "../components/AlertDeleteHero";
import getUserType from "./../utils/getUserType";
import { useTranslation } from "react-i18next";

interface Props {
  studentEmail: string;
  studentName: string;
  validDate: string;
  validYear: number;
}

const AllowEmailsTeacher = () => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [selectedIdentity, setSelectedIdentity] = useState("name");
  const [showSinglePop, setShowSinglePop] = useState(false);
  const [showBulkPop, setShowBulkPop] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);

  //selectedEmail,selectedName,selectedValidDate;
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedValidYear, setSelectedValidYear] = useState(0);
  const [num, setNum] = useState(0);
  const [userTypeNow, setUserTypeNow] = useState("");

  const [filteredStudents, setFilteredStudents] = useState([]);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);

    // 根据用户选择的标识符进行筛选
    const filteredStudents = emails.filter((student: Props) => {
      if (selectedIdentity === "name") {
        return student.studentName
          .toLowerCase()
          .includes(inputValue.toLowerCase());
      } else if (selectedIdentity === "email") {
        return student.studentEmail
          .toLowerCase()
          .includes(inputValue.toLowerCase());
      }
      return true; // 如果没有选择标识符，默认显示所有学生
    });

    // 更新符合条件的学生数目

    setFilteredStudents(filteredStudents);
  };

  const handleIdentityChange = (event: any) => {
    setSelectedIdentity(event.target.value);
  };

  const handleCloseSingle = () => {
    initData();
    setShowSinglePop(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    setLoading(true);
    getInitUserType();
    // AuthService.getSchoolAllowedAddTeachersAmount()
    //   .then(({ data }) => {
    //     setNum(data.amount);

    //     setLoading(false);
    //   })
    //   .catch((e) => {
    //     console.log("ExplainContext出错：", e);
    //   });

    setNum(import.meta.env.VITE_DEFAULT_TEACHING_SFATT_AMOUNT);
    AuthService.getAllAllowedTeacherEmails()
      .then(({ data }) => {
        setEmails(data.data);
        setFilteredStudents(data.data);
        console.log("现在的filteredData:", data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const getInitUserType = () => {
    const user = getUserType();
    setUserTypeNow(user);
    console.log("========啦啦啦userType:", userTypeNow);
  };

  const handleCloseBulk = () => {
    initData();
    setShowBulkPop(false);
  };

  const handleEdit = (
    studentEmail: string,
    studentName: string,
    validYear: number
  ) => {
    // 在这里处理编辑事件，可以访问email
    if (userTypeNow !== "school") {
      return alert("Only school's admin user can edit teacher's information!");
    }
    console.log(
      `收到email:${studentEmail};name:${studentName};validDate:${validYear}`
    );
    setSelectedEmail(studentEmail);
    setSelectedName(studentName);
    setSelectedValidYear(validYear);
    setShowSinglePop(true);
  };

  const handleDelete = () => {
    if (userTypeNow !== "school") {
      return alert(
        "Only school's admin user can delete teacher's information!"
      );
    }
    setConfirmDeletePopup(false);
    setLoading(true);
    AuthService.deleteAllowedTeacherEmail(selectedEmail)
      .then(() => {
        initData();
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const handlePreDelete = (email: string, name: string) => {
    if (userTypeNow !== "school") {
      return alert(
        "Only school's admin user can delete teacher's information!"
      );
    }
    console.log(`此时的name:${name}`);
    setSelectedEmail(email);
    setSelectedName(name);
    setConfirmDeletePopup(true);
  };
  return (
    <CommonPageWithHeader
      loading={loading}
      error={error}
      explain={true}
      type={8}
      leftNum={filteredStudents.length}
    >
      {/** Search */}
      <div className="twoButtonsContainer">
        {/** Add one*/}
        <button
          type="button"
          className="btn btn-warning"
          style={{
            fontWeight: "bold",
            padding: "1rem 2rem",
            color: "white",
            minWidth: 120,
          }}
          onClick={() => {
            if (emails.length >= num) {
              return alert("Out of limitation!");
            }
            setShowSinglePop(true);
            setSelectedEmail("");
            setSelectedName("");
            setSelectedValidYear(0);
          }}
        >
          {/* Add A hero */}
          {/* 添加教师的Email */}
          {t("add_teacher_email_btn")}
          <AiOutlineUserAdd
            color={"white"}
            size={"1.8rem"}
            style={{ marginLeft: 2 }}
          />
        </button>
      </div>
      <div className="heroSearchContainer" style={{ marginTop: "1rem" }}>
        <input
          type="text"
          value={inputValue}
          className="searchHeroInput"
          onChange={handleInputChange}
          placeholder={`${t("searcher_teacher_info")}${selectedIdentity}`}
          style={{
            marginLeft: 3,
            fontSize: "1.1rem",
            paddingLeft: 16,
            borderRadius: 4,
            paddingTop: "1rem",
            paddingBottom: "1rem",
            borderColor: "#b6b4b4",
          }}
        />

        {/**添加按什么搜查 */}
        <div className="searchSelectionContainer">
          {/* 搜索内容 */}
          {t("search_content")}：{/**student'name */}
          <label>
            <input
              className="radioStyle"
              type="radio"
              value="name"
              checked={selectedIdentity === "name"}
              onChange={handleIdentityChange}
            />
            name
          </label>
          {/**student'email */}
          <label>
            <input
              className="radioStyle"
              type="radio"
              value="email"
              checked={selectedIdentity === "email"}
              onChange={handleIdentityChange}
            />
            email
          </label>
        </div>

        {/**total :符合条件的，默认是全部*/}
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
            display: "flex",
            marginTop: "1rem",
            fontWeight: "bold",
          }}
        >
          {/* 总共 */}
          {t("total")}:{filteredStudents.length}
        </div>

        {/**Emails */}
        <div className="emailsListContainer">
          <div className="tableTitle">
            <h5 className="numberContainer"></h5>
            <h5>{t("teacher_name")}</h5>
            <h5>Email</h5>
            <h5>{t("student_account_valid_to")}</h5>
            <h5></h5>
          </div>
          <ol className="list-group">
            {filteredStudents.map((item: any, index) => (
              <li className={"list-group-item-each"} key={index}>
                <EmailItem
                  index={index + 1}
                  studentEmail={item.teacherEmail}
                  studentName={item.teacherName}
                  validDate={item.validDate}
                  validYear={item.validYear}
                  onEdit={handleEdit}
                  onDelete={handlePreDelete}
                />
              </li>
            ))}
          </ol>
        </div>
      </div>

      {/**Emails */}

      {/**popups */}
      {showSinglePop && (
        <AddEmailsFrom
          single={true}
          handleClosePopup={handleCloseSingle}
          selectedEmail={selectedEmail}
          selectedName={selectedName}
          selectedValidYear={selectedValidYear}
          userType="teacher"
        />
      )}
      {showBulkPop && (
        <AddEmailsFrom
          single={false}
          handleClosePopup={handleCloseBulk}
          userType="teacher"
        />
      )}

      {confirmDeletePopup && (
        <AlertDeleteHero
          email={selectedEmail}
          heroName={selectedName}
          onPressCancel={() => {
            setSelectedName("");
            setConfirmDeletePopup(false);
          }}
          onPressConfirm={handleDelete}
        />
      )}
    </CommonPageWithHeader>
  );
};

export default AllowEmailsTeacher;
