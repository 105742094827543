import SchoolPricingItem from "./SchoolPricingItem";
import { useTranslation } from "react-i18next";

const SchoolPricingComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="schoolComponentContainer">
      {/**Payment way: */}
      <div style={{ padding: "3rem", textAlign: "center" }}>
        <p>
          {/* 接受全球客户使用 Visa、Mastercard、American
          Express、Discover、大来国际卡和部分带有中国银联标志的银行卡的支付，支付宝 */}
          {t("support_bank_card")}
        </p>
      </div>
      {/**Big title */}
      <p className="pricingPageTitle">{t("pricing_for_school")}</p>

      {/**subtitle */}
      <p
        className="pricingPageSubTitle"
        style={{ marginLeft: "30%", marginRight: "30%", lineHeight: "1.8" }}
      >
        {/* 适用适用对象 ： 幼儿园 ， 小学 ，中学，初中，高中，大学等院校 */}
        {t("suitable_to_users_school")}
      </p>
      <div className="priceItemsList">
        <SchoolPricingItem
          price={import.meta.env.VITE_SCHOOL_BASE}
          edition={t("basic_plan")}
          suitable={"100"}
          showPrice={true}
        />
        {/* <SchoolPricingItem
          price={import.meta.env.VITE_SCHOOL_STANDER}
          edition={t("stander_plan")}
          suitable={"50"}
          showPrice={true}
        />
        <SchoolPricingItem
          price={import.meta.env.VITE_SCHOOL_PRO}
          edition={t("pro_plan")}
          suitable={"100"}
          showPrice={true}
        /> */}
        {/* <SchoolPricingItem
          edition={"定制版"}
          suitable={">100"}
          showPrice={false}
        /> */}
      </div>
    </div>
  );
};

export default SchoolPricingComponent;
