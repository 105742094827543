import moment from "moment";
import getUserLocale from "get-user-locale";
import "moment/dist/locale/zh-cn";
import "moment/dist/locale/es";

const formatJoinDate = (timeString: string) => {
  let lang;
  lang =
    localStorage.getItem("lang") === ""
      ? getUserLocale().split("-")[0]
      : localStorage.getItem("lang");
  console.log("现在的lang:", lang);
  console.log("传进来的timeStamp:", timeString);

  // 根据用户的语言设置设置 moment 的语言
  switch (lang) {
    case "zh":
      moment.locale("zh-cn");
      break;
    case "es":
      moment.locale("es");
      break;
    default:
      moment.locale("en");
  }

  const formattedDate = moment(timeString).format("LL"); // 格式化日期
  return formattedDate;
};

export default formatJoinDate;
