import { AiOutlineEdit } from "react-icons/ai";
import { BsPencilSquare } from "react-icons/bs";
import { BsTrash } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const ICON_SIZE = "2rem";

const IconsExplain = () => {
  const { t } = useTranslation();
  return (
    <div className="iconsContainer">
      {/** edit student's info*/}
      <div className="singleIconContainer">
        <AiOutlineEdit size={ICON_SIZE} />
        {/* 修改学生资料 */}
        {t("edit_student_info")}
      </div>
      {/** delete student*/}
      <div className="singleIconContainer">
        <BsTrash size={ICON_SIZE} />
        {/* 永久删除学生，且无法恢复 */}
        {t("delete_student_account_notice")}
      </div>
      {/** add event to student*/}
      <div className="singleIconContainer">
        <BsPencilSquare size={ICON_SIZE} />
        {/* 为学生添加惩罚或者奖励的事件 */}
        {t("add_event_to_student")}
      </div>
    </div>
  );
};

export default IconsExplain;
