import CommonPageWithHeader from "../components/CommonPageWithHeader";
import SchoolPricingComponent from "../components/schoolPricing/SchoolPricingComponent";
import StorePricingComponent from "../components/storePricing/StorePricingComponent";

const Pricing = () => {
  return (
    <CommonPageWithHeader>
      {/**学校定价 */}
      <SchoolPricingComponent />

      {/**奖励商店定价 */}
      <StorePricingComponent />
    </CommonPageWithHeader>
  );
};

export default Pricing;
