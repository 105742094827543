interface Props {
  type: string;
  isHover?: boolean;
  onPressChoose?: (type: string) => void;
}

const OnlineIcon = ({ type, isHover, onPressChoose }: Props) => {
  const classContent = isHover
    ? "storeLinkItemContainer1"
    : "storeLinkItemContainer";

  if (typeof type === "string") {
    return (
      <div
        className={classContent}
        style={{
          display: "flex",
          height: "100%",
        }}
        onClick={() => onPressChoose && onPressChoose(type)}
      >
        {/**title */}
        {type === "Amazon" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/amazon.png"
              className="smallIcon"
              style={{ marginRight: "1rem" }}
            />
          </div>
        )}

        {type === "Taobao" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/taobao.png"
              className="smallIcon"
              style={{ width: 20, height: 20, marginRight: 2 }}
            />
            淘宝（Taobao）
            <p className="label"></p>
          </div>
        )}

        {type === "Tmall" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/tmall.png"
              className="smallIcon"
              style={{ width: 20, height: 20 }}
            />
            <p className="label">天猫(Tmall)</p>
          </div>
        )}

        {type === "Tiktok" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/tiktok.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Tiktok store(抖音商城)</p>
          </div>
        )}

        {type === "JD" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/jd.png"
              className="smallIcon"
              style={{ width: 30, height: 20 }}
            />
            <p className="label">(JD)</p>
          </div>
        )}

        {type === "Ebay" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/ebay.png"
              className="smallIcon"
              style={{ width: 32, height: 32 }}
            />
            <p className="label">Ebay</p>
          </div>
        )}

        {type === "Shopify" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/shopify.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Shopify</p>
          </div>
        )}

        {type === "Aliexpress" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/aliexpress.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Aliexpress</p>
          </div>
        )}

        {type === "Wish" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/wish.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
          </div>
        )}

        {type === "Lazada" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/lazada.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Lazada</p>
          </div>
        )}

        {type === "Walmart" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/walmart.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Walmart</p>
          </div>
        )}

        {type === "Flipkart" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/flipkart.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
          </div>
        )}

        {type === "Youtube" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/youtube.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Youtube</p>
          </div>
        )}

        {type === "twitter" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/twitter.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Twitter</p>
          </div>
        )}

        {type === "instagram" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/instagram.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Instagram</p>
          </div>
        )}
        {type === "Facebook" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/facebook.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Facebook</p>
          </div>
        )}

        {type === "Wei bo(微博)" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/weibo.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Wei bo(微博)</p>
          </div>
        )}

        {type === "Twitch" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/twitch.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Twitch</p>
          </div>
        )}

        {type === "Booking" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/booking.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Booking</p>
          </div>
        )}
        {type === "Airbnb" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="./images/airbnb.png"
              className="smallIcon"
              style={{ width: 25, height: 25 }}
            />
            <p className="label">Airbnb</p>
          </div>
        )}
      </div>
    );
  } else {
    return null; // 或者可以显示一个默认值，或者其他处理方式
  }
};

export default OnlineIcon;
