import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

interface Props {
  label?: string;
  placeholder?: string;
  name: string;
  className?: string;
  handleChange: (value: any) => void;
  onFocus?: () => void;
  value?: any;
  maxLength?: number; // 添加一个属性来设置最大字符数
  height?: number;
}

const TexteraNomal = ({
  label,
  placeholder,
  name,
  className,
  handleChange,
  onFocus,
  value,
  maxLength,
  height,
}: Props) => {
  const { t } = useTranslation();
  const labelTranslated = t(label!);
  const placeholderTranslated = t(placeholder!);

  const [charCount, setCharCount] = useState(value ? value.length : 0);
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    if (value) {
      setCharCount(value.length);
      setInputValue(value);
    }
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    if (maxLength && newValue.length > maxLength) {
      // 如果字符数超过限制，不更新输入框的值
      return;
    }
    setCharCount(newValue.length);
    setInputValue(newValue); // 更新本地的inputValue
    handleChange(newValue);
  };

  return (
    <div className={className}>
      {/**label */}
      <label
        style={{
          display: "flex",
          color: "#5f7fff",
        }}
      >
        {labelTranslated}：{" "}
        {maxLength && (
          <div style={{ color: charCount > maxLength ? "red" : "#5f7fff" }}>
            {charCount}/{maxLength}
          </div>
        )}
      </label>

      {/**input bar */}
      <textarea
        className="form-control"
        name={name}
        onChange={handleInputChange}
        placeholder={placeholderTranslated}
        onFocus={onFocus}
        value={value ? value : inputValue} // 使用value或本地的inputValue
        readOnly={false} // 根据条件设置是否只读
        style={{
          padding: "1rem",
          color: "#666",
          marginBottom: "1rem",
          fontSize: "1rem",
          borderColor: maxLength && charCount > maxLength ? "red" : "",
          height: height,
        }}
      />
    </div>
  );
};

export default TexteraNomal;
