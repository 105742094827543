import { useEffect, useRef, useState } from "react";
import AuthService from "../services/service";
import { useTranslation } from "react-i18next";

import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import ErrorMsg from "../components/ErrorMsg";

const backendUrl = import.meta.env.VITE_BACKEND_URL;

const CheckoutForm = () => {
  const stripe = useStripe();

  const elements = useElements();

  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const tokenRef = useRef("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    const token = AuthService.getTempToken();
    if (token) tokenRef.current = token;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      const { message } = submitError;
      if (message) setErrorMessage(message);
      setLoading(false);
      return;
    }

    // // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const res = await fetch(`${backendUrl}/api/v1/school/checkout-session`, {
      method: "POST",
      headers: {
        Authorization: tokenRef.current,
      },
    });

    // console.log("res是：", res);

    const { clientSecret } = await res.json();

    const { error } = await stripe!.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      clientSecret: clientSecret,
      confirmParams: {
        return_url: `${
          import.meta.env.VITE_FRONTEND_URL
        }/register/register-form/register-form-school-info/checkout/checkout-form/email-verify`,
      },
    });
    setLoading(false);
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      const { message } = error;
      setErrorMessage(message);
    } else {
      // Check current title after payment is confirmed
      // const currentTitle = document.title;
      // console.log("现在的title是：", currentTitle);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <h1 className="registerTitleH1" style={{ marginBottom: "5%" }}>
        {t("school_glory_school_register")} (3/5)
      </h1>
      {errorMessage && <ErrorMsg error={errorMessage} />}
      <h1 style={{ marginBottom: 16, fontWeight: "bold" }}>
        {/* Notice： */}
        {t("notice")}
      </h1>
      <p className="bankNotice" style={{ marginBottom: "3%" }}>
        {/* Please make sure to enter the right information. */}
        {t("register_notice")}
      </p>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button
          type="submit"
          disabled={!stripe || !elements}
          className="btn btn-warning"
          style={{
            width: "100%",
            marginTop: 30,
            padding: 9,
            fontWeight: "bold",
            color: "white",
          }}
        >
          {/* Pay ${import.meta.env.VITE_REGISTER_PRICE} */}
          {t("payment_btn")}
        </button>
      </form>

      {loading && (
        <p style={{ marginTop: 16, fontWeight: "bold" }}>
          {/* Processing... */}
          {t("processing")}
        </p>
      )}
    </div>
  );
};

export default CheckoutForm;
