import { useEffect, useState, useRef } from "react";
import CommonPageWithHeader from "../components/CommonPageWithHeader";
import { Event, Hero, RewardPunishItem, studentHeroAtom } from "../datas/atoms";
import AuthService from "../services/service";
import { useAtom } from "jotai";
import { BsFillChatSquareTextFill } from "react-icons/bs";
import { PiMedalFill } from "react-icons/pi";
import { FaSadCry } from "react-icons/fa";
import RewardPunishUserItem from "../components/RewardPunishUserItem";
import { AiOutlineCloseCircle } from "react-icons/ai";
import SchoolRules from "../components/rule/SchoolRules";
import TexteraNomal from "../components/store/TexteraNomal";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import successImg from "/images/done.png";
import { Link } from "react-router-dom";

interface Props {
  heroItem: Hero;
}
const IMG_SIZE = 150;

const UserProfile = ({ heroItem }: Props) => {
  const [hero, setHero] = useAtom(studentHeroAtom);
  const img_url = import.meta.env.VITE_IMG_URL;
  const [selectedTab, setSelectedTab] = useState("all");
  const [totalPoints, setTotalPoints] = useState(0);
  const [totalRewardPoints, setTotalRewardPoints] = useState(0);
  const [totalPunishmentPoints, setTotalPunishmentPoints] = useState(0);
  const [ableToApplyRewardAmount, setAbleToApplyRewardAmount] = useState(0);
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState(events);
  const [rewardsAmount, setRewardsAmount] = useState(0);
  const [punishmentAmount, setPunishmentAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [typeNow, setTypeNow] = useState("reward");
  const [schoolId, setSchoolId] = useState("");
  const [showPop, setShowPop] = useState(false);
  const [reportContent, setReportContent] = useState("");
  const [image, setImage] = useState("");
  const imageRef = useRef<HTMLInputElement>(null);
  const [showReportPop, setShowReportPop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  // const [rewardedList, setRewardedList] = useState([]);
  const [commentsAmount, setCommentsAmount] = useState(0);
  // const [awaitingComments, setAwaitingComments] = useState([]);
  const { t } = useTranslation();

  const handleTabClick = (tab: string) => {
    if (tab === "all") {
      setSelectedTab("all");
      setFilteredEvents(events);
    }

    if (tab === "rewards") {
      setSelectedTab("rewards");
      setFilteredEvents(
        events.filter((m: RewardPunishItem) => m.type === "reward")
      );
    }

    if (tab === "deductions") {
      setSelectedTab("deductions");
      setFilteredEvents(
        events.filter((m: RewardPunishItem) => m.type === "punishment")
      );
    }
  };

  const handleClosePopup = () => {
    setShowPop(false);
    setTypeNow("");
  };

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    AuthService.getHeroProfile()
      .then(({ data }) => {
        const { user, extraInfo } = data;
        setTotalPoints(extraInfo.total_points);
        setTotalRewardPoints(extraInfo.total_reward_points);
        setTotalPunishmentPoints(extraInfo.total_punishment_points);
        setAbleToApplyRewardAmount(extraInfo.able_to_apply_reward_amount);
        setEvents(user.events);
        setFilteredEvents(user.events);
        setRewardsAmount(extraInfo.total_rewardEvents_amount);
        setPunishmentAmount(extraInfo.total_punishmentEvents_amount);
        setTotalAmount(user.events.length);
        setSchoolId(user.schoolId._id);
        console.log("user.events:", user.events);
        // setRewardedList(user.rewards);

        setHero({
          _id: user._id,
          image: user.image,
          heroName: user.heroName,
          schoolId: user.schoolId,
          studentEmail: user.studentEmail,
          time: user.time,
          validDate: user.validDate,
          validYear: user.validYear,
          info: user.info,
          events: user.events,
          reports: user.reports,
        });
        getAllAwaitingComments();
      })

      .catch((error) => {
        console.log("error：", error.response.data.message);
        localStorage.removeItem("userSelf");
      });
  };

  const getAllAwaitingComments = () => {
    AuthService.awaitingToComments()
      .then(({ data }) => {
        setCommentsAmount(data.awaitingComments.length);
        // setAwaitingComments(data.awaitingComments);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error：", error.response.data.message);
        localStorage.removeItem("userSelf");
      });
  };

  const onPressRewardRule = () => {
    setTypeNow("reward");
    setShowPop(true);
  };

  const onPressPunishmentRule = () => {
    setTypeNow("punishment");
    setShowPop(true);
  };
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    setImage(URL.createObjectURL(file));
  };

  const handleImageClick = () => {
    imageRef!.current!.click();
  };

  const handleUploadReport = () => {
    setLoading(true);
    if (reportContent !== "" && imageRef!.current!.value !== "") {
      const formData = new FormData();
      formData.append("reportContent", reportContent);
      formData.append("image", imageRef!.current!.files![0]);

      AuthService.reportToSchool(formData)
        .then(async () => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          return setError(error.response.data.message);
        });
    } else {
      setLoading(false);
      return setError(t("inComplete"));
    }
  };

  return (
    <CommonPageWithHeader>
      <div className="userProfileTopbarContainer">
        {/**Title */}
        <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}></p>
        {/**rules */}
        <div className="rulesBtnsContainer">
          {/**reward */}
          <button
            type="button"
            className="btn btn-success singleBtn"
            onClick={onPressRewardRule}
          >
            <PiMedalFill color={"white"} size={"1.5rem"} />
          </button>
          {/**punish */}
          <button
            type="button"
            className="btn btn-danger singleBtn"
            onClick={onPressPunishmentRule}
          >
            <FaSadCry color={"white"} size={"1.5rem"} />
          </button>
        </div>

        {/**report button */}
        <div className="reportBtnContainer">
          <button
            className="contactButton"
            onClick={() => setShowReportPop(true)}
          >
            <BsFillChatSquareTextFill
              size={"1.5rem"}
              color={"white"}
              className="reportButton"
            />
          </button>
          <p style={{ marginTop: 5 }}>
            {/* 一键举报 */}
            {t("one_click_btn")}
          </p>
        </div>
      </div>

      {/**contact school btn */}

      <div className="userProfile_container">
        {/**user info */}
        <div className="userProfile_box1">
          {/**Image */}
          <img src={`${img_url}${hero.image}`} className="userImage" />
          <p className="userName">{hero.heroName || heroItem.heroName}</p>
          <div style={{ margin: "1rem", fontSize: "1.2rem" }}>
            <PiMedalFill color={"green"} size={"1.5rem"} />
            {totalPoints}
          </div>
          {/**separate line */}
          <div
            style={{
              height: "1px",
              width: "100%",
              backgroundColor: "	#DCDCDC",
              marginBottom: "1.5rem",
            }}
          />
          <div>
            {/**email */}
            <p>
              {t("student_email")}: {hero.studentEmail}
            </p>
            {/**school name */}
            <p>
              {t("school_name_of_student")}：{hero.schoolId.name}
            </p>
            {/**info */}
            <p>
              {t("student_info")}：{hero.info}
            </p>
            <p>
              {/* 奖励总分 */}
              {t("total_reward_points")}：{totalRewardPoints}
            </p>
            <p>
              {/* 扣分总分 */}
              {t("total_punish_points")}：{totalPunishmentPoints}
            </p>
            <p>
              {/* 可申请奖励 */}
              {t("available_to_reward_amount")}：
              <Link to="/getAbleToReward">{ableToApplyRewardAmount} </Link>
            </p>

            <p style={{ marginBottom: "3rem" }}>
              {/* 待评价 */}
              {t("awaiting_comment")}：
              <Link
                to="/awaitingComments"
                style={{ color: commentsAmount > 0 ? "red" : "black" }}
                // state={{ data: awaitingComments }}
              >
                {commentsAmount}
              </Link>
            </p>
            <Link
              to={"/successful-rewarded-list"}
              style={{
                padding: "1rem 3rem",
                textDecoration: "none",
                backgroundColor: "#5f7fff",
                color: "white",
                borderRadius: "1rem",
              }}
              // state={{ data: rewardedList }}
            >
              {/* 查看已成功申请的奖励 */}
              {t("check_available_to_reward_btn")}
            </Link>
          </div>
        </div>
        {/**list*/}
        <div className="userProfile_box2">
          <div className="userProfile_box2_innerContainer">
            {/**title */}
            <div className="selectedButtonsContainer">
              <button
                className={`tabButton ${
                  selectedTab === "all" ? "selected" : ""
                }`}
                onClick={() => handleTabClick("all")}
              >
                {/* 全部 */}
                {t("total")}( {totalAmount} )
              </button>
              <button
                className={`tabButton ${
                  selectedTab === "rewards" ? "selected" : ""
                }`}
                onClick={() => handleTabClick("rewards")}
              >
                {/* 奖励历史 */}
                {t("rewarded_history")}( {rewardsAmount} )
              </button>
              <button
                className={`tabButton ${
                  selectedTab === "deductions" ? "selected" : ""
                }`}
                onClick={() => handleTabClick("deductions")}
              >
                {/* 扣分历史 */}
                {t("punished_history")}( {punishmentAmount} )
              </button>
            </div>

            {/**Events list */}
            <div className="eventListContainer">
              {events.length > 0 && (
                <ol>
                  {filteredEvents.map((item: Event, index: number) => (
                    <li key={index}>
                      <RewardPunishUserItem
                        event={item}
                        userType={"student"}
                        // onPressChange={onPressChange}
                        // onPressDeleteEvent={onPressDeleteEvent}
                      />
                    </li>
                  ))}
                </ol>
              )}
              {events.length === 0 && (
                <p>
                  ---
                  {/* 目前还没有记录 */}
                  {t("no_record_yet")}
                  ---
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/**reward punishment popup */}
      {showPop && (
        <div className="addHeroComponent">
          {" "}
          <div className="alertPop" style={{ padding: "3rem" }}>
            <SchoolRules type={typeNow} schoolId={schoolId} />
          </div>
          <AiOutlineCloseCircle
            size={"3rem"}
            color={"white"}
            style={{ marginTop: "1rem" }}
            onClick={handleClosePopup}
            className="iconHover"
          />
        </div>
      )}

      {/**一键举报 */}
      {showReportPop && (
        <div className="addHeroComponent">
          {" "}
          <div className="alertPop" style={{ minHeight: "85%" }}>
            {loading && !success && <Loading />}
            {!loading && !success && (
              <>
                {/**举报内容 */}
                <div
                  style={{
                    width: "60%",
                    marginTop: "3rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {error && <p style={{ color: "red" }}>{error}</p>}
                  <TexteraNomal
                    className="normalInputText"
                    placeholder=""
                    name={"other"}
                    handleChange={(e) => setReportContent(e)}
                    height={100}
                    label={`*${t("report_content")}`}
                  />
                </div>
                {/**证据图片 */}

                <p className="label">
                  *{/* 请上传证据 */}
                  {t("upload_evidence")}
                </p>
                {/**upload image*/}
                <div className="uploadIconContainer" onClick={handleImageClick}>
                  <div
                    style={{
                      width: IMG_SIZE,
                      height: IMG_SIZE,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(181, 160, 160, 0.377)",
                      borderRadius: "1rem",
                      marginTop: 5,
                    }}
                  >
                    {image !== "" ? (
                      <img
                        src={image}
                        style={{
                          width: IMG_SIZE,
                          height: IMG_SIZE,
                          borderRadius: "1rem",
                          objectFit: "cover",
                        }}
                        alt="School Icon"
                      />
                    ) : (
                      <img
                        src={"../../images/picture.png"}
                        style={{
                          width: IMG_SIZE,
                          height: IMG_SIZE,
                          borderRadius: "1rem",
                          objectFit: "cover",
                        }}
                        alt="Default Icon"
                      />
                    )}
                  </div>

                  <input
                    type="file"
                    ref={imageRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>

                {/**提交按钮 */}

                <button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    padding: "1rem 3rem",
                    marginTop: "3rem",
                    marginBottom: "3rem",
                  }}
                  onClick={handleUploadReport}
                >
                  {/* 提交 */}
                  {t("submit")}
                </button>
              </>
            )}
            {success && !loading && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p>
                  {/* 感谢你的举报，学校教职人员会尽快调查此事，请等待通知！ */}
                  {t("thank_you_for_reporting")}
                </p>
                <img
                  src={successImg}
                  style={{ width: "6rem", height: "6rem" }}
                />
              </div>
            )}
          </div>
          <AiOutlineCloseCircle
            size={"3rem"}
            color={"white"}
            style={{ marginTop: "1rem" }}
            onClick={() => {
              setShowReportPop(false);
              setSuccess(false);
              setImage("");
            }}
            className="iconHover"
          />
        </div>
      )}
    </CommonPageWithHeader>
  );
};

export default UserProfile;
