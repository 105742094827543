interface Props {
  action: string; // delete or update
  state: boolean; // switch button's statement
  //   type: string; // reward or punishment
  onPressCancel: () => void;
  onPressConfirm: () => void;
}

import { useState } from "react";
import { useTranslation } from "react-i18next";

const ConfirmSwitchStateComponent = ({
  action,
  state,
  onPressCancel,
  onPressConfirm,
}: Props) => {
  const { t } = useTranslation();
  const [message] = useState(
    !state
      ? "你确定要清除已经处理好的事件吗？"
      : "你确定你已经处理好该事件了吗？"
  );
  return (
    <div className="alertContainer">
      <div className="alertPop">
        {/**title */}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            padding: "1rem",
            alignItems: "center",
          }}
        >
          <p>{action === "delete" ? "你确定要删除这个event吗？" : message}</p>
        </div>
        {/**buttons */}
        <div
          style={{
            display: "flex",
            width: "80%",

            justifyContent: "space-around",
            marginTop: "2rem",
          }}
        >
          {/**Yes */}
          <div
            style={{
              width: "40%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <button
              type="button"
              className="btn btn-primary"
              onClick={onPressConfirm}
              style={{ width: "100%", padding: "1.2rem" }}
            >
              {t("yes")}
            </button>
          </div>

          {/**No */}
          <div
            style={{
              width: "40%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <button
              type="button"
              className="btn btn-outline-primary"
              style={{ color: "blue", width: "100%", padding: "1.2rem" }}
              onClick={onPressCancel}
            >
              {t("no")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmSwitchStateComponent;
