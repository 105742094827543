import TikItem from "../TikItem";
import { useTranslation } from "react-i18next";

interface Props {
  price?: number;
  edition: string;
  suitable: string;
  showPrice: boolean;
}

const SchoolPricingItem = ({ price, edition, showPrice }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="schoolPricingItemContainer">
      {/**price / year */}
      {showPrice && (
        <p className="priceYearP">
          {price}€/{t("pay_by_year")}
        </p>
      )}
      {!showPrice && <p className="priceYearP">€ ?/{t("pay_by_year")}</p>}
      {/**edition */}
      <p style={{ fontSize: "large", fontWeight: "bold" }}>{edition}</p>
      {/**suitable for how many teachers */}
      {
        <p style={{ textAlign: "center", margin: "1rem", fontSize: "1.1rem" }}>
          {t("able_to_add_teaching_staff")}
        </p>
      }
      {/**seperate line */}
      <div className="seperateLineForPricing" />
      {/**list items */}
      <p style={{ padding: "1rem" }}></p>
      {/* <TikItem content={"自定义本校的奖励行为"} /> */}
      {/* <TikItem content={t("customize_reward_behaviors_for_this_school")} /> */}
      {/* <TikItem content={"自定义本校的扣分行为"} /> */}
      {/* <TikItem content={t("customize_deduction_behaviors_for_this_school")} /> */}
      {/* <TikItem content={"添加惩罚事件"} /> */}
      <TikItem content={t("add_penalty_events")} />
      {/* <TikItem content={"添加奖励规则"} /> */}
      <TikItem content={t("add_reward_rules")} />
      {/* <TikItem
        content={"本校的学生和老师及其家人们可使用奖励商城中提供的奖励内容"}
      /> */}
      <TikItem
        content={t(
          "students_teachers_and_their_families_in_this_school_can_use_the_rewards_provided_in_the_reward_store"
        )}
      />
      {/**start button */}
      {/* <button
        type="button"
        className="btn btn-primary"
        style={{
          backgroundColor: "#5f7fff",
          padding: "1rem",
          margin: "1rem",
          width: "100%",
        }}
      >
        {showPrice ? "开始购买" : "联系我们"}
      </button> */}
    </div>
  );
};

export default SchoolPricingItem;
