import { BsFillTrash3Fill } from "react-icons/bs";
import { BiSolidEditAlt } from "react-icons/bi";
import formatJoinDate from "../utils/formatJoinDate";

interface Props {
  index: number;
  studentEmail: string;
  studentName: string;
  validDate: string;
  validYear: number;

  onEdit: (
    studentEmail: string,
    studentName: string,
    validYear: number
  ) => void;
  onDelete: (studentEmail: string, studentName: string) => void;
}

const EmailItem = ({
  index,
  studentEmail,
  studentName,
  validDate,
  validYear,

  onEdit,
  onDelete,
}: Props) => {
  return (
    <div className="emailContainer">
      <p className="numberContainer emailInnerContent">{index}</p>
      <p className="emailInnerContent"> {studentName}</p>
      <p className="emailInnerContent">{studentEmail}</p>
      {/**添加毕业时间 */}
      <p>{formatJoinDate(validDate)}</p>

      {/**edit / delete */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <BiSolidEditAlt
          color={"#807d77"}
          size={"2.5rem"}
          className="iconHover"
          style={{ marginRight: "1.5rem" }}
          onClick={() => onEdit(studentEmail, studentName, validYear)}
        />

        <BsFillTrash3Fill
          color={"#807d77"}
          size={"2.5rem"}
          className="iconHover"
          onClick={() => onDelete(studentEmail, studentName)}
        />
      </div>
    </div>
  );
};

export default EmailItem;
