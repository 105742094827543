import { Navigate } from "react-router-dom";

const ProtectInitUser = ({ children }: any) => {
  const token = localStorage.getItem("userSelf");
  if (!token) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectInitUser;
